import { Snackbar, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

const useStyles = makeStyles({
    root: {
        height: "100%",
    },
    content: {

        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minWidth: "371px",
        maxWidth: "450px",
        minHeight: "73px",
        padding: "18px 15px",
        background: "#FFFFFF",
        border: "0.886295px solid #BDBDBD",
        boxShadow: "0px 0px 3.54518px rgba(0, 0, 0, 0.16)",
        borderRadius: "10.6px 10.6355px 10.6355px 10.6355px",
    }
})

/**
 * 
 * @param {bool} props.open open status
 * @param {func} props.onClose onclose callback
 * @param {String} props.message text to display in Snackbar
 * @returns <Snackbar />
 */
const Messager = (props) => {
    const classes = useStyles();
    return <Snackbar 
        className={classes.root}
        open={props.open}
        autoHideDuration={1500}
        onClose={props.onClose}
        anchorOrigin={{
            vertical: "top",
            horizontal: "center"
         }}
    >
        <div className={classes.content}>
            <IconButton
                aria-label="close"
                onClick={() => {
                if (props.onClose) {
                    props.onClose();
                }
                }}
                sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <Typography variant="bodyText" sx={{margin: "35px 0"}}>{props.message}</Typography>
        </div>
    </Snackbar>
}

export default Messager;