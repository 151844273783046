import React from "react";

import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles({
  input: (props) => {
    return {
      // background: "#F1F2F3",
      width: props.width ? props.width : 279,
      // height: props.height ? props.height: 36,
      padding: "8px 12px !important",
    };
  },
});

export default function AeroTextInput(props) {
  const classes = useStyle(props);
  const { id, label, variant, placeholder, color } = props;
  return (
    <TextField
      id={id}
      label={label}
      variant={variant}
      className={classes.input}
      color={color}
      size={"small"}
      placeholder={placeholder}
    />
  );
}
