import React from "react";

import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import { alpha, styled } from "@mui/material/styles";

// Required parameter: labelName, width, placeholder
// Optional parameter: padding-bottom (default: padding-bottom: '15px'), type (default: type="text"), required (default: false)
const InputLabelCustomized = React.forwardRef((props, ref) => {
  const CustomizedInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      borderRadius: 5,
      type: props.type ? props.type : "text",
      position: "relative",
      backgroundColor: theme.palette.mode === "light" ? "#FFFFFF" : "#2b2b2b",
      border: "1px solid #ced4da",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
      height: "15px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  let paddingBottom = "15px"; // default padding bottom
  if (props["padding-bottom"]) {
    paddingBottom = props["padding-bottom"];
  }

  let type = "text"; // default type
  if (props.type) {
    type = props.type;
  }

  return (
    <>
      <FormControl
        variant="standard"
        sx={{
          width: props.width,
          padding: props.minify ? "0px" : "5px",
          "padding-bottom": props.minify? "0px": paddingBottom,
        }}
      >
        {!props.minify && <InputLabel
          shrink
          htmlFor={props.id}
          sx={{ position: "relative", textAlign: "left" }}
        >
          <Typography color={"#333333"}>{props.labelName}</Typography>
        </InputLabel>}
        <CustomizedInput
          ref={ref ? ref : null}
          type={type}
          required={props.required}
          placeholder={props.placeholder}
          defaultValue={props.value ? props.value : ""}
          className="customized-input"
          onChange={props.onChange? props.onChange : () => {}}
          id={props.id}
          key={props.key ? props.key : null}
        />
      </FormControl>
    </>
  );
});

export default InputLabelCustomized;
