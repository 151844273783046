import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { FormatListBulleted, MapOutlined } from "@mui/icons-material";
import { useMediaQuery, useTheme } from "@mui/material";
import { Button, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

import Banner from "../../../components/Banner";
import Dropdown from "../../../components/Dropdown";
import Messager from "../../../components/Messager";
import UploadMapPopup from "../../../components/UploadMapPopup";
import { DeviceContext, UserContext } from "../../../components/UserContext";
import { parseRows } from "../ParseData";
import DeviceMgmtHome from "../views/DeviceMgmtHome";
import MapView from "../views/MapView";

const useStyles = makeStyles({
  boldText: {
    fontWeight: "bold",
  },
});

// function QuickSearchToolbar(props) {
//   return (
//     <TextField
//       style={{ ...props.style }}
//       variant="standard"
//       value={props.value}
//       onChange={props.onChange}
//       placeholder="Search…"
//       InputProps={{
//         disableUnderline: true,
//         startAdornment: <SearchIcon fontSize="small" />,
//         endAdornment: (
//           <IconButton
//             title="Clear"
//             aria-label="Clear"
//             size="small"
//             style={{ visibility: props.value ? "visible" : "hidden" }}
//             onClick={props.clearSearch}
//           >
//             <ClearIcon fontSize="small" />
//           </IconButton>
//         ),
//       }}
//     />
//   );
// }

// QuickSearchToolbar.propTypes = {
//   clearSearch: PropTypes.func.isRequired,
//   onChange: PropTypes.func.isRequired,
//   value: PropTypes.string.isRequired,
// };

const DeviceMgmt = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const context = useContext(UserContext);
  const deviceContext = useContext(DeviceContext).raw;
  const history = useNavigate();
  const [unassignedRows, setunassignedRows] = useState([]);
  const [floorOptions, setFloorOptions] = useState([]);
  const [allLables, setAllLabels] = useState([]);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [uploadMapmModal, setUploadMapmModal] = useState(false);
  const [placedRows, setPlacedRows] = useState([]);
  const [filteredPlaceRows, setFilteredPlaceRows] = useState([]);
  const [curLocation, setLocation] = useState("All Location");
  const [curStatus, setStatus] = useState("all status"); // reserve for future using
  const [curLabel, setLabel] = useState("all labels"); // reserve for future using
  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState(<></>);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isIpadScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  useEffect(() => {
    let result = parseRows(
      curLocation,
      curStatus,
      curLabel,
      context.sensorMapInfoOverview,
      deviceContext
    );
    setPlacedRows(result[0]);
    setunassignedRows(result[1]);
    setFilteredPlaceRows(result[0]);

    let options = Object.keys(context.sensorMapInfoOverview);
    if (/map-view/.test(location.href) || /map-view/.test(location.pathname)) {
      const index = options.indexOf("Unassigned");
      if (index > -1) {
        options.splice(index, 1);
      }
      if (
        curLocation === "Unassigned" ||
        curLocation === "All Location" ||
        !curLocation ||
        !options.includes(curLocation)
      ) {
        if (options.length > 0) {
          setLocation(options[0]);
        } else {
          setLocation(null);
        }
      }
    }
    if (
      /list-view/.test(location.href) ||
      /list-view/.test(location.pathname)
    ) {
      const index = options.indexOf("Unassigned");
      if (index > -1) {
        let temp = options[options.length - 1];
        options[options.length - 1] = "Unassigned";
        options[index] = temp;
      }
      options.unshift("All Location");
      if (!curLocation) {
        setLocation("All Location");
      }
    }
    setFloorOptions(options);

    // let allLabels = getAllLabels(context.sensorMapInfoOverview, deviceContext);
    // allLabels.unshift("all labels");
    // setAllLabels(allLabels);
  }, [
    location.href,
    location.pathname,
    curLocation,
    // curStatus,
    // curLabel,
    context.sensorMapInfoOverview,
    deviceContext,
  ]);

  const handleAddMap = () => {
    setUploadMapmModal(true);
  };

  const bannerText = (
    <p>
      You have{" "}
      <span className={classes.boldText}>
        {unassignedRows.length} unassigned devices
      </span>
      , Click here to set them up.
    </p>
  );

  const bannerButton = (
    <Button
      onClick={() => {
        history("/dashboard/management/device/list-view");
        setLocation("Unassigned");
      }}
      style={{ fontWeight: "bold", textTransform: "none", cursor: "pointer" }}
    >
      Set Up
    </Button>
  );

  // const getInitValue = () => {
  //   let index = location.pathname.indexOf("/management");
  //   if (index !== -1 && location.pathname.substring(index + 11) !== "") {
  //     let latterURL = location.pathname.substring(index + 11);
  //     if (latterURL.indexOf("/device") !== -1) {
  //       return "/device";
  //     } else {
  //       return "/map";
  //     }
  //   } else {
  //     // default
  //     return "/device";
  //   }
  // };
  // const [value, setValue] = React.useState(getInitValue());
  // const [searchText, setSearchText] = React.useState("");
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const getFilteringInfo = () => {
    let result = [];
    if (curLocation !== "All Location") {
      result.push(curLocation);
    }
    if (curStatus !== "all status") {
      result.push(curStatus);
    }
    if (curLabel !== "all labels") {
      result.push(curLabel);
    }
    let resultString = result[0];
    for (let i = 1; i < result.length; i++) {
      resultString += ", " + result[i];
    }
    return resultString;
  };

  function showMessage(message) {
    setMessageOpen(true);
    setMessage(message);
  }

  return (
    // <div style={{ height: "calc(100vh - 70px)", width: "100%" }}>
    <div style={{ width: "100%" }}>
      {unassignedRows.length > 0 && (
        <Banner bannerText={bannerText} bannerButton={bannerButton} />
      )}
      <div
        style={{
          display: "flex",
          paddingBottom: "0.5vh",
          marginBottom: "25px",
          justifyContent: "flex-start",
          width: "100%",
          height: "36.5px",
        }}
      >
        <Grid width="250px" marginRight={{ xs: "10px", sm: "20px" }}>
          <Dropdown
            placeholder="Locations"
            selection={curLocation}
            width="auto"
            setSelection={setLocation}
            value="device-mgmt-all-location"
            options={floorOptions}
            extendable={true}
            extendWord={"Add a new map"}
            extendCallBack={handleAddMap}
          />
        </Grid>
        <UploadMapPopup
          showMessage={showMessage}
          externalOpenControl
          open={uploadMapmModal}
          setOpen={setUploadMapmModal}
          setImageUploaded={setImageUploaded}
          imageUploaded={imageUploaded}
          setLocation={setLocation}
        />

        {!(
          /map-view/.test(location.href) || /map-view/.test(location.pathname)
        ) ? (
          <Button
            variant="contained"
            size="medium"
            href="/#/dashboard/management/device/map-view"
            style={{
              textTransform: "none",
              marginLeft: "auto",
              width: "200px",
              height: "36px",
              whiteSpace: "nowrap",
            }}
          >
            <MapOutlined style={{ marginRight: "10px" }}></MapOutlined>
            {isSmallScreen ? "Map View" : " Switch to Map View"}
          </Button>
        ) : (
          <Button
            variant="contained"
            size="medium"
            href="/#/dashboard/management/device/list-view"
            style={{
              textTransform: "none",
              marginLeft: "auto",
              width: "200px",
              height: "36px",
              whiteSpace: "nowrap",
            }}
          >
            <FormatListBulleted
              style={{ marginRight: "10px" }}
            ></FormatListBulleted>
            {isSmallScreen ? "List View" : "Switch to List View"}
          </Button>
        )}
      </div>

      <Routes>
        <Route
          exact
          path={`device`}
          element={
            <DeviceMgmtHome
              setLocation={setLocation}
              curLocation={curLocation}
              curStatus={curStatus}
              curLabel={curLabel}
              filteredPlaceRows={filteredPlaceRows}
              setFilteredPlaceRows={setFilteredPlaceRows}
            />
            //  setTabValue={setValue} searchText={searchText} />
          }
        />
        <Route
          path={`device/list-view`}
          element={
            <DeviceMgmtHome
              setLocation={setLocation}
              curLocation={curLocation}
              curStatus={curStatus}
              curLabel={curLabel}
              filteredPlaceRows={filteredPlaceRows}
              setFilteredPlaceRows={setFilteredPlaceRows}
            />
            //  setTabValue={setValue} searchText={searchText} />
          }
        />
        {/* <Route
          path={`map`}
          element={<MapMgmt curLocation={curLocation} filteredPlaceRows={filteredPlaceRows} setFilteredPlaceR ows={setFilteredPlaceRows} />
//  setTabValue={setValue} searchText={searchText} />
}
        /> */}
        <Route
          path={`device/map-view`}
          element={
            <MapView
              curLocation={curLocation}
              curStatus={curStatus}
              curLabel={curLabel}
              setLocation={setLocation}
              filteredPlaceRows={filteredPlaceRows}
              setFilteredPlaceRows={setFilteredPlaceRows}
            />
            //  setTabValue={setValue} searchText={searchText} />
          }
        />
      </Routes>
      <Messager
        open={messageOpen}
        onClose={() => {
          setMessageOpen(false);
        }}
        message={message}
      />
    </div>
  );
};

export default DeviceMgmt;
