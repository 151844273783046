import React from "react";

import { makeStyles } from "@mui/styles";

import visionImg from "../../../util/landing/vision.png";
import VisionCircle from "../components/VisionCircle";

const useStyle = makeStyles({
  vision: {
    position: "relative",
    width: "100%",
    height: 552,
    backgroundImage: "url(" + visionImg + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
});

const visions = [
  {
    body: "Temperature",
    left: ((288 + 126 / 2) / 1440) * 100 + "%",
    top: ((69 + 126 / 2) / 552) * 100 + "%",
    diameter: 126,
    fontSize: 20,
    lineHeight: 24,
  },
  {
    body: "PM",
    left: ((959 + 96 / 2) / 1440) * 100 + "%",
    top: ((65 + 96 / 2) / 552) * 100 + "%",
    diameter: 96,
    fontSize: 30,
    lineHeight: 36,
  },
  {
    body: "Noise",
    left: ((117 + 117 / 2) / 1440) * 100 + "%",
    top: ((180 + 117 / 2) / 552) * 100 + "%",
    diameter: 117,
    fontSize: 30,
    lineHeight: 36,
  },
  {
    body: "Pressure",
    left: ((1111 + 127 / 2) / 1440) * 100 + "%",
    top: ((224 + 127 / 2) / 552) * 100 + "%",
    diameter: 127,
    fontSize: 30,
    lineHeight: 36,
  },
  {
    body: "CO2",
    left: ((303 + 96 / 2) / 1440) * 100 + "%",
    top: ((343 + 96 / 2) / 552) * 100 + "%",
    diameter: 96,
    fontSize: 30,
    lineHeight: 36,
  },
  {
    body: "Hazard Gas",
    left: ((983 + 128 / 2) / 1440) * 100 + "%",
    top: ((353 + 128 / 2) / 552) * 100 + "%",
    diameter: 128,
    fontSize: 30,
    lineHeight: 36,
  },
];
export default function Vision(props) {
  const classes = useStyle();
  return (
    <div className={classes.vision}>
      <VisionCircle
        width={420}
        height={420}
        left={"50%"}
        top={"50%"}
        title={"Our Vision"}
        padding={40}
        body={
          "Our all-in-one sensor platform includes sensors that measure environmental " +
          "parameters that matter to occupants  and use data-driven methods to improve your " +
          "indoor environment from every aspect."
        }
        fontSize={18}
        lineHeight={26}
      />
      {visions.map((vision) => {
        return (
          <VisionCircle
            width={vision.diameter}
            height={vision.diameter}
            left={vision.left}
            top={vision.top}
            body={vision.body}
            key={vision.body}
            fontSize={vision.fontSize}
            lineHeight={vision.lineHeight}
          />
        );
      })}
    </div>
  );
}
