import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Analytics, Auth } from "aws-amplify";

import { Grid } from "@mui/material";

import DashboardAuthenticator from "../components/DashboardAuthenticator";
import NavBar from "../components/NarBar/NavBar";
import { DeviceContext, UserContext } from "../components/UserContext";
import SettingView from "../features/setting/pages/Setting";
import Admin from "./admin/pages/Admin";
import DataAnalyticsView from "./data-analytics/pages/DataAnalytics";
import DeviceMgmt from "./device-management/pages/DeviceMgmt";
import HomeView from "./home/pages/Home";
import "./index.css";
import ActionCenterView from "./notification/pages/Notification";

const SOCKET_URL = "wss://fgqli1k9gf.execute-api.us-east-1.amazonaws.com/Prod?";

const Feature = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const HostURL = "https://b432d666jc.execute-api.us-east-1.amazonaws.com/Prod";
  const [userData, setUserData] = useState({});
  const [deviceHourlyData, setDeviceHourlyData] = useState({});
  const [floorHourlyAggregate, setFloorHourlyAggregate] = useState(null);
  const [layerToSensors, setLayerToSensors] = useState({});
  const [sensorObjects, setSensorObjects] = useState({});
  const [layerNameToMapUrl, setLayerNameToMapUrl] = useState({});

  const [loading, setLoading] = useState(true);
  const [fetch1Loading, setFetch1Loading] = useState(true);
  const [fetch2Loading, setFetch2Loading] = useState(true);
  const [fetch3Loading, setFetch3Loading] = useState(true);
  const [fetchNotificationLoading, setFetchNotificationLoading] =
    useState(true);
  const [fetchFloorHourlyAggregateLoading, setFloorHourlyAggregateLoading] =
    useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [deviceErrorMsg, setDeviceErrorMsg] = useState(null);
  const [sensorCount, setSensorCount] = useState(0);
  const [onlineSensors, setOnlineSensors] = useState({});
  const [notificationLogData, setNotificationLogData] = useState({});
  const [notificationSetting, setNotificationSetting] = useState(null);

  const [websocket, setWebsocket] = useState(null);

  // Establish the WebSocket connection when the component mounts
  useEffect(() => {
    if (user && user.attributes && user.attributes.email_verified && !loading) {
      const ws = new WebSocket(SOCKET_URL + "user=" + user.attributes.email);

      ws.onopen = (event) => {
        console.log("WebSocket connection opened:", event);
      };

      ws.onmessage = (event) => {
        const message = event.data;
        console.log("WebSocket received message:", message);
        const messageObject = JSON.parse(message);
        if (messageObject.action === "broadcast") {
          const pendingInsertData = JSON.parse(messageObject["data"]);
          setNotificationLogData((prevState) => {
            const newNotificationLogData = [
              ...prevState.results,
              pendingInsertData,
            ];
            return {
              count: newNotificationLogData.length,
              results: newNotificationLogData,
            };
          });
        }
        // Update your form state or handle the received message
      };

      ws.onclose = (event) => {
        console.log("WebSocket connection closed:", event);
        clearInterval(interval);
      };

      ws.onerror = (event) => {
        console.error("WebSocket error:", event);
      };

      setWebsocket(ws);

      const heartbeat = () => {
        if (ws.readyState === WebSocket.OPEN) {
          const message = { action: "sendmessage", data: "heartbeat" };
          ws.send(JSON.stringify(message)); // send the message to the server
        }
      };

      const interval = setInterval(heartbeat, 60000); // set the interval to send a message every 30 seconds

      // Clean up the WebSocket connection when the component unmounts
      return () => {
        if (ws) {
          ws.close();
          clearInterval(interval);
        }
      };
    }
  }, [user, loading]);

  function checkStatus(response) {
    if (response.ok) {
      return response;
    } else {
      setErrorMsg("Request Error: " + response.statusText);
    }
  }

  function checkFetchDeviceDataStatus(response) {
    if (response.ok) {
      return response;
    } else {
      setDeviceErrorMsg("Request Error: " + response.statusText);
    }
  }

  /**
   * fetch device data, current api is in Centry Daylight time
   * @param {array} expSensorAPIReturn sensorAPIReturn list of all online device
   * (current example device)
   * @param {string} HostURL host url to fetch from
   * @param {boolean} update if true, fetch most recent hour data and update context,
   * if not fetch most recent eight hour date
   */

  const setUserDataFun = (data) => {
    let result = {};

    let layerToSensorsTemp = {};
    for (let i = 0; i < data.length; i++) {
      let mapName = data[i]["map_name"];

      result[mapName] = data[i];
      let sensors = data[i]["sensors"];
      let deviceIds = [];
      for (let j = 0; j < sensors.length; j++) {
        let deviceId = Object.keys(sensors[j])[0];
        deviceIds.push(deviceId);
      }
      layerToSensorsTemp[mapName] = deviceIds;
    }
    setUserData(result);
    setLayerToSensors(layerToSensorsTemp);
  };
  // make auth page switch to the proper view, if logged in, swap into home page with saved user.

  useEffect(() => {
    setLoading(true);
    if (user && user.attributes && user.attributes.email_verified) {
      Auth.currentSession().catch((error) => {
        // cannot refresh token, require login again
        signOut();
      });

      let curDate = new Date();
      let eightHourPrev = new Date(curDate.getTime() - 1000 * 60 * 60 * 8);
      // eightHourPrev.setHours(eightHourPrev.getHours() - 7);
      let oneDayPrev = new Date(curDate.getTime() - 1000 * 60 * 60 * 24);
      // oneDayPrev.setHours(oneDayPrev.getUTCHours() - 24);
      let oneHourPrev = new Date(curDate.getTime() - 1000 * 60 * 60 * 1);
      // curDate = convertTZ(curDate, "UTC");
      // eightHourPrev = convertTZ(eightHourPrev, "UTC");
      // oneHourPrev = convertTZ(oneHourPrev, "UTC");
      // oneDayPrev = convertTZ(oneDayPrev, "UTC");

      const fetchEightHourDeviceData = (
        expSensorAPIReturn,
        HostURL,
        update
      ) => {
        // let curDate = new Date("December 01, 2022 00:07:00"); // TODO: remove it replace with current time
        if (update) {
          return Promise.all(
            expSensorAPIReturn.map((sensor) => {
              let sensorInfo = sensor;
              if (sensorInfo.online) {
                setOnlineSensors((o) => {
                  if (o && typeof o == "object") {
                    o[sensorInfo["particle_id"]] = sensorInfo;
                  }
                  return o;
                });
              }
              return fetch(
                `${HostURL}/device_to_db?device_id=${sensorInfo["particle_id"]}&` +
                  `start_year=${oneDayPrev.getUTCFullYear()}&start_month=${
                    oneDayPrev.getUTCMonth() + 1
                  }&` +
                  `start_day=${oneDayPrev.getUTCDate()}&start_hour=${oneDayPrev.getUTCHours()}&` +
                  `end_year=${curDate.getUTCFullYear()}&end_month=${
                    curDate.getUTCMonth() + 1
                  }&end_day=${curDate.getUTCDate()}&end_hour=${curDate.getUTCHours()}`
              )
                .then(checkFetchDeviceDataStatus)
                .then((data) => {
                  data.json();
                })
                .then((data) => {
                  setDeviceHourlyData((o) => {
                    o[sensorInfo["particle_id"]] = o[sensorInfo["particle_id"]]
                      .concat(data.results)
                      .slice(12);
                    return o;
                  });
                });
            })
          ).catch((error) => {
            console.error("Error fetching data: ", error);
            setDeviceErrorMsg(error.message);
          });
        } else {
          return Promise.all(
            expSensorAPIReturn.map((sensor) => {
              let sensorInfo = sensor;
              if (sensorInfo.online) {
                setOnlineSensors((o) => {
                  if (o && typeof o == "object") {
                    o[sensorInfo["particle_id"]] = sensorInfo;
                  }
                  return o;
                });
              }
              return fetch(
                `${HostURL}/device_to_db?device_id=${sensorInfo["particle_id"]}&` +
                  `start_year=${eightHourPrev.getUTCFullYear()}&start_month=${
                    eightHourPrev.getUTCMonth() + 1
                  }&` +
                  `start_day=${eightHourPrev.getUTCDate()}&start_hour=${eightHourPrev.getUTCHours()}&` +
                  `end_year=${curDate.getUTCFullYear()}&end_month=${
                    curDate.getUTCMonth() + 1
                  }&end_day=${curDate.getUTCDate()}&end_hour=${curDate.getUTCHours()}`
              )
                .then(checkFetchDeviceDataStatus)
                .then((data) => data.json())
                .then((data) => {
                  const dataString = JSON.stringify(data);
                  const celsiusToFahrenheight = dataString.replaceAll(
                    /"temperature":"([0-9]+.[0-9]+)"/g,
                    (match, celsius) => {
                      let fahrenheit = (celsius * 9) / 5 + 32;
                      return `"temperature": "${fahrenheit.toFixed(2)}"`;
                    }
                  );
                  const parsedData = JSON.parse(celsiusToFahrenheight);
                  setDeviceHourlyData((o) => {
                    o[sensorInfo["particle_id"]] = parsedData.results;
                    return o;
                  });
                });
            })
          ).catch((error) => {
            console.error("Error fetching data: ", error);
            setDeviceErrorMsg(error.message);
          });
        }
      };

      const fetchFloorHourlyAggregate = () => {
        // fetch(
        //   `${HostURL}/device_to_db_agg?start_year=${oneDayPrev.getUTCFullYear()}&start_month=${
        //       oneDayPrev.getUTCMonth()
        //     }&start_day=${eightHourPrev.getUTCDate()}&start_hour=${eightHourPrev.getUTCHours()}&` +
        //     `end_year=${curDate.getUTCFullYear()}&end_month=${
        //       curDate.getUTCMonth()
        //     }&end_day=${curDate.getUTCDate()}&end_hour=${curDate.getUTCHours()}`
        // )
        fetch(
          `${HostURL}/device_to_db_agg?start_year=${oneDayPrev.getUTCFullYear()}&start_month=${
            oneDayPrev.getUTCMonth() + 1
          }&start_day=${oneDayPrev.getUTCDate()}&start_hour=${
            oneDayPrev.getUTCHours() + 1
          }&` +
            `end_year=${curDate.getUTCFullYear()}&end_month=${
              curDate.getUTCMonth() + 1
            }&end_day=${curDate.getUTCDate()}&end_hour=${curDate.getUTCHours()}&user=${
              user.attributes.email
            }`
        )
          .then(checkFetchDeviceDataStatus)
          .then((data) => data.json())
          .then((data) => {
            const dataString = JSON.stringify(data);
            const celsiusToFahrenheight = dataString.replaceAll(
              /"temperature":([0-9]+.[0-9]+)/g,
              (match, celsius) => {
                let fahrenheit = (celsius * 9) / 5 + 32;
                return `"temperature": ${fahrenheit.toFixed(2)}`;
              }
            );
            const parsedData = JSON.parse(celsiusToFahrenheight);
            console.log("parsedData agg", parsedData);
            setFloorHourlyAggregateLoading(false);
            setFloorHourlyAggregate(parsedData.results);
          })
          .catch((error) => {
            console.error("Error fetching data: ", error);
            setDeviceErrorMsg(error.message);
          });
      };

      if (user !== undefined) {
        let userEmail = undefined;
        if (
          user.attributes !== undefined &&
          user.attributes.email !== undefined
        ) {
          userEmail = user.attributes.email;
        } else if (user.username !== undefined && user.username.includes("@")) {
          userEmail = user.username;
        }
        // userEmail = "jh846@uw.edu"; // TODO: delete
        // user.attributes.email = "zehuaz4@uw.edu"; // TODO: delete
        if (userEmail !== undefined) {
          setFetch1Loading(true);
          setFetch2Loading(true);
          setFetch3Loading(true);
          setFetchNotificationLoading(true);
          fetch(HostURL + "/sensor_all_info?user=" + userEmail)
            .then(checkStatus)
            .then((data) => data.json())
            .then((data) => {
              let collectedTime = Date.now();
              let dataResults = data.results;
              dataResults["collectedTime"] = collectedTime;
              setUserDataFun(dataResults);
            })
            .catch((error) => {
              console.log("Error fetching data: ", error.message);
              setErrorMsg(error.message);
            })
            .finally(() => {
              setFetch1Loading(false);
            });

          fetch(HostURL + "/label_sensor?user=" + userEmail)
            .then(checkStatus)
            .then((data) => data.json())
            .then((data) => {
              let newSensorObjects = {};
              Object.values(data["results"]).forEach((sensor) => {
                newSensorObjects[sensor["particle_id"]] = sensor;
              });
              let collectedTime = Date.now();
              newSensorObjects["collectedTime"] = collectedTime;
              setSensorObjects(newSensorObjects);
              return data;
            })
            .then((data) => {
              setSensorCount(data.count);
              return data;
            })
            .then((data) =>
              fetchEightHourDeviceData(
                Object.values(data.results),
                HostURL,
                false
              )
            )
            .catch((error) => {
              console.log("Error fetching data: ", error);
              setErrorMsg(error.message);
            })
            .finally(() => {
              setFetch2Loading(false);
            });

          function setLayerImageContext(imageDataParsed) {
            let results = {};
            for (let i = 0; i < imageDataParsed.results.length; i++) {
              results[imageDataParsed.results[i]["layer_name"]] =
                imageDataParsed.results[i]["background_image_url"];
            }
            delete results["Unassigned"];
            setLayerNameToMapUrl(results);
          }
          fetch(HostURL + "/indoor_map_layer?user=" + userEmail)
            .then(checkStatus)
            .then((data) => data.json())
            .then((data) => {
              setLayerImageContext(data);
            })
            .catch((error) => {
              console.log("Error fetching data: ", error);
              setErrorMsg((o) => o + error.message);
            })
            .finally(() => {
              setFetch3Loading(false);
            });

          // let currentTime = new Date();

          // // Get the current UTC year
          // let year = currentTime.getUTCFullYear();

          // // Get the current UTC month (starts from 0)
          // let month = currentTime.getUTCMonth() + 1;

          // // Get the current UTC date
          // let date = currentTime.getUTCDate();

          // // Get the current UTC hour
          // let hour = currentTime.getUTCHours();
          // let notificationURL =
          //   HostURL +
          //   `/notify?user=${userEmail}&end_year=${year}&end_month=${month}&end_day=${date}&end_hour=${hour}`;let curDate = new Date();
          let curDate = new Date();
          curDate = convertTZ(curDate, "UTC");
          let notificationURL =
            HostURL +
            `/notify?user=${userEmail}&end_year=${curDate.getUTCFullYear()}&end_month=${
              curDate.getUTCMonth() + 1
            }&end_day=${curDate.getUTCDate()}&end_hour=${curDate.getUTCHours()}`;
          // fetch notifications and settings
          fetch(HostURL + `/setting_query?user=${userEmail}`)
            .then((response) => {
              return response.json();
            })
            .then((response) => {
              setNotificationSetting(response["results"]["notification"]);
            })
            .then(() => {
              // after get the setting, query the notification record
              fetch(notificationURL)
                .then((response) => {
                  return response.json();
                })
                .then((response) => {
                  response["results"] = response["results"].map((item) => {
                    item["Class"] = parseInt(item["Class"].trim());
                    return item;
                  });
                  setNotificationLogData(response);
                })
                .finally(() => {
                  setFetchNotificationLoading(false);
                });
            });
        }
        fetchFloorHourlyAggregate();
      }
    }
  }, [user]);

  function convertTZ(date, tzString) {
    return new Date(
      (typeof date === "string" ? new Date(date) : date).toLocaleString(
        "en-US",
        { timeZone: tzString }
      )
    );
  }

  useEffect(() => {
    if (
      !fetch1Loading &&
      !fetch2Loading &&
      !fetch3Loading &&
      !fetchNotificationLoading &&
      !fetchFloorHourlyAggregateLoading
    ) {
      setLoading(false);
    }
  }, [
    fetch1Loading,
    fetch2Loading,
    fetch3Loading,
    fetchNotificationLoading,
    fetchFloorHourlyAggregateLoading,
  ]);

  const paramIds = [
    // "dp05",
    // "dp10",
    // "dp25",
    // "dp50",
    // "dp100",
    // "pm1Env",
    // "pm1p0Std",
    "pm2p5Env",
    // "pm2p5Std",
    "pm10p0Env",
    "dp03",
    "temperature",
    "humidity",
    // "pm10p0Std",
  ];
  const paramNames = [
    // "Dp 0.5",
    // "Dp 1.0",
    // "Dp 2.5",
    // "Dp 5.0",
    // "Dp 10.0",
    // "Env PM 1.0",
    // "Std PM 1.0",
    "PM 2.5",
    // "Std PM 2.5",
    "PM 10.0",
    "Dp 0.3",
    // "Std PM 10.0",
    "Temperature",
    "Humidity",
  ];
  const paramNamesTooltips = [
    // "Dp 0.5",
    // "Dp 1.0",
    // "Dp 2.5",
    // "Dp 5.0",
    // "Dp 10.0",
    // "Env PM 1.0",
    // "Std PM 1.0",
    <div>
      <h4>PM 2.5</h4>
      <p>
        Mass concentration of fine particles in the air that are{" "}
        <b>2.5 micrometers in diameter or smaller</b>.
      </p>
      <p>
        These particles are very small and can{" "}
        <b>
          penetrate deep into the lungs, potentially causing respiratory
          problems and other health issues.
        </b>
      </p>
    </div>,
    // "Std PM 2.5",
    <div>
      <h4>PM 10.0</h4>
      <p>
        Mass concentration of particles with a{" "}
        <b>diameter of 10 micrometers or less</b>
      </p>
      <p>
        These particles are still small enough to be inhaled but are larger than
        PM 2.5 and{" "}
        <b>tend to be filtered out more efficiently by the nose and throat</b>.
      </p>
    </div>,
    <div>
      <h4>Dp 0.3</h4>
      <p>
        Particle number concentration for particles{" "}
        <b>greater than 0.3 microns</b>.{" "}
      </p>
      <p>
        It refers to the{" "}
        <b>
          number of particles present in a given volume of air in particles per
          100 cubic centimeters (Particles/.1L)
        </b>
      </p>
    </div>,
    // "Std PM 10.0",
    <div>
      <h4>Temperature</h4>
      <p>
        {" "}
        Temperature and humidity are two critical factors in creating a
        comfortable and healthy indoor environment.
      </p>
    </div>,
    <div>
      <h4>Humidity</h4>
      <p>
        Temperature and humidity are two critical factors in creating a
        comfortable and healthy indoor environment.
      </p>
    </div>,
  ];
  const paramUnits = [
    // "particles/.1L",
    "μg/m³",
    "μg/m³",
    "particles/.1L",
    "°F",
    "%",
  ];
  // TODO: change threshold of dp03 and add humidity and temperature, current dp03 threshold is pm2.5 * 3000
  const thresholds = {
    pm2p5Env: [0, 12, 35.4],
    pm10p0Env: [0, 54, 154],
    dp03: [0, 36000, 106200],
    temperature: [0, 0, 0],
    humidity: [0, 0, 0],
  };
  const thresholdName = ["Good", "Moderate", "Unhealthy"];
  const thresholdColor = ["#73CF96", "#F2994A", "#EB5757"];

  return (
    <Grid container id="ancestor-container">
      <DashboardAuthenticator
        authProtectedContent={({ user }) => {
          return (
            <UserContext.Provider
              value={{
                loading: loading,
                error: errorMsg,
                paramIds: paramIds,
                paramNames: paramNames,
                paramNamesTooltips: paramNamesTooltips,
                paramUnits: paramUnits,
                userEmail: user.attributes.email,
                sensorCount: sensorCount,
                sensorMapInfoOverview: userData,
                layerNameToMapUrl: layerNameToMapUrl,
                layerToSensors: layerToSensors,
                sensorObjects: sensorObjects,
                setSensorObjects: setSensorObjects,
                onlineSensors: onlineSensors,
                threshold: thresholds,
                thresholdName: thresholdName,
                thresholdColor: thresholdColor,
                errorMsg: deviceErrorMsg,
                setErrorMsg: setErrorMsg,
                setSensorCount: setSensorCount,
                notificationSetting: notificationSetting,
                setNotificationSetting: setNotificationSetting,
                notificationLogData: notificationLogData,
                setOnlineSensors: setOnlineSensors,
                setLayerNameToMapUrl: setLayerNameToMapUrl,
                setUserData: setUserData,
                setLayerToSensors: setLayerToSensors,
              }}
            >
              <DeviceContext.Provider
                value={{
                  raw: deviceHourlyData,
                  agg: floorHourlyAggregate,
                }}
              >
                <Grid item xs={12}>
                  <NavBar />
                </Grid>
                <Grid
                  marginTop="72px"
                  item
                  container
                  xs={12}
                  paddingLeft={{ xs: "20px", sm: "56px" }}
                  paddingRight={{ xs: "20px", sm: "56px" }}
                  paddingTop={{ xs: "20px", sm: "27px" }}
                  style={{
                    backgroundColor: "#FAFAFB",
                    height: "fit-content",
                    paddingBottom: "5vh",
                    minHeight: "calc(100vh - 72px)",
                  }}
                >
                  <Routes>
                    <Route exact path="/" element={<HomeView />} />
                    <Route exact path="/home/*" element={<HomeView />} />
                    <Route
                      path="/data-analytics/*"
                      element={<DataAnalyticsView />}
                    />
                    <Route path="/management/*" element={<DeviceMgmt />} />
                    <Route
                      path="/notification/*"
                      element={<ActionCenterView />}
                    />
                    <Route path="/setting/*" element={<SettingView />} />
                    {user.signInUserSession.idToken &&
                      user.signInUserSession.idToken.payload &&
                      user.signInUserSession.idToken.payload[
                        "cognito:groups"
                      ] &&
                      user.signInUserSession.idToken.payload[
                        "cognito:groups"
                      ].includes("Dashboard_Admin") && (
                        <Route path="/admin/" element={<Admin />} />
                      )}
                    <Route
                      path="*"
                      element={<Navigate to="/dashboard/home/" replace />}
                    />
                  </Routes>
                </Grid>
              </DeviceContext.Provider>
            </UserContext.Provider>
          );
        }}
      />
    </Grid>
  );
};
export default Feature;
