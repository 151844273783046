import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  button: {
    height: "36px",
    "&.MuiButton-outlinedPrimary": {
      border: "1.5px solid",
    },
  },
  buttonLarge: {
    padding: "14px 53px !important",
    height: "52px",
    "&.MuiButton-outlinedPrimary": {
      border: "1.5px solid",
    },
  },
});

const AeroButton = (props) => {
  const classes = useStyles();
  const { variant, value, onClick, color, large } = props;
  return (
    <Button
      color={color ? color : "primary"}
      onClick={onClick}
      variant={variant}
      className={large ? classes.buttonLarge : classes.button}
      sx={{ ...props.style }}
    >
      {value}
    </Button>
  );
};

export default AeroButton;
