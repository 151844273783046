import React from "react";

import { Analytics } from "aws-amplify";

import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { SvgIcon, Tooltip, Typography, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";

/**
 * This is a customized dropdown component which can used for general purposes
 * props：
 *     init : initial value of this drop down
 *     placeholder: placeholder of this dropdown
 *     setSelection: callback function, need to accept the selected option
 *     setSelectionId: callback function, need to accept the index of the selection option
 *     options: array of string of the options
 *     styles: HTML style object to define the layout of the dropdown
 *     width: width of the dropdown component, can be defined separately from the style object
 *     selection: the selected option
 *     callback: additional callback function
 *     dropdownPurpose: For AWS Pinpoint analysis, a string to describe purpose of dropdown
 *     extendable: boolean value to note if there a button as the last option of the selection
 *     extendWord: the word of the button of last option.
 *     extendCallBack: the callback function of the button which is the last option
 * ...
 */
const Dropdown = React.forwardRef((props, ref) => {
  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      border: "1px solid #ABB0B5",
      borderTop: "none",
      borderRadius: "0px 0px 10px 10px",
      marginTop: "0px",
      minWidth: "140px",
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 10px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  let menuList = null;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [placeholder, setPlaceHolder] = React.useState(
    props.init
      ? props.placeholder
        ? props.placeholder + ": " + props.init
        : props.init
      : props.placeholder
      ? props.placeholder
      : ""
  );
  const [listWidth, setListWidth] = React.useState(0);
  const theme = useTheme();
  const open = Boolean(anchorEl);
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleClick = (e) => {
    document.querySelector(`#dropdown-button-${props.value}`).style[
      "border-radius"
    ] = "10px 10px 0px 0px";
    setAnchorEl(e.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
    document.querySelector(`#dropdown-button-${props.value}`).style[
      "border-radius"
    ] = "10px 10px 10px 10px";
    let option = e.target.textContent;
    if (option) {
      setPlaceHolder(
        (Boolean(props.placeholder) ? props.placeholder + ": " : "") + option
      );
      props.setSelection(option);
      if (props.dropdownPurpose) {
        Analytics.record({
          name: "Option Select",
          attributes: {
            purpose: props.dropdownPurpose,
            option: option,
          },
        });
      }
      if (props.setSelectionId) {
        props.setSelectionId(props.options.findIndex((x) => x === option));
      }
    }
    if (props.callback) {
      props.callback();
    }
  };

  const handleExtend = (e, callback) => {
    setAnchorEl(null);
    document.querySelector(`#dropdown-button-${props.value}`).style[
      "border-radius"
    ] = "10px 10px 10px 10px";
    if (callback) {
      callback();
    }
  };

  function DropdownArrowDownIcon(props) {
    return (
      <SvgIcon {...props}>
        <svg
          width="21"
          height="21"
          viewBox="-3 -3 21 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="none"
            d="M1 1L9 9L17 1"
            stroke="#BFBABE"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </SvgIcon>
    );
  }

  const getButtonWidth = () => {
    return (
      document.querySelector(`#dropdown-button-${props.value}`)?.offsetWidth - 2
    );
  };
  window.onresize = function () {
    if (menuList == null) {
      menuList = document.querySelectorAll(".MuiMenuItem-root");
    }
    for (let i = 0; i < menuList.length; i++) {
      menuList[i].style.width = getButtonWidth();
    }
  };

  const getButtonText = () => {
    let text = "";
    if (props.selection === undefined) {
      text = placeholder;
    } else {
      if (props.selection === null) {
        text = props.placeholder;
      } else {
        if (
          props.options === undefined ||
          (props.options !== undefined && props.options.length === 0)
        ) {
          text = "";
        } else {
          text = props.selection;
        }
      }
    }
    return (
      <Typography variant="input" style={{ fontWeight: "600" }}>
        {text}
      </Typography>
    );
  };

  const menuItem = (option) => {
    return (
      <MenuItem
        style={{ whiteSpace: "normal", heigh: "50px !important" }}
        heigh={50}
        divider={props.extendable}
        onClick={handleClose}
        disableRipple
        sx={{ width: getButtonWidth, height: "50px" }}
        option={option}
      >
        <Typography variant="subText" color={theme.palette.textPrimary.main}>
          {option}
        </Typography>
      </MenuItem>
    );
  };

  return (
    <Box
      id={`dropdown-box-${props.value}`}
      style={{
        width: props.width ? props.width : "100%",
        position: "relative",
        ...props.style,
      }}
    >
      <Button
        disabled={
          props.disabled ||
          props.options === undefined ||
          (props.options !== undefined && props.options.length === 0)
        }
        ref={ref ? ref : null}
        id={`dropdown-button-${props.value}`}
        aria-controls="demo-customized-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        disableFocusRipple
        disableRipple
        onClick={function (e) {
          handleClick(e);
        }}
        endIcon={<DropdownArrowDownIcon sx={{ justifySelf: "flex-end" }} />}
        sx={{
          background: "#FFFFFF",
          color: "#2E3134",
          textTransform: "none",
          minWidth: "142px",
          border: "1px solid #ABB0B5",
          borderRadius: "10px 10px 10px 10px",
          justifyContent: "space-between",
          width: "100%",
          height: "40px",
          position: "relative",
        }}
      >
        {getButtonText()}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onChange={handleClose}
        sx={{ marginTop: "0px" }}
      >
        {props.options?.map((option, key) => {
          if (option === props.options[props.options.length - 1]) {
            let element =
              props.tooltips && !isMobileScreen ? (
                <Tooltip
                  key={key}
                  title={props.tooltips[key]}
                  placement="right"
                  arrow
                >
                  {menuItem(option)}
                </Tooltip>
              ) : (
                menuItem(option)
              );
            return element;
          } else {
            let element =
              props.tooltips && !isMobileScreen ? (
                <Tooltip title={props.tooltips[key]} placement="right" arrow>
                  <MenuItem
                    key={key}
                    style={{ whiteSpace: "normal" }}
                    divider
                    onClick={handleClose}
                    disableRipple
                    sx={{ width: getButtonWidth, height: "50px" }}
                    option={option}
                  >
                    <Typography
                      variant="subText"
                      color={theme.palette.textPrimary.main}
                    >
                      {option}
                    </Typography>
                  </MenuItem>
                </Tooltip>
              ) : (
                <MenuItem
                  key={key}
                  style={{ whiteSpace: "normal" }}
                  divider
                  onClick={handleClose}
                  disableRipple
                  sx={{ width: getButtonWidth, height: "50px" }}
                  option={option}
                >
                  <Typography
                    variant="subText"
                    color={theme.palette.textPrimary.main}
                  >
                    {option}
                  </Typography>
                </MenuItem>
              );
            return element;
          }
        })}
        {props.extendable && props.options && (
          <MenuItem
            style={{
              whiteSpace: "normal",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={(e) => {
              handleExtend(
                e,
                props.extendCallBack ? props.extendCallBack : null
              );
            }}
            disableRipple
            sx={{ width: getButtonWidth, height: "50px" }}
            option={props.options[0]}
          >
            <Typography
              variant="h4"
              color={theme.palette.Updated_Tertiary.main}
            >
              {props.extendWord}
            </Typography>

            <AddIcon style={{ color: "#8977E4" }}></AddIcon>
          </MenuItem>
        )}
      </StyledMenu>
    </Box>
  );
});

export default Dropdown;
