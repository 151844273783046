import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState, useContext} from "react";
import AdminDeviceTable from "../components/AdminDeviceTable";
import { Auth } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { deleteSensor } from "../../../components/AccessDBGlobalFunctions";
import { UserContext } from "../../../components/UserContext.js";


const HostURL = "https://b432d666jc.execute-api.us-east-1.amazonaws.com/Prod";

function Admin(props) {
    const [adminData, setAdminData] = useState({}) ;
    const HostURL = "https://b432d666jc.execute-api.us-east-1.amazonaws.com/Prod";
    const {user} = useAuthenticator((context) => [context.user]);
		const [loading, setLoading] = useState(false);
    const context = useContext(UserContext);
    useEffect(() => {
      let active = true;
      (async () => {
          let page = 1;
          setLoading(true);
          await fetchData(page, {});
        })();
        return () => {
          active = false;
        };
    }, [user]);

    const fetchData = async (page, dataSet) => {
      fetch(`${HostURL}/admin_info?page=${page}`, 
        {
          method: "GET",
          headers: {
          Token: user.signInUserSession.accessToken.jwtToken,
          }
        })
      .then(response => response.json())
      .then(data => {
        if (data.results.length === 0) {
          setLoading(false);
          setAdminData(dataSet);
        } else {
          let newData = {...dataSet};
          for (let item of data['results']) {
            item['id'] = item['particle_id'];
            newData[item['particle_id']] = item;
          }
          return fetchData(page + 1, newData);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
    }

    const handleDelete = async (id) => {
      return deleteSensor([id], context)
        .then(() => setAdminData(oldData => {
          let newData = {...oldData};
          delete newData[id];
          return newData;
        }))
        .catch((error) => console.error(error));
    }
  
    return <div style={{width: "100%"}}>
      <AdminDeviceTable adminData={adminData} loading={loading} handleDelete={handleDelete}/>
    </div>
}

export default Admin