import React from "react";
import { Route, Routes } from "react-router-dom";

import Dashboard from "../views/Dashboard";
import UploadMap from "../views/UploadMap";

const HomeView = (props) => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <Dashboard setUrlValue={props.setUrlValue} loading={props.loading} />
        }
      ></Route>
      <Route exact path="/upload-map" element={<UploadMap />}></Route>
    </Routes>
  );
};
export default HomeView;
