import React from "react";

import { Grid, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";

const PaperCustomized = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  position: "relative",
  elevation: 2,
  width: "100%",
  color: theme.palette.text.secondary,
  // cursor: "pointer",
  backgroundColor: "#FCFCFC",
  height: "100%",
  boxSizing: "borderBox",
  background: "#FCFCFC",
  /* Border - Light */
  border: "1px solid #E0E2E5",
  /* Section Basic Shadow */
  boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
  borderRadius: "10px",
}));

export default function OverviewCard(props) {
  const theme = useTheme();

  return (
    <>
      <PaperCustomized
        onClick={props.clickCallback ? props.clickCallback : null}
        sx={props.sx ? props.sx : null}
      >
        <Grid
          style={{
            padding: "20px",
            paddingTop: "15px",
            paddingBottom: "15px",
            height: "100%",
            width: "100%",
            margin: "0px",
          }}
          container
          alignItems={"center"}
          columnSpacing={{ xs: 1 }}
        >
          <Grid
            item
            container
            width="36px"
            justifyContent={"center"}
            style={{ padding: "0px" }}
          >
            <Grid item width="100%" height="100%">
              <div
                class="overviewCardIcon"
                style={{
                  display: "flex",
                  borderRadius: "20px",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "36px",
                  height: "36px",
                  backgroundColor: props["background-color"],
                }}
              >
                {props.icon}
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction={"column"}
            xs
            style={{ paddingLeft: "15px" }}
          >
            <Grid item>{props.topTitle}</Grid>
            <Grid item>
              <Typography variant="h5" color={theme.palette.textPrimary.main}>
                {props.subTitle}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </PaperCustomized>
    </>
  );
}
