import React, { useState } from "react";

import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

import PopupModal from "../../../components/PopupModal";
import AllDeviceMapZoomLegend from "./AllDeviceMapZoomLegend";

const FullViewDeviceMapZoom = (props) => {
  const [zoomModalOpen, setZoomModalOpen] = useState(false);
  const buttonStyle = {
    color: "#989FA7",
    borderColor: "#989FA7",
    padding: "0",
    maxHeight: "30px",
    maxWidth: "30px",
    minWidth: "30px",
    minHeight: "30px",
  };

  const zoomPopUpBtn = (
    <Button
      style={buttonStyle}
      variant="outlined"
      aria-label="fullscreen"
      size="large"
    >
      <OpenInFullIcon
        onClick={() => {
          setZoomModalOpen(true);
        }}
      />
    </Button>
  );

  return (
    <PopupModal
      buttonPrompt=""
      title={"Map"}
      width="92vw"
      maxWidth="100vw"
      height="84vh"
      maxHeight="100vh"
      button={zoomPopUpBtn}
      content={
        <div style={{ height: "60vh" }}>
          <AllDeviceMapZoomLegend
            zoomModal={true}
            id={props.id}
            mapName={props.mapName}
            map={props.map}
            paramSelection={props.paramSelection}
          />
        </div>
      }
      modalOpen={zoomModalOpen}
      setModalOpen={setZoomModalOpen}
    />
  );
};
export default FullViewDeviceMapZoom;
