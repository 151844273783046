import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Authenticator,
  Button,
  Heading,
  Text,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Auth } from "aws-amplify";

import { Grid, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import authImage from "../util/auth/purpleBuilding.svg";
import { ReactComponent as ViolettLogo } from "../util/logo/loginLarge.svg";
import { ReactComponent as ViolettSmallLogo } from "../util/logo/loginSmall.svg";

const useStyles = makeStyles({
  authHeadFootContainer: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginBottom: "2vh",
  },
  ViolettLogoCreateAccount: {
    padding: 0,
    width: "7.7%",
    height: "4.95vh",
    marginRight: "10px",
  },
  ViolettLogo: {
    cursor: "pointer",
  },
});

function DashboardAuthenticator(props) {
  const classes = useStyles();
  const history = useNavigate();
  let location = useLocation();
  const [passwordInput, setPasswordInput] = useState();
  const { user, toSignIn, toSignUp } = useAuthenticator();

  useEffect(() => {
    if (/signIn/.test(location.href) || /signIn/.test(location.pathname)) {
      toSignIn();
    }
    if (/signUp/.test(location.href) || /signUp/.test(location.pathname)) {
      toSignUp();
    }
  }, []);
  // }, [location, toSignIn, toSignUp]);

  useEffect(() => {
    const ancestorContainer = document.getElementById("ancestor-container");
    if (!user) {
      ancestorContainer.style.display = "flex";
      ancestorContainer.style.justifyContent = "right";
      // ancestorContainer.style.backgroundImage = `url(${authImage})`;
      ancestorContainer.style.backgroundSize = "contain";
    } else {
      ancestorContainer.style.justifyContent = "center";
      ancestorContainer.style.backgroundImage = "none";
    }
  }, [user]);

  const formFields = {
    signIn: {
      username: {
        labelHidden: false,
        placeholder: "Enter your email here",
        isRequired: true,
        label: "Email",
      },
      password: {
        labelHidden: false,
        placeholder: "Enter your password here",
        isRequired: true,
        label: "Password",
      },
    },
    signUp: {
      email: {
        labelHidden: false,
        isRequired: true,
        placeholder: "Enter your email",
        label: "Email address",
      },
      password: {
        labelHidden: false,
        placeholder: "Enter your password here",
        isRequired: true,
        label: "Password",
      },
      confirm_password: {
        labelHidden: false,
        placeholder: "Please confirm your password",
        label: "Confirm password",
      },
    },
  };

  const components = {
    SignUp: {
      Header() {
        return (
          <div>
            <div className={classes.authHeadFootContainer}>
              <div
                className={classes.ViolettLogoCreateAccount}
                id="ViolettLogo-create-account"
                onClick={() => {
                  history("/");
                }}
              >
                <ViolettSmallLogo
                  onClick={() => {
                    window.location.href = "https://violettuv.com";
                  }}
                  className={classes.ViolettLogo}
                  width="100%"
                  height="100%"
                />
              </div>
              <Heading level={4}>Create New Account</Heading>
            </div>
          </div>
        );
      },
      Footer() {
        return (
          <div>
            <div className={classes.authHeadFootContainer}>
              <Typography variant="bodyText" color="#646872">
                Already have an account?{" "}
                <Link
                  fontWeight="normal"
                  size="small"
                  variation="link"
                  textDecoration="underline"
                  onClick={() => {
                    toSignIn();
                    // history("/dashboard/signIn");
                  }}
                >
                  Login
                </Link>
              </Typography>
            </div>
            <div className={classes.authHeadFootContainer}></div>
          </div>
        );
      },
    },
    SignIn: {
      FormFields() {
        return (
          <>
            <Authenticator.SignUp.FormFields />
          </>
        );
      },
      Header() {
        return (
          <div
            className={classes.authHeadFootContainer}
            onClick={() => {
              history("/");
            }}
          >
            <ViolettLogo
              className={classes.ViolettLogo}
              onClick={() => {
                window.location.href = "https://violettuv.com";
              }}
            />
          </div>
        );
      },
      Footer() {
        const { toResetPassword } = useAuthenticator();
        return (
          <div>
            <div className={classes.authHeadFootContainer}>
              <Button
                fontWeight="bold"
                size="normal"
                variation="default"
                onClick={() => {
                  toSignUp();
                  // history("/dashboard/signUp");
                }}
                width="50%"
              >
                Create Account
              </Button>
            </div>
            <div className={classes.authHeadFootContainer}></div>
            <div className={classes.authHeadFootContainer}>
              <Link
                textDecoration="underline"
                fontWeight="normal"
                size="small"
                onClick={toResetPassword}
              >
                Forgot Password?
              </Link>
            </div>
          </div>
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        return (
          <div
            className={classes.ViolettLogoCreateAccount}
            id="ViolettLogo-create-account"
            onClick={() => {
              history("/");
            }}
          >
            <ViolettSmallLogo
              onClick={() => {
                window.location.href = "https://violettuv.com";
              }}
              className={classes.ViolettLogo}
              width="100%"
              height="100%"
            />
          </div>
        );
      },
    },
    SetupTOTP: {
      Header() {
        return (
          <div
            className={classes.ViolettLogoCreateAccount}
            id="ViolettLogo-create-account"
            onClick={() => {
              history("/");
            }}
          >
            <ViolettSmallLogo
              className={classes.ViolettLogo}
              width="100%"
              height="100%"
            />
          </div>
        );
      },
    },
    ConfirmSignIn: {
      Header() {
        return (
          <div
            className={classes.ViolettLogoCreateAccount}
            id="ViolettLogo-create-account"
            onClick={() => {
              history("/");
            }}
          >
            <ViolettSmallLogo
              className={classes.ViolettLogo}
              width="100%"
              height="100%"
            />
          </div>
        );
      },
    },
    ConfirmResetPassword: {
      Header() {
        return (
          <div
            className={classes.ViolettLogoCreateAccount}
            id="ViolettLogo-create-account"
            onClick={() => {
              history("/");
            }}
          >
            <ViolettSmallLogo
              className={classes.ViolettLogo}
              width="100%"
              height="100%"
            />
          </div>
        );
      },
    },
    ResetPassword: {
      Header() {
        return (
          <div>
            <div
              className={classes.ViolettLogoCreateAccount}
              id="ViolettLogo-create-account"
              onClick={() => {
                history("/");
              }}
            >
              <ViolettSmallLogo
                onClick={() => {
                  window.location.href = "https://violettuv.com";
                }}
                className={classes.ViolettLogo}
                width="100%"
                height="100%"
              />
            </div>
            <Heading level={4} paddingBottom={"6.4vh"}>
              Forgot Password?
            </Heading>
            <Text>
              Enter your registered email address below to receive a password
              reset code.
            </Text>
          </div>
        );
      },
    },
  };

  const services = {
    async handleSignUp(formData) {
      let { username, password, attributes } = formData;
      history("/dashboard/home", { replace: true });
      return Auth.signUp({
        username,
        password,
        attributes,
      });
    },
    async handleConfirmSignUp(formData) {
      let { username, code } = formData;
      await Auth.confirmSignUp(username, code);
      history(0);
    },

    async handleSignIn(formData) {
      let { username, password } = formData;
      history("/dashboard/home", { replace: true });
      return Auth.signIn({
        username,
        password,
      });
    },
  };

  return (
    <Grid container>
      <Grid
        item
        xs={0}
        md={user ? 0 : 4.8}
        style={{
          backgroundImage: `url(${authImage})`,
          backgroundSize: "cover",
        }}
      ></Grid>
      <Grid item xs={12} md={user ? 12 : 7.2}>
        <Authenticator
          services={services}
          formFields={formFields}
          initialState="signIn"
          components={components}
        >
          {props.authProtectedContent}
        </Authenticator>
      </Grid>
    </Grid>
  );
}

export default DashboardAuthenticator;
