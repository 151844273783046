import React, { useState } from "react";

import { Grid } from "@mui/material";

import AlertAndActionItems from "./AlertAndActionItems";
import Overview from "./Overview";
import RealTimeStatusUpdates from "./RealTimeStatusUpdates";

export default function Dashboard(props) {
  const [paramSelection, setParamSelection] = useState("PM 2.5");

  return (
    <Grid item container minHeight="800px">
      <Grid
        item
        container
        height={{ lg: "calc(100% - 56px)", xs: "fit-content" }}
      >
        <Grid
          container
          item
          xs={12}
          lg={8.5}
          paddingRight={{ lg: "24px", xs: "0" }}
          height="100%"
        >
          <Grid
            item
            container
            style={{
              height: "20%",
              maxHeight: "150px",
            }}
          >
            <Overview paramSelection={paramSelection} />
          </Grid>
          <Grid
            item
            container
            style={{
              height: "80%",
              minHeight: "calc(100% - 150px)",
            }}
          >
            <RealTimeStatusUpdates
              paramSelection={paramSelection}
              setParamSelection={setParamSelection}
              setUrlValue={props.setUrlValue}
              loading={props.loading}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} lg={3.5}>
          <Grid container item>
            <AlertAndActionItems login="true" setUrlValue={props.setUrlValue} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
