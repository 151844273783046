const HostURL = "https://b432d666jc.execute-api.us-east-1.amazonaws.com/Prod";

function checkStatus(response) {
  if (response.ok) {
    return response;
  } else {
    throw Error("Request Error: " + response.statusText);
  }
}

export function deleteDeviceInDB(id, context) {
  fetch(HostURL + "/sensor", {
    method: "DELETE",
    body: JSON.stringify({
      particle_id: id,
    }),
  })
    .then(checkStatus)
    .then(() => updateContextAfterDeleteDeviceInDB(id, context))
    .catch((error) => console.error("Error delete device: ", error));
}

const updateContextAfterDeleteDeviceInDB = (deviceId, context) => {
  let floor = "";
  let layerToSensors = JSON.parse(JSON.stringify(context.layerToSensors));
  let floors = Object.keys(layerToSensors);
  for (let i = 0; i < floors.length; i++) {
    if (layerToSensors[floors[i]].includes(deviceId)) {
      floor = floors[i];
      break;
    }
  }
  let index = layerToSensors[floor].indexOf(deviceId);
  layerToSensors[floor].splice(index, 1);
  context.setLayerToSensors(layerToSensors);

  let sensorMapInfoOverview = JSON.parse(
    JSON.stringify(context.sensorMapInfoOverview)
  );
  let sensors = sensorMapInfoOverview[floor];
  if (sensors) {
    sensors = sensors["sensors"];
    for (let i = 0; i < sensors.length; i++) {
      if (Object.keys(sensors[i])[0] === deviceId) {
        sensorMapInfoOverview[floor]["sensors"].splice(i, 1);
        break;
      }
    }
  }
  context.setUserData(sensorMapInfoOverview);
};

const updateContextAfterUpdateMapInDB = (
  mapName,
  newMapName,
  newImageUrl,
  response,
  context,
  setLocation
) => {
  let layerNameToMapUrl = JSON.parse(
    JSON.stringify({ ...context.layerNameToMapUrl })
  );
  let layerToSensors = JSON.parse(
    JSON.stringify({ ...context.layerToSensors })
  );
  let sensorMapInfoOverview = JSON.parse(
    JSON.stringify({ ...context.sensorMapInfoOverview })
  );

  layerNameToMapUrl[newMapName] = newImageUrl;
  layerToSensors[newMapName] = layerToSensors[mapName];
  sensorMapInfoOverview[newMapName] = {
    ...context.sensorMapInfoOverview[mapName],
    map_name: newMapName,
    background_name: newImageUrl,
  };

  if (mapName !== newMapName) {
    delete layerNameToMapUrl[mapName];
    delete layerToSensors[mapName];
    delete sensorMapInfoOverview[mapName];
  }

  context.setLayerNameToMapUrl(layerNameToMapUrl);
  context.setLayerToSensors(layerToSensors);

  context.setUserData(sensorMapInfoOverview);
  if (setLocation) {
    setLocation(newMapName);
  }
  return response;
};

export function updateMapInDB(
  imageName,
  mapName,
  newMapName,
  context,
  setLocation
) {
  let newImageUrl = context.layerNameToMapUrl[mapName]; //don't update if no new iamge upload
  if (imageName) {
    newImageUrl = `https://violett-dashboard-userfile162811-staging.s3.amazonaws.com/public/UsersData/${context.userEmail.replace(
      "@",
      "%40"
    )}/indoorMapBackground/${imageName.replaceAll(" ", "+")}`;
  }
  return fetch(HostURL + "/indoor_map_layer", {
    method: "PATCH",
    body: JSON.stringify({
      user: context.userEmail,
      new_background_image_url: newImageUrl,
      layer_name: mapName.replaceAll("'", "''"),
      new_layer_name:
        newMapName !== mapName
          ? newMapName.replaceAll("'", "''")
          : mapName.replaceAll("'", "''"), // update name if have new name
    }),
  })
    .then(checkStatus)
    .then((response) =>
      updateContextAfterUpdateMapInDB(
        mapName,
        newMapName !== mapName ? newMapName : mapName,
        newImageUrl,
        response,
        context,
        setLocation
      )
    )
    .catch((error) => console.error("Error update map image: ", error));
}

const updateContextAfterPostNewMapToDB = (floor, newImageUrl, context) => {
  let layerNameToMapUrl = JSON.parse(JSON.stringify(context.layerNameToMapUrl));
  layerNameToMapUrl[floor] = newImageUrl;
  context.setLayerNameToMapUrl(layerNameToMapUrl);

  let sensorMapInfoOverview = JSON.parse(
    JSON.stringify(context.sensorMapInfoOverview)
  );
  sensorMapInfoOverview[floor] = {
    map_name: floor,
    background_name: newImageUrl,
    sensors: [],
  };
  context.setUserData(sensorMapInfoOverview);

  let layerToSensors = JSON.parse(JSON.stringify(context.layerToSensors));
  layerToSensors[floor] = [];
  context.setLayerToSensors(layerToSensors);
};

export function postNewMapToDB(imageName, layerName, context) {
  let newImageUrl = `https://violett-dashboard-userfile162811-staging.s3.amazonaws.com/public/UsersData/${context.userEmail.replace(
    "@",
    "%40"
  )}/indoorMapBackground/${imageName.replaceAll(" ", "+")}`;
  return fetch(HostURL + "/indoor_map_layer", {
    method: "POST",
    body: JSON.stringify({
      user: context.userEmail,
      background_image_url: newImageUrl,
      layer_name: layerName.replaceAll("'", "''"),
    }),
  })
    .then(checkStatus)
    .then(() =>
      updateContextAfterPostNewMapToDB(layerName, newImageUrl, context)
    )
    .catch((error) => console.error("Error inserting image: ", error));
}

export function insertSensorToDBWithoutLocation(
  deviceId,
  deviceName,
  productId,
  context
) {
  return fetch(HostURL + "/sensor", {
    method: "POST",
    body: JSON.stringify({
      user: context.userEmail,
      sensors: [
        {
          particle_id: deviceId, // e00fce684f441347666803db
          sensor_name: deviceName.replaceAll("'", "''"), // beta-06
          particle_product_id: productId,
        },
      ],
    }),
  })
    .then(checkStatus)
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      updateContextAfterInsertDeviceToDBWithLocation(
        "Unassigned",
        deviceId,
        deviceName,
        productId,
        null,
        context,
        response
      );
    })
    .catch((error) => {
      console.error(error);
    });
}

const updateContextAfterInsertDeviceToDBWithLocation = (
  layer,
  deviceId,
  deviceName,
  productId,
  finalPosition,
  context,
  response
) => {
  let sensorMapInfoOverview = { ...context.sensorMapInfoOverview };
  let item = {};
  let auto;
  let speed;
  let lock;
  if (response.result["online"]) {
    auto = response.result["auto"];
    speed = response.result["speed"];
    lock = response.result["lock"];
  }
  item[deviceId] = {
    particle_id: deviceId,
    sensor_name: deviceName,
    labels: [],
    auto: auto,
    speed: speed,
    lock: lock,
    particle_product_id: productId,
    online: response.result["online"],
    indoor_position: finalPosition
      ? finalPosition
      : {
          x_coordinate: -50,
          y_coordinate: 50,
        },
  };
  sensorMapInfoOverview[layer]["sensors"].push(item);
  context.setUserData(sensorMapInfoOverview);

  let onlineSensors = { ...context.onlineSensors };
  onlineSensors[deviceId] = item[deviceId];
  context.setOnlineSensors(onlineSensors);

  let layerToSensors = JSON.parse(JSON.stringify(context.layerToSensors));
  layerToSensors[layer].push(deviceId);
  context.setLayerToSensors(layerToSensors);

  let sensorObjects = { ...context.sensorObjects };
  sensorObjects[deviceId] = item[deviceId];
  context.setSensorObjects(sensorObjects);
};

export function insertSensorToDBWithLocation(
  layer,
  deviceId,
  deviceName,
  productId,
  finalPosition,
  context
) {
  return fetch(HostURL + "/sensor", {
    method: "POST",
    body: JSON.stringify({
      user: context.userEmail,
      sensors: [
        {
          particle_id: deviceId, // e00fce684f441347666803db
          particle_product_id: productId, // find on particle
          sensor_name: deviceName.replaceAll("'", "''"), // beta-06
        },
      ],
    }),
  })
    .then(checkStatus)
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      addDeviceToLayer(deviceId, layer, context);
      return response;
    })
    .then((response) => {
      updatePosition(deviceId, finalPosition, context);
      return response;
    })
    .then((response) =>
      updateContextAfterInsertDeviceToDBWithLocation(
        layer,
        deviceId,
        deviceName,
        productId,
        finalPosition,
        context,
        response
      )
    )
    .catch((error) => {
      console.error("Fail to insert device to db");
    });
}

// indoor position will be initialized with (0, 0) in db when the sensor is inserted.
// So no need to insert new indoor position. Instead of that, just update it.
export function updatePosition(deviceId, finalPosition, context, update) {
  let payload = {};
  payload[deviceId] = finalPosition;
  fetch(HostURL + "/indoor_position_update", {
    method: "POST",
    body: JSON.stringify(payload),
  })
    .then(checkStatus)
    .then(() => {
      if (update) {
        updateContextAfterUpdateIndoorPosition(
          deviceId,
          finalPosition,
          context
        );
      }
    })
    .catch((error) => {
      console.error("Fail to update Indoor position", error);
    });
}

// only using no non-init case. Init case can not run in correct order
// use to setState is async functions. There will not be a sensor object
// in the context when the sensor is initialized.
function updateContextAfterUpdateIndoorPosition(id, finalPosition, context) {
  let newSensorObjects = { ...context.sensorObjects };
  newSensorObjects[id]["indoor_position"] = finalPosition;
  context.setSensorObjects(newSensorObjects);

  let floor = "";
  let layerToSensors = context.layerToSensors;
  let floors = Object.keys(layerToSensors);
  for (let i = 0; i < floors.length; i++) {
    if (layerToSensors[floors[i]].includes(id)) {
      floor = floors[i];
      break;
    }
  }

  let sensorMapInfoOverview = JSON.parse(
    JSON.stringify(context.sensorMapInfoOverview)
  );
  let sensors = sensorMapInfoOverview[floor];
  if (sensors) {
    sensors = sensors["sensors"];
    for (let i = 0; i < sensors.length; i++) {
      if (Object.keys(sensors[i])[0] === id) {
        sensorMapInfoOverview[floor]["sensors"][i]["indoor_position"] =
          finalPosition;
        break;
      }
    }
  }
  context.setUserData(sensorMapInfoOverview);
}

export function addDeviceToLayer(deviceId, layer, context) {
  return fetch(HostURL + "/indoor_map_layer_sensor", {
    method: "POST",
    body: JSON.stringify({
      user: context.userEmail,
      particle_id: deviceId,
      layer_name: layer.replaceAll("'", "''"),
    }),
  })
    .then(checkStatus)
    .catch((error) => {
      console.error("Fail to add device to layer", error);
    });
}

export function updateContextAfterUpdateSensorLayer(
  deviceId,
  previousLayer,
  layer,
  context
) {
  // layerToSensors
  let newLayerToSensors = { ...context.layerToSensors };
  const index = newLayerToSensors[previousLayer].indexOf(deviceId);
  if (index > -1) {
    newLayerToSensors[previousLayer].splice(index, 1);
  }
  newLayerToSensors[layer].push(deviceId);
  context.setLayerToSensors(newLayerToSensors);
  // sensorMapInfoOverview
  let newSensorMapInfoOverview = { ...context.sensorMapInfoOverview };
  let insertObject = newSensorMapInfoOverview[previousLayer]["sensors"].filter(
    (item) => Object.keys(item)[0] === deviceId
  )[0];
  newSensorMapInfoOverview[previousLayer]["sensors"] = newSensorMapInfoOverview[
    previousLayer
  ]["sensors"].filter((item) => Object.keys(item)[0] !== deviceId);
  newSensorMapInfoOverview[layer]["sensors"].push(insertObject);
  context.setUserData(newSensorMapInfoOverview);
}

const updateContextAfterUpdateSensor = (id, name, context) => {
  let floor = "";
  let layerToSensors = context.layerToSensors;
  let floors = Object.keys(layerToSensors);
  for (let i = 0; i < floors.length; i++) {
    if (layerToSensors[floors[i]].includes(id)) {
      floor = floors[i];
      break;
    }
  }

  let newSensorObjects = { ...context.sensorObjects };
  newSensorObjects[id]["sensor_name"] = name;
  context.setSensorObjects(newSensorObjects);

  let sensorMapInfoOverview = JSON.parse(
    JSON.stringify(context.sensorMapInfoOverview)
  );

  let sensors = sensorMapInfoOverview[floor];
  if (sensors) {
    sensors = sensors["sensors"];
    for (let i = 0; i < sensors.length; i++) {
      if (Object.keys(sensors[i])[0] === id) {
        sensorMapInfoOverview[floor]["sensors"][i][id]["sensor_name"] = name;
        break;
      }
    }
  }
  context.setUserData(sensorMapInfoOverview);
  return { ...context, sensorMapInfoOverview };
};

export async function updateSensor(deviceId, productID, deviceName, context) {
  return fetch(HostURL + "/sensor", {
    method: "PATCH",
    body: JSON.stringify({
      sensor: {
        particle_id: deviceId,
        particle_product_id: productID,
        sensor_name: deviceName.replaceAll("'", "''"),
      },
    }),
  })
    .then(checkStatus)
    .then(() => {
      let newContext = updateContextAfterUpdateSensor(
        deviceId,
        deviceName,
        context
      );
      return newContext;
    })
    .catch((error) => console.error("Error delete device: ", error));
}

const updateContextAfterDeleteSensor = (ids, context) => {
  let layerToSensors = context.layerToSensors;
  let sensorMapInfoOverview = JSON.parse(
    JSON.stringify(context.sensorMapInfoOverview)
  );
  let newSensorObjects = { ...context.sensorObjects };
  let newOnlineSensors = { ...context.onlineSensors };

  ids.forEach((id) => {
    delete newSensorObjects[id];
    delete newOnlineSensors[id];
    Object.keys(layerToSensors).forEach((floor) => {
      if (layerToSensors[floor].includes(id)) {
        let index = layerToSensors[floor].indexOf(id);
        layerToSensors[floor].splice(index, 1);
        let sensors = sensorMapInfoOverview[floor];
        if (sensors) {
          sensors = sensors["sensors"];
          for (let i = 0; i < sensors.length; i++) {
            if (Object.keys(sensors[i])[0] === id) {
              sensorMapInfoOverview[floor]["sensors"].splice(i, 1);
              break;
            }
          }
        }
      }
    });
  });

  context.setOnlineSensors(newOnlineSensors);
  context.setLayerToSensors(layerToSensors);
  context.setUserData(sensorMapInfoOverview);
  context.setSensorObjects(newSensorObjects);
  return {
    onlineSensors: newOnlineSensors,
    layerToSensors: layerToSensors,
    sensorMapInfoOverview: sensorMapInfoOverview,
    sensorObjects: newSensorObjects,
    ...context,
  };
};

export async function deleteSensor(deviceIds, context) {
  return Promise.all(
    deviceIds.map((deviceID) => {
      return fetch(HostURL + "/sensor", {
        method: "DELETE",
        body: JSON.stringify({
          particle_id: deviceID,
        }),
      });
    })
  )
    .then(() => updateContextAfterDeleteSensor(deviceIds, context))
    .catch((error) => console.error("Error delete device: ", error));
  // return updateContextAfterDeleteSensor(deviceIds, context);
}

export function updateContextAfterDeleteMap(floor, setLocation, context) {
  let layerNameToMapUrl = JSON.parse(JSON.stringify(context.layerNameToMapUrl));
  delete layerNameToMapUrl[floor];
  context.setLayerNameToMapUrl(layerNameToMapUrl);
  let floors = Object.keys(layerNameToMapUrl);
  if (floors.length < 1) {
    setLocation(null);
  } else {
    setLocation(floors[0]);
  }

  let sensorMapInfoOverview = JSON.parse(
    JSON.stringify(context.sensorMapInfoOverview)
  );
  sensorMapInfoOverview["Unassigned"]["sensors"] = [
    ...sensorMapInfoOverview["Unassigned"]["sensors"],
    ...sensorMapInfoOverview[floor]["sensors"],
  ];
  delete sensorMapInfoOverview[floor];
  context.setUserData(sensorMapInfoOverview);

  let layerToSensors = JSON.parse(JSON.stringify(context.layerToSensors));
  layerToSensors["Unassigned"] = [
    ...layerToSensors["Unassigned"],
    ...layerToSensors[floor],
  ];
  delete layerToSensors[floor];
  context.setLayerToSensors(layerToSensors);
}

export function deleteMap(floor, setLocation, context) {
  fetch(HostURL + "/indoor_map_layer", {
    method: "DELETE",
    body: JSON.stringify({
      user: context.userEmail,
      layer_name: floor.replaceAll("'", "''"),
    }),
  })
    .then(checkStatus)
    .then(() => updateContextAfterDeleteMap(floor, setLocation, context))
    .catch((error) => console.error("Error delete map image: ", error));
}

export function deleteNotification(device_id, time) {
  fetch(HostURL + "/notify", {
    method: "DELETE",
    body: JSON.stringify({
      device_id: device_id,
      time: time,
    }),
  })
    .then(checkStatus)
    .catch((error) =>
      console.error("Error delete notification record: ", error)
    );
}

export function updateNotificationSetting(user, notification) {
  fetch(HostURL + "/setting_query", {
    method: "POST",
    body: JSON.stringify({
      user: user,
      notification: notification,
    }),
  })
    .then(checkStatus)
    .catch((error) =>
      console.error("Error delete notification record: ", error)
    );
}

export function batchRemoteControl(deviceIdList, ctrObj, context) {
  let requestList = deviceIdList.map((deviceId) => {
    let productID = context.sensorObjects[deviceId]["particle_product_id"];
    return fetch(HostURL + "/sensor_setting", {
      method: "POST",
      body: JSON.stringify({
        device_id: deviceId,
        product_id: productID,
        functions: ctrObj,
      }),
    })
      .then((response) => {
        checkStatus(response);
        return deviceId;
      })
      .catch((error) =>
        console.error("Error delete notification record: ", error)
      );
  });
  return Promise.all(requestList).then((successIdList) => {
    updateContextAfterBatchUpdateSensorSetting(successIdList, ctrObj, context);
  });
}

export function updateRemoteControl(device_id, ctrObj, context, setLocation) {
  let productID = context.sensorObjects[device_id]["particle_product_id"];
  return fetch(HostURL + "/sensor_setting", {
    method: "POST",
    body: JSON.stringify({
      device_id: device_id,
      product_id: productID,
      functions: ctrObj,
    }),
  })
    .then(checkStatus)
    .then(() => {
      updateContextAfterUpdateSensorSetting(
        device_id,
        ctrObj,
        context,
        setLocation
      );
    })
    .catch((error) => console.error("Error delete update device: ", error));
}

export const updateContextAfterUpdateSensorSetting = (
  id,
  ctrObj,
  context,
  setLocation
) => {
  let floor = "";
  let layerToSensors = context.layerToSensors;
  let floors = Object.keys(layerToSensors);
  for (let i = 0; i < floors.length; i++) {
    if (layerToSensors[floors[i]].includes(id)) {
      floor = floors[i];
      break;
    }
  }

  let sensorObjects = { ...context.sensorObjects };
  sensorObjects[id]["speed"] = ctrObj.speed;
  sensorObjects[id]["auto"] = ctrObj.auto;
  sensorObjects[id]["lock"] = ctrObj.lock;
  context.setSensorObjects(sensorObjects);

  let sensorMapInfoOverview = JSON.parse(
    JSON.stringify(context.sensorMapInfoOverview)
  );

  let sensors = sensorMapInfoOverview[floor];
  if (sensors) {
    sensors = sensors["sensors"];
    for (let i = 0; i < sensors.length; i++) {
      if (Object.keys(sensors[i])[0] === id) {
        sensorMapInfoOverview[floor]["sensors"][i][id]["speed"] = ctrObj.speed;
        sensorMapInfoOverview[floor]["sensors"][i][id]["auto"] = ctrObj.auto;
        sensorMapInfoOverview[floor]["sensors"][i][id]["lock"] = ctrObj.lock;
        break;
      }
    }
  }
  context.setUserData(sensorMapInfoOverview);
  if (setLocation) {
    setLocation(floor);
  }
  return { ...context, sensorObjects, sensorMapInfoOverview };
};

export const updateContextAfterBatchUpdateSensorSetting = (
  idList,
  ctrObj,
  context
) => {
  let sensorObjects = { ...context.sensorObjects };
  let sensorMapInfoOverview = JSON.parse(
    JSON.stringify(context.sensorMapInfoOverview)
  );
  idList.forEach((id) => {
    let floor = "";
    let layerToSensors = context.layerToSensors;
    let floors = Object.keys(layerToSensors);
    for (let i = 0; i < floors.length; i++) {
      if (layerToSensors[floors[i]].includes(id)) {
        floor = floors[i];
        break;
      }
    }
    sensorObjects[id]["speed"] = ctrObj.speed;
    sensorObjects[id]["auto"] = ctrObj.auto;
    sensorObjects[id]["lock"] = ctrObj.lock;
    let sensors = sensorMapInfoOverview[floor];
    if (sensors) {
      sensors = sensors["sensors"];
      for (let i = 0; i < sensors.length; i++) {
        if (Object.keys(sensors[i])[0] === id) {
          sensorMapInfoOverview[floor]["sensors"][i][id]["speed"] =
            ctrObj.speed;
          sensorMapInfoOverview[floor]["sensors"][i][id]["auto"] = ctrObj.auto;
          sensorMapInfoOverview[floor]["sensors"][i][id]["lock"] = ctrObj.lock;
          break;
        }
      }
    }
  });
  context.setSensorObjects(sensorObjects);
  context.setUserData(sensorMapInfoOverview);
  return { ...context, sensorObjects, sensorMapInfoOverview };
};

export const checkSensorValidation = (id) => {
  return fetch(HostURL + `/sensor_validate?id=${id}`)
    .then(checkStatus)
    .then((response) => {
      return response.json();
    })
    .catch((error) =>
      console.error(`Fail to check the validation of Device : ${id}`, error)
    );
};

export const refreshRealtimeStatus = (context, setLoading) => {
  let loading1 = true;
  let loading2 = true;
  // refresh userData
  fetch(HostURL + "/sensor_all_info?user=" + context.userEmail)
    .then(checkStatus)
    .then((data) => data.json())
    .then((data) => {
      let refreshResults = {};

      let layerToSensorsTemp = {};
      for (let i = 0; i < data.results.length; i++) {
        let mapName = data.results[i]["map_name"];

        refreshResults[mapName] = data.results[i];
        let sensors = data.results[i]["sensors"];
        let deviceIds = [];
        for (let j = 0; j < sensors.length; j++) {
          let deviceId = Object.keys(sensors[j])[0];
          deviceIds.push(deviceId);
        }
        layerToSensorsTemp[mapName] = deviceIds;
      }
      let collectedTime = Date.now();
      refreshResults["collectedTime"] = collectedTime;
      context.setUserData(refreshResults);
      context.setLayerToSensors(layerToSensorsTemp);
    })
    .catch((error) => {
      console.log("Error fetching data: ", error.message);
      context.setErrorMsg(error.message);
    })
    .finally(() => {
      loading1 = false;
      if (!loading2) {
        setLoading(false);
      }
    });

  // refresh sensor object storage
  fetch(HostURL + "/label_sensor?user=" + context.userEmail)
    .then(checkStatus)
    .then((data) => data.json())
    .then((data) => {
      let newSensorObjects = {};
      Object.values(data["results"]).forEach((sensor) => {
        newSensorObjects[sensor["particle_id"]] = sensor;
      });
      let collectedTime = Date.now();
      newSensorObjects["collectedTime"] = collectedTime;
      context.setSensorObjects(newSensorObjects);
      return data;
    })
    .then((data) => {
      context.setSensorCount(data.count);
      return data;
    })
    .catch((error) => {
      console.log("Error fetching data: ", error);
      context.setErrorMsg(error.message);
    })
    .finally(() => {
      loading2 = false;
      if (!loading1) {
        setLoading(false);
      }
    });
};
