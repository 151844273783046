import { useContext, useEffect, useState } from "react";
import FlipMove from "react-flip-move";
import { useNavigate } from "react-router-dom";

import {
  Box,
  CircularProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { deleteNotification } from "../../../components/AccessDBGlobalFunctions";
import AlertItemCardStatus from "../../../components/AlertItemCardStatus";
import { UserContext } from "../../../components/UserContext";
import NotificationSettingModal from "../components/NotificationSettingModal";

function createData(
  id,
  notification,
  timeOfOccurence,
  deviceName,
  floor,
  links,
  type
) {
  return { id, notification, timeOfOccurence, deviceName, floor, links, type };
}

const useStyles = makeStyles((theme) => ({
  th: {
    fontWeight: "bold",
  },
  td1: {
    width: "35%",
  },
  td2: {
    width: "15%",
  },
  td3: {
    width: "15%",
  },
  td4: {
    width: "15%",
  },
  td5: {
    width: "15%",
  },
  mainTable: {
    width: "100%",
    "& tr": {
      width: "100%",
      display: "table",
      textAlign: "left",
      backgroundColor: "#ffffff",
      marginBottom: "10px",
      marginTop: "10px",
      borderBottom: "1px solid #ccc",
    },
    "& tr td": {
      padding: "10px",
    },
  },
}));

const NotificationCenterView = () => {
  const theme = useTheme();
  const userContext = useContext(UserContext);
  const [alertSettingOpen, setAlertSettingOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const history = useNavigate();
  const classes = useStyles();

  function onLogDelete(e, device_id, index) {
    setRows((currentRows) => currentRows.filter((row) => row.id !== index));
    // e.target.closest("tr").classList.add(classes.fadeOut);
    // setTimeout(() => {
    //   setRows((currentRows) =>
    //     currentRows.filter((row) => row.timeOfOccurence !== time)
    //   );
    //   const logTableBody = e.target.parentNode.parentNode.parentNode.parentNode;
    //   const logEntry = e.target.parentNode.parentNode.parentNode;
    //   logTableBody.removeChild(logEntry);
    //   // deleteNotification(device_id, time);
    //   // If you're using an API call to delete the notification, place it here
    // }, 500); // match the duration of the animation
  }

  /**
   * TODO: REMOVE THIS LATER
   * This function used on translate the index of notfication class in db
   * to format of frontend
   * @param {int} index
   * @returns {int} formatted index
   */
  function databaseClassificationToFrontendClassification(index) {
    // switch (index) {
    //   case 3:
    //     return 0;
    //   case 2:
    //     return 1;
    //   case 1:
    //     return 2;
    //   default:
    //     break;
    // }
    return index;
  }

  /**
   *
   * @param {Date} dateObject date object with created with the notification record's timestamp (This timestmape is in UTC)
   * @returns {String} formatted time string
   */
  function getTimeString(dateObject) {
    const monthInShort = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let month = monthInShort[dateObject.getMonth()];
    let date = dateObject.getDate();
    let year = dateObject.getFullYear();
    let hour = dateObject.getHours();
    let minute = dateObject.getMinutes();

    var formattedHour = (hour % 12 || 12).toString().padStart(2, "0");

    var formattedMintue = minute.toString().padStart(2, "0");

    // Format the minute as a two-digit number, with a leading zero if necessary

    // Append the AM/PM designator
    var ampm = hour < 12 ? "AM" : "PM";
    return `${month} ${date}, ${year} at ${formattedHour}:${formattedMintue} ${ampm}`;
  }

  useEffect(() => {
    if (
      userContext &&
      Object.keys(userContext.notificationLogData).length !== 0
    ) {
      const response = userContext.notificationLogData;
      setRows(() => {
        let rows = [];
        response["results"].forEach((record, index) => {
          if (!record["Deleted"]) {
            rows.push(
              createData(
                index,
                <div style={{ display: "flex", alignItems: "center" }}>
                  <AlertItemCardStatus
                    color={
                      userContext.thresholdColor[
                        databaseClassificationToFrontendClassification(
                          parseInt(record["Class"])
                        )
                      ]
                    }
                    status={
                      userContext.thresholdName[
                        databaseClassificationToFrontendClassification(
                          parseInt(record["Class"])
                        )
                      ]
                    }
                  />
                  <p style={{ marginLeft: "10px" }}> {record["Message"]} </p>
                </div>,
                getTimeString(new Date(record["Time"] + "+0000")),
                record["Name"] ? record["Name"] : record["Device_ID"],
                record["Location"] ? record["Location"] : "Unassigned",
                <div>
                  <Link
                    style={{ marginRight: "10px" }}
                    onClick={(e) =>
                      history(
                        `/dashboard/data-analytics/device-detail/${record["Device_ID"]}`
                      )
                    }
                  >
                    View Details
                  </Link>
                  <Link
                    onClick={(e) => onLogDelete(e, record["Device_ID"], index)}
                  >
                    Delete
                  </Link>
                </div>,
                record["Type"]
              )
            );
          }
        });
        return rows;
      });
    }
  }, [userContext]);

  return (
    <Box style={{ width: "100%" }}>
      <Box display="flex" justifyContent="space-between">
        <Typography
          variant="h2"
          color={theme.palette.h2.main}
          marginBottom="2.92vh"
          fontWeight={700}
        >
          Notifications
        </Typography>
        <NotificationSettingModal
          notificationSetting={userContext.notificationSetting}
          setNotificationSetting={userContext.setNotificationSetting}
          alertSettingOpen={alertSettingOpen}
          setAlertSettingOpen={setAlertSettingOpen}
        />
      </Box>
      <TableContainer
        component={Paper}
        style={{
          height: "88%",
          minHeight: "80vh",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        {userContext.loading ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress sx={{ marginBottom: "5vh" }} />
            <Typography variant="h3" color={theme.palette.h2.main}>
              Loading ...
            </Typography>
          </Box>
        ) : rows.length === 0 ? (
          <div
            style={{
              display: "flex",
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h3" color="#858D97">
              {" "}
              No Alert Yet{" "}
            </Typography>
          </div>
        ) : (
          <table className={classes.mainTable}>
            <tbody>
              <tr className={classes.th}>
                <td className={classes.td1} style={{ paddingLeft: "3.78vh" }}>
                  Notification
                </td>
                <td className={classes.td2} align="left">
                  Time of Occurrence
                </td>
                <td className={classes.td3} align="left">
                  Device Name
                </td>
                <td className={classes.td4} align="left">
                  Floor
                </td>
                <td className={classes.td5} align="left"></td>
              </tr>
              <FlipMove
                leaveAnimation={{
                  from: { transform: "scale(1, 1)", opacity: "1" },
                  to: {
                    transform: "translateY(0)",
                    opacity: "0",
                  },
                }}
                staggerDurationBy={30}
                duration={750}
                delay={30}
              >
                {rows.map((row) => {
                  if (
                    userContext.notificationSetting === 3 ||
                    (userContext.notificationSetting === 1 &&
                      row.type === "pm2p5") ||
                    (userContext.notificationSetting === 2 &&
                      row.type === "pm10p0")
                  ) {
                    return (
                      <tr key={row.id}>
                        <td
                          className={classes.td1}
                          style={{ paddingLeft: "3.78vh" }}
                        >
                          {row.notification}
                        </td>
                        <td className={classes.td2} align="left">
                          {row.timeOfOccurence}
                        </td>
                        <td className={classes.td3} align="left">
                          {row.deviceName}
                        </td>
                        <td className={classes.td4} align="left">
                          {row.floor}
                        </td>
                        <td className={classes.td5} align="left">
                          {row.links}
                        </td>
                      </tr>
                    );
                  } else {
                    return null; // Returning null instead of an empty <div>
                  }
                })}
              </FlipMove>
            </tbody>
          </table>
        )}
      </TableContainer>
    </Box>
  );
};
export default NotificationCenterView;
