import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import Messager from "../../../components/Messager";
import { UserContext } from "../../../components/UserContext";
import PopupModal from "../../../components/PopupModal";

const useStyles = makeStyles({
  deviceManagementTable: {
    background: "#FFFFFF",
    boxSizing: "border-box",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.16)",
    borderRadius: "6px 6px 6px 6px",
    height: "72vh",
    width: "100%",
    padding: "30px",
    "& .MuiDataGrid-root": {
      width: "100%",
      border: "none",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      padding: "0 !important",
    },
    "& .MuiDataGrid-columnSeparator": {
      visibility: "hidden",
    },
    "& .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
    "& .MuiDataGrid-columnHeader:focus": {
      outline: "none !important",
    },
    "& .MuiDataGrid-menuIcon": {
      visibility: "visible",
    },
  },
  toolbar: {
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
    padding: "10px 20px",
  },
});

/**
 * @param {array} props.rows the content of device table
 */
const AdminDeviceTable = (props) => {
  const context = useContext(UserContext);
  const theme = useTheme();
  const classes = useStyles();
  const [rowSelect, setRowSelect] = React.useState([]);
  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState(<></>);
  /**
   * How to Calculate the width of column with flex?
   * First, we need to have a non-resizable column with a fixed width.
   * In this case, the button group should be a good choice.
   * Then we can set the `flex` attribute for the rest of columns
   * (which is equal to total width - width of button group column).
   * For Example, if device name column demands 15% of the rest space,
   * set flex: 0.15 for the device name column.
   */
  const columns = [
    {
      field: "user",
      headerName: "User Email",
      // disableColumnMenu: true,
      flex: 0.13,
    },
    {
      field: "sensor_name",
      headerName: "Device Name",
      // disableColumnMenu: true,
      flex: 0.1,
      sortable: false,
    },
    {
      field: "particle_id",
      headerName: "Unit ID",
      // disableColumnMenu: true,
      flex: 0.1,
      sortable: false,
    },
    {
      field: "",
      headerName: "Details",
      flex: 0.05,
      renderCell: (cellValues) => {
        return <DetailModal
          showMessage={showMessage}
          info={props.adminData[cellValues.row.id]}
          handleDelete={props.handleDelete}
          id={cellValues.row.id} />;
      },
      disableColumnMenu: true,
      sortable: false,
    },
  ];

  function showMessage(message) {
    setMessageOpen(true);
    setMessage(message);
  }

  return (
    <div className={classes.deviceManagementWrapper}>
      <Messager
        open={messageOpen}
        onClose={() => {
          setMessageOpen(false);
        }}
        message={message}
      />
      <div className={classes.deviceManagementTable}>
        {props.loading ? (
          <Paper
            style={{
              border: "none",
              boxShadow: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress style={{ marginRight: "10px" }} />
            <Typography variant="h3" color={theme.palette.h2.main}>
              Loading ...
            </Typography>
          </Paper>
        ) : (
          <DataGrid
            rows={
              Object.values(props.adminData)
            }
            columns={columns}
            spacing={30}
            checkboxSelection
            hideFooterPagination
            disableSelectionOnClick
            onSelectionModelChange={(selectedRows) => {
              setRowSelect(selectedRows);
            }}
            selectionModel={rowSelect}
            onCellClick={(params, event) => {
              event.defaultMuiPrevented = true;
            }}
            // slot={{
            //   toolbar: GridToolbar
            // }}
          />
        )}
      </div>
    </div>
  );
};
export default AdminDeviceTable;


function DetailModal(props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(async () => {
    if (open) {
      setLoading(true);
      const HostURL = "https://b432d666jc.execute-api.us-east-1.amazonaws.com/Prod";
      try {
        const response = await fetch(`${HostURL}/particleInterface?device_id=${props.id}`);
        const responseData = await response.json();
        setData({...props.info, ...responseData['result']});
        console.log(props.id, responseData['result']);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
  }, [open]);
  return (<PopupModal
    modalOpen={open}
    setModalOpen={setOpen}
    onClose={() => {
      setData({});
      setOpen(false);
      setLoading(false);
    }}
    buttonPrompt={"Details"}
    title={props.id}
    width="69vh"
    maxHeight="60vh"
    content={
      <div>
        {Object.keys(data).map((item, index) => {
          return <div key={index} style={{display: "grid", gridTemplateColumns: "auto auto", justifyContent: "space-between", marginBottom: "15px"}}>
          <Typography variant="bodyText">{item}</Typography>
          <Typography variant="bodyText">{data[item].toString()}</Typography>
        </div>
        })}
        {loading ? <CircularProgress /> : <></>}
      </div>
    }
    action={<ConfirmModal 
      deviceName={props.info.sensor_name}
      handleDelete={async (callback) => {
        return props.handleDelete(props.id)
          .then(() => {{
            props.showMessage("Device Deleted Successfully")
          }})
          .catch((error) => {
            console.error(error);
            props.showMessage("Failed to Delete Device")})
          .finally(() => {    
            if (callback !== undefined) {
              callback();
            }
            setOpen(false);
          })
      }}></ConfirmModal>}
  />
    
  )
}

function ConfirmModal(props) {
  const {deviceName, handleDelete} = props;
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const handleClick = async () => {
    return handleDelete(() => setOpen(false));
  };

  return (<PopupModal
    modalOpen={open}
    setModalOpen={setOpen}
    onClose={() => {setOpen(false)}}
    button={
      <Button
        variant={"outlined"}
        style={{ 
          textTransform: "none", 
          borderColor: theme.palette.red.main,
          color: theme.palette.red.main
        }}
        onClick={() => setOpen(true)}
      >
        Delete
      </Button>
    }
    width="69vh"
    height={"242px"}
    content={
      <div style={{ textAlign: "left" }}>
        <Typography variant="subText" sx={{
          fontWeight: 550,
          fontSize: "18px",
          lineHeight: "24px",
        }}>
          Are you sure you would like to delete "{deviceName}" from system?
        </Typography>
      </div>
    }
    action={
      <>
        <Button
          onClick={() => {setOpen(false)}}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={handleClick}
          style={{ 
            textTransform: "none", 
            border: '2px solid' + theme.palette.red.main,
            backgroundColor: theme.palette.red.main
          }}
          variant="contained"
        >
          Delete
        </Button>
      </>
    }
  />);
}