/**
 * Method used to get the data for graphs
 * @param {*} floorData userContext.sensorMapInfoOverview[props.floor] example: {
    "map_name": "Test Floor Plan",
    "background_name": ,
     "sensors": [
        {
            "xxx": {
                "particle_id": "xxx",
                "sensor_name": "Test Unit d012",
                "labels": [],
                "particle_product_id": 18711,
                "online": true,
                "speed": 1,
                "auto": false,
                "lock": false,
                "indoor_position": {
                    "x_coordinate": -50,
                    "y_coordinate": 50
                }
            }
        },
        {
            "xxx": {
                "particle_id": "xxx",
                "sensor_name": "Test Unit d3f8",
                "labels": [],
                "particle_product_id": 18711,
                "online": true,
                "speed": 1,
                "auto": false,
                "lock": false,
                "indoor_position": {
                    "x_coordinate": -20.976080130809436,
                    "y_coordinate": 33.78207881578431
                }
            }
        }
    ]
}
 * @param {*} param paramid - dp03
 * @param {*} deviceData {
    "e00fce68e31e11e41982d012": [
        {
            "altitude": "66",
            "ExpirationTime_TTL": "1679940206",
            "pm10p0Env": "0",
            "pm1Env": "0",
            "dp03": "150",
            "dp25": "0",
            "dp100": "0",
            "temperature": "26.06",
            "pm2p5Std": "0",
            "dp05": "44",
            "dp10": "1",
            "pm2p5Env": "0",
            "pm10p0Std": "0",
            "pressure": "100523",
            "dp50": "0",
            "aqi": "0",
            "humidity": "12",
            "Time": "2023-02-25T18:03:26",
            "pm1p0Std": "0",
            "Device_ID": "e00fce68e31e11e41982d012"
        },
    ]
}
 * @returns
 */
export function FloorGraphDataProvider(floorData, param, deviceData) {
  if (!floorData || !param || !floorData.sensors || !deviceData) {
    return { error: "graph data fetching error" };
  } else {
    let sensors = floorData.sensors;
    let realTimeDevicesDataArr = [];
    let labelArray = [];
    for (let i = 0; i < sensors.length; i++) {
      let deviceIData = deviceData[Object.keys(sensors[i])[0]];
      if (deviceIData) {
        realTimeDevicesDataArr.push(deviceIData);
        labelArray.push(sensors[i][Object.keys(sensors[i])[0]]["sensor_name"]);
      }
    }
    let timeInterval = 8 * 60 + 1; // 8 hours
    let timeArray = Array(timeInterval);
    if (
      realTimeDevicesDataArr &&
      realTimeDevicesDataArr.length > 0 &&
      realTimeDevicesDataArr[0].length > 0
    ) {
      let mostRecentMinute = new Date(
        new Date(
          realTimeDevicesDataArr[0][realTimeDevicesDataArr[0].length - 1][
            "Time"
          ]
        ).toLocaleString()
      ); // fetch 8 hour so should be 8 hours
      let hourCount = 0;
      for (let i = timeArray.length - 2; i >= 0; i--) {
        if (i % 1 === 0) {
          // timeArray[i] = new Date(
          //   mostRecentMinute.getTime() - 60 * hourCount * 1000
          // ).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
          let utcDate = new Date(
            mostRecentMinute.getTime() - 60 * hourCount * 1000
          );
          let clientTimezoneOffset = new Date().getTimezoneOffset();
          const clientTimestamp =
            utcDate.getTime() - clientTimezoneOffset * 60 * 1000;
          const clientDate = new Date(clientTimestamp);
          timeArray[i] = clientDate;
          hourCount++;
        } else {
          timeArray[i] = null;
        }
      }
      let dataArray = [];
      for (let deviceIData of realTimeDevicesDataArr) {
        let sensorDataArray = Array(timeInterval);
        for (let deviceDataRecord of deviceIData) {
          let recentMinToCollectedTime =
            mostRecentMinute - new Date(deviceDataRecord["Time"]);
          let timeIndex = Math.round(recentMinToCollectedTime / 1000 / 60);
          sensorDataArray[timeIndex] = deviceDataRecord[param];
        }
        dataArray.push(sensorDataArray);
      }
      return { data: dataArray, time: timeArray, label: labelArray };
    } else {
      return { data: [], time: [], label: labelArray };
    }
  }
}

export function FloorAggregateGraphDataProvider(
  aggregateData,
  map,
  paramId,
  deviceId
) {
  if (!aggregateData || !map) {
    return { error: "floor aggregation graph data fetching error" };
  } else if (
    !aggregateData[map] ||
    !aggregateData[map]["floor_avg"] ||
    Object.keys(aggregateData[map]).length === 0 ||
    Object.keys(aggregateData[map]["floor_avg"]).length === 0
  ) {
    return { data: [], time: [], label: [] };
  } else {
    let dataArray = [];
    let timeArray = [];
    const floorAVGTimestamps = Object.keys(aggregateData[map]["floor_avg"]);
    for (let i = 0; i < floorAVGTimestamps.length; i++) {
      let paramData =
        aggregateData[map]["floor_avg"][floorAVGTimestamps[i]][paramId];
      if (
        deviceId &&
        aggregateData[map] &&
        aggregateData[map][deviceId] &&
        aggregateData[map][deviceId][floorAVGTimestamps[i]] &&
        aggregateData[map][deviceId][floorAVGTimestamps[i]][paramId]
      ) {
        paramData =
          aggregateData[map][deviceId][floorAVGTimestamps[i]][paramId];
      }
      dataArray.push(paramData);
      let utcDate = new Date(floorAVGTimestamps[i]);
      let clientTimezoneOffset = new Date().getTimezoneOffset();
      const clientTimestamp =
        utcDate.getTime() - clientTimezoneOffset * 60 * 1000;
      const clientDate = new Date(clientTimestamp);
      timeArray.push(clientDate);
    }
    return { data: dataArray, time: timeArray.sort(), label: [] };
  }
}

export function FloorDeviceHourlyGraphProvider(aggregateData, map, paramId) {
  if (!aggregateData || !map) {
    return { error: "floor aggregation graph data fetching error" };
  } else if (
    !aggregateData[map] ||
    !aggregateData[map]["floor_avg"] ||
    Object.keys(aggregateData[map]).length === 0 ||
    Object.keys(aggregateData[map]["floor_avg"]).length === 0
  ) {
    return { data: [], time: [], label: [] };
  } else {
    let dataArr = [];
    let labelArr = [];
    let deviceList = Object.keys(aggregateData[map]);
    const deviceAVGTimestamps = Object.keys(aggregateData[map][deviceList[0]]);

    for (let i = 0; i < deviceList.length; i++) {
      if (deviceList[i] !== "floor_avg") {
        let deviceDataArr = [];
        for (let j = 0; j < deviceAVGTimestamps.length; j++) {
          if (
            aggregateData[map][deviceList[i]][deviceAVGTimestamps[j]] &&
            (aggregateData[map][deviceList[i]][deviceAVGTimestamps[j]][
              paramId
            ] ||
              aggregateData[map][deviceList[i]][deviceAVGTimestamps[j]][
                paramId
              ] === 0)
          ) {
            let paramData =
              aggregateData[map][deviceList[i]][deviceAVGTimestamps[j]][
                paramId
              ];
            deviceDataArr[j] = paramData;
          }
        }
        dataArr.push(deviceDataArr);
        labelArr.push(deviceList[i]);
      }
    }
    let timeArray = [];
    for (let i = 0; i < deviceAVGTimestamps.length; i++) {
      let utcDate = new Date(deviceAVGTimestamps[i]);
      let clientTimezoneOffset = new Date().getTimezoneOffset();
      const clientTimestamp =
        utcDate.getTime() - clientTimezoneOffset * 60 * 1000;
      const clientDate = new Date(clientTimestamp);
      timeArray.push(clientDate);
    }
    return { data: dataArr, time: timeArray.sort(), label: labelArr };
  }
}
