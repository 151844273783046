import React from "react";

import { Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import footerLogo from "../../../util/landing/FooterLogo.png";

const useStyle = makeStyles({
  footer: {
    width: "100%",
    backgroundColor: "#CFD6E6",
    paddingBottom: 58,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  logo: {
    marginTop: 58,
  },
  spec: {
    marginTop: 10,
  },
});

export default function Footer(props) {
  const theme = useTheme();
  const classes = useStyle();
  return (
    <div className={classes.footer}>
      <img src={footerLogo} alt="footer logo" className={classes.logo} />
      <Typography variant={"subText"} color={theme.palette.subText.main}>
        © 2020 AeroSpec. All rights reserved
      </Typography>
    </div>
  );
}
