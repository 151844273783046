import React, { useContext } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import { DeviceContext, UserContext } from "../../../components/UserContext";

export default function DeviceRealTimeTable(props) {
  const sensorObjects = useContext(UserContext).sensorObjects;
  const data = useContext(DeviceContext).raw;
  const theme = useTheme();
  const CustomizedTable = styled(Table)(({ theme }) => ({
    "& .MuiTableCell-head": {
      ...theme.typography.h4,
      color: "#697077",
      paddingBottom: "10px !important",
      textAlign: "left",
    },
    "& .MuiTableCell-root": {
      ...theme.typography.subText,
      textAlign: "left",
      borderTop: "0",
      borderBottom: "0",
      padding: "1px",
    },
  }));

  const statusStyle = (row, id) => {
    // row will be NaN for "N/A" and "Loading" dividing with 10
    if (!row) {
      return {
        fontWeight: 700,
        color: "gray",
      };
    }
    let fontColor = "rgb(51, 51, 51)";
    if (sensorObjects && sensorObjects[id] && !sensorObjects[id].online) {
      return {
        fontWeight: 700,
        color: theme.palette.offline.main,
        textAlign: "left",
      };
    }
    if (!props.paramSelection.includes("pm")) {
      fontColor = theme.palette.primary.main;
      return {
        fontWeight: 700,
        color: fontColor,
        textAlign: "left",
      };
    }

    // if (row >= 310) {
    //   fontColor = '#C55A1E'
    // } else if (row >= 201) {
    //   fontColor = '#9B51E0'
    // } else if (row >= 151) {
    //   fontColor = '#EB5757'
    // } else if (row >= 101) {
    if (row >= 100) {
      fontColor = theme.palette.red.main;
    } else if (row >= 51) {
      fontColor = theme.palette.yellow.main;
    } else {
      fontColor = theme.palette.green.main;
    }
    return {
      fontWeight: 700,
      color: fontColor,
      textAlign: "left",
    };
  };

  return (
    <CustomizedTable>
      <TableHead>
        <TableRow>
          <TableCell style={{ textAlign: "left" }}>Device</TableCell>
          <TableCell style={{ textAlign: "left" }}>Real-time</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.floorData.sensors.map((sensor, index) => {
          sensor = Object.values(sensor)[0];
          // data loaded, sensor in data object, and have data, take the latest data , otherwise N/A or loading if loading.
          let realTimeData =
            !!Object.keys(data) &&
            !!data[sensor["particle_id"]] &&
            data[sensor["particle_id"]].length > 0
              ? data[sensor["particle_id"]].length > 0
                ? data[sensor["particle_id"]][
                    data[sensor["particle_id"]].length - 1
                  ][props.paramSelection]
                : "Loading"
              : "N/A";
          if (
            sensorObjects &&
            sensorObjects[sensor["particle_id"]] &&
            sensorObjects[sensor["particle_id"]][props.paramSelection]
          ) {
            realTimeData =
              sensorObjects[sensor["particle_id"]][props.paramSelection];
          }
          return (
            <TableRow key={index}>
              <TableCell style={{ textAlign: "left" }}>
                {sensor["sensor_name"]}
              </TableCell>
              <TableCell
                style={statusStyle(
                  props.paramSelection.toLowerCase().includes("dp")
                    ? realTimeData / 10
                    : realTimeData,
                  sensor["particle_id"]
                )}
              >
                {realTimeData == 'N/A' ? 'Offline': realTimeData}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </CustomizedTable>
  );
}
