import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  sx: {
    width: "100%",
    height: "41px",
    backgroundColor: "#F5F2F0",
    padding: "0 20px 0 20px",
    marginBottom: "33px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "5px",
  },
}));

const Banner = (props) => {
  const classes = useStyles();
  const { bannerText, bannerButton } = props;
  return (
    <Box className={classes.sx}>
      {bannerText}
      {bannerButton}
    </Box>
  );
};

export default Banner;
