import React, { useEffect, useRef } from "react";

import AirIcon from "@mui/icons-material/Air";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import EditSensorsPopup from "../../../components/EditSensorsPopup";
import SensorDetailsPopup from "../../../components/SensorDetailsPopup";

const useStyles = makeStyles({
  navItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "0.3rem",
    position: "relative",
    listStyle: "none",
  },
  dropdown: {
    zIndex: 2,
    position: "absolute",
    top: "calc(100% - 5px)",
    right: 0,
    borderRadius: "8px",
    transition: "height 400ms ease",
    minWidth: "0px",
    width: "fit-content",
  },
  iconButton: {
    width: "calc(72 * 0.65)",
    height: "calc(72 * 0.65)",
    backgroundColor: "#ffffff",
    border: "none",
    borderRadius: "50%",
    padding: "5px",
    margin: "2px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 150ms ease",
    cursor: "pointer",
  },
  menu: {
    backgroundColor: "#ffffff",
    border: "1px solid #E0E2E5",
    borderRadius: "5px",
    boxSizing: "border-box",
    padding: "5px",
    width: "fit-content",
  },
  rows: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
  },
  icon: {
    marginRight: "10px",
  },
});

export default function DeviceTableEditControlMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <>
      <IconButton aria-describedby={id} type="button" onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>

      <Popper id={id} open={open} anchorEl={anchorEl}>
        <DropdownMenu
          row={props.row}
          disabled={props.disabled}
          showMessage={props.showMessage}
          setLocation={props.setLocation}
          context={props.context}
        />
      </Popper>
    </>
  );
}

function DeviceTableEditControlDropdownContainer(props) {
  const classes = useStyles();
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (props.open && ref.current && !ref.current.contains(e.target)) {
        props.setOpen(false);
      } else if (e.target.id === "view-all-alerts-btn") {
        // navigate to action center and close the notif dropdown
        document.getElementById("notification").click();
        props.setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [props, props.open]);

  return (
    <li className={classes.navItem} ref={ref}>
      <button
        className={classes.iconButton}
        onClick={() => props.setOpen(!props.open)}
      >
        {props.icon}
      </button>

      {props.open && props.children}
    </li>
  );
}

function DropdownMenu(props) {
  const classes = useStyles();

  return (
    <div className={classes.dropdown}>
      <MenuList className={classes.menu}>
        <MenuItem
          disableRipple
          style={{
            backgroundColor: "transparent",
            cursor: "default",
          }}
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <EditSensorsPopup
              key={`edit-${props.row.id}`}
              iflink={true}
              deviceId={props.row.id}
              deviceName={props.row.deviceName}
              location={props.row.location}
              initPosition={
                props.context.sensorObjects[props.row.id]
                  ? props.context.sensorObjects[props.row.id].indoor_position
                  : null
              }
              buttonPrompt={"Edit"}
              disabled={props.disabled}
              showMessage={props.showMessage}
            />
          </ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <AirIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <SensorDetailsPopup
              setLocation={props.setLocation}
              key={`control-${props.row.id}`}
              deviceId={props.row.id}
              deviceName={props.row.deviceName}
              status={props.row.connection}
              connection={props.row.connection}
              forMgt={true}
              buttonPrompt={"Control"}
              disabled={props.disabled}
              showMessage={props.showMessage}
            />
          </ListItemText>
        </MenuItem>
      </MenuList>
    </div>
  );
}
