import React from "react";

import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles({
  circle: (props) => {
    return {
      position: "absolute",
      width: props.width,
      height: props.height,
      left: props.left,
      top: props.top,
      background: "rgba(255,255,255,0.3)",
      // opacity: 0.3,
      borderRadius: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    };
  },
  body: (props) => {
    return {
      fontFamily: "SF Pro Display",
      fontSize: props.fontSize + "px",
      fontWeight: 400,
      lineHeight: props.lineHeight + "px",
      display: "flex",
      textAlign: "center",
      justifyContent: "center",
    };
  },
});

export default function VisionCircle(props) {
  const classes = useStyle(props);
  const theme = useTheme();
  return (
    <div className={classes.circle}>
      <Typography variant={"h1"}>{props.title}</Typography>
      <p
        className={classes.body}
        style={{ color: theme.palette.textPrimary2.main }}
      >
        {props.body}
      </p>
    </div>
  );
}
