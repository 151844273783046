import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

const PaperOverlay = styled((props) => <Paper {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  padding: "0px",
  position: "absolute",
  border: "none",
  bottom: `0px`,
  left: `0px`,
  backgroundColor: "rgba(255, 255, 255, 0.63)",
  height: `100%`,
  width: `100%`,
  boxShadow: "none",
}));

export default PaperOverlay;
