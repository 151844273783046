import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

import Dropdown from "../../../components/Dropdown";
import EditSensorsPopup from "../../../components/EditSensorsPopup";
import Messager from "../../../components/Messager";
import UploadMapPopup from "../../../components/UploadMapPopup";
import { DeviceContext, UserContext } from "../../../components/UserContext";
import OverViewCard from "../components/OverViewCard";

const useStyles = makeStyles({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "top",
  },
  selectorContainer: {
    display: "flex",
  },
  cardContainer: {
    width: "100%",
  },
});

const PaperCustomized = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  elevation: 2,
  position: "relative",
  backgroundColor: "#FCFCFC",
  width: "100%",
  height: "50vh",
  alignItems: "center",
  boxSizing: "borderBox",
  /* Border - Light */
  border: "1px solid #E0E2E5",
  /* Section Basic Shadow */
  boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
}));

const OverView = (props) => {
  const history = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const userContext = useContext(UserContext);
  const deviceContext = useContext(DeviceContext).raw;
  const [paramSelection, setParamSelection] = useState("PM 2.5");
  const [dataTypeSelection, setDataTypeSelection] = useState("Area Average");
  const [overViewCards, setOverViewCards] = useState([]);
  const [numLocations, setNumLocations] = useState(0);
  const [noMapNoSensors, setIfNoMapNoSensors] = useState(false);
  const [message, setMessage] = useState("");
  const [messageOpen, setMessageOpen] = useState(false);

  useEffect(() => {
    setIfNoMapNoSensors(
      !userContext ||
        (userContext &&
          userContext.userEmail &&
          (Object.keys(userContext.layerNameToMapUrl).length === 0 ||
            (Object.keys(userContext.layerNameToMapUrl).length === 1 &&
              Object.keys(userContext.layerNameToMapUrl)[0] ===
                "Unassigned")) &&
          (Object.keys(userContext.layerToSensors).length === 0 ||
            (Object.keys(userContext.layerToSensors).length === 1 &&
              Object.keys(userContext.layerToSensors)[0] === "Unassigned")))
    );
  }, [userContext]);

  useEffect(() => {
    let mapsInfo = userContext.sensorMapInfoOverview;
    let layerSensors = userContext.layerToSensors;
    let nonNullLocationCount = 0;
    if (mapsInfo && layerSensors) {
      let cards = Object.keys(mapsInfo).map((mapName, index) => {
        if (mapName !== "null" && mapName !== "Unassigned") {
          nonNullLocationCount++;
          let floorDeviceData = null;
          if (layerSensors[mapName]) {
            floorDeviceData = {};
            for (let i = 0; i < layerSensors[mapName].length; i++) {
              if (deviceContext[layerSensors[mapName][i]]) {
                floorDeviceData[layerSensors[mapName][i]] =
                  deviceContext[layerSensors[mapName][i]];
              }
            }
          }
          return (
            <OverViewCard
              key={index}
              floorId={index}
              paramSelectionName={paramSelection}
              paramSelection={
                userContext.paramIds[
                  userContext.paramNames.indexOf(paramSelection)
                ]
              }
              paramName={paramSelection}
              floorData={mapsInfo[mapName]}
              floorDeviceData={floorDeviceData}
              floor={mapName}
            />
          );
        } else {
          return <div />;
        }
      });
      setNumLocations(nonNullLocationCount);
      setOverViewCards(cards);
    }
  }, [paramSelection, userContext, deviceContext]);

  useEffect(() => {
    switch (dataTypeSelection) {
      case "Area Average":
        history("/dashboard/data-analytics/overview/area-average");
        break;
      case "8 Hour Average":
        history("/dashboard/data-analytics/overview/8-hour-average");
        break;
      case "Reporting Hazardous":
        history("/dashboard/data-analytics/overview/harzard");
        break;
      case "All Device Status":
        history("/dashboard/data-analytics/overview/all-device");
        break;
      default:
        console.log("selection error");
    }
  }, [
    userContext.sensorMapInfoOverview,
    paramSelection,
    dataTypeSelection,
    history,
  ]);

  function showMessage(message) {
    setMessageOpen(true);
    setMessage(message);
  }

  return (
    <Grid className={classes.cardContainer}>
      <Grid
        className={classes.headerContainer}
        marginBottom={{ md: "20px", xs: "10px" }}
      >
        <Typography variant="h2" color={theme.palette.h2.main}>
          Locations({numLocations})
        </Typography>
        <Grid item>
          <Grid>
            <Dropdown
              dropdownPurpose={"Data Type"}
              selection={paramSelection}
              disabled={noMapNoSensors}
              setSelection={setParamSelection}
              placeholder="Parameter"
              value="da-params"
              options={userContext.paramNames}
              tooltips={userContext.paramNamesTooltips}
            />
          </Grid>
          {/* <Grid className={classes.selector}><Dropdown setSelection={setDateSelection} placeholder="06/07/20-06/08/20" value="da-date-range" options={[""]}/></Grid> */}
          {/*
            TODO: add "8 Hour Average", "Reporting Hazardous","All Device Status"
          */}
          {/* <Grid className={classes.selector}>
            <Dropdown
              disabled={noMapNoSensors}
              selection={dataTypeSelection}
              setSelection={setDataTypeSelection}
              placeholder="Data"
              value="da-data-type"
              width={206}
              options={[
                "Area Average",
                "Reporting Hazardous",
                "All Device Status",
              ]}
            />
          </Grid> */}
          {/* <Grid className={classes.selector}><Dropdown setSelection={setSortSelection} placeholder="Sort" value="da-sort" options={["Floor Name", "Registered Recently", "Number of Devices"]}/></Grid> */}
        </Grid>
      </Grid>
      <Grid>
        {noMapNoSensors ? (
          <PaperCustomized>
            <Typography variant="h2" color={theme.palette.h2.main}>
              No Map In Record. No Sensors In Record.
            </Typography>
            <Grid sx={{ display: "flex", marginTop: "20px" }}>
              <Grid sx={{ marginRight: "20px" }}>
                <UploadMapPopup buttonText="Upload Map"></UploadMapPopup>
              </Grid>
              <EditSensorsPopup
                add
                showMessage={showMessage}
                buttonVariant="text"
              />
            </Grid>
          </PaperCustomized>
        ) : userContext.loading ? (
          <Paper
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <CircularProgress style={{ marginRight: "10px" }} />
            <Typography variant="h3" color={theme.palette.h2.main}>
              Loading ...
            </Typography>
          </Paper>
        ) : (
          overViewCards
        )}
      </Grid>
      <Messager open={messageOpen} message={message} />
    </Grid>
  );
};
export default OverView;
