import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Bar } from "react-chartjs-2";

import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineController,
  LinearScale,
  PointElement,
  TimeScale,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-moment";
import ChartAnnotation from "chartjs-plugin-annotation";

import { DeviceContext, UserContext } from "../../../components/UserContext";

// import FloorGraphDataProvider, {
//   FloorAggregateGraphDataProvider,
// } from "./FloorGraphDataProvider";

ChartJS.register(
  Filler,
  CategoryScale,
  LinearScale,
  TimeScale,
  LineController,
  PointElement,
  BarElement,
  Tooltip,
  Legend,
  ChartAnnotation
);

const useStyles = makeStyles({
  rightSection: {
    height: "100%",
    padding: "20px",
  },
  rightSectionDeviceView: {
    height: "100%",
    padding: 0,
  },
  chartTitle: {
    marginBottom: "20px",
  },
  chartContainer: {
    height: "auto",
  },
});
/**
 * @param {Array} props.data the data of the graph
 * @param {Array} props.labels the legend labels of the graph
 * @param {boolean} props.eightHour if we want to turn on eight hour
 * @param {string} props.paramId
 * @param {Array} props.time // the x axis
 * @param {boolean} props.hideLegend if need to hide the legend
 * @param {boolean} props.nullState // if the graph is in null state
 * @returns
 */
const AreaAverageGraph = (props) => {
  const classes = useStyles(props);
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({ datasets: [] });
  // const [averageDataArr, setAverageDataArr] = useState([]);
  const [drawColor, setDrawColor] = useState(false); // for update color gradient
  let labels = props.time;
  labels.sort(function (a, b) {
    return a - b;
  });
  const userContext = useContext(UserContext);
  const deviceContext = useContext(DeviceContext);

  // const thresholdLabel = userContext.thresholdName;
  const colors = userContext.thresholdColor;
  const createGradient = useCallback(
    (chart) => {
      let realThresholdArr = [];
      if (props.paramId) {
        realThresholdArr = userContext.theshold[props.paramId];
      }
      // if (props.paramId) {
      //   if (props.paramId.toLowerCase().includes("dp")) {
      //     realThresholdArr = realThresholdArr.map((x) => {
      //       return x * 10;
      //     });
      //   }
      // }
      // green yellow orange red purple scarlet-orange
      if (!chart.chartArea) {
        return null;
      }
      const chartYAxis = chart.scales.y;
      const YbottomPixel = chartYAxis.getPixelForValue(chartYAxis.min);
      const YtopPixel = chartYAxis.getPixelForValue(chartYAxis.max);
      const chartHeight = YbottomPixel - YtopPixel;
      const gradient = chart.ctx.createLinearGradient(
        0,
        YtopPixel,
        0,
        YtopPixel + chartHeight
      );
      let testY = chart.scales.y.getPixelForValue(1);
      if (testY > 0) {
        if (chartYAxis.max < realThresholdArr[1]) {
          gradient.addColorStop(0.5, colors[0]);
        } else if (
          chartYAxis.max > realThresholdArr[realThresholdArr.length - 1]
        ) {
          gradient.addColorStop(0.5, colors[colors.length - 1]);
        } else {
          for (let i = realThresholdArr.length - 1; i > 0; i--) {
            let yPixel = chart.scales.y.getPixelForValue(realThresholdArr[i]);
            let gradientRatio = (yPixel - YtopPixel) / chartHeight;
            if (
              (gradientRatio > 0) &
              (gradientRatio !== undefined) &
              (colors[i] !== undefined) &
              (gradient !== undefined)
            ) {
              gradient.addColorStop(gradientRatio - 0.01, colors[i]); // bias could be changed for transition between color segments
              gradient.addColorStop(gradientRatio + 0.01, colors[i - 1]);
            }
          }
        }
      }
      return gradient;
    },
    [colors, props.paramId, userContext]
  );
  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector("div");
    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.style.borderRadius = "3px";
      tooltipEl.style.opacity = 1.5;
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.transform = "translate(-50%, 0)";
      tooltipEl.style.transition = "all .1s ease";
      tooltipEl.style.width = "190px";
      tooltipEl.style.height = "70px";

      const table = document.createElement("table");
      table.style.margin = "0px";
      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  // const nullStateFiller = {
  //   id: "background",
  //   beforeDatasetsDraw(chart, args, pluginOptions) {
  //     const ctx = chart.ctx;
  //     ctx.save();
  //     ctx.globalCompositeOperation = "destination-over";
  //     ctx.fillStyle = "rgba(248, 248, 249, 1)";
  //     ctx.fillRect(0, 0, chart.width, chart.height);
  //     ctx.restore();
  //   },
  // };

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (
      tooltip.body &&
      tooltip.body.length > 0 &&
      tooltip.body[0].lines.length > 0 &&
      tooltip.title
    ) {
      const arrowSvg = document.createElement("div");
      const arrow = `
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10 0L10 10L-2.18557e-07 5L10 0Z" fill="#4E4E4E" />
        </svg>`;
      arrowSvg.innerHTML = arrow;
      const timeLine = document.createElement("p");
      const dateTime = new Date(tooltip.title[0]);
      const pmOrAm = dateTime.getHours() > 12 ? "PM" : "AM";
      const hoursFormat = (dateTime.getHours() % 12) + " " + pmOrAm;
      timeLine.innerHTML = `Time: `;
      const timeBold = document.createElement("strong");
      timeBold.appendChild(document.createTextNode(hoursFormat));
      timeLine.append(timeBold);
      timeLine.style.marginTop = "0px";
      timeLine.style.marginBottom = "5px";
      timeLine.style.color = "#BFBABE";
      timeBold.style.color = "white";
      timeLine.style.fontSize = "14px";
      timeBold.style.fontWeight = 800;
      const dataLine = document.createElement("p");
      const paramName =
        userContext.paramNames[
          userContext.paramIds.indexOf(props.paramSelection)
        ];
      const paramUnit =
        userContext.paramUnits[
          userContext.paramIds.indexOf(props.paramSelection)
        ];
      dataLine.innerHTML = paramName + `: `;
      const dataLineBold = document.createElement("strong");
      dataLineBold.appendChild(
        document.createTextNode(
          Number(tooltip.body[0].lines[0]).toFixed(2) + " " + paramUnit
        )
      );
      dataLine.append(dataLineBold);
      dataLine.style.marginTop = "0px";
      dataLine.style.marginBottom = "0px";
      dataLine.style.color = "#BFBABE";
      dataLine.style.fontSize = "14px";
      dataLineBold.style.color = "white";
      dataLineBold.style.fontWeight = 800;
      while (tooltipEl.firstChild) {
        tooltipEl.removeChild(tooltipEl.firstChild);
      }
      const wrapperOuter = document.createElement("div");
      wrapperOuter.style.display = "flex";
      wrapperOuter.style.alignItems = "center";
      const wrapper = document.createElement("div");
      wrapper.appendChild(timeLine);
      wrapper.appendChild(dataLine);
      wrapper.style.borderRadius = "5px";
      wrapper.style.padding = "10px";
      wrapper.style.backgroundColor = "#4E4E4E";
      wrapperOuter.appendChild(arrowSvg);
      wrapperOuter.appendChild(wrapper);
      tooltipEl.appendChild(wrapperOuter);
    }
    const {
      offsetLeft: positionX,
      offsetTop: positionY,
      offsetWidth: canvasWidth,
      offsetHeight: canvasHeight,
    } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left =
      positionX + tooltip.caretX + tooltipEl.offsetWidth / 2 + "px";
    if (canvasWidth - tooltip.caretX < tooltipEl.offsetWidth) {
      tooltipEl.style.left =
        positionX + tooltip.caretX - tooltipEl.offsetWidth / 2 + "px";
      const arrow = tooltipEl.firstChild.firstChild;
      tooltipEl.firstChild.removeChild(tooltipEl.firstChild.firstChild);
      arrow.style.transform = "scaleX(-1)";
      tooltipEl.firstChild.appendChild(arrow);
    }
    tooltipEl.style.top =
      positionY + tooltip.caretY - tooltipEl.offsetHeight / 2 + "px";
    if (canvasHeight - tooltip.caretY < tooltipEl.offsetHeight) {
      tooltipEl.style.top =
        positionY + tooltip.caretY - tooltipEl.offsetHeight + "px";
    }
    tooltipEl.style.zIndex = "10";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = "10px";
  };
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 1,
      },
      line: {
        spanGaps: true,
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "hour",
          stepSize: 2,
        },
        min: props.nullState
          ? new Date(new Date().getTime() - 25 * 60 * 60 * 1000)
          : labels[0],
        max: props.nullState
          ? new Date(new Date().getTime() - 1 * 60 * 60 * 1000)
          : labels[labels.length - 1],
        grid: {
          display: false,
        },
        ticks: {
          callback: (value, index, values) => {
            // if (index === values.length - 12 || index === values.length - 24) {
            //   return value;
            // }
            if (index === values.length - 1) {
              return "Now";
            } else {
              return value;
            }
          },
        },
      },
      y: {
        ticks: {
          maxTicksLimit: 5,
          padding: 5,
        },
        max:
          props.nullState || Math.max(...props.data) == 0
            ? 200
            : Math.max(...props.data),
        beginAtZero: true,
        grid: {
          drawBorder: false,
        },
        // gridLines: {
        //   drawBorder: false,
        //   // borderDash: [10, 5], // set the border dash pattern
        //   // zeroLineWidth: 0, // hide the zero line
        // },
      },
    },
    plugins: {
      legend: {
        display: props.hideLegend ? false : true,
      },
      tooltip: {
        enabled: false,
        external: externalTooltipHandler,
      },
      customTitle: {
        display: true,
        position: "nearest",
        text: userContext.paramUnits[
          userContext.paramNames.findIndex(
            (element) => element === props.paramSelectionName
          )
        ],
        color: "#989FA7",
      },
      annotation: {
        annotations: {
          line1: {
            // Unique ID for this annotation
            type: "line",
            yMin: 100,
            yMax: 100,
            borderWidth: 0,
            label: {
              content: props.nullState ? "No data is available" : "",
              font: {
                size: "18px",
                //styleName: Heading 3;
                family: "Inter",
                weight: 600,
                height: "26px",
                letterSpacing: "0em",
                textAlign: "left",
              },
              enabled: true,
              position: "center",
              backgroundColor: "rgba(0,0,0,0)",
              color: "rgba(167, 167, 167, 1)",
            },
          },
        },
      },
    },
  };

  const customTitle = {
    id: "customTitle",
    beforeLayout: (chart, args, opts) => {
      const { display, font } = opts;
      if (!display) {
        return;
      }

      const { ctx } = chart;
      ctx.font = font || '12px "Helvetica Neue", Helvetica, Arial, sans-serif';

      const { width } = ctx.measureText(opts.text);
      chart.options.layout.padding.top = 40;
      // chart.options.layout.padding.right = width * 1.1;
    },
    afterDraw: (chart, args, opts) => {
      const { font, text, color, xtitle } = opts;
      const {
        ctx,
        chartArea: { top, bottom, left, right },
      } = chart;
      if (opts.display) {
        ctx.fillStyle = color || Bar.defaults.color;
        ctx.font =
          font || '500 12px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(text, 3, 14);
        // ctx.fillText(xtitle, right + 20, bottom + 20);
      }
    },
  };

  // const getAnnotateColor = (average) => {
  //   for (let i = 1; i < thresholdArr.length; i++) {
  //     if (average < thresholdArr[i] & average > thresholdArr[i-1]) {
  //       return colors[i - 1] + '26';
  //     }
  //   }
  //   return colors[colors.length - 1] + '26';
  // }
  // const getAnnotateWording = (average) => {
  //   for (let i = 1; i < thresholdArr.length; i++) {
  //     if (average < thresholdArr[i] & average > thresholdArr[i-1]) {
  //       return thresholdLabel[i-1] + ' ('+average+')';
  //     }
  //   }
  //   return thresholdArr[thresholdArr - 1] + ' ('+average+')';
  // }

  const eightHourChartOption = () => {
    let chartOptionsCopy = chartOptions;
    // let anotateAverage = (up, low) => {
    //   let sum = 0;
    //   let nonNullCount = 0;
    //   for (let i = up; i < low; i++) {
    //     if (averageDataArr[i] !== null) {
    //       sum += averageDataArr[i];
    //       nonNullCount++;
    //     }
    //   }
    //   let average = Math.round(sum / nonNullCount);
    //   return average;
    // }
    // chartOptionsCopy.plugins['annotation'] = {
    //   annotations: {
    //     box1: {
    //       // Indicates the type of annotation
    //       type: 'box',
    //       xMin: 0,
    //       xMax: 3,
    //       adjustScaleRange: true,
    //       backgroundColor: getAnnotateColor(anotateAverage(0, 3)),
    //       label: {
    //         enabled: true,
    //         content: getAnnotateWording(anotateAverage(0, 3)),
    //         position: {
    //           x: '50%',
    //           y: '0%'
    //         }
    //       },
    //       borderColor: getAnnotateColor(anotateAverage(0, 3)),
    //     },
    //     box2: {
    //       // Indicates the type of annotation
    //       type: 'box',
    //       xMin: 3,
    //       xMax: 7,
    //       adjustScaleRange: true,
    //       backgroundColor: getAnnotateColor(anotateAverage(3, 7)),
    //       label: {
    //         enabled: true,
    //         content: getAnnotateWording(anotateAverage(3, 7)),
    //         position: {
    //           x: '50%',
    //           y: '0%'
    //         }
    //       },
    //       borderColor: getAnnotateColor(anotateAverage(3, 7))
    //     },
    //     box3: {
    //       // Indicates the type of annotation
    //       type: 'box',
    //       xMin: 7,
    //       xMax: 12,
    //       adjustScaleRange: true,
    //       backgroundColor: getAnnotateColor(anotateAverage(7, 11)),
    //       label: {
    //         enabled: true,
    //         content: getAnnotateWording(anotateAverage(7, 11)),
    //         position: {
    //           x: '40%',
    //           y: '0%'
    //         }
    //       },
    //       borderColor: getAnnotateColor(anotateAverage(7, 11))
    //     }
    //   },
    // };
    return chartOptionsCopy;
  };

  useEffect(() => {
    labels.sort(function (a, b) {
      return a - b;
    });
    function generateHourList() {
      return Array.from({ length: 26 }, (_, i) =>
        new Date(Date.now() - i * 60 * 60 * 1000).getHours()
      ).sort((a, b) => a - b);
    }
    var hourList = generateHourList();

    const data = {
      labels: props.nullState ? hourList : labels,
      datasets: [
        {
          data: props.nullState ? [] : props.data,
          fill: true,
          borderColor: "#BE7DDB",
          backgroundColor: "#BE7DDB",
          hoverBackgroundColor: "#9A54B9",
          hoverBorderColor: "#9A54B9",
          borderWidth: 2,
          tension: 0.1,
          spanGaps: false,
          minBarLength: 7,
          barPercentage: 0.5,
          borderRadius: 5,
        },
      ],
    };
    setChartData(data);
  }, [props.data, labels, props.labels]);

  //update color gradient
  useEffect(() => {
    if (drawColor) {
      const chart = chartRef.current;
      const newChartData = {
        ...chartData,
        datasets: chartData.datasets.map((dataset) => ({
          ...dataset,
          borderColor: createGradient(chart),
        })),
      };
      setChartData(newChartData);
      setDrawColor(false);
    }
  }, [drawColor, chartData, createGradient]);

  return (
    <Grid
      container
      className={
        props.deviceView ? classes.rightSectionDeviceView : classes.rightSection
      }
      direction="column"
      sx={{ ...props.style }}
    >
      {props.deviceView ? (
        <div></div>
      ) : (
        <Grid
          item
          height="12%"
          sx={{ display: props.hideTitle ? "none" : "block" }}
        >
          <Typography variant="h4" sx={{ marginBottom: "20px" }}>
            {props.paramSelectionName} Location Average
          </Typography>
        </Grid>
      )}
      <Grid
        item
        height={props.deviceView ? "100%" : "88%"}
        width="100%"
        padding="10px"
        // backgroundColor={
        //   props.nullState ? "rgba(248, 248, 249, 1)" : "rgba(0,0,0,0)"
        // }
      >
        <Bar
          ref={chartRef}
          data={chartData}
          options={props.eightHour ? eightHourChartOption() : chartOptions}
          plugins={
            props.eightHour ? ["chartjs-plugin-annotation", customTitle] : []
          }
        />
      </Grid>
    </Grid>
  );
};
export default AreaAverageGraph;
