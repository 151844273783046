import { useContext } from "react";
import { Route, Routes } from "react-router-dom";

import { DeviceContext, UserContext } from "../../../components/UserContext";
import DeviceView from "../views/DeviceView";
import FloorView from "../views/FloorView";
import OverView from "../views/OverView";

const DataAnalytics = () => {
  const userContext = useContext(UserContext);
  const deviceContext = useContext(DeviceContext).raw;
  const devicewholecontext = useContext(DeviceContext);
  console.log("DADC", devicewholecontext);
  console.log("DAUC", userContext);

  return (
    <Routes>
      <Route path={``} element={<OverView />} />
      <Route path={`overview/:overviewId`} element={<OverView />} />
      <Route path={`floor-detail/:floorId`} element={<FloorView />} />
      <Route path={`device-detail/:deviceId`} element={<DeviceView />} />
    </Routes>
  );
};
export default DataAnalytics;
