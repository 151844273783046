import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useAuthenticator } from "@aws-amplify/ui-react";
import { Analytics } from "aws-amplify";

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import DehazeIcon from "@mui/icons-material/Dehaze";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { ReactComponent as ViolettLogoHorizontal } from "../../util/logo/websiteHeader.svg";
import UserInfoDropdownContainer from "./UserInfoDropdownContainer";

const useStyles = makeStyles({
  navbar: {
    position: "fixed",
    top: 0,
    zIndex: 1000,
    width: "100vw",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.03)",
    backgroundColor: "#ffffff",
  },
  navtabs: {
    "& .MuiTabs-scroller.MuiTabs-fixed": {
      height: "fit-content",
    },
    "& .MuiTabs-indicator": {
      top: "35px",
      backgroundColor: "#ABB0B5",
    },
    "& .MuiTab-root": {
      paddingLeft: "0",
      paddingRight: "0",
      marginLeft: "1.08vw",
      marginRight: "1.08vw",
      "&.Mui-selected": {
        color: "#2E3134",
        fontWeight: "700",
      },
    },
  },
  accordionSummary: {
    height: "72px",
    // flexDirection: "row-reverse",
    paddingLeft: "20px",
    paddingRight: "20px",
    margin: "0",
    "& .MuiAccordionSummary-content": {
      margin: "0",
      justifyContent: "space-between",
    },
  },
  accordionList: {
    paddingBottom: "0",
    width: "100%",
  },
});

export default function NavBar(props) {
  let location = useLocation();
  let history = useNavigate();
  const classes = useStyles();
  const [accordionExpand, setAccordionExpand] = useState(false);
  const [tabvalue, setTabValue] = React.useState(0);
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const theme = useTheme();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAccordionClose = () => {
    setAccordionExpand(false);
  };

  useEffect(() => {
    switch (true) {
      case /home/.test(location.pathname):
        setTabValue(0);
        handleTabClick("Home Tab");
        break;
      case /data-analytics/.test(location.pathname):
        setTabValue(1);
        handleTabClick("Data-Analytics Tab");
        break;
      case /management/.test(location.pathname):
        setTabValue(2);
        handleTabClick("Management Tab");
        break;
      case /notification/.test(location.pathname):
        setTabValue(3);
        handleTabClick("Notification Tab");
        break;
      default:
        setTabValue(0);
        handleTabClick("Home Tab");
        break;
    }
  }, [location]);

  const handleTabClick = (tabName) => {
    Analytics.record({
      name: "Tab Viewed",
      attributes: {
        tabName: tabName,
      },
    });
  };

  return (
    <nav className={classes.navbar}>
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        elevation={0}
        expanded={accordionExpand}
      >
        <AccordionSummary
          elevation={0}
          className={classes.accordionSummary}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
          expandIcon={
            <DehazeIcon
              onClick={() => {
                setAccordionExpand((prev) => !prev);
              }}
              sx={{ display: { xs: "flex", md: "none" }, fontSize: "1.5rem" }}
            />
          }
        >
          <Box style={{ display: "flex" }}>
            <Box
              onClick={() => {
                history("/dashboard/home");
              }}
              height="30px"
              marginTop="10px"
              marginBottom="10px"
            >
              <ViolettLogoHorizontal />
            </Box>
            {/* screen greater than medium display tabs, else display in hamburger menu*/}
            <Box
              id="tabs"
              sx={{
                width: { xs: 0, md: "auto" },
                visibility: { xs: "hidden", md: "visible" },
              }}
            >
              <Tabs
                value={tabvalue}
                onChange={handleTabChange}
                className={classes.navtabs}
                style={{
                  "& .Mui-indicator": {
                    width: "70%",
                  },
                }}
              >
                <Tab
                  disableRipple
                  component={Link}
                  sx={{ textTransform: "none" }}
                  label="Dashboard"
                  to="/dashboard/home"
                />
                <Tab
                  disableRipple
                  component={Link}
                  sx={{ textTransform: "none" }}
                  label="Data Analytics"
                  to="/dashboard/data-analytics"
                />
                <Tab
                  disableRipple
                  component={Link}
                  sx={{ textTransform: "none" }}
                  label="Management"
                  to="/dashboard/management/device/list-view"
                />
                <Tab
                  disableRipple
                  component={Link}
                  sx={{ textTransform: "none" }}
                  label="Notifications"
                  to="/dashboard/notification"
                />
              </Tabs>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              [theme.breakpoints.down("md")]: {
                display: "none",
              },
            }}
            style={{}}
          >
            <UserInfoDropdownContainer />
            {/* <NotificationDropdownContainer setValue={props.setValue} /> */}
            {/* <Tab
              disableRipple
              component={Link}
              sx={{
                minWidth: "25px",
                paddingLeft: "10px",
                paddingRight: "20px",
              }}
              to="/dashboard/setting"
              label={<SettingsOutlinedIcon color="action" fonsize="medium" />}
            /> */}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{ display: { xs: "flex", md: "none" } }}
          style={{ paddingTop: "0px", paddingBottom: "0px" }}
        >
          <List className={classes.accordionList}>
            <Divider />
            <ListItem
              onClick={handleAccordionClose}
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
            >
              <ListItemButton component={Link} to="/dashboard/home">
                <ListItemText primary="Dashboard" />
              </ListItemButton>
            </ListItem>
            <ListItem
              onClick={handleAccordionClose}
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
            >
              <ListItemButton component={Link} to="/dashboard/data-analytics">
                <ListItemText primary="Data Analytics" />
              </ListItemButton>
            </ListItem>
            <ListItem
              onClick={handleAccordionClose}
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
            >
              <ListItemButton
                component={Link}
                to="/dashboard/management/device/list-view"
              >
                <ListItemText primary="Device Management" />
              </ListItemButton>
            </ListItem>
            <ListItem
              onClick={handleAccordionClose}
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
            >
              <ListItemButton component={Link} to="/dashboard/notification">
                <ListItemText primary="Notification" />
              </ListItemButton>
            </ListItem>

            <ListItem
              onClick={handleAccordionClose}
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
            >
              <ListItemButton component={Link} to="/dashboard/setting">
                <ListItemText primary="Account Settings" />
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem style={{ paddingTop: "0px", paddingBottom: "0px" }}>
              <ListItemButton disabled style={{ opacity: "1" }}>
                <ListItemIcon>
                  <AccountCircleOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={user.attributes.email} />
              </ListItemButton>
            </ListItem>
            <ListItem style={{ paddingTop: "0px", paddingBottom: "0px" }}>
              <ListItemButton
                onClick={() => {
                  handleAccordionClose();
                  history("/");
                  signOut();
                }}
              >
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </nav>
  );
}
