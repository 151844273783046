import { createTheme } from "@mui/material/styles";

export default createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 680,
      lg: 1024,
      xl: 1440,
    },
  },
  palette: {
    primary: {
      main: "#BE7DDB",
      light: "#E3B2FA",
      dark: "#7815B8",
    },
    secondary: {
      main: "#F7CC92",
    },
    green: {
      main: "#6FCF97",
    },
    blue: {
      main: "#3B7DED",
    },
    offline: {
      main: "#2E3134",
    },
    yellow: {
      main: "#F3C434",
    },
    orange: {
      main: "#FF9213",
    },
    red: {
      main: "#EB5757",
      dark: "#EB5757",
    },
    unselected: {
      main: "#CDCFD2",
    },
    purple: {
      main: "#9B51E0",
    },
    brown: {
      main: "#C55A1E",
    },
    active: {
      main: "#219653",
    },
    alert: {
      main: "#ED1C24",
    },
    textPrimary: {
      main: "#2E3134",
      light: "#CDCFD2",
    },
    textSecondary: {
      main: "#697077",
    },
    grayIcon: {
      main: "#899097",
    },
    subText: {
      main: "#989FA7",
    },
    darkBorder: {
      main: "#ABB0B5",
    },
    dropdown: {
      main: "#CDCFD2",
    },
    lightBorder: {
      main: "#E0E2E5",
    },
    selectedBg: {
      main: "#FAFAFB",
    },
    sectionBg: {
      main: "#FBFCFD",
    },
    h2: {
      main: "#858D97",
    },
    link: {
      main: "#BE7DDB",
    },
    headingText: {
      main: "#333333",
    },
    Updated_Tertiary: {
      main: "#8977E4",
    },
    lightGrey: {
      main: "#F5F2F0",
    },
    darkGrey: {
      main: "#646872",
    },
    dark: {
      main: "#242424",
    },
    midGrey: {
      main: "#BFBABE",
    },
    totalOffset: 0.2,
  },
  typography: {
    h1: {
      fontFamily: "Inter",
      fontSize: "30px",
      fontWeight: 600,
      lineHeight: "36px",
      letterSpacing: "-0.01em",
      textAlign: "left",
    },
    h2: {
      //styleName: Heading/Heading 2;
      fontFamily: "Inter",
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "30px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    h3Light: {
      //styleName: Heading/Heading 3 SemiBold;
      fontFamily: "Inter",
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    h3: {
      //styleName: Heading/Heading 3 SemiBold;
      fontFamily: "Inter",
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "24px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    h4: {
      //styleName: Heading/Heading 4;
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "20px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    h5: {
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "-0.03em",
      textAlign: "left",
    },
    bodyText: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    subText: {
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "18px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    caption: {
      fontFamily: "Inter",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    link: {
      //styleName: Body/Link Text;
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0em",
      textAlign: "left",
      textDecoration: "underline",
    },
    input: {
      //styleName: Components/Input Field Text;
      fontFamily: "Inter",
      fontSize: "15px",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "-0.03em",
      textAlign: "left",
    },
    tooltipHead: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "16px",
      lineHeight: "18px",
      color: "#BFBABE",
    },
    tooltipAccent: {
      fontWeight: 700,
      color: "#FFFFFF",
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 600,
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            letterSpacing: "-0.03em",
            border: "solid #BE7DDB",
            boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.08)",
            boxSizing: "border-box",
            background: "#FFFFFF",
            color: "#BE7DDB",
            textTransform: "capitalize",
            "&:hover": {
              background: "#F5F2F0",
              border: "solid #9A54B9",
              boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.08)",
              boxSizing: "border-box",
            },
            "&:focus": {
              background: "#F5F2F0",
              border: "solid #9A54B9",
              boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.08)",
              boxSizing: "border-box",
              outline: "2px solid #BFBABE",
              outlineOffset: "2px",
            },
            "&:active": {
              background: "#F5F2F0",
              border: "solid #7815B8",
              color: "#7815B8",
              boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.08)",
              boxSizing: "border-box",
            },
            "&:disabled": {
              background: "#EEF1F4",
              color: "#899097",
            },
          },
        },
        {
          props: { variant: "contained" },
          style: {
            background: "#BE7DDB",
            boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.08)",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 600,
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            letterSpacing: "-0.03em",
            color: "#FFFFFF",
            textTransform: "capitalize",
            "&:hover": {
              background: "#9A54B9",
              boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.08)",
              color: "#FFFFFF",
            },
            "&:focus": {
              background: "#9A54B9",
              boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.08)",
              color: "#FFFFFF",
              outline: "2px solid #BFBABE",
              outlineOffset: "2px",
              border: "#9A54B9",
            },
            "&:active": {
              background: "#7815B8",
              color: "#FFFFFF",
              boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.08)",
            },
            "&:disabled": {
              background: "#F5F2F0",
              color: "#BFBABE",
            },
          },
        },
      ],
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#8977E4",
          cursor: "pointer",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          elevation: 2,
          position: "relative",
          alignItems: "center",
          boxSizing: "borderBox",
          background: "#FFFFFF",
          /* Border - Light */
          border: "1px solid #E0E2E5",
          /* Section Basic Shadow */
          boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
          borderRadius: "10px",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: "#4E4E4E",
        },
        tooltip: {
          backgroundColor: "#4E4E4E",
          borderRadius: "5px",
          color: "#BFBABE",
          fontFamily: "Roboto",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "18px",
          padding: "10px",
          gap: "10px",

          "& p": {
            margin: "0px",
            marginBottom: "10px",
            marginRight: "0px",
            paddingRight: "0px",
            // padding: "0px",
          },
          "& p:last-child": {
            marginBottom: "0px",
            // padding: "0px",
          },
          "& b": {
            fontWeight: 700,
            color: "#FFFFFF",
          },
          "& h4": {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 800,
            fontSize: "16px",
            lineHeight: "18px",
            marginBottom: "10px",
            marginTop: "0px",
          },
        },
      },
    },
  },
});
