import React from "react";

import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import AeroButton from "../../../components/AeroButton";
import AeroTextInput from "../../../components/AeroTextInput";

const useStyle = makeStyles({
  body: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  h1: {
    marginTop: "106px !important",
  },
  text: {
    marginTop: "29px !important",
  },
  inputGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 24,
    marginBottom: 112,
  },
});

export default function Email(props) {
  const classes = useStyle();
  return (
    <div className={classes.body} id={"keep"}>
      <Typography variant={"h1"} className={classes.h1}>
        Receive our updates 📬
      </Typography>
      <Typography variant={"body"} className={classes.text}>
        We are still updating and implementing new features on our website.{" "}
      </Typography>
      <Typography variant={"body"}>
        Receive our updates at the earliest time by subscribing our newsletter.
      </Typography>
      <div className={classes.inputGroup}>
        <AeroTextInput
          placeholder={"Enter your email address"}
          color="primary"
        />
        <AeroButton
          variant={"contained"}
          value={<Typography variant={"button2"}>Subscribe</Typography>}
        />
      </div>
    </div>
  );
}
