import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import ErrorIcon from "@mui/icons-material/Error";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, CircularProgress, Grid, IconButton, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";

import { refreshRealtimeStatus } from "../../../components/AccessDBGlobalFunctions";
import Dropdown from "../../../components/Dropdown";
import EditSensorsPopup from "../../../components/EditSensorsPopup";
import Legends from "../../../components/Legends";
import MapWithAllSensors from "../../../components/MapWithAllSensors";
import Messager from "../../../components/Messager";
import UploadMapPopup from "../../../components/UploadMapPopup";
import { UserContext } from "../../../components/UserContext";
import PaperOverlay from "../components/PaperOverlay";
import SensorsTable from "../components/SensorsTable";
import exampleMap from "../util/ExampleMap.png";

const PaperCustomized = styled(Paper)(({ theme, background }) => ({
  ...theme.typography.body2,
  padding: "20px",
  elevation: 2,
  position: "relative",
  width: "100%",
  height: "100%",
  alignItems: "center",
  boxSizing: "borderBox",
  background: background ? background : "#FFFFFF",
  /* Border - Light */
  border: "1px solid #E0E2E5",
  /* Section Basic Shadow */
  boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
  borderRadius: "10px",
  [theme.breakpoints.down("sm")]: {
    padding: "5px",
  },
}));

const PaperCustomizedDashed = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: "#FCFCFC;",
  height: "100%",
  borderColor: "lightgray",
  borderWidth: "2px",
  borderStyle: "dashed",
  display: "flex",
  elevation: "0",
  boxShadow: "none",
  alignItems: "center",
  justifyContent: "center",
  boxSizing: "border-box",
}));

const Layer2Container = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    padding: "10px",
    height: "380px",
  },
  color: "#FFFFFF",
  width: "100%",
  boxShadow: "none",
  background: "#FFFFFF",
  border: "1px solid #E0E2E5",
  borderRadius: "5px",
}));

export default function RealTimeStatusUpdates(props) {
  const [imageUploaded, setImageUploaded] = useState(false);
  const [floorOptions, setFloorOptions] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [ifNoMapNoSensors, setIfNoMapNoSensors] = useState(true);
  const [ifMapWithoutSensor, setIfMapWithoutSensor] = useState(false);
  const [uploadMapmModal, setUploadMapmModal] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState(<></>);
  const [realTimeRefreshLoading, setRealTimeRefreshLoading] = useState(false);
  const userContext = useContext(UserContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const sensorTableRef = useRef();

  useEffect(() => {
    setIfNoMapNoSensors(
      !userContext ||
        (userContext &&
          userContext.userEmail &&
          (Object.keys(userContext.layerNameToMapUrl).length === 0 ||
            (Object.keys(userContext.layerNameToMapUrl).length === 1 &&
              Object.keys(userContext.layerNameToMapUrl)[0] ===
                "Unassigned")) &&
          (Object.keys(userContext.layerToSensors).length === 0 ||
            (Object.keys(userContext.layerToSensors).length === 1 &&
              Object.keys(userContext.layerToSensors)[0] === "Unassigned")))
    );
    setIfMapWithoutSensor(
      userContext &&
        userContext.userEmail &&
        userContext.layerToSensors &&
        userContext.layerToSensors[selectedFloor] &&
        userContext.layerToSensors[selectedFloor].length === 0
    );
  }, [userContext, selectedFloor]);

  useEffect(() => {
    if (
      userContext.userEmail &&
      userContext.layerNameToMapUrl &&
      userContext.layerToSensors
    ) {
      let options = Object.keys(userContext.layerToSensors);
      let index = options.indexOf("Unassigned");
      if (index > -1) {
        options.splice(index, 1);
      }
      setFloorOptions(options);
      if (!selectedFloor) {
        setSelectedFloor(options[0]);
      }
    }
    // const isLoading = userContext.loading;
    // setLoading(isLoading);
  }, [userContext]);

  function handleNoMapNoSensorPaperOverlay() {
    // user don't have any map
    if (ifNoMapNoSensors) {
      return (
        <PaperOverlay>
          <Grid container sx={{ height: "100%" }}>
            <Grid
              container
              item
              xs={12}
              lg={8}
              alignItems="center"
              justifyContent="center"
            >
              <UploadMapPopup
                setLocation={setSelectedFloor}
                buttonText="Upload Map"
                setImageUploaded={setImageUploaded}
                imageUploaded={imageUploaded}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              lg={4}
              alignItems="center"
              justifyContent="center"
            >
              <EditSensorsPopup
                add
                showMessage={showMessage}
                confirmCallback={() => {
                  props.setUrlValue("/management/list-view");
                  navigate("/dashboard/management/list-view", {
                    replace: true,
                  });
                }}
              />
            </Grid>
          </Grid>
        </PaperOverlay>
      );
    }
  }

  const handleAddMap = () => {
    setUploadMapmModal(true);
  };

  function showMessage(message) {
    setMessageOpen(true);
    setMessage(message);
  }

  return (
    <Grid
      height="100%"
      container
      item
      xs={12}
      lg={12}
      paddingBottom={{ xs: "20px", lg: "0" }}
    >
      <Grid
        item
        paddingBottom={{ xs: "10px", md: "20px" }}
        display={{ md: "flex", xs: "block" }}
        direction="row"
        height={{ md: "70px", xs: "fit-content" }}
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Grid
          marginBottom={{ xs: "10px", md: "0px" }}
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
        >
          <Box display="flex">
            <Typography
              variant="h2"
              color={theme.palette.h2.main}
              marginRight="10px"
            >
              Real-time Status
            </Typography>
          </Box>
        </Grid>
        <Grid
          display="flex"
          justifyContent={{ xs: "flex-start", md: "flex-end" }}
          sx={{
            flexDirection: "row",
            marginLeft: "2px",
            position: "relative",
          }}
          width="fit-content"
        >
          <Grid
            item
            minWidth={{ xs: "200px" }}
            marginBottom={{ xs: "10px", sm: "0px" }}
            marginRight={{ xs: "0px", sm: "10px", lg: "20px" }}
          >
            <Dropdown
              selection={" " + selectedFloor}
              setSelection={setSelectedFloor}
              placeholder={"Floor"}
              value="dashboard-"
              options={floorOptions}
              style={{ padding: "0" }}
              extendable={true}
              extendWord={"Add a new map"}
              extendCallBack={handleAddMap}
            />
          </Grid>
          <UploadMapPopup
            setLocation={setSelectedFloor}
            showMessage={showMessage}
            externalOpenControl
            open={uploadMapmModal}
            setOpen={setUploadMapmModal}
            setImageUploaded={setImageUploaded}
            imageUploaded={imageUploaded}
          />
          <Messager
            open={messageOpen}
            onClose={() => {
              setMessageOpen(false);
            }}
            message={message}
          />
          <Grid item>
            <Dropdown
              dropdownPurpose={"Data Type"}
              selection={props.paramSelection}
              setSelection={props.setParamSelection}
              placeholder="Parameter"
              value="dashboard-PM"
              tooltips={
                userContext.paramNamesTooltips
                  ? [...userContext.paramNamesTooltips]
                  : []
              }
              options={
                userContext.paramNames ? [...userContext.paramNames] : []
              }
            />
          </Grid>
          {/* <PaperOverlay sx={{top: theme.spacing(3.5), marginLeft: `calc(0% - ${theme.spacing(2.5)})`, paddingRight: `calc(${theme.spacing(2.5)} + ${theme.spacing(2.5)})`}}/> */}
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        lg={12}
        height={{ xs: "fit-content", lg: "calc(100% - 70px)" }}
        style={{ minHeight: "calc(100% - 70px)" }}
      >
        <PaperCustomized background="#FCFCFC" height="100%">
          <Grid item container height="100%" width="100%" xs={12} lg={12}>
            {userContext.error !== null ? (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ErrorIcon sx={{ width: "6vh", height: "10vh" }} />
                <Typography variant="h3" color={theme.palette.h2.main}>
                  Oops! Something Wrong: {userContext.error}
                </Typography>
              </Box>
            ) : userContext.loading ? (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress sx={{ marginBottom: "5vh" }} />
                <Typography variant="h3" color={theme.palette.h2.main}>
                  Loading ...
                </Typography>
              </Box>
            ) : (
              <Grid
                container
                item
                xs={12}
                lg={12}
                justifyContent="center"
                sx={{ position: "relative", width: "100%", height: "100%" }}
              >
                <Grid
                  container
                  item
                  width={{
                    xs: "100%",
                    lg: "calc(100% - max(33.33%, 240px))",
                  }}
                  height={{ xs: "fit-content", sm: "60vw", lg: "100%" }}
                  maxHeight={{ xs: "600px", lg: "none" }}
                  direction="column"
                  paddingRight={{ xs: 0, lg: "20px" }}
                >
                  <Grid
                    id="layer2-container"
                    component={Layer2Container}
                    sx={{
                      borderWidth: "1px",
                    }}
                    height="100%"
                    item
                    container
                    direction="column"
                  >
                    {ifNoMapNoSensors ? (
                      <PaperCustomizedDashed>
                        <img
                          src={exampleMap}
                          alt="map for demo"
                          style={{ height: "100%", width: "100%" }}
                        />
                      </PaperCustomizedDashed>
                    ) : (
                      <Grid
                        item
                        width="100%"
                        maxWidth="100%"
                        height="100%"
                        maxHeight="100%"
                      >
                        {realTimeRefreshLoading ? (
                          <Box
                            sx={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress sx={{ marginBottom: "5vh" }} />
                            <Typography
                              variant="h3"
                              color={theme.palette.h2.main}
                            >
                              Refreshing ...
                            </Typography>
                          </Box>
                        ) : (
                          <>
                            <MapWithAllSensors
                              type={props.paramSelection}
                              id="dashboard-realtime-map"
                              map={userContext.layerNameToMapUrl[selectedFloor]}
                              mapFloor={selectedFloor}
                              style={{ height: "90%", width: "90%" }}
                            />
                            <Legends
                              simplify={
                                props.paramSelection
                                  ? !props.paramSelection
                                      .toLowerCase()
                                      .includes("pm")
                                  : false
                              }
                            />
                          </>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid
                  container
                  component={Layer2Container}
                  item
                  minWidth="240px"
                  width={{ xs: "100%", lg: "33.33%" }}
                  height={{ xs: "180px", sm: "30vw", lg: "100%" }}
                  maxHeight={{ xs: "219px", lg: "none" }}
                  marginTop={{ xs: "5px", sm: "20px", lg: "0px" }}
                  padding="calc(max(10px, 3%))"
                  direction="column"
                >
                  <Grid
                    item
                    style={{
                      width: "100%",
                      marginBottom: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h3"
                      color={theme.palette.textPrimary.main}
                    >
                      Devices
                    </Typography>
                    {/* <IconButton
                        onClick={() => {
                          setRealTimeRefreshLoading(true);
                          refreshRealtimeStatus(
                            userContext,
                            setRealTimeRefreshLoading
                          );
                        }}
                      >
                        <RefreshIcon />
                      </IconButton> */}
                  </Grid>
                  <Grid
                    item
                    xs
                    style={{ width: "100%", overflowX: "auto" }}
                    ref={sensorTableRef}
                  >
                    {ifNoMapNoSensors ? (
                      <SensorsTable
                        setLocation={setSelectedFloor}
                        setUrlValue={props.setUrlValue}
                        paramSelection={props.paramSelection}
                        floor={selectedFloor}
                        refreshing={realTimeRefreshLoading}
                      />
                    ) : ifMapWithoutSensor ? (
                      <PaperCustomizedDashed style={{ height: "100%" }}>
                        <EditSensorsPopup
                          add
                          showMessage={showMessage}
                          confirmCallback={() => {
                            props.setUrlValue("/management/list-view");
                            navigate("/dashboard/management/list-view", {
                              replace: true,
                            });
                          }}
                        />
                      </PaperCustomizedDashed>
                    ) : (
                      <SensorsTable
                        setLocation={setSelectedFloor}
                        setUrlValue={props.setUrlValue}
                        paramSelection={props.paramSelection}
                        floor={selectedFloor}
                        refreshing={realTimeRefreshLoading}
                      />
                    )}
                  </Grid>
                </Grid>

                {/* the overlay for empty dashboard */}
                {handleNoMapNoSensorPaperOverlay()}
              </Grid>
            )}
          </Grid>
        </PaperCustomized>
        <Box width="100%" display="flex" marginTop="16px">
          <Box
            style={{
              alignItems: "center",
              marginRight: "10px",
              width: "fit-content",
            }}
          >
            <Typography variant="h5" style={{ color: "#989FA7" }}>
              {userContext.sensorObjects &&
              userContext.sensorObjects.collectedTime
                ? "Data updated at " +
                  new Date(
                    userContext.sensorObjects.collectedTime
                  ).toLocaleTimeString()
                : ""}
            </Typography>
          </Box>
          <IconButton
            style={{ padding: "0" }}
            onClick={() => {
              setRealTimeRefreshLoading(true);
              refreshRealtimeStatus(userContext, setRealTimeRefreshLoading);
            }}
          >
            <RefreshIcon color="#c0c3c8" />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
}
