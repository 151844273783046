import React from "react";

import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import AeroButton from "../../../components/AeroButton";
import bannerImg from "../../../util/landing/banner.png";

const useStyle = makeStyles({
  banner: {
    marginTop: "72px",
    width: "100%",
    overflow: "hidden",
    backgroundImage: "url(" + bannerImg + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  bannerContent: {
    marginTop: 90,
    marginBottom: 101,
    width: (580 / 1440) * 100 + "%",
    position: "relative",
    // bottom: 421 + 150,
    left: (800 / 1440) * 100 + "%",
    zIndex: 2,
  },
  subTitle: {
    marginTop: "23px !important",
    marginBottom: "71px !important",
  },
  body: {
    marginTop: "0",
    marginBottom: "24px",
  },
});

export default function Banner(props) {
  const classes = useStyle();
  const theme = useTheme();
  return (
    <div className={classes.banner} id={"Home"}>
      <div className={classes.bannerContent}>
        <div>
          <Typography variant={"h1"} color={theme.palette.textPrimary2.main}>
            One stop solution
          </Typography>
          <Typography
            variant={"h1"}
            color={theme.palette.textPrimary2.main}
            display="inline"
          >
            For
          </Typography>
          <Typography variant={"h1"} color={"primary"} display="inline">
            &nbsp;indoor air quality
          </Typography>
        </div>
        <Typography
          variant={"h2"}
          className={classes.subTitle}
          color={theme.palette.textPrimary2.main}
        >
          Powered by AI &#38; sensor networks
        </Typography>
        <p className={classes.body}>
          <Typography variant={"body"} color={theme.palette.textPrimary2.main}>
            AeroSpec is a cloud-based environment monitoring platform that
            continuously monitors indoor air quality and provides in-depth
            analysis and actionable insights powered by artificial intelligence.
          </Typography>
        </p>
        <AeroButton
          value={<Typography variant="button">Request a demo</Typography>}
          variant={"contained"}
          large
        />
      </div>
    </div>
  );
}
