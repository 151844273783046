import React, { useContext } from "react";
import Draggable from "react-draggable";

import { Tooltip } from "@mui/material";

import { UserContext } from "../../../components/UserContext";

const Marker = React.forwardRef((props, ref) => {
  const userContext = useContext(UserContext);
  let initialDeltaPosition = { x: 0, y: 0 };
  if (props.position) {
    initialDeltaPosition = {
      x: parseFloat(props.position.x_coordinate),
      y: parseFloat(props.position.y_coordinate),
    };
  }

  let [activeDrags, setActiveDrags] = React.useState(0);
  const [deltaPosition, setDeltaPosition] =
    React.useState(initialDeltaPosition); //position after change

  const handleDrag = (e, ui) => {
    const { x, y } = deltaPosition;
    setDeltaPosition({
      x: x + ui.deltaX,
      y: y + ui.deltaY,
    });
  };

  const onStart = () => {
    setActiveDrags(activeDrags + 1);
  };

  const onStop = () => {
    setActiveDrags(activeDrags - 1);
    if (props.setFinalPosition) {
      props.setFinalPosition({
        x_coordinate:
          props.positionPct.x_coordinate + (deltaPosition.x / imageWidth) * 100,
        y_coordinate:
          props.positionPct.y_coordinate +
          (deltaPosition.y / imageHeight) * 100,
      });
    }
  };

  // For controlled component
  const onControlledDrag = (e, position) => {};

  const onControlledDragStop = (e, position) => {
    onControlledDrag(e, position);
    onStop();
  };

  let dragHandlers = null;
  if (props.disableDraggable) {
    dragHandlers = { onStart: () => false };
  } else {
    dragHandlers = {
      onStart: onStart,
      onStop: onControlledDragStop,
      onDrag: handleDrag,
    };
  }

  const { imageHeight, imageWidth } = props;

  let size = 19;
  if (props.size) {
    size = props.size;
  }

  let draggableBounds = {
    top: (-props.positionPct.y_coordinate * imageHeight) / 100 + size / 2,
    left:
      -imageWidth -
      (props.positionPct.x_coordinate * imageWidth) / 100 +
      size / 2,
    right: (-props.positionPct.x_coordinate * imageWidth) / 100 - size / 2,
    bottom:
      imageHeight -
      (props.positionPct.y_coordinate * imageHeight) / 100 -
      size / 2,
  };
  const offset = -size / 2;
  let markerStylePosition = {
    position: "absolute",
    right: `calc(${-props.positionPct.x_coordinate}% + ${offset}px)`, // Adjust left position
    top: `calc(${props.positionPct.y_coordinate}% + ${offset}px)`,
    width: size,
  };

  const getPosition = () => {
    let result = {
      x_coordinate:
        "" + (-100 * (initialDeltaPosition.x + deltaPosition.x)) / imageWidth,
      y_coordinate:
        "" + (100 * (initialDeltaPosition.y + deltaPosition.y)) / imageHeight,
    };
    return result;
  };
  return (
    <div
      id="test"
      onClick={(e) => {
        if (props.onClick) {
          props.onClick(e);
        }
      }}
      style={markerStylePosition}
    >
      <Draggable
        bounds={draggableBounds}
        {...dragHandlers}
        ref={ref ? ref : null}
        getPosition={getPosition}
      >
        <div>
          <Tooltip
            arrow
            placement="top"
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, 10], // Adjusts the gap to the sensor point (vertical)
                  },
                },
              ],
            }}
            title={
              <div>
                <h4>{props.deviceName}</h4>
                {userContext &&
                userContext.paramIds &&
                userContext.paramNames &&
                userContext.paramUnits &&
                props.paramSelection ? (
                  userContext.paramIds.map((paramId) => {
                    const paramNumIndex = userContext.paramIds.indexOf(paramId);
                    const BOrP = `${
                      paramId === props.paramSelection ? "b" : "p"
                    }`;
                    let data = null;
                    if (props.tooltipData) {
                      data = props.tooltipData[paramId];
                      if (
                        userContext.sensorObjects &&
                        userContext.sensorObjects[props.device] &&
                        userContext.sensorObjects[props.device][paramId]
                      ) {
                        data = userContext.sensorObjects[props.device][paramId];
                      }
                    }
                    return (
                      <p style={{ marginBottom: "5px" }}>
                        <BOrP style={{ marginBottom: "5px" }}>
                          {userContext.paramNames[paramNumIndex]}:{" "}
                          {data ? data : "--"}{" "}
                          {data ? userContext.paramUnits[paramNumIndex] : ""}
                        </BOrP>
                      </p>
                    );
                  })
                ) : (
                  <div></div>
                )}
                <p
                  style={{
                    color: "#989FA7",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    letterSpacing: "-0.03em",
                    textAlign: "left",
                    marginTop: "10px",
                  }}
                >
                  {"Updated at " +
                    new Date(
                      userContext.sensorObjects.collectedTime
                    ).toLocaleTimeString()}
                </p>
              </div>
            }
            className={"tooltip"}
            disableHoverListener={props.disableTooltip ? true : false}
          >
            <svg
              width={size}
              height={size}
              viewBox={`0 0 ${size} ${size}`}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx={size / 2}
                cy={size / 2}
                r={size / 2}
                fill={props.color ? props.color : "black"}
                fill-opacity="0.5"
              />
              <circle
                cx={size / 2}
                cy={size / 2}
                r={size / 2 - 4}
                fill={props.color ? props.color : "black"}
              />
            </svg>
          </Tooltip>
        </div>
      </Draggable>
    </div>
  );
});

export default Marker;
