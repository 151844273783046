import React, { useContext, useRef, useState } from "react";

import { Storage } from "aws-amplify";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Chip,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";

import PropTypes from "prop-types";

import InputLabelCustomized from "../features/home/components/InputLabelCustomized";
import { postNewMapToDB, updateMapInDB } from "./AccessDBGlobalFunctions";
import PopupModal from "./PopupModal";
import { UserContext } from "./UserContext";

/**
 * @param {string} props.buttonText name of the button for upload map popup
 * @param {boolean} props.outlined if the button is outlined
 * @param {boolean} props.imageUploaded it is the parent state for setImageUpload function
 * to set. When there is image upload, this boolean state toggles and triggers parent page
 * rerender with updated map
 * @param {function} props.setImageUploaded the parent state function to toggle imageUploaded
 * state in order to trigger parent rerendering
 * @param {boolean} props.externalOpenControl if the component is opened externally, it will not
 * render the button. Note, with external controlling, [open, setOpen] must be passed by props.
 */

const UploadMapPopup = (props) => {
  const {
    buttonText,
    outlined,
    imageUploaded,
    setImageUploaded,
    setCurrentMapName,
    showMessage,
    update,
  } = props;
  const context = useContext(UserContext);

  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const theme = useTheme();
  const mapNameInputRef = React.createRef();
  const selectedFileNameRef = useRef(null);
  const hiddenFileInput = useRef(null);
  const noMapNameInputErrorRef = useRef(null);
  const noMapFileInputErrorRef = useRef(null);
  const handleClickOpen = () => {
    if (props.externalOpenControl) {
      props.setOpen(true);
    } else {
      setOpen(true);
    }
  };
  const handleClose = () => {
    if (props.externalOpenControl) {
      setUploading(false);
      props.setOpen(false);
    } else {
      setUploading(false);
      setOpen(false);
    }
  };

  const updateMapHandle = (fileUploaded) => {
    setUploading(true);
    let inputValue = mapNameInputRef.current.querySelector("input").value;
    let newMapName =
      props.defaultValue === inputValue ? props.defaultValue : inputValue;
    if (fileUploaded) {
      // upload image to DB
      Storage.put(
        `UsersData/${context.userEmail}/indoorMapBackground/` +
          fileUploaded.name,
        fileUploaded,
        {
          acl: "public-read",
          contentType: "image/png",
        }
      )
        .then(() => {
          updateMapInDB(
            fileUploaded.name,
            props.defaultValue,
            newMapName,
            context,
            setCurrentMapName
          );
        })
        .then(() => {
          if (setCurrentMapName) {
            setCurrentMapName(newMapName);
          }
          handleClose();
        })
        .then(() => {
          if (props.setLocation) {
            props.setLocation(newMapName);
          }
        })
        .catch((error) => {
          console.error("Error upload map image: ", error);
          setUploading(false);
        });
    } else {
      updateMapInDB(
        null,
        props.defaultValue,
        newMapName,
        context,
        setCurrentMapName
      )
        .then(() => {
          if (setCurrentMapName) {
            setCurrentMapName(newMapName);
          }
          handleClose();
        })
        .then(() => {
          if (props.setLocation) {
            props.setLocation(newMapName);
          }
        })
        .catch((error) => {
          console.error("Error upload map image: ", error);
          setUploading(false);
        });
    }
  };

  const handleNoImageSelected = () => {
    selectedFileNameRef.current.textContent = "Please select an image file.";
    selectedFileNameRef.current.style.color = red["A400"];
  };

  const addMapToDB = (fileUploaded) => {
    setUploading(true);
    let mapName = mapNameInputRef.current.querySelector("input").value;
    noMapNameInputErrorRef.current.style.display = "hidden";
    noMapFileInputErrorRef.current.style.display = "hidden";
    if (!mapName && !fileUploaded) {
      noMapNameInputErrorRef.current.style.display = "block";
      noMapFileInputErrorRef.current.style.display = "block";
      setUploading(false);
    } else if (!mapName) {
      console.log(fileUploaded);
      noMapNameInputErrorRef.current.style.display = "block";
      setUploading(false);
    } else if (!fileUploaded) {
      noMapFileInputErrorRef.current.style.display = "block";
      setUploading(false);
    } else if (fileUploaded.name.split(".").reverse()[0] !== "png") {
      noMapFileInputErrorRef.current.style.display = "block";
      setUploading(false);
    } else {
      // upload image to DB
      Storage.put(
        `UsersData/${context.userEmail}/indoorMapBackground/` +
          fileUploaded.name,
        fileUploaded,
        {
          acl: "public-read",
          contentType: "image/png",
        }
      )
        .then(() => {
          postNewMapToDB(fileUploaded.name, mapName, context)
            .then(() => {
              if (props.setLocation) {
                props.setLocation(mapName);
              }
            })
            .then(() => {
              if (setCurrentMapName) {
                setCurrentMapName(mapName);
              }
              if (showMessage) {
                showMessage(mapName + " has been added!");
              }
              if (imageUploaded === false) {
                setImageUploaded(true);
              }
              handleClose();
            });
        })
        .catch((error) => {
          console.error("Error upload map image: ", error);
          setUploading(false);
        });
    }
  };

  const handleAddMap = (e) => {
    e.preventDefault();
    const fileUploaded = hiddenFileInput.current.files[0];
    if (update) {
      updateMapHandle(fileUploaded);
      if (showMessage) {
        let inputValue = mapNameInputRef.current.querySelector("input").value;
        let newMapName =
          props.defaultValue === inputValue ? props.defaultValue : inputValue;
        showMessage(newMapName + " has been updated!");
      }
    } else {
      addMapToDB(fileUploaded);
    }
  };

  const handleClickChip = (event) => {
    hiddenFileInput.current.click();
  };

  const handleSelectFile = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded && fileUploaded.type.includes("image")) {
      let fileName = fileUploaded.name;
      if (fileName.length > 36) {
        fileName = fileName.substring(0, 36) + "...";
      }
      selectedFileNameRef.current.textContent = fileName;
      selectedFileNameRef.current.style.color = "#000000";
      document.querySelector("#select-file-chip").style.display = "none";
      document.querySelector("#change-file-chip").style.display =
        "inline-block";
    } else if (fileUploaded && !fileUploaded.type.includes("image")) {
      handleNoImageSelected();
    }
  };

  return (
    <div>
      {!props.externalOpenControl &&
        (props.iflink ? (
          <Link onClick={handleClickOpen}>{buttonText}</Link>
        ) : (
          <Button
            variant={outlined ? "outlined" : "contained"}
            sx={{ textTransform: "none" }}
            onClick={handleClickOpen}
          >
            {buttonText}
          </Button>
        ))}

      <PopupModal
        width="fit-content"
        height="fit-content"
        iflink
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        modalOpen={props.externalOpenControl ? props.open : open}
        title={
          <Typography variant="h2" color={theme.palette.textPrimary.main}>
            {update ? "Edit Floor Map" : "Add a Floor Map"}
          </Typography>
        }
        content={
          <div style={{ width: "100%", textAlign: "left" }}>
            <InputLabelCustomized
              ref={mapNameInputRef}
              labelName="Map Name"
              width="95%"
              placeholder="Name this Map"
              value={update ? props.defaultValue : ""}
              required
            />
            <Typography
              variant="subText"
              ref={noMapNameInputErrorRef}
              sx={{
                fontSize: "12px",
                color: red["A400"],
                display: "none",
                paddingLeft: "5px",
                marginTop: "-10px",
                marginBottom: "10px",
              }}
            >
              Please enter a map name
            </Typography>
            <Grid
              container
              alignItems="center"
              sx={{
                height: "30px",
                padding: "0 0 0 5px",
                boxSizing: "content-box",
                width: "calc(100% - 5px)",
                gap: "12px",
              }}
            >
              <Typography
                variant="bodyText"
                color={theme.palette.headingText.main}
                sx={{ fontSize: "12px" }}
              >
                Upload File (Only support png)
              </Typography>
              <Chip
                id="select-file-chip"
                item
                onClick={handleClickChip}
                onDelete={handleClickChip}
                label="Select file"
                deleteIcon={<AddIcon />}
                variant="outlined"
                sx={{
                  fontSize: "12px",
                  height: "30px",
                  lineHeight: "20px",
                  borderRadius: "10px",
                  border: "2px solid #BFBABE",
                }}
              />
              <Typography
                variant="bodyText"
                color={theme.palette.headingText.main}
                ref={selectedFileNameRef}
                item
                sx={{ fontSize: "12px" }}
              >
                {props.fileName
                  ? props.fileName.length > 36
                    ? props.fileName.substring(0, 36) + "..."
                    : props.fileName
                  : "Currently no file selected"}
              </Typography>
              <input
                type="file"
                accept="image/png"
                ref={hiddenFileInput}
                onChange={handleSelectFile}
                style={{ display: "none" }}
              />
              <Typography
                variant="bodyText"
                item
                id="change-file-chip"
                onClick={handleClickChip}
                sx={{
                  cursor: "pointer",
                  fontSize: "12px",
                  marginLeft: "10px",
                  color: "#8977E4",
                  textDecoration: "underline",
                  display: "none",
                }}
              >
                Change file
              </Typography>
            </Grid>
            <Typography
              variant="subText"
              ref={noMapFileInputErrorRef}
              sx={{
                fontSize: "12px",
                color: red["A400"],
                display: "none",
                paddingLeft: "5px",
                marginTop: "-10px",
                marginBottom: "10px",
              }}
            >
              Please upload an image
            </Typography>
          </div>
        }
        action={
          <>
            {" "}
            <Button
              autoFocus
              onClick={handleAddMap}
              variant="contained"
              disabled={uploading}
              size="small"
              sx={{ textTransform: "none", marginRight: "10px" }}
            >
              {uploading ? "Uploading" : update ? "Update" : "Add"}
            </Button>
            <Button
              autoFocus
              onClick={handleClose}
              variant="outlined"
              size="small"
              sx={{ textTransform: "none" }}
            >
              Cancel
            </Button>
          </>
        }
      ></PopupModal>
    </div>
  );
};

export default UploadMapPopup;
