import React, { createRef, useContext, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Box, Button, Grid, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { GlobalSettings } from "../../../components/GlobalSettings";
import MapWithAllSensors from "../../../components/MapWithAllSensors";
import FullViewDeviceMapZoom from "./FullViewDeviceMapZoom";

const useStyles = makeStyles((theme) => ({
  map: {
    width: "100%",
    height: "40.5vh",
  },
  iconStack: {
    height: "100%",
  },
  legendToolSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "100%",
  },
  legendSection: {
    display: "flex",
    marginBottom: "2.02vh",
    height: "fit-content",
    justifyContent: "flex-start",
  },
  legendDot: {
    display: "flex",
    alignItems: "center",
  },
}));
const buttonStyle = {
  color: "#989FA7",
  borderColor: "#989FA7",
  padding: "0",
  maxHeight: "30px",
  maxWidth: "30px",
  minWidth: "30px",
  minHeight: "30px",
};

export default function AllDeviceMapZoomLegend(props) {
  const classes = useStyles();
  const theme = useTheme();
  const globalSettings = useContext(GlobalSettings);
  const mapRef = createRef();
  const [zoomModalOpen, setZoomModalOpen] = useState(false);

  const legends = () => {
    let legendFields1 = [];
    let legendFields2 = [];
    const AQICategoriesList = globalSettings.AQICategories.list;
    const AQICategoriesDetail = globalSettings.AQICategories.details;
    if (AQICategoriesDetail !== null && AQICategoriesList !== null) {
      if (props.paramSelection.toLowerCase().indexOf("pm") < 0) {
        ["Sensor Online", "Sensor Offline"].forEach((item, index) => {
          const legendLine = (
            <Box key={"legendLine~" + index}>
              <div className={classes.legendDot}>
                <svg
                  style={{ marginRight: "5px" }}
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="7"
                    cy="7"
                    r="7"
                    fill={
                      index == 0
                        ? theme.palette.primary.main
                        : theme.palette.offline.main
                    }
                    opacity="0.5"
                  ></circle>
                  <circle
                    cx="7"
                    cy="7"
                    r="5"
                    fill={
                      index == 0
                        ? theme.palette.primary.main
                        : theme.palette.offline.main
                    }
                  ></circle>
                </svg>
                <Typography
                  variant="caption"
                  style={{ width: "8.10 vh" }}
                  color={theme.palette.subText.main}
                >
                  {item}
                </Typography>
              </div>
            </Box>
          );
          legendFields1.push(legendLine);
        });
      } else {
        for (let i = 0; i < AQICategoriesList.length; i++) {
          const color = AQICategoriesDetail[AQICategoriesList[i]].color;
          const text = AQICategoriesDetail[AQICategoriesList[i]].text;
          const legendLine = (
            <Box key={"legendLine~" + i}>
              <div className={classes.legendDot}>
                <svg
                  style={{ marginRight: "5px" }}
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="7"
                    cy="7"
                    r="7"
                    fill={color}
                    opacity="0.5"
                  ></circle>
                  <circle cx="7" cy="7" r="5" fill={color}></circle>
                </svg>
                <Typography
                  variant="caption"
                  style={{ width: "8.10 vh" }}
                  color={theme.palette.subText.main}
                >
                  {text}
                </Typography>
              </div>
            </Box>
          );
          if (i < 4) {
            legendFields1.push(legendLine);
          } else {
            legendFields2.push(legendLine);
          }
        }
      }
    }
    return (
      <Box className={classes.legendSection}>
        <Box style={{ marginRight: "1vh" }}>{legendFields1}</Box>
        <Box style={{ display: "flex", flexDirection: "column" }}>
          {legendFields2}
        </Box>
      </Box>
    );
  };

  const processMapDate = () => {
    let mapDate = new Date("2020-06-07T15:00:00");
    return mapDate.toDateString() + " at " + mapDate.toLocaleTimeString();
  };
  return (
    <TransformWrapper
      initialScale={1}
      initialPositionX={0}
      initialPositionY={0}
    >
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <React.Fragment>
          <Grid container direction="column" sx={{ height: "100%" }}>
            <Grid
              item
              sx={{ height: "80%", width: "100%" }}
              id="all-device-zoom-leg"
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <TransformComponent
                  wrapperStyle={
                    props.zoomModal
                      ? { height: "100%", width: "100%" }
                      : { height: "80%", width: "100%" }
                  }
                  contentStyle={{ width: "100%", height: "100%" }}
                >
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      alignSelf: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <MapWithAllSensors
                      ref={mapRef}
                      id={props.id}
                      map={props.map}
                      mapFloor={props.mapName}
                      style={{ width: "100%", height: "100%" }}
                      type={props.paramSelection}
                      isAnalyticFloorDetail={true}
                    />
                  </div>
                </TransformComponent>
              </Box>
            </Grid>
            <Grid
              item
              sx={{ height: "20%", width: "100%", justifyContent: "flex-end" }}
            >
              <Box className={classes.legendToolSection}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  {legends()}
                  {/* <Typography
                    variant="subText"
                    color={theme.palette.subText.main}
                  >
                    {" "}
                    Real-time data: {processMapDate()}
                  </Typography> */}
                </Box>
                <Stack
                  className={classes.iconStack}
                  alignItems="flex-end"
                  justifyContent="flex-end"
                  direction="column"
                  spacing={2}
                >
                  {props.zoomModal ? null : (
                    <FullViewDeviceMapZoom
                      id={props.id}
                      modalOpen={zoomModalOpen}
                      mapName={props.mapName}
                      map={props.map}
                      paramSelection={props.paramSelection}
                    />
                  )}
                  <Button
                    style={buttonStyle}
                    className={classes.icon}
                    variant="outlined"
                    aria-label="zoomin"
                    size="large"
                  >
                    <AddIcon onClick={() => zoomIn()} />
                  </Button>
                  <Button
                    style={buttonStyle}
                    className={classes.icon}
                    variant="outlined"
                    aria-label="zoomout"
                    size="large"
                  >
                    <RemoveIcon onClick={() => zoomOut()} />
                  </Button>
                  <Button
                    style={buttonStyle}
                    className={classes.icon}
                    variant="outlined"
                    aria-label="resetzoom"
                    size="large"
                  >
                    <RestartAltIcon onClick={() => resetTransform()} />
                  </Button>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </TransformWrapper>
  );
}
