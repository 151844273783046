import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify, Analytics } from "aws-amplify";
import { ThemeProvider } from "@mui/material/styles";

import "./App.css";
import amplifyConfigure from "./aws-exports";
import { GlobalSettings, settings } from "./components/GlobalSettings";
import AeroTheme from "./components/Theme/AeroTheme";
import Dashboard from "./features/index";
import Landing from "./features/landing/pages/Landing";

Amplify.configure(amplifyConfigure);
Analytics.enable();

function App() {
  return (
    <GlobalSettings.Provider value={settings}>
      <ThemeProvider theme={AeroTheme}>
        <Authenticator.Provider>
          <div>
            <Routes>
              <Route exact path="/" element={<Dashboard />} />
              <Route path="/dashboard/*" element={<Dashboard />} />
              <Route path="*" element={<Navigate to="/dashboard/*" replace />} />
            </Routes>
          </div>
        </Authenticator.Provider>
      </ThemeProvider>
    </GlobalSettings.Provider>
  );
}

export default App;
