import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useAuthenticator } from "@aws-amplify/ui-react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  Avatar,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  navItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "0.3rem",
    position: "relative",
    listStyle: "none",
  },
  dropdown: {
    zIndex: 2,
    position: "absolute",
    top: "calc(100% - 5px)",
    right: 0,
    borderRadius: "8px",
    transition: "height 400ms ease",
    minWidth: "250px",
  },
  iconButton: {
    width: "calc(72 * 0.65)",
    height: "calc(72 * 0.65)",
    backgroundColor: "#ffffff",
    border: "none",
    borderRadius: "50%",
    padding: "5px",
    margin: "2px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 150ms ease",
    cursor: "pointer",
  },
  menu: {
    backgroundColor: "#ffffff",
    border: "1px solid #E0E2E5",
    borderRadius: "5px",
    boxSizing: "border-box",
  },
  rows: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
  },
  icon: {
    marginRight: "10px",
  },
  listIcon: {
    width: "24px",
    height: "24px",
    marginRight: "10px",
    border: "1px",
  },
});

export default function UserInfoDropdwon(props) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <UserInfoDropdownContainer
        icon={<AccountCircleIcon color="action" fonsize="medium" />}
        open={open}
        setOpen={setOpen}
      >
        <DropdownMenu setOpen={setOpen} setValue={props.setValue} />
      </UserInfoDropdownContainer>
    </>
  );
}

function UserInfoDropdownContainer(props) {
  const classes = useStyles();
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (props.open && ref.current && !ref.current.contains(e.target)) {
        props.setOpen(false);
      } else if (e.target.id === "view-all-alerts-btn") {
        // navigate to action center and close the notif dropdown
        document.getElementById("notification").click();
        props.setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [props, props.open]);

  return (
    <li className={classes.navItem} ref={ref}>
      <button
        className={classes.iconButton}
        onClick={() => props.setOpen(!props.open)}
      >
        {props.icon}
      </button>

      {props.open && props.children}
    </li>
  );
}

function DropdownMenu(props) {
  const classes = useStyles();
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const history = useNavigate();

  return (
    <div className={classes.dropdown}>
      <MenuList className={classes.menu}>
        <MenuItem
          disableRipple
          style={{
            backgroundColor: "transparent",
            cursor: "default",
          }}
        >
          <ListItemIcon>
            <Avatar
              sx={{
                color: "#000000",
                bgcolor: "#F5F4FE",
                height: "32px",
                width: "32px",
                marginRight: "10px",
              }}
            >
              {user.attributes.preferred_username
                ? user.attributes.preferred_username.charAt(0)
                : user.attributes.email.charAt(0)}
            </Avatar>
          </ListItemIcon>
          <ListItemText>
            {user.attributes.preferred_username ?? (
              <Typography variant="bodyText">
                {user.attributes.preferred_username}
              </Typography>
            )}
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "16px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#697077",
              }}
            >
              {user.attributes.email}
            </Typography>
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            props.setOpen(false);
            history("/dashboard/setting");
          }}
        >
          <ListItemIcon>
            <SettingsOutlinedIcon className={classes.listIcon} />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="h5">Account Settings</Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            history("/");
            signOut();
          }}
        >
          <ListItemIcon>
            <LogoutIcon className={classes.listIcon} />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="h5">Logout</Typography>
          </ListItemText>
        </MenuItem>
      </MenuList>
    </div>
  );
}
