import { createContext } from "react";

export const settings = {
  AQICategories: {
    list: [
      // 'hazardous',
      // 'veryUnhealthy',
      "unhealthy",
      // 'unhealthyForSensitiveGroup',
      "moderate",
      "good",
      "offline",
    ],
    details: {
      // hazardous: {
      //   text: 'Hazardous',
      //   thresholds: [301, 500],
      //   color:  '#C55A1E'
      // },
      // veryUnhealthy: {
      //   text: 'Very Unhealthy',
      //   thresholds: [201, 300],
      //   color: '#9B51E0'
      // },
      unhealthy: {
        text: "Unhealthy",
        thresholds: [100, 200],
        color: "#EB5757",
      },
      // unhealthyForSensitiveGroup: {
      //   text: 'Unhealthy for Sensitive Group',
      //   thresholds: [101, 150],
      //   color: '#F2994A'
      // },
      moderate: {
        text: "Moderate",
        thresholds: [51, 100],
        color: "#F2CD5B",
      },
      good: {
        text: "Good",
        thresholds: [0, 50],
        color: "#6FCF97",
      },
      offline: {
        text: "Sensor offline",
        thresholds: null,
        color: "#4F4F4F",
      },
    },
  },
};
export const GlobalSettings = createContext(settings);
