import React, { useContext, useEffect, useRef, useState } from "react";
import { Chart } from "react-chartjs-2";

import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-moment";

import { UserContext } from "../../../components/UserContext";

ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);
const useStyles = makeStyles({
  chartTitle: {
    marginBottom: "1.7578vh",
  },
  chartContainer: {
    height: "auto",
  },
  rightSection: {
    height: "100%",
    padding: "0px",
  },
});

//suppose now every device is harzard
const DeviceHistoryGraph = (props) => {
  const classes = useStyles();
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({ datasets: [] });
  const labels = props.time;
  labels.sort(function (a, b) {
    return a - b;
  });
  const userContext = useContext(UserContext);

  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector("div");
    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.style.borderRadius = "3px";
      tooltipEl.style.opacity = 1.5;
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.transform = "translate(-50%, 0)";
      tooltipEl.style.transition = "all .1s ease";
      tooltipEl.style.width = "190px";
      tooltipEl.style.height = "70px";

      const table = document.createElement("table");
      table.style.margin = "0px";
      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (
      tooltip.body &&
      tooltip.body.length > 0 &&
      tooltip.body[0].lines.length > 0 &&
      tooltip.title
    ) {
      const arrowSvg = document.createElement("div");
      const arrow = `
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10 0L10 10L-2.18557e-07 5L10 0Z" fill="#4E4E4E" />
        </svg>`;
      arrowSvg.innerHTML = arrow;
      const timeLine = document.createElement("p");
      const dateTime = new Date(tooltip.title[0]);
      const pmOrAm = dateTime.getHours() > 12 ? "PM" : "AM";
      const hoursFormat = (dateTime.getHours() % 12) + " " + pmOrAm;
      timeLine.innerHTML = `Time: `;
      const timeBold = document.createElement("strong");
      timeBold.appendChild(document.createTextNode(hoursFormat));
      timeLine.append(timeBold);
      timeLine.style.marginTop = "0px";
      timeLine.style.marginBottom = "5px";
      timeLine.style.color = "#BFBABE";
      timeBold.style.color = "white";
      timeLine.style.fontSize = "14px";
      timeBold.style.fontWeight = 800;
      const dataLine = document.createElement("p");
      const paramName =
        userContext.paramNames[
          userContext.paramIds.indexOf(props.paramSelection)
        ];
      const paramUnit =
        userContext.paramUnits[
          userContext.paramIds.indexOf(props.paramSelection)
        ];
      dataLine.innerHTML = paramName + `: `;
      const dataLineBold = document.createElement("strong");
      dataLineBold.appendChild(
        document.createTextNode(
          parseFloat(tooltip.body[0].lines[0].split(": ")[1]).toFixed(2) +
            " " +
            paramUnit
        )
      );
      dataLine.append(dataLineBold);
      dataLine.style.marginTop = "0px";
      dataLine.style.marginBottom = "0px";
      dataLine.style.color = "#BFBABE";
      dataLine.style.fontSize = "14px";
      dataLineBold.style.color = "white";
      dataLineBold.style.fontWeight = 800;
      while (tooltipEl.firstChild) {
        tooltipEl.removeChild(tooltipEl.firstChild);
      }
      const wrapperOuter = document.createElement("div");
      wrapperOuter.style.display = "flex";
      wrapperOuter.style.alignItems = "center";
      const wrapper = document.createElement("div");
      wrapper.appendChild(timeLine);
      wrapper.appendChild(dataLine);
      wrapper.style.borderRadius = "5px";
      wrapper.style.padding = "10px";
      wrapper.style.backgroundColor = "#4E4E4E";
      wrapperOuter.appendChild(arrowSvg);
      wrapperOuter.appendChild(wrapper);
      tooltipEl.appendChild(wrapperOuter);
    }
    const {
      offsetLeft: positionX,
      offsetTop: positionY,
      offsetWidth: canvasWidth,
      offsetHeight: canvasHeight,
    } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left =
      positionX + tooltip.caretX + tooltipEl.offsetWidth / 2 + "px";
    if (canvasWidth - tooltip.caretX < tooltipEl.offsetWidth) {
      tooltipEl.style.left =
        positionX + tooltip.caretX - tooltipEl.offsetWidth / 2 + "px";
      const arrow = tooltipEl.firstChild.firstChild;
      tooltipEl.firstChild.removeChild(tooltipEl.firstChild.firstChild);
      arrow.style.transform = "scaleX(-1)";
      tooltipEl.firstChild.appendChild(arrow);
    }
    tooltipEl.style.top =
      positionY + tooltip.caretY - tooltipEl.offsetHeight / 2 + "px";
    if (canvasHeight - tooltip.caretY < tooltipEl.offsetHeight) {
      tooltipEl.style.top =
        positionY + tooltip.caretY - tooltipEl.offsetHeight + "px";
    }
    tooltipEl.style.zIndex = "10";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = "10px";
  };

  console.log("props.data", props.data);
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 1,
      },
      line: {
        spanGaps: true,
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "hour",
          stepSize: 2,
        },
        min: new Date(new Date().getTime() - 25 * 60 * 60 * 1000),
        max: new Date(new Date().getTime() - 1 * 60 * 60 * 1000),
        grid: {
          display: false,
        },
        ticks: {
          callback: (value, index, values) => {
            if (index === values.length - 1) {
              return "Now";
            } else {
              return value;
            }
          },
        },
      },
      y: {
        grid: {
          drawBorder: false,
        },
        ticks: {
          maxTicksLimit: 5,
          padding: 5,
        },
        beginAtZero: true,
        max: 200,
        gridLines: {
          borderDash: [10, 5], // set the border dash pattern
          zeroLineWidth: 0, // hide the zero line
        },
      },
    },
    plugins: {
      legend: {
        display: props.hideLegend ? false : true,
      },
      tooltip: {
        enabled: false,
        external: externalTooltipHandler,
      },
      customTitle: {
        display: true,
        position: "nearest",
        text: userContext.paramUnits[
          userContext.paramNames.findIndex(
            (element) => element === props.paramSelectionName
          )
        ],
        color: "#989FA7",
        // xtitle: "Now",
      },
      annotation: {
        annotations: {
          line1: {
            // Unique ID for this annotation
            type: "line",
            yMin: 100,
            yMax: 100,
            borderWidth: 0,
            label: {
              content: props.nullState ? "No data is available" : "",
              font: {
                size: "18px",
                //styleName: Heading 3;
                family: "Inter",
                weight: 600,
                height: "26px",
                letterSpacing: "0em",
                textAlign: "left",
              },
              enabled: true,
              position: "center",
              backgroundColor: "rgba(0,0,0,0)",
              color: "rgba(167, 167, 167, 1)",
            },
          },
        },
      },
    },
  };

  const customTitle = {
    id: "customTitle",
    beforeLayout: (chart, args, opts) => {
      const { display, font } = opts;
      if (!display) {
        return;
      }

      const { ctx } = chart;
      ctx.font = font || '12px "Helvetica Neue", Helvetica, Arial, sans-serif';

      const { width } = ctx.measureText(opts.text);
      chart.options.layout.padding.top = 30;
      // chart.options.layout.padding.right = width;
    },
    afterDraw: (chart, args, opts) => {
      const { font, text, color, xtitle } = opts;
      const {
        ctx,
        chartArea: { top, bottom, left, right },
      } = chart;
      if (opts.display) {
        ctx.fillStyle = color || Chart.defaults.color;
        ctx.font =
          font || '500 12px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(text, 3, 10);
        // ctx.fillText(xtitle, right - 20, bottom + 20);
      }
    },
  };

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }
    let sensorsData = props.data;
    let getSensorsData = () => {
      let datasetData = [];
      let legendArray = props.labels;
      for (let i = 0; i < sensorsData.length; i++) {
        datasetData.push({
          label: legendArray[i],
          data: sensorsData.length === 0 ? [] : sensorsData[i],
        });
      }
      return datasetData;
    };
    function generateHourList() {
      return Array.from({ length: 26 }, (_, i) =>
        new Date(Date.now() - i * 60 * 60 * 1000).getHours()
      ).sort((a, b) => a - b);
    }
    var hourList = generateHourList();
    const data = {
      labels: props.nullState ? hourList : labels,
      datasets: getSensorsData(),
    };

    const colors = ["#007FFF", "#89CFF0", "#000080"];

    const chartData = {
      ...data,
      datasets: data.datasets.map((dataset, index) => {
        return {
          ...dataset,
          borderColor: colors[index],
          borderWidth: 2,
          cubicInterpolationMode: "monotone",
          tension: 0,
        };
      }),
    };
    setChartData(chartData);
  }, [props.data, labels, props.labs, props.labels]);

  return (
    <Grid
      item
      container
      direction="column"
      style={{
        height: props.height ? props.height : "100%",
        width: props.width ? props.width : "100%",
      }}
      className={classes.rightSection}
    >
      {/* <Grid item height="10%">
        <Typography variant="h4" sx={{ marginBottom: "1.76vh" }}>
          {props.floorId ? props.floorId + " All Devices" : "Device History"}
        </Typography>
      </Grid> */}
      <Grid item xs width="100%" height="90%">
        {console.log("digit", chartData)}
        <Chart
          ref={chartRef}
          type="line"
          data={chartData}
          options={chartOptions}
          plugins={[customTitle]}
        />
      </Grid>
    </Grid>
  );
};
export default DeviceHistoryGraph;
