import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Collapse,
  Grid,
  Grow,
  Link as MuiLink,
  Paper,
} from "@mui/material";
import { Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import AlertItemCardStatus from "../../../components/AlertItemCardStatus";

const AlertItemPaper = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: "20px",
  color: "#FFFFFF",
  width: "100%",
  boxShadow: "none",
  background: "#FFFFFF",
  border: "1px solid #E0E2E5",
  borderRadius: "5px",
  marginBottom: "20px",
  /* Border - Light */
}));

export default function AlertAndActionItemsCard(props) {
  const theme = useTheme();
  const history = useNavigate();
  const deleteAlertItem = (e) => {
    setGrowed(false);
    setTimeout(() => {
      setCollapsed(false);
      setTimeout(() => {
        e.target.parentNode.parentNode.parentNode.parentNode.remove();
      }, 1000);
    }, 500);
  };
  const [growed, setGrowed] = useState(true);
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Box>
      <Collapse in={collapsed} timeout={1000}>
        <Grow in={growed} timeout={1000}>
          <Grid container item xs={12} md={12}>
            <AlertItemPaper>
              <Grid container item xs={12} md={12} rowSpacing={1}>
                <Grid
                  id="tag-room-container"
                  container
                  item
                  columnSpacing={1}
                  xs={12}
                  md={12}
                  sx={{ position: "relative" }}
                >
                  <Grid container item alignItems="center">
                    <Grid item sx={{ marginRight: "10px" }}>
                      <AlertItemCardStatus
                        status={props.status}
                        color={props.color}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="h4"
                        color={theme.palette.textPrimary.main}
                      >
                        {props.deviceName}
                      </Typography>
                    </Grid>
                  </Grid>
                  <CloseIcon
                    sx={{
                      position: "absolute",
                      top: 4,
                      right: 0,
                      cursor: "pointer",
                    }}
                    style={{ fill: "black" }}
                    onClick={deleteAlertItem}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography
                    variant="bodyText"
                    color={theme.palette.headingText.main}
                  >
                    {props.logText}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography color="#697077">{props.logTime}</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Link
                    to="/dashboard/notification"
                    onClick={() => {
                      history(`/dashboard/notification`);
                    }}
                  >
                    <MuiLink>View Details</MuiLink>
                  </Link>
                </Grid>
              </Grid>
            </AlertItemPaper>
          </Grid>
        </Grow>
      </Collapse>
    </Box>
  );
}
