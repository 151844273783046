import React, { useContext, useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";

import ErrorIcon from "@mui/icons-material/Error";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import WifiIcon from "@mui/icons-material/Wifi";
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  useMediaQuery,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";

import EditSensorsPopup from "../../../components/EditSensorsPopup";
import Messager from "../../../components/Messager";
import { DeviceContext, UserContext } from "../../../components/UserContext";
import OverviewCard from "../components/OverviewCard";

const PaperCustomized = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  elevation: 2,
  position: "relative",
  backgroundColor: "#FCFCFC",
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "borderBox",
  background: "#FCFCFC",
  /* Border - Light */
  border: "1px solid #E0E2E5",
  /* Section Basic Shadow */
  boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
  borderRadius: "10px",
}));

const CardGridCustomized = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    marginRight: "20px",
    flexBasis: "calc(33.33% - 13.33px)",
  },
  [theme.breakpoints.down("lg")]: {
    marginRight: "10px",
    flexBasis: "calc(33.33% - 6.67px)",
  },
  [theme.breakpoints.down("md")]: {
    marginRight: "10px",
    flexBasis: "calc(50% - 5px)",
    marginBottom: "10px",
    height: "96px",
  },
}));

const useStyles = makeStyles({
  cardIcon: {
    fill: "white",
  },
});

export default function Overview(props) {
  const userContext = useContext(UserContext);
  const deviceContext = useContext(DeviceContext).raw;
  const [hazardCount, setHazardCount] = useState(null);
  const [averages, setAverages] = useState("N/A");
  const theme = useTheme();
  const classes = useStyles();
  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState(<></>);
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    // all tabs uses most recent 10 min data
    // avg(most recent 10 min data collected by device 1, device2,...)
    if (userContext) {
      const deviceList = userContext.sensorObjects;
      setHazardCount((oldHazardCount) => {
        if (!oldHazardCount) return 0;
      });
      let hazardDeviceCount = 0;
      if (deviceList && Object.keys(deviceList).length > 0) {
        Object.keys(deviceList).forEach((onlineSensorId) => {
          const deviceData = deviceContext[onlineSensorId];
          if (!!deviceData && Object.values(deviceData).length > 0) {
            if (
              userContext.threshold &&
              userContext.paramIds &&
              userContext.paramNames &&
              props.paramselection
            ) {
              let paramData =
                deviceData[deviceData.length - 1][
                  userContext.paramIds[
                    userContext.paramNames.indexOf(props.paramselection)
                  ]
                ];
              if (props.paramselection.toLowerCase().includes("dp")) {
                paramData = paramData / 10;
              }
              const paramThreshold =
                userContext.threshold[
                  userContext.paramIds[
                    userContext.paramNames.indexOf(props.paramselection)
                  ]
                ];
              if (
                Number(paramData) >
                Number(paramThreshold[paramThreshold.length - 1])
              ) {
                hazardDeviceCount++;
              }
            }
          }
        });
        setHazardCount(hazardDeviceCount);
      }
    }
  }, [userContext, deviceContext, props.paramselection]);

  useEffect(() => {
    if (userContext) {
      const deviceList = userContext.onlineSensors;
      let paramIds = userContext.paramIds;
      if (userContext.userEmail && deviceList && paramIds) {
        let paramTotal = 0;
        let count = 0;
        Object.keys(deviceList).forEach((onlineSensorId) => {
          let deviceData = deviceContext[onlineSensorId];
          if (!!deviceData && Object.values(deviceData).length > 0) {
            let paramData =
              deviceData[deviceData.length - 1][
                userContext.paramIds[
                  userContext.paramNames.indexOf(props.paramSelection)
                ]
              ];
            paramTotal += Number(paramData);
            count++;
          }
        });
        if (count !== 0) {
          let average = paramTotal / count;
          setAverages(average.toFixed(0));
        }
      }
    }
  }, [userContext, deviceContext, props.paramSelection]);
  let hazardousDeviceStat = (
    <>
      <Typography variant="h4" color={theme.palette.textPrimary.main}>
        <span style={{ fontSize: "25px", lineHeight: "20px" }}>
          {hazardCount}
        </span>
        <span style={{ fontWeight: 100, color: "#BDBDBD" }}> / </span>
        <span style={{ fontSize: "18px", color: "#333333" }}>
          {Object.keys(userContext.sensorObjects).length-1}
        </span>
      </Typography>
    </>
  );

  let onlineDeviceStat = (
    <>
      <Typography variant="h4" color={theme.palette.textPrimary.main}>
        <span style={{ fontSize: "25px", lineHeight: "20px" }}>
          {Object.keys(userContext.onlineSensors).length}
        </span>
        <span style={{ fontWeight: 100, color: "#BDBDBD" }}> / </span>
        <span style={{ fontSize: "18px", color: "#333333" }}>
          {Object.keys(userContext.sensorObjects).length-1}
        </span>
      </Typography>
    </>
  );
  // const deviceReportHazardousCallback = () => {
  //   document.getElementById("data-analytics").click();
  // };

  // const PM25Callback = () => {
  //   document.getElementById("data-analytics").click();
  // };

  function showMessage(message) {
    setMessageOpen(true);
    setMessage(message);
  }

  const LoadedCardsContainer = isMobileScreen ? Carousel : Grid;
  const LoadedCardsContainerProps = isMobileScreen
    ? {
        sx: { width: "100%" },
        navButtonsProps: {
          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
          style: {
            display: "none",
          },
        },
        indicatorContainerProps: {
          style: {
            marginTop: "0px",
          },
        },
        indicatorIconButtonProps: {
          style: {
            paddingLeft: "3px", // 1
            paddingRight: "3px",
          },
        },
      }
    : {
        container: true,
        item: true,
        style: {
          height: "fit-content",
          display: "flex",
          flexWrap: "wrap",
        },
      };

  return (
    <Grid
      container
      item
      xs={12}
      md={12}
      paddingBottom={{ xs: "0px", sm: "20px" }}
      height="100%"
      display="flex"
    >
      <Box paddingBottom="20px" height="fit-content">
        <Typography variant="h2" color={theme.palette.h2.main}>
          Overview
        </Typography>
      </Box>
      {userContext.error !== null ? (
        <Grid item xs={12} md={12} height="calc(100% - 70px)">
          <PaperCustomized>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ErrorIcon sx={{ width: "6vh", height: "10vh" }} />
              <Typography variant="h3" color={theme.palette.h2.main}>
                Oops! Something Wrong: {userContext.error}
              </Typography>
            </Box>
          </PaperCustomized>
        </Grid>
      ) : userContext.loading ? (
        <Grid
          item
          xs={12}
          md={12}
          minHeight="100px"
          style={{ height: "calc(100% - 50px)", paddingBottom: "20px" }}
        >
          <PaperCustomized>
            <Box
              sx={{
                width: "100%",
                height: "80px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                style={{ width: "30px", height: "30px", marginBottom: "5px" }}
              />
              <Typography variant="h4" color={theme.palette.h2.main}>
                Loading ...
              </Typography>
            </Box>
          </PaperCustomized>
        </Grid>
      ) : userContext.userEmail !== null &&
        Object.keys(userContext.sensorObjects).length === 0 ? (
        <Grid item xs={12} md={12} minHeight="100px">
          <PaperCustomized>
            <Typography variant="h4" color={theme.palette.textSecondary.main}>
              No device is under this account.
            </Typography>
            <div style={{ marginLeft: "10px" }}>
              <EditSensorsPopup
                add
                showMessage={showMessage}
                buttonVariant="contained"
              />
            </div>
          </PaperCustomized>
        </Grid>
      ) : (
        <LoadedCardsContainer
          {...LoadedCardsContainerProps}
          style={{
            width: "100%",
            height: "calc(100% - 50px)",
          }}
        >
          <CardGridCustomized>
            <OverviewCard
              background-color={theme.palette.red.main}
              topTitle={hazardousDeviceStat}
              subTitle="Report hazardous"
              icon={<PriorityHighIcon className={classes.cardIcon} />}
            />
          </CardGridCustomized>
          <CardGridCustomized
            sx={{
              [theme.breakpoints.down("md")]: {
                marginRight: "0px",
              },
            }}
          >
            {props.paramSelection &&
            userContext &&
            userContext.paramNames &&
            userContext.paramNames.indexOf(props.paramSelection) !== -1 &&
            averages !== null ? (
              <OverviewCard
                background-color={theme.palette.green.main}
                topTitle={
                  <Typography
                    variant="h4"
                    color={theme.palette.textPrimary.main}
                  >
                    {averages}{" "}
                    {
                      userContext.paramUnits[
                        userContext.paramNames.findIndex(
                          (element) => element === props.paramSelection
                        )
                      ]
                    }
                  </Typography>
                }
                subTitle={`Avg. ${props.paramSelection}`}
                icon={<TrendingUpOutlinedIcon className={classes.cardIcon} />}
              />
            ) : (
              <OverviewCard
                background-color={theme.palette.green.main}
                topTitle={"Oops! Can't calculate average"}
                icon={<TrendingUpOutlinedIcon className={classes.cardIcon} />}
              />
            )}
          </CardGridCustomized>
          <CardGridCustomized style={{ marginRight: "0px" }}>
            {userContext.onlineSensors ? (
              <OverviewCard
                sx={{ cursor: "auto" }}
                background-color={theme.palette.green.main}
                topTitle={onlineDeviceStat}
                subTitle="Devices online"
                icon={<WifiIcon className={classes.cardIcon} />}
              />
            ) : (
              <OverviewCard
                sx={{ cursor: "auto" }}
                background-color={theme.palette.green.main}
                topTitle="Oops can't calculate how many device online"
                icon={<WifiIcon className={classes.cardIcon} />}
              />
            )}
          </CardGridCustomized>
        </LoadedCardsContainer>
      )}
      <Messager
        open={messageOpen}
        onClose={() => {
          setMessageOpen(false);
        }}
        message={message}
      />
    </Grid>
  );
}
