import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Snackbar, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  body: {
    width: "44vw",
  },
  title: {
    height: "30px",
    width: "100%",
    alignSelf: "center",
    marginTop: "4px",
    marginBottom: "40px",
  },
});

/**
 * @param {bool} props.open open status
 * @param {func} props.setOpen set open status
 * @param {Array<String>} props.sensors id of deleting sensors
 * @param {Array<String>} props.sensorsName
 * @param {funct} props.deleteFunc delete call back
 * @returns
 */
export default function DeleteSensorDialog(props) {
  const { open, setOpen, sensors, sensorsName, deleteFunc } = props;
  const theme = useTheme();
  const classes = useStyles();
  const isIpadScreenOrSmaller = useMediaQuery((theme) =>
    theme.breakpoints.down("md")
  );
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    deleteFunc();
    setOpen(false);
  };

  return (
    <>
      <Button
        style={{
          borderColor: theme.palette.red.main,
          color: theme.palette.red.main,
        }}
        variant="outlined"
        onClick={() => {
          props.setOpen(true);
        }}
      >
        {isIpadScreenOrSmaller ? "Delete" : "Delete Device"}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle className={classes.title}>
          <Typography variant="h3">Delete Device(s)</Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers={false}>
          <Typography gutterBottom>
            Are you sure you would like to delete the
            <span style={{ display: "inline", fontWeight: "bold" }}>
              {sensors.length > 1
                ? " " + sensors.length + " devices"
                : " " + sensorsName[0]}
            </span>
            ? The current owner will lose the ability to view or interact with
            the device via the Violett Dashboard, and it will be returned to an
            deleted state.
            <br />
            <br />
            Note you should only delete a device if you wish to transfer
            ownership of it to another Violett account or user.
            <br />
            <br />
            Are you sure you want to delete
            <span style={{ display: "inline", fontWeight: "bold" }}>
              {sensors.length > 1
                ? " " + sensors.length + " devices"
                : " " + sensorsName[0]}
            </span>
            ?
          </Typography>
        </DialogContent>
        <DialogActions style={{ width: "100%", padding: "0 28px 35px" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{ boxSizing: "border-box", marginRight: "10px" }}
              onClick={handleClose}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              style={{ boxSizing: "border-box" }}
              onClick={handleDelete}
              variant="contained"
            >
              Delete
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
