import React, { useContext, useEffect, useRef } from "react";

import { MenuItem, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import EditSensorsPopup from "../../../components/EditSensorsPopup";
import { GlobalSettings } from "../../../components/GlobalSettings";
import SensorDetailsPopup from "../../../components/SensorDetailsPopup";
import { DeviceContext, UserContext } from "../../../components/UserContext";

const useStyles = makeStyles({
  root: {
    height: "100%",
    width: "100%",
    background: "#FFF",
    border: "1px solid #E0E2E5",
    boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
    borderRadius: "10px",
  },
  header: {
    padding: "24px 16px",
    display: "flex",
    gridTemplateColumns: "auto auto",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tableRow: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    justifyContent: "space-between !important",
    alignItems: "center",
    height: "50px",
  },
});
function CollapsibleMapList(props) {
  const {
    rows,
    setRows,
    message,
    showMessage,
    selectedDevice,
    setSelectedDevice,
  } = props;
  const context = useContext(UserContext);
  const data = useContext(DeviceContext).raw;
  const globalSettings = useContext(GlobalSettings);
  const AQICategoriesDetail = globalSettings.AQICategories.details;
  const theme = useTheme();
  const classes = useStyles();
  const deviceListItemsRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside of the list
    function handleClickOutside(event) {
      if (
        deviceListItemsRef.current &&
        !deviceListItemsRef.current.contains(event.target)
      ) {
        // Clicked outside of the list
        setSelectedDevice("");
      }
    }

    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getColor = (deviceID) => {
    if (selectedDevice !== "") {
      if (selectedDevice === deviceID) {
        return theme.palette.yellow.main;
      } else {
        return "#CDCFD2";
      }
    }
    if (
      context.sensorObjects &&
      context.sensorObjects[deviceID] &&
      !context.sensorObjects[deviceID]["online"]
    ) {
      return AQICategoriesDetail.offline.color;
    }
    let auto = context.sensorObjects[deviceID]["auto"];
    if (auto) {
      return theme.palette.green.main;
    } else {
      return theme.palette.blue.main;
    }
  };

  const getStatus = (deviceID) => {
    if (!context.sensorObjects[deviceID]) {
      return "N/A";
    }
    if (!context.sensorObjects[deviceID].online) {
      return "Offline";
    }
    if (context.sensorObjects[deviceID]["auto"]) {
      return "Auto Mode";
    } else {
      return "Manual Mode";
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography
          variant="h2"
          sx={{
            fontWeight: 800,
            fontSize: "15px !important",
            lineHeight: "20px !important",
            letterSpacing: "-0.03em !important",
            color: "#242424",
          }}
        >
          Devices in {props.selectedMap}
        </Typography>
        <EditSensorsPopup
          add
          showMessage={props.showMessage}
          buttonVariant="text"
        />
      </div>
      <div ref={deviceListItemsRef}>
        {rows.map((row, index) => {
          let color = getColor(row.id, null);
          return (
            <MenuItem
              key={"device~" + row.id}
              sx={{
                backgroundColor:
                  selectedDevice === row.id ? theme.palette.lightGrey.main : "",
              }}
              style={{ display: "flex" }}
              className={classes.tableRow}
            >
              <div
                id="left-tag"
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
                onClick={() => {
                  if (selectedDevice == row.id) {
                    setSelectedDevice("");
                  } else {
                    setSelectedDevice(row.id);
                  }
                }}
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="7" cy="7" r="7" fill={color} fill-opacity="0.5" />
                  <circle cx="7" cy="7" r="5" fill={color} />
                </svg>

                <Typography variant={"subText"} style={{ marginLeft: "12px" }}>
                  {row["deviceName"]}
                </Typography>
              </div>

              {selectedDevice === row.id ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <EditSensorsPopup
                    key={`edit-${row.id}`}
                    iflink={true}
                    deviceId={row.id}
                    deviceName={row.deviceName}
                    location={row.location}
                    initPosition={
                      context.sensorObjects[row.id]
                        ? context.sensorObjects[row.id].indoor_position
                        : null
                    }
                    buttonPrompt={"Edit"}
                    showMessage={showMessage}
                  />
                  &nbsp;|&nbsp;
                  <SensorDetailsPopup
                    setLocation={props.setLocation}
                    key={`control-${row.id}`}
                    deviceId={row.id}
                    title={row.deviceName}
                    deviceName={row.deviceName}
                    status={row.connection}
                    connection={row.connection}
                    forMgt={true}
                    buttonPrompt={"Control"}
                    showMessage={() => {
                      showMessage(
                        'Device "' + row.deviceName + '" has been updated !'
                      );
                    }}
                  />
                </div>
              ) : (
                <Typography
                  variant={"subText"}
                  color={theme.palette.subText.main}
                  key={"device~" + row.id}
                >
                  {getStatus(row.id)}
                </Typography>
              )}
            </MenuItem>
          );
        })}{" "}
      </div>
    </div>
  );
}

export default CollapsibleMapList;
