import { createContext } from "react";

// call after user login
export const userInfo = {
  userEmail: "",
  sensorMapInfoOverview: {},
  /*

   {
    "Area 1": {
      "map_name": "Area 1",
      "background_name": "https://sensor-network-userfile.s3.amazonaws.com/UsersData/jh846%40uw.edu/indoorMapBackground/download.png",
      "sensors": [
        {
          "e00fce68b9bafc09c257fd66": {
            "particle_id": "e00fce68b9bafc09c257fd66",
            "sensor_name": "Beta-11",
            "labels": [],
            "particle_product_id": 11334,
            "online": false,
            "indoor_position": {
              "x_coordinate": -43,
              "y_coordinate": 47
            }
          }
        }
      ]
    },
    "Area 2": {}
    ...
  ]
  */
  layerNameToMapUrl: {},
  layerToSensors: {} /* {"Area1": ["a", "b"]} */,
  sensorObjects: {},
  onlineSensors: {},
  setOnlineSensors: (onlineSensorsObjects) => {},
  setLayerNameToMapUrl: (layerNameToMapUrlInfo) => {},
  setUserData: (userData) => {},
  setLayerToSensors: (layerToSensorsInfo) => {},
  setSensorObjects: (sensorObjects) => {},
};

// call for past 24 hours & append every 5 minute
export const deviceInfo = {
  "": [], // {'[id]':[info] }
  floorAggregate: {},
};

export const UserContext = createContext(userInfo);
export const DeviceContext = createContext(deviceInfo);
