import React from "react";

import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import why1 from "../../../util/landing/why1.png";
import why2 from "../../../util/landing/why2.png";
import why3 from "../../../util/landing/why3.png";

const useStyle = makeStyles({
  why: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  titleGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: 95,
    textAlign: "center",
  },
  subTitleGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    textAlign: "center",
    marginTop: 42,
  },
  subwhy: {
    display: "grid",
    gridTemplateRows: "repeat(3, auto)",
    gridRowGap: 69,
    justifyContent: "center",
    marginTop: 110,
    padding: "0 199px",
  },
  whyCard: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
    display: "grid",
    gridTemplateColumns: "repeat(2, 50%)",
    alignItems: "center",
    justifyContent: "center",
  },
  pic: {
    width: "100%",
    maxHeight: "100%",
    margin: "0 auto",
  },
  cardContent: {
    boxSizing: "border-box",
    height: "100%",
    width: "100%",
    padding: 40,
  },
  cardSubtitle: {
    marginTop: "17px !important",
  },
  cardBody: {
    margin: "117px 0 0 0",
  },
});

const whyCardData = [
  {
    pic: why1,
    title: "Protect your health with",
    subtitle: "Continuous Monitoring",
    text:
      "Our sensor platform Aero can be agilely deployed across buildings and streaming environmental " +
      "data including noise, humidity, temperature, pressure, CO2, PM and hazardous gas.",
  },
  {
    pic: why2,
    title: "Understand your environment with",
    subtitle: "In Depth Analysis",
    text:
      "Our sensor platform Aero can be agilely deployed across buildings and streaming environmental" +
      " data including noise, humidity, temperature, pressure, CO2, PM and hazardous gas.",
  },
  {
    pic: why3,
    title: "Improve your properties with",
    subtitle: "Actionable Insights",
    text:
      "Our sensor platform Aero can be agilely deployed across buildings and streaming environmental " +
      "data including noise, humidity, temperature, pressure, CO2, PM and hazardous gas.",
  },
];

export default function Why(props) {
  const classes = useStyle();
  const theme = useTheme();
  return (
    <div className={classes.why} id={"why"}>
      <div className={classes.titleGroup}>
        <Typography variant={"h3"} color={theme.palette.primary.main}>
          Why Aerospec
        </Typography>
        <Typography variant={"h3"} color={theme.palette.primary.main}>
          Indoor air quality has more impact than you can imagine...
        </Typography>
      </div>
      <div className={classes.subTitleGroup}>
        <Typography variant={"h2"} color={theme.palette.textPrimary2.main}>
          Understanding and preserving a healthy indoor environment
        </Typography>
        <Typography variant={"h2"} color={theme.palette.textPrimary2.main}>
          can prevent health issues and increase productivity.
        </Typography>
      </div>
      <div className={classes.subwhy}>
        {whyCardData.map((value, index) => {
          if (index % 2 !== 0) {
            return (
              <div className={classes.whyCard} key={index}>
                <div className={classes.cardContent}>
                  <Typography variant={"h3"} color={theme.palette.primary.main}>
                    {value.title}
                  </Typography>
                  <Typography
                    variant={"h2"}
                    color={theme.palette.textPrimary2.main}
                  >
                    {value.subtitle}
                  </Typography>
                  <p className={classes.cardBody}>
                    <Typography
                      variant={"body"}
                      color={theme.palette.textPrimary2.main}
                    >
                      {value.text}
                    </Typography>
                  </p>
                </div>
                <img src={value.pic} alt="value pic" className={classes.pic} />
              </div>
            );
          }
          return (
            <div className={classes.whyCard} key={index}>
              <img src={value.pic} alt="value pic" className={classes.pic} />
              <div className={classes.cardContent}>
                <Typography
                  variant={"h3"}
                  color={theme.palette.primary.main}
                  className={classes.cardTitle}
                >
                  {value.title}
                </Typography>
                <Typography
                  variant={"h2"}
                  color={theme.palette.textPrimary2.main}
                  className={classes.cardSubtitle}
                >
                  {value.subtitle}
                </Typography>
                <p className={classes.cardBody}>
                  <Typography
                    variant={"body"}
                    color={theme.palette.textPrimary2.main}
                  >
                    {value.text}
                  </Typography>
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
