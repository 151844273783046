import React, { useContext, useState } from "react";

import { useAuthenticator } from "@aws-amplify/ui-react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Button,
  Stack,
  Switch,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import { updateNotificationSetting } from "../../../components/AccessDBGlobalFunctions";
import AlertItemCardStatus from "../../../components/AlertItemCardStatus";
import PopupModal from "../../../components/PopupModal";
import { UserContext } from "../../../components/UserContext";

const AntSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark"
            ? "#2ECA45"
            : theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor:
      theme.palette.mode === "dark" ? "#E9E9EA" : theme.palette.midGrey.main,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function NotificationSettingModal(props) {
  const userContext = useContext(UserContext);
  const user = useAuthenticator((context) => [context.user]);
  let [pm2p5, setPm2p5] = useState(-1);
  let [pm10p0, setPm10p0] = useState(-1);
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const handleCancelSettingChange = () => {
    setPm2p5(-1);
    setPm10p0(-1);
    props.setAlertSettingOpen(false);
  };
  const handleSaveChange = () => {
    props.setNotificationSetting(() => {
      let newNotification = 0;
      if (pm2p5 >= 0) {
        newNotification += pm2p5;
      } else {
        if (
          props.notificationSetting === 3 ||
          props.notificationSetting === 1
        ) {
          newNotification += 1;
        }
      }
      if (pm10p0 >= 0) {
        newNotification += pm10p0;
      } else {
        if (
          props.notificationSetting === 3 ||
          props.notificationSetting === 2
        ) {
          newNotification += 2;
        }
      }
      let email = user.user.attributes.email; // TODO: replace this with context later
      updateNotificationSetting(email, newNotification);
      setPm2p5(-1);
      setPm10p0(-1);
      return newNotification;
    });
    props.setAlertSettingOpen(false);
  };

  function generateAlertSettingLegend() {
    const thresholdLegends = [];
    for (let i = 0; i < userContext.thresholdColor.length; i++) {
      let infoText =
        "If turned on, we will send hazardous alert when PM 2.5 is higher than 150 based on USEPA standard.";
      if (i === 1) {
        infoText =
          "If turned on, we will send unhealthy alert when PM 2.5 is higher than 50 based on USEPA standard.";
      }
      if (i === 0) {
        infoText =
          "If turned on, we will send updates when PM 2.5 is going back below 50.";
      }
      thresholdLegends.push(
        <div
          key={i}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "1.3vh",
            marginLeft: "2.16vh",
          }}
        >
          <AlertItemCardStatus
            color={userContext.thresholdColor[i]}
            status={userContext.thresholdName[i]}
          />
          <p
            style={{
              lineHeight: "14px",
              marginTop: 0,
              marginBottom: 0,
              marginLeft: "0.7vh",
              marginRight: "0.83vh",
            }}
          >
            {userContext.thresholdName[i]} Alert
          </p>
          <Tooltip
            title={<p style={{ color: "white" }}>{infoText}</p>}
            placement={isMobileScreen ? "top" : "right"}
          >
            <InfoOutlinedIcon color="#828282" />
          </Tooltip>
        </div>
      );
    }
    return thresholdLegends;
  }

  return (
    <PopupModal
      modalOpen={props.alertSettingOpen}
      setModalOpen={props.setAlertSettingOpen}
      buttonPrompt="Notification Settings"
      title="Notification Settings"
      width="80vh"
      height="58vh"
      content={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            width: "100%",
            textAlign: "left",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1.5vh",
                backgroundColor: "#F8F8F8",
                paddingLeft: "1.08vh",
                paddingRight: "1.08vh",
              }}
            >
              <h3
                style={{
                  marginTop: "0.5vh",
                  marginBottom: "0.5vh",
                  fontWeight: 700,
                }}
              >
                PM 2.5 Notification
              </h3>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>OFF</Typography>
                <AntSwitch
                  defaultChecked={
                    props.notificationSetting === 3 ||
                    props.notificationSetting === 1
                  }
                  inputProps={{ "aria-label": "ant design" }}
                  onChange={(e) => {
                    setPm2p5(+e.target.checked);
                  }}
                />
                <Typography>ON</Typography>
              </Stack>
            </div>
            {Object.keys(userContext).length !== 0 ? (
              generateAlertSettingLegend()
            ) : (
              <div></div>
            )}
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1.5vh",
                backgroundColor: "#F8F8F8",
                paddingLeft: "1.08vh",
                paddingRight: "1.08vh",
              }}
            >
              <h3
                style={{
                  marginTop: "0.5vh",
                  marginBottom: "0.5vh",
                  fontWeight: 700,
                }}
              >
                PM 10 Notification
              </h3>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>OFF</Typography>
                <AntSwitch
                  defaultChecked={
                    props.notificationSetting === 3 ||
                    props.notificationSetting === 2
                  }
                  inputProps={{ "aria-label": "ant design" }}
                  onChange={(e) => {
                    setPm10p0(+e.target.checked << 1);
                  }}
                />
                <Typography>ON</Typography>
              </Stack>
            </div>
            {Object.keys(userContext).length !== 0 ? (
              generateAlertSettingLegend()
            ) : (
              <div></div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <Button
              onClick={handleCancelSettingChange}
              variant="outlined"
              style={{ marginRight: "15px" }}
            >
              Cancel
            </Button>
            <Button onClick={handleSaveChange} variant="contained">
              Save
            </Button>
          </div>
        </div>
      }
    />
  );
}

export default NotificationSettingModal;
