import React, { useContext, useState } from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";

import { deleteSensor } from "../../../components/AccessDBGlobalFunctions";
import EditSensorsPopup from "../../../components/EditSensorsPopup";
import Messager from "../../../components/Messager";
import SensorDetailsPopup from "../../../components/SensorDetailsPopup";
import { UserContext } from "../../../components/UserContext";
import DeleteSensorDialog from "./DeleteSensorDialog";
import DeviceBatchCtrDialog from "./DeviceBatchCtrDialog";
import DeviceTableEditControlMenu from "./DeviceTableEditControlMenu";

const useStyles = makeStyles({
  deviceManagementTable: {
    background: "#FFFFFF",
    boxSizing: "border-box",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.16)",
    borderRadius: "6px 6px 6px 6px",
    height: "72vh",
    width: "100%",
    "& .MuiDataGrid-root": {
      width: "100%",
      height: "100%",
      border: "none",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      padding: "0 !important",
    },
    "& .MuiDataGrid-columnSeparator": {
      visibility: "hidden",
    },
    "& .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
    "& .MuiDataGrid-columnHeader:focus": {
      outline: "none !important",
    },
    "& .MuiDataGrid-columnHeaderCheckbox": {
      visibility: "hidden",
    },
    "& .MuiDataGrid-footerContainer": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "800",
      fontSize: "15px",
      lineHeight: "20px",
      /* identical to box height, or 133% */

      letterSpacing: "-0.03em",

      /* Mid Grey */

      color: "#BFBABE",
    },
    "& .MuiDataGrid-cell > p": {
      margin: "0px",
    },
    "& .MuiDataGrid-columnsContainer": {
      width: "100%",
      minWidth: "0px",
    },
    "& .MuiDataGrid-virtualScrollerContent": {
      minWidth: "0px",
      width: "100%",
    },
    "& .MuiDataGrid-columnHeaders": {
      position: "static",
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      width: "100%",
    },
  },
});

const DetailsButton = (
  cellValues,
  disabled,
  showMessage,
  setLocation,
  isIpadOrSmaller
) => {
  const context = useContext(UserContext);
  let row = cellValues.row;

  return (
    <>
      {isIpadOrSmaller ? (
        <DeviceTableEditControlMenu
          row={cellValues.row}
          disabled={disabled}
          showMessage={showMessage}
          setLocation={setLocation}
          context={context}
        />
      ) : (
        <>
          <EditSensorsPopup
            key={`edit-${row.id}`}
            iflink={true}
            deviceId={row.id}
            deviceName={row.deviceName}
            location={row.location}
            initPosition={
              context.sensorObjects[row.id]
                ? context.sensorObjects[row.id].indoor_position
                : null
            }
            buttonPrompt={"Edit"}
            disabled={disabled}
            showMessage={showMessage}
          />
          <p>&nbsp;|&nbsp;</p>
          <SensorDetailsPopup
            // setLocation={setLocation}
            key={`control-${row.id}`}
            deviceId={row.id}
            deviceName={row.deviceName}
            status={row.connection}
            connection={row.connection}
            forMgt={true}
            buttonPrompt={"Control"}
            disabled={disabled}
            showMessage={showMessage}
          />
        </>
      )}
    </>
  );
};

/**
 * @param {array} props.rows the content of device table
 */
const DeviceTable = (props) => {
  const context = useContext(UserContext);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMediumOrGreater = useMediaQuery((theme) =>
    theme.breakpoints.up("md")
  );
  const isIpadOrSmaller = useMediaQuery((theme) =>
    theme.breakpoints.down("lg")
  );
  const classes = useStyles();
  const [rows, setRows] = [props.rows, props.setRows];
  const [rowSelect, setRowSelect] = React.useState([]);
  const [delDiaOpen, setDelDiaOpen] = useState(false);
  const [ctrDiaOpen, setCtrDiaOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState(<></>);
  /**
   * How to Calculate the width of column with flex?
   * First, we need to have a non-resizable column with a fixed width.
   * In this case, the button group should be a good choice.
   * Then we can set the `flex` attribute for the rest of columns
   * (which is equal to total width - width of button group column).
   * For Example, if device name column demands 15% of the rest space,
   * set flex: 0.15 for the device name column.
   */
  const columns = [
    { field: "id", headerName: "ID", hide: true },
    {
      field: "deviceName",
      headerName: isMobileScreen ? "Name" : "Device Name",
      disableColumnMenu: true,
      flex: isMobileScreen ? 0.06 : isIpadOrSmaller ? 0.12 : 0.22,
    },
    {
      field: "location",
      headerName: "Map",
      disableColumnMenu: true,
      flex: isMobileScreen ? 0.06 : isIpadOrSmaller ? 0.1 : 0.12,
      hide: isIpadOrSmaller ? true : false,
    },
    {
      field: "connection",
      headerName: "Connection",
      disableColumnMenu: true,
      flex: isMobileScreen ? 0.06 : isIpadOrSmaller ? 0.1 : 0.12,
      hide: isMediumOrGreater ? false : true,
      renderCell: (cellValues) => {
        let color = "red";
        let display_text = "Offline";
        if (cellValues.value === "On") {
          color = "black";
          display_text = "Online";
        }
        return (
          <p
            style={{
              color: color,
            }}
          >
            {display_text}
          </p>
        );
      },
    },
    {
      field: "speed",
      headerName: "Fan Speed",
      align: "left",
      headerAlign: "left",
      flex: isMobileScreen ? 0.06 : isIpadOrSmaller ? 0.1 : 0.12,
      renderCell: (cellValues) => {
        if (cellValues.row.auto) {
          return <p>Auto</p>;
        }
        let speed = cellValues.row.speed;
        if (speed === 0) {
          return <p>off</p>;
        }
        if (!speed) {
          return <p>- -</p>;
        }
        return <p>{cellValues.row.speed}</p>;
      },
      disableColumnMenu: true,
    },
    {
      field: "lock",
      headerName: "Lock",
      flex: isMobileScreen ? 0.06 : isIpadOrSmaller ? 0.1 : 0.12,
      renderCell: (cellValues) => {
        if (cellValues.row.lock === null) {
          return <p>- -</p>;
        }
        return <p>{cellValues.row.lock ? "Locked" : "Unlocked"}</p>;
      },
      disableColumnMenu: true,
    },
    // {
    //   field: "fliter",
    //   headerName: "Filter",
    //   flex: 0.12,
    //   renderCell: (cellValues) => {
    //     return <p>- -</p>;
    //   },
    //   disableColumnMenu: true,
    // },
    {
      field: "more",
      headerName: "",
      flex: isMobileScreen ? 0.06 : isIpadOrSmaller ? 0.1 : 0.12,
      renderCell: (cellValues) => {
        return DetailsButton(
          cellValues,
          rowSelect.length > 0,
          showMessage,
          props.setLocation,
          isIpadOrSmaller
        );
      },
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
    },
  ];

  function SelectAllAddDeviceToolbar(props) {
    return (
      <Box
        style={{
          toolbar: {
            justifyContent: "flex-end",
            display: "flex",
            alignItems: "flex-start",
            flexWrap: "wrap",
            padding: "10px 20px",
          },
        }}
      >
        <EditSensorsPopup add showMessage={showMessage} buttonVariant="text" />
      </Box>
    );
  }

  function DeleteRowToolbar(props) {
    return (
      <Box
        sx={{
          justifyContent: "flex-end",
          display: "flex",
          alignItems: "flex-start",
          flexWrap: "wrap",
          [theme.breakpoints.down("sm")]: {
            justifyContent: "flex-end",
            display: "flex",
            padding: "0px",
          },
        }}
      >
        <div style={{ marginRight: "10px" }}>
          <DeleteSensorDialog
            open={delDiaOpen}
            sensors={rowSelect}
            sensorsName={rowSelect.map((id) => {
              return context.sensorObjects[id]["sensor_name"];
            })}
            setOpen={setDelDiaOpen}
            deleteFunc={handleDeleteRow}
          />
        </div>
        {isMobileScreen ? (
          <div></div>
        ) : (
          <Button
            variant="outlined"
            sx={{
              textTransform: "none",
              marginRight: "10px",
              [theme.breakpoints.down("sm")]: {
                margin: "0px",
              },
            }}
            onClick={handleDeselectAll}
          >
            Cancel
          </Button>
        )}
        <DeviceBatchCtrDialog
          style={{ marginRight: "10px" }}
          buttonPrompt={isMobileScreen ? "Control" : "Devices Control"}
          open={ctrDiaOpen}
          handleClose={() => {
            setCtrDiaOpen(false);
          }}
          sensors={rowSelect}
        />
        {/* <AeroButton variant="outlined" value="Update Cycle" style={buttonStyle} onClick={handleUpdateCycle}/> */}
      </Box>
    );
  }

  function showMessage(message) {
    setMessageOpen(true);
    setMessage(message);
  }

  function handleDeselectAll() {
    setRowSelect([]);
  }

  function handleDeleteRow() {
    deleteSensor(rowSelect, context);
    let result = rows.filter((item) => {
      return !rowSelect.includes(item.id);
    });
    setRowSelect([]);
    setRows(result);
    showMessage("Devices deleted!");
  }

  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      const ids = rows.map((row) => row.id);
      setRowSelect(ids);
    } else {
      setRowSelect([]);
    }
  };

  return (
    <div>
      <Messager
        open={messageOpen}
        onClose={() => {
          setMessageOpen(false);
        }}
        message={message}
      />
      <Grid
        className={classes.deviceManagementTable}
        container
        sx={{
          padding: "30px",
          [theme.breakpoints.down("sm")]: {
            padding: "10px",
          },
          "& .MuiDataGrid-cell": {
            [theme.breakpoints.down("sm")]: {
              padding: "0px",
            },
          },
          "& .MuiDataGrid-columnHeader": {
            [theme.breakpoints.down("sm")]: {
              padding: "0px",
            },
          },
        }}
      >
        {context.loading ? (
          <Paper
            style={{
              border: "none",
              boxShadow: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <CircularProgress style={{ marginRight: "10px" }} />
            <Typography variant="h3" color={theme.palette.h2.main}>
              Loading ...
            </Typography>
          </Paper>
        ) : (
          <Grid item style={{ width: "100%", height: "100%" }}>
            <DataGrid
              autoWidth
              autoHeight
              headerHeight="30px"
              components={{
                Toolbar: () => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <div style={{ minWidth: "110px" }}>
                        <SelectAllCheckbox
                          checked={selectAll}
                          onChange={handleSelectAllChange}
                        />
                        <Typography variant="input">Select All</Typography>
                      </div>
                      {rowSelect.length === 0 ? (
                        <div>
                          <SelectAllAddDeviceToolbar />
                        </div>
                      ) : (
                        <DeleteRowToolbar />
                      )}
                    </div>
                  );
                },
              }}
              rows={
                rows
                // rows.filter((row) => {
                //   let searchRegex = new RegExp(escapeRegExp(props.searchText), "i");
                //   return Object.keys(row).some((field) => {
                //     return searchRegex.test(row[field].toString());
                //   });
                // })
              }
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5]}
              spacing={30}
              checkboxSelection
              hideFooterPagination
              disableSelectionOnClick
              onSelectionModelChange={(selectedRows) => {
                setRowSelect(selectedRows);
              }}
              selectionModel={rowSelect}
              onCellClick={(params, event) => {
                event.defaultMuiPrevented = true;
              }}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

function SelectAllCheckbox({ checked, onChange }) {
  return <Checkbox checked={checked} onChange={onChange} />;
}
export default DeviceTable;
