import React, { useEffect, useState } from "react";

import {
  AccountSettings,
  TextField,
  useAuthenticator,
} from "@aws-amplify/ui-react";

import { Alert, Button, Paper, Snackbar, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import PopupModal from "../../../components/PopupModal";

const useStyles = makeStyles((theme) =>
  createStyles({
    textfield: {
      marginBottom: "10px",
      width: "100%",
    },
  })
);

function AccountSetting() {
  const classes = useStyles();
  const { user, signOut } = useAuthenticator();
  const [saveBtnDisable, setSaveBtnDisable] = useState(true);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [passwordUpdateSuccessOpen, setPasswordUpdateSuccessOpen] =
    useState(false);
  let timer;

  const textFieldOnChange = (event) => {
    if (event.target.value) {
      setSaveBtnDisable(false);
    } else {
      setSaveBtnDisable(true);
    }
  };

  const onPasswordChangeSuccess = () => {
    setPasswordUpdateSuccessOpen(true);
    timer = setTimeout(() => {
      setPasswordUpdateSuccessOpen(false);
      setChangePasswordModalOpen(false);
    }, 2000);
  };

  const components = {
    SubmitButton: ({ onClick, isDisabled }) => (
      <div
        style={{
          display: "flex",
          top: "auto",
          alignSelf: "end",
          justifySelf: "end",
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            setChangePasswordModalOpen(false);
          }}
          style={{ marginRight: "10px" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          type="submit"
          onClick={onClick}
          disabled={isDisabled}
        >
          Confirm
        </Button>
      </div>
    ),
  };

  useEffect(() => {
    return () => clearTimeout(timer);
  }, [timer]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "78%",
      }}
    >
      <Paper
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          width: "50vw",
          height: "57.3vh",
          minHeight: "300px",
          minWidth: "290px",
        }}
      >
        <Typography variant="h2">Your Account</Typography>
        <TextField
          className={classes.textfield}
          placeholder="New Name"
          label="Name"
          onChange={textFieldOnChange}
        ></TextField>
        <TextField
          className={classes.textfield}
          placeholder={user ? user.attributes.email : "New name"}
          label="Email"
          onChange={textFieldOnChange}
        ></TextField>
        <TextField
          className={classes.textfield}
          placeholder="New Phone Number"
          label="Phone Number"
          onChange={textFieldOnChange}
        ></TextField>
        <div
          style={{
            alignSelf: "end",
            justifySelf: "end",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "auto",
            width: "100%",
          }}
        >
          <PopupModal
            modalOpen={changePasswordModalOpen}
            setModalOpen={setChangePasswordModalOpen}
            iflink={true}
            buttonPrompt="Change Password"
            title="Change Password"
            width="59vw"
            height="60vw"
            maxWidth="490px"
            maxHeight="400px"
            content={
              <div>
                <AccountSettings.ChangePassword
                  components={components}
                  onSuccess={onPasswordChangeSuccess}
                ></AccountSettings.ChangePassword>
                <Snackbar
                  style={{
                    top: "0vh",
                  }}
                  anchorOrigin={{ vertical: "center", horizontal: "center" }}
                  open={passwordUpdateSuccessOpen}
                >
                  <Alert
                    style={{
                      padding: "25px",
                      border: "1px solid #BDBDBD",
                      boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.4)",
                      borderRadius: "20px 20px 20px 20px",
                    }}
                  >
                    <Typography variant="h3">
                      Password Updated Successfully!
                    </Typography>
                  </Alert>
                </Snackbar>
              </div>
            }
          ></PopupModal>
          <div style={{ display: "flex" }}>
            <Button
              onClick={signOut}
              variant="outlined"
              style={{ marginRight: "10px" }}
            >
              Log Out
            </Button>
            <Button variant="contained" disabled={saveBtnDisable}>
              Save Changes
            </Button>
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default AccountSetting;
