import React from "react";

import { makeStyles } from "@mui/styles";

import DeviceTable from "../Components/DeviceTable";

const useStyles = makeStyles({
  root: {
    margin: `auto`,
    width: `100%`,
  },
});

function DeviceMgmtHome(props) {
  const classes = useStyles();
  const { curLocation, curStatus, curLabel, filteredPlaceRows, setFilteredPlaceRows, searchText, setLocation } = props;
  // const [dialogOpen, setDialogOpen] = useState(false);

  // const handleDialogOpen = () => {
  //   setDialogOpen(true);
  // };

  const getVisiblity = () => {
    const check = () => {
      if (
        curLocation === "All Location" &&
        curStatus === "all status" &&
        curLabel === "all labels"
      ) {
        return "hidden";
      } else {
        return "visible";
      }
    };
    let result = check();
    return { visibility: result };
  };



  return (
    <div className={classes.root}>

      {/* <div style={{ width: "100%", paddingBottom: "0.5vh", ...getVisiblity() }}>
      </div> */}
      <DeviceTable
        setLocation={setLocation}
        rows={filteredPlaceRows}
        setRows={setFilteredPlaceRows}
        searchText={searchText}
        curLocation={curLocation}
        curStatus={curStatus}
        curLabel={curLabel}
      />
    </div>
  );
}

export default DeviceMgmtHome;
