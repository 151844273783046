import React from "react";

import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import how1 from "../../../util/landing/how1.png";
import how2 from "../../../util/landing/how2.png";
import how3 from "../../../util/landing/how3.png";

const useStyle = makeStyles({
  how: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 64,
  },
  title: {
    marginTop: "76px !important",
    marginBottom: "29px !important",
  },
  subTitle: {
    maxWidth: 1039,
    textAlign: "center",
  },
  subhow: {
    width: ((346 * 3) / 1440) * 100 + "%",
    display: "grid",
    gridTemplateColumns: "repeat(3, 33%)",
    justifyContent: "center",
    marginTop: 17,
  },
  howCard: {
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "36px 26px 14px",
  },
  pic: {
    marginBottom: 27,
  },
  body: {
    textAlign: "center",
  },
});

const howCardData = [
  {
    pic: how1,
    title: "Set up sensors",
    text: "Deploy sensors across your properties and link them with your floor plan",
  },
  {
    pic: how2,
    title: "Get data feeds",
    text: "Recieve data streams and actionable insights from our web platform ",
  },
  {
    pic: how3,
    title: "Improve environment",
    text: "Based on our suggestions, improve the interior air quality and see instant effect",
  },
];

export default function How(props) {
  const classes = useStyle();
  const theme = useTheme();
  return (
    <div className={classes.how} id={"how"}>
      <Typography
        variant={"h3"}
        color={theme.palette.primary.main}
        className={classes.title}
      >
        How it works
      </Typography>
      <Typography
        variant={"h2"}
        color={theme.palette.textPrimary2.main}
        className={classes.subTitle}
      >
        Monitor, analyze &#38; improve your indoor air quality through a
        embedded sensor networks
      </Typography>
      <div className={classes.subhow}>
        {howCardData.map((value, index, array) => {
          return (
            <div className={classes.howCard} key={index}>
              <img src={value.pic} alt="value pic" className={classes.pic} />
              <p>
                <Typography
                  variant={"number"}
                  color={theme.palette.number.main}
                >
                  {index + 1}
                </Typography>
              </p>
              <Typography
                variant={"h3"}
                color={theme.palette.textPrimary2.main}
                style={{ textAlign: "center" }}
              >
                {value.title}
              </Typography>
              <p className={classes.body}>
                <Typography
                  variant={"body"}
                  color={theme.palette.textPrimary2.main}
                >
                  {value.text}
                </Typography>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
