import React, { useContext, useEffect, useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import AreaAverageGraph from "../features/data-analytics/components/AreaAverageGraph";
import {
  FloorAggregateGraphDataProvider,
  FloorGraphDataProvider,
} from "../features/data-analytics/components/FloorGraphDataProvider";
import { updateRemoteControl } from "./AccessDBGlobalFunctions";
import PopupModal from "./PopupModal";
import { DeviceContext, UserContext } from "./UserContext";

const useStyles = makeStyles({
  remoteControlItemLeft: {
    display: "flex",
    alignItems: "center",
  },
  remoteControlItem: {
    display: "flex",
    justifyContent: "space-between",
  },
});
function SensorDetailsPopup(props) {
  const userContext = useContext(UserContext);
  const deviceContext = useContext(DeviceContext).raw;
  const aggContext = useContext(DeviceContext).agg;
  const classes = useStyles();
  const [deviceLock, setDeviceLock] = useState(false);
  const [automode, setAutoMode] = useState(false);
  const [speedModeNumber, setSpeedModeNumber] = useState(-1);
  const [graphData, setGraphData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [tooltipOpen1, setToolTipOpen1] = React.useState(false);
  const [tooltipOpen2, setToolTipOpen2] = React.useState(false);
  const [ifNullState, setNullState] = React.useState(false);
  const [ifOffline, setOffLine] = React.useState(false);

  const handleTooltipClose1 = () => {
    setToolTipOpen1(false);
  };

  const handleTooltipClose2 = () => {
    setToolTipOpen2(false);
  };

  const handleTooltipOpen1 = () => {
    setToolTipOpen1(true);
  };
  const handleTooltipOpen2 = () => {
    setToolTipOpen2(true);
  };

  // const InfoTooltip = styled(({ className, ...props }) => (
  //   <Tooltip {...props} classes={{ popper: className }} />
  // ))(({ theme }) => ({
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     backgroundColor: "#ffffff",
  //     color: "rgba(0, 0, 0, 0.87)",
  //     maxWidth: 220,
  //     fontSize: "1.5vh",
  //     border: "1px solid #E0E2E5",
  //     boxShadow: "0px 4px 10px 0px #00000026",
  //   },
  // }));

  const handleCancelSettingChange = () => {
    // props.setSensorDetailsOpen(false);
    if (userContext && props.deviceId) {
      if (userContext["sensorObjects"][props.deviceId]) {
        setAutoMode(userContext["sensorObjects"][props.deviceId]["auto"]);
        setSpeedModeNumber(
          userContext["sensorObjects"][props.deviceId]["speed"] ||
            userContext["sensorObjects"][props.deviceId]["speed"] > -1
            ? userContext["sensorObjects"][props.deviceId]["speed"]
            : -1
        );
        setDeviceLock(userContext["sensorObjects"][props.deviceId]["lock"]);
      }
    }
    setModalOpen(false);
  };

  const handleSaveChange = () => {
    //TODO: actual save to our system
    // props.setSensorDetailsOpen(false);
    let ctrObj = {
      auto: automode,
      lock: deviceLock,
      speed: speedModeNumber,
    };
    setLoading(true);
    updateRemoteControl(props.deviceId, ctrObj, userContext, props.setLocation)
      .then(() => {
        setLoading(false);
        setModalOpen(false);
      })
      .then(() => {
        if (props.showMessage) {
          props.showMessage(props.deviceName + " has been updated!");
        }
      });
  };

  const handleDeviceLock = (event) => {
    setDeviceLock(event.target.checked);
  };
  const handleAutoMode = (event) => {
    setAutoMode(event.target.checked);
  };

  useEffect(() => {
    if (
      userContext &&
      deviceContext &&
      props.deviceId &&
      props.floor &&
      Object.keys(userContext) !== 0 &&
      Object.keys(deviceContext) !== 0 &&
      props.paramSelection
    ) {
      const floorDeviceData = {};
      floorDeviceData[props.deviceId] = deviceContext[props.deviceId];
      const floorData = userContext.sensorMapInfoOverview[props.floor];
      const selectedParamIdIndex = userContext.paramNames.findIndex(
        (element) => element === props.paramSelection
      );
      const selectedParamId =
        selectedParamIdIndex !== -1
          ? userContext.paramIds[selectedParamIdIndex]
          : null;
      // const finalGraphData = FloorGraphDataProvider(
      //   floorData,
      //   selectedParamId,
      //   floorDeviceData
      // );
      const finalGraphData = FloorAggregateGraphDataProvider(
        aggContext,
        props.floor,
        selectedParamId,
        props.deviceId
      );
      setGraphData(finalGraphData);
    } else {
      if (props.forMgt !== undefined || props.forMgt === true) {
        if (
          userContext &&
          deviceContext &&
          props.deviceId &&
          props.floor &&
          Object.keys(userContext) !== 0 &&
          Object.keys(deviceContext) !== 0
        ) {
          setNullState(true);
        }
      } else {
        setNullState(true);
      }
    }
    if (userContext && props.deviceId) {
      if (userContext["sensorObjects"][props.deviceId]) {
        if (!userContext["sensorObjects"][props.deviceId]["online"]) {
          setNullState(true);
          setOffLine(true);
        }
        setAutoMode(userContext["sensorObjects"][props.deviceId]["auto"]);
        setSpeedModeNumber(
          userContext["sensorObjects"][props.deviceId]["speed"] !== null &&
            userContext["sensorObjects"][props.deviceId]["speed"] !==
              undefined &&
            userContext["sensorObjects"][props.deviceId]["speed"] > -1
            ? userContext["sensorObjects"][props.deviceId]["speed"]
            : -1
        );
        setDeviceLock(userContext["sensorObjects"][props.deviceId]["lock"]);
      }
    } else {
      setNullState(true);
    }
  }, [
    userContext,
    deviceContext,
    props.paramSelection,
    props.deviceId,
    props.floor,
  ]);
  console.log(
    "usercontextdevice",
    userContext["sensorObjects"][props.deviceId]
  );
  return (
    <PopupModal
      onClose={handleCancelSettingChange}
      disabled={props.disabled}
      iflink={props.iflink ? props.iflink : true}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      buttonPrompt={
        props.buttonPrompt ? (
          props.buttonPrompt
        ) : (
          <MoreHorizIcon width="24px" htmlColor="#111111" />
        )
      }
      title={
        <Box display="flex" alignItems="center">
          <Typography
            variant="h2"
            style={{ padding: "0px", height: "fit-content" }}
          >
            {props.deviceName}
          </Typography>
          {ifOffline ? (
            <Typography
              variant="h3"
              style={{ color: "rgba(167, 167, 167, 1)", marginLeft: "5px" }}
            >
              (Offline)
            </Typography>
          ) : (
            <div />
          )}
        </Box>
      }
      width="69vh"
      height={
        !(props.forMgt !== undefined || props.forMgt === true) ? "75vh" : "33vh"
      }
      maxHeight={"800px"}
      content={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            width: "100%",
            textAlign: "left",
          }}
        >
          {!(props.forMgt !== undefined || props.forMgt === true) && (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "1.5vh",
                  backgroundColor: "#F8F8F8",
                  paddingLeft: "1.08vh",
                  paddingRight: "1.08vh",
                }}
              >
                <h3
                  style={{
                    marginTop: "0.5vh",
                    marginBottom: "0.5vh",
                    fontWeight: 700,
                  }}
                >
                  {props.paramSelection} (
                  {
                    userContext.paramUnits[
                      userContext.paramNames.indexOf(props.paramSelection)
                    ]
                  }
                  )
                </h3>
              </div>
              <div style={{ height: "30vh", marginBottom: "15px" }}>
                {Object.keys(graphData).length > 0 ? (
                  <AreaAverageGraph
                    nullState={ifNullState}
                    deviceView
                    hideTitle
                    hideLegend
                    eightHour={false}
                    paramSelection={
                      userContext.paramIds[
                        userContext.paramNames.indexOf(props.paramSelection)
                      ]
                    }
                    paramIds={
                      userContext.paramIds[
                        userContext.paramNames.indexOf(props.paramSelection)
                      ]
                    }
                    paramSelectionName={props.paramSelection}
                    labels={graphData.label}
                    data={graphData.data}
                    time={graphData.time}
                  />
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          )}
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1.5vh",
                backgroundColor: "#F8F8F8",
                paddingLeft: "1.08vh",
                paddingRight: "1.08vh",
              }}
            >
              <h3
                style={{
                  marginTop: "0.5vh",
                  marginBottom: "0.5vh",
                  fontWeight: 700,
                }}
              >
                Remote Control
              </h3>
            </div>
            <div>
              <div className={classes.remoteControlItem}>
                <div className={classes.remoteControlItemLeft}>
                  <Typography variant="h4" style={{ marginRight: "10px" }}>
                    Device Lock
                  </Typography>
                  {isMobileScreen ? (
                    <ClickAwayListener onClickAway={handleTooltipClose1}>
                      <Tooltip
                        onClose={handleTooltipClose1}
                        open={tooltipOpen1}
                        title={
                          <p style={{ color: "white" }}>
                            If turned on, user can’t change setting on the
                            device unless it is unlocked.
                          </p>
                        }
                        placement="top"
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                      >
                        <InfoOutlinedIcon
                          onClick={handleTooltipOpen1}
                          style={{ color: "#828282" }}
                        />
                      </Tooltip>
                    </ClickAwayListener>
                  ) : (
                    <Tooltip
                      title={
                        <p style={{ color: "white" }}>
                          If turned on, user can’t change setting on the device
                          unless it is unlocked.
                        </p>
                      }
                      placement="right"
                    >
                      <InfoOutlinedIcon style={{ color: "#828282" }} />
                    </Tooltip>
                  )}
                </div>
                <div>
                  <Typography variant="subText">
                    {ifNullState ? "--" : deviceLock ? "ON" : "OFF"}
                  </Typography>
                  <Checkbox
                    disabled={deviceLock === null}
                    checked={deviceLock}
                    onChange={handleDeviceLock}
                  />
                </div>
              </div>
              <div className={classes.remoteControlItem}>
                <div className={classes.remoteControlItemLeft}>
                  <Typography variant="h4" style={{ marginRight: "10px" }}>
                    Auto Mode
                  </Typography>
                  {isMobileScreen ? (
                    <ClickAwayListener onClickAway={handleTooltipClose2}>
                      <Tooltip
                        onClose={handleTooltipClose2}
                        open={tooltipOpen2}
                        title={
                          <p style={{ color: "white" }}>
                            If turned on, user can’t change setting on the
                            device unless it is unlocked.
                          </p>
                        }
                        placement="top"
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                      >
                        <InfoOutlinedIcon
                          onClick={handleTooltipOpen2}
                          style={{ color: "#828282" }}
                        />
                      </Tooltip>
                    </ClickAwayListener>
                  ) : (
                    <Tooltip
                      title={
                        <p style={{ color: "white" }}>
                          If turned on, device will maintain the PM 2.5 at 50,
                          based on USEPA’s suggestion.
                        </p>
                      }
                      placement="right"
                    >
                      <InfoOutlinedIcon style={{ color: "#828282" }} />
                    </Tooltip>
                  )}
                </div>
                <div>
                  <Typography variant="subText">
                    {ifNullState ? "--" : automode ? "ON" : "OFF"}
                  </Typography>
                  <Checkbox
                    disabled={automode === null}
                    checked={automode}
                    onChange={handleAutoMode}
                  />
                </div>
              </div>
              <div className={classes.remoteControlItem}>
                <div className={classes.remoteControlItemLeft}>
                  <Typography variant="h4">Fan Speed</Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    disabled={
                      speedModeNumber == 0 || automode || speedModeNumber == -1
                    }
                    onClick={() => {
                      setSpeedModeNumber((o) => o - 1);
                    }}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                  <Typography>
                    {speedModeNumber == -1 ? "--" : speedModeNumber}
                  </Typography>
                  <IconButton
                    disabled={
                      speedModeNumber == 5 || automode || speedModeNumber == -1
                    }
                    onClick={() => {
                      setSpeedModeNumber((o) => o + 1);
                    }}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      action={
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <Button
            onClick={handleCancelSettingChange}
            variant="outlined"
            style={{ marginRight: "15px" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveChange}
            disabled={loading}
            variant="contained"
          >
            {loading ? (
              <>
                <CircularProgress
                  style={{ height: "20px", marginRight: "5px", width: "20px" }}
                />
                <>Loading</>
              </>
            ) : (
              "Update"
            )}
          </Button>
        </div>
      }
    />
  );
}

export default SensorDetailsPopup;
