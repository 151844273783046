import React, {useState} from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PopupModal from "../../../components/PopupModal";
import { useTheme } from "@mui/material/styles";

export default function DeleteMapDialog(props) {
  const { floorName, deleteMap, showMessage } = props;
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const handleDelete = () => {
    showMessage(floorName + " has been deleted");
    deleteMap(floorName);
    setOpen(false);
  };

  return (
      <PopupModal
        iflink={props.iflink}
        modalOpen={open}
        setModalOpen={setOpen}
        onClose={() => {setOpen(false)}}
        buttonPrompt={props.buttonPrompt? props.buttonPrompt : "Delete Map"}
        width="69vh"
        height={"242px"}
        content={
          <div style={{ textAlign: "left" }}>
            <Typography variant="subText" sx={{
              fontWeight: 550,
              fontSize: "18px",
              lineHeight: "24px",
            }}>
              Are you sure you would like to delete "{floorName}" Map?
            </Typography>
            <br />
            <Typography variant="subText" sx={{
              fontWeight: 550,
              fontSize: "14px",
              lineHeight: "16px",
              color: theme.palette.subText.main
            }}>
              All devices in {floorName} will became unassigned.
            </Typography>
          </div>
        }
        action={
          <>
            <Button
              onClick={handleDelete}
              style={{ textTransform: "none", border: "2px solid " + theme.palette.primary.main }}
              variant="contained"
            >
              Delete
            </Button>
            <Button
              onClick={() => {setOpen(false)}}
              variant="outlined"
            >
              Cancel
            </Button>
          </>
        }
      />
  );
}
