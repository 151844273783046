import React, { useContext, useEffect, useState } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import PopupModal from "../../../components/PopupModal";
import SensorDetailsPopup from "../../../components/SensorDetailsPopup";
import { DeviceContext, UserContext } from "../../../components/UserContext";

export default function SensorsTable(props) {
  const theme = useTheme();
  const userContext = useContext(UserContext);
  const deviceContext = useContext(DeviceContext).raw;
  const [rows, setRows] = useState([]);
  const [infoIconOpen, setInfoIconOpen] = useState(false);
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const CustomizedTable = styled(Table)(({ theme }) => ({
    "& .MuiTableCell-head": {
      ...theme.typography.h5,
      color: "#697077",
      padding: "0px",
      paddingBottom: "10px",
      verticalAlign: "top",
    },
    "& .MuiTableCell-root": {
      ...theme.typography.subText,
      textAlign: "left",
      borderTop: "0",
      borderBottom: "0",
      padding: "0px",
      paddingBottom: "10px",
    },
  }));
  function createData(
    deviceId,
    deviceName,
    location,
    status,
    labels,
    connection
  ) {
    return { deviceId, deviceName, location, status, labels, connection };
  }

  useEffect(() => {
    if (userContext && !userContext.loading) {
      let tempRows = [];
      let sensors = userContext.sensorMapInfoOverview[props.floor];
      if (
        sensors &&
        props.paramSelection &&
        userContext.threshold &&
        userContext.paramNames &&
        userContext.paramIds
      ) {
        sensors = sensors["sensors"];
        let paramId =
          userContext.paramIds[
            userContext.paramNames.indexOf(props.paramSelection)
          ];
        let thresholdName = userContext.thresholdName;
        let threshold = userContext.threshold[paramId];
        for (let i = 0; i < sensors.length; i++) {
          let deviceId = Object.keys(sensors[i])[0];
          let deviceName = sensors[i][deviceId]["sensor_name"];
          let labels = sensors[i][deviceId]["labels"];
          let connection = sensors[i][deviceId]["online"];
          let deviceData = deviceContext[deviceId];

          if (!!deviceData && Object.values(deviceData).length > 0) {
            let paramRecentRecord = deviceData[deviceData.length - 1][paramId];
            if (
              userContext &&
              userContext.sensorObjects &&
              userContext.sensorObjects[deviceId] &&
              userContext.sensorObjects[deviceId][paramId]
            ) {
              paramRecentRecord = userContext.sensorObjects[deviceId][paramId];
            }
            let paramRecentRecordNum = paramRecentRecord;
            if (paramId.toLowerCase().includes("dp")) {
              paramRecentRecord = paramRecentRecord / 10;
            }
            let recordThresholdName = "Fail to get status";
            if (!threshold) {
              recordThresholdName = paramRecentRecordNum;
            } else {
              for (let j = 0; j < threshold.length; j++) {
                if (
                  j === threshold.length - 1 &&
                  Number(paramRecentRecord) > Number(threshold[j])
                ) {
                  recordThresholdName = thresholdName[thresholdName.length - 1];
                } else if (
                  Number(paramRecentRecord) >= Number(threshold[j]) &&
                  Number(paramRecentRecord) < Number(threshold[j + 1])
                ) {
                  recordThresholdName = thresholdName[j];
                } else {
                  continue;
                }
              }
            }
            tempRows.push(
              createData(
                deviceId,
                deviceName,
                props.floor,
                paramId.toLowerCase().includes("pm")
                  ? recordThresholdName + `(${paramRecentRecordNum})`
                  : paramRecentRecordNum,
                labels,
                connection
              )
            );
          } else {
            tempRows.push(
              createData(
                deviceId,
                deviceName,
                props.floor,
                "N/A",
                labels,
                connection
              )
            );
          }
        }
        setRows(tempRows);
      }
    }
  }, [props.floor, props.paramSelection, userContext, deviceContext]);

  useEffect(() => {
    console.log("Sensor Table loading", props.refreshing);
    console.log("sensor all info", userContext.sensorObjects);
  }, [props.refreshing]);

  const getStatusColor = (row) => {
    if (row.status) {
      if (!row.connection) {
        return theme.palette.offline.main;
      }
      const string = new String(row.status);
      const startIndex = 0;
      const endIndex = string.indexOf("(");
      if (!(string.indexOf("N/A") < 0)) {
        if (endIndex < 0) {
          return theme.palette.primary.main;
        }
        return theme.palette.green.main;
      }
      if (endIndex < 0) {
        return theme.palette.primary.main;
      }
      const result = string.slice(startIndex, endIndex);
      return userContext.thresholdColor[
        userContext.thresholdName.indexOf(result)
      ];
    }
  };

  return (
    <>
      {props.refreshing ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress sx={{ marginBottom: "5vh" }} />
          <Typography variant="h3" color={theme.palette.h2.main}>
            Refreshing ...
          </Typography>
        </Box>
      ) : (
        <CustomizedTable>
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: "70px", maxWidth: "70px" }}>
                Name
              </TableCell>
              <TableCell
                style={{
                  minWidth: "70px",
                  maxWidth: "70px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "inline-block", marginRight: "3px" }}>
                  {"Status (" +
                    userContext.paramUnits[
                      userContext.paramNames.findIndex(
                        (element) => element === props.paramSelection
                      )
                    ] +
                    ")"}
                </div>
                {isMobileScreen &&
                props.paramSelection &&
                userContext.paramNames.indexOf(props.paramSelection) >= 0 ? (
                  <PopupModal
                    button={
                      <InfoOutlinedIcon
                        style={{ color: "#828282" }}
                        onClick={() => {
                          setInfoIconOpen(true);
                        }}
                      />
                    }
                    title="Parameter Explained"
                    setModalOpen={setInfoIconOpen}
                    modalOpen={infoIconOpen}
                    content={
                      <Box>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "1.5vh",
                            backgroundColor: "#F8F8F8",
                            paddingLeft: "1.08vh",
                            paddingRight: "1.08vh",
                          }}
                        >
                          <h3
                            style={{
                              marginTop: "0.5vh",
                              marginBottom: "0.5vh",
                              fontWeight: 700,
                            }}
                          >
                            {props.paramSelection}
                          </h3>
                        </div>
                        <div style={{ textAlign: "left" }}>
                          {userContext.paramNamesTooltips[
                            userContext.paramNames.indexOf(props.paramSelection)
                          ].props.children.slice(1)}
                        </div>

                        {["PM 2.5", "PM 10"].includes(props.paramSelection) ? (
                          <div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "1.5vh",
                                backgroundColor: "#F8F8F8",
                                paddingLeft: "1.08vh",
                                paddingRight: "1.08vh",
                              }}
                            >
                              <h3
                                style={{
                                  marginTop: "0.5vh",
                                  marginBottom: "0.5vh",
                                  fontWeight: 700,
                                }}
                              >
                                AQI-based Status
                              </h3>
                            </div>
                            <div style={{ textAlign: "left" }}>
                              <p>
                                {" "}
                                <b>Good: 0-50 </b>
                              </p>
                              <p>
                                {" "}
                                <b>Moderate: 51-100</b>{" "}
                              </p>
                              <p>
                                {" "}
                                <b>Unhealthy: 101-500</b>{" "}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </Box>
                    }
                  />
                ) : (
                  <Tooltip
                    style={{ display: "inline-block" }}
                    title={
                      <Box
                        sx={{
                          "& p": { marginBottom: "0px" },
                          "& b": { fontWeight: 400 },
                        }}
                      >
                        <h4 style={{ color: "white" }}>Status based on AQI</h4>
                        <p>
                          {" "}
                          <b>Good: 0-50 </b>
                        </p>
                        <p>
                          {" "}
                          <b>Moderate: 51-100</b>{" "}
                        </p>
                        <p>
                          {" "}
                          <b>Unhealthy: 101-500</b>{" "}
                        </p>
                      </Box>
                    }
                    placement="right"
                  >
                    <InfoOutlinedIcon style={{ color: "#828282" }} />
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.deviceId}>
                <TableCell>{row.deviceName}</TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: getStatusColor(row),
                  }}
                >
                  {!(row.status.toString().indexOf("N/A") < 0)
                    ? "Offline"
                    : row.status}
                </TableCell>
                <TableCell>
                  <SensorDetailsPopup
                    setLocation={props.setLocation}
                    setUrlValue={props.setUrlValue}
                    location={row.location}
                    deviceId={row.deviceId}
                    floor={props.floor}
                    deviceName={row.deviceName}
                    status={row.status}
                    connection={row.connection}
                    labels={row.labels}
                    paramSelection={props.paramSelection}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </CustomizedTable>
      )}
    </>
  );
}
