import React, { useContext, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";

import GetStatusNameAndColor from "../../../components/GetStatusNameAndColor";
import { UserContext } from "../../../components/UserContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const useStyles = makeStyles({
  rightSection: {
    height: "100%",
    padding: "2.34vh 2.44vh 2.34vh 2.15vh",
  },
  chartTitle: {
    marginBottom: "1.7578vh",
  },
  chartContainer: {
    height: "auto",
  },
});

const AllDeviceGraph = (props) => {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const [fullHourTime, setFullHourTime] = useState(null);
  const [fullHourData, setFullHourData] = useState(null);

  useEffect(() => {
    if (
      props.time &&
      props.data &&
      userContext.threshold &&
      userContext.thresholdColor &&
      userContext.thresholdName &&
      props.paramSelection
    ) {
      let fullHours = [];
      let fullHoursIndex = [];
      for (let i = 0; i < props.time.length; i++) {
        if (props.time[i]) {
          if (props.time[i].getMinutes() === 0) {
            fullHours.push(props.time[i]);
            fullHoursIndex.push(i);
          }
        }
      }
      let fullHoursStatusDeviceCount = [];
      let thresholdNameArr = userContext.thresholdName;
      let fullHourLength = fullHours.length;
      for (let i = 0; i < thresholdNameArr.length; i++) {
        fullHoursStatusDeviceCount.push(Array(fullHourLength).fill(0));
      }
      fullHoursIndex.map((id, index) => {
        // for all devices
        for (let j = 0; j < props.data.length; j++) {
          const deviceCurStatusId = GetStatusNameAndColor(
            userContext.threshold[props.paramSelection],
            userContext.thresholdName,
            userContext.thresholdColor,
            props.data[j][Math.floor(id / 10) * 10]
          ).statusId;
          if (deviceCurStatusId !== -1) {
            fullHoursStatusDeviceCount[deviceCurStatusId][index]++;
          }
        }
      });
      setFullHourData(fullHoursStatusDeviceCount);
      setFullHourTime(fullHours);
    }
  }, [props.time, props.data, props.label, userContext]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
        text: "Chart.js Bar Chart - Stacked",
      },
      legend: {
        display: false,
      },
      customTitle: {
        display: true,
        text: "(Device Count)",
        color: "#989FA7",
        xtitle: "Now",
      },
    },
    scales: {
      x: {
        stacked: true,
        type: "time",
        time: {
          unit: "hour",
        },
        min: props.time[0],
        max: props.time[props.time.length - 1],
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        stacked: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  const customTitle = {
    id: "customTitle",
    beforeLayout: (chart, args, opts) => {
      const { display, font } = opts;
      if (!display) {
        return;
      }

      const { ctx } = chart;
      ctx.font = font || '12px "Helvetica Neue", Helvetica, Arial, sans-serif';

      const { width } = ctx.measureText(opts.text);
      chart.options.layout.padding.top = 30;
      chart.options.layout.padding.right = width * 1.1;
    },
    afterDraw: (chart, args, opts) => {
      const { font, text, color, xtitle } = opts;
      const {
        ctx,
        chartArea: { top, bottom, left, right },
      } = chart;
      if (opts.display) {
        ctx.fillStyle = color;
        ctx.font =
          font || '500 12px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(text, 3, 10);
        ctx.fillText(xtitle, right - 20, bottom + 20);
      }
    },
  };

  const data = {
    labels: fullHourTime,
    datasets: fullHourData
      ? fullHourData.map((deviceCountTime, index) => {
          return {
            label: userContext.thresholdName[index],
            data: deviceCountTime,
            backgroundColor: userContext.thresholdColor[index],
          };
        })
      : [],
  };
  return (
    <Grid container direction="column" className={classes.rightSection}>
      <Grid item height="12%">
        <Typography variant="h4" sx={{ marginBottom: "1.76vh" }}>
          Device Number by Status
        </Typography>
      </Grid>
      <Grid
        xs
        item
        height="88%"
        width="100%"
        className={classes.chartContainer}
      >
        {fullHourData && fullHourTime ? (
          <Bar options={options} data={data} plugins={[customTitle]} />
        ) : (
          <div />
        )}
      </Grid>
    </Grid>
  );
};

export default AllDeviceGraph;
