export default function GetStatusNameAndColor(
  threshold,
  thresholdName,
  thresholdColor,
  paramRecentRecord,
  isPM,
  theme
) {
  let recordThresholdName = "N/A";
  let recordThresholdColor = "gray";
  let recordId = -1;
  if (paramRecentRecord === "N/A") {
    recordThresholdName = "No Data";
  }
  if (!threshold) {
    return {};
  }
  for (let j = 0; j < threshold.length; j++) {
    if (
      j === threshold.length - 1 &&
      Number(paramRecentRecord) > Number(threshold[j])
    ) {
      recordThresholdName = thresholdName[thresholdName.length - 1];
      recordThresholdColor = thresholdColor[thresholdColor.length - 1];
      recordId = thresholdColor.length - 1;
    } else if (
      Number(paramRecentRecord) >= Number(threshold[j]) &&
      Number(paramRecentRecord) < Number(threshold[j + 1])
    ) {
      recordThresholdName = thresholdName[j];
      recordThresholdColor = thresholdColor[j];
      recordId = j;
    } else {
      continue;
    }
  }
  if (isPM !== null && isPM !== undefined && !isPM) {
    recordThresholdColor = theme.palette.primary.main;
  }
  return {
    status: paramRecentRecord,
    statusId: recordId,
    statusName: recordThresholdName,
    statusColor: recordThresholdColor,
  };
}
