import React, { useContext } from "react";

import { Box, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { UserContext } from "../components/UserContext";

const useStyles = makeStyles((theme) => ({
  legendSection: {
    display: "flex",
    flexDirection: "row",
    height: "fit-content",
    justifyContent: (props) => (props.alignLeft ? "left" : "right"),
    maxHeight: "100%",
    maxWidth: "100%",
    flexWrap: "wrap",
  },
  legendDot: {
    display: "flex",
    alignItems: "center",
  },
}));

export default function Legends(props) {
  const theme = useTheme();
  const classes = useStyles(props);
  const userContext = useContext(UserContext);

  let legendFields1 = [];

  if (userContext && userContext.thresholdName && userContext.thresholdColor) {
    const legendLine = (color, text, i) => (
      <Grid
        key={"legendLine~" + i}
        sx={{ height: "fit-content" }}
        marginRight={{ xs: "3px", sm: "10px" }}
      >
        <div className={classes.legendDot}>
          <svg
            style={{ marginRight: "5px" }}
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="7" cy="7" r="7" fill={color} opacity="0.5"></circle>
            <circle cx="7" cy="7" r="5" fill={color}></circle>
          </svg>
          <Typography
            variant="caption"
            style={{ width: "8.10 vh" }}
            color={theme.palette.subText.main}
          >
            {text}
          </Typography>
        </div>
      </Grid>
    );
    if (props.simplify) {
      legendFields1.push(
        legendLine(theme.palette.primary.main, "Online", "Online")
      );
    } else {
      for (let i = 0; i < userContext.thresholdName.length; i++) {
        const color = userContext.thresholdColor[i];
        const text = userContext.thresholdName[i];
        legendFields1.push(legendLine(color, text, i));
      }
    }
    legendFields1.push(
      legendLine("#4F4F4F", "Offline", userContext.thresholdName.length)
    );
  }

  return (
    <Box
      style={{ height: props.height ? props.height : "auto" }}
      className={classes.legendSection}
    >
      {legendFields1}
    </Box>
  );
}
