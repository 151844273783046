import React, { useContext, useEffect, useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  Button,
  Checkbox,
  IconButton, Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";

import PopupModal from "../../../components/PopupModal";
import { DeviceContext, UserContext } from "../../../components/UserContext";
import { batchRemoteControl } from "../../../components/AccessDBGlobalFunctions";
import context from "react-bootstrap/esm/AccordionContext";

const useStyles = makeStyles({
  remoteControlItemLeft: {
    display: "flex",
    alignItems: "center",
  },
  remoteControlItem: {
    display: "flex",
    justifyContent: "space-between",
  },
})

/**
 * 
 * @param {func} props.handleClose close callback
 * @param {bool} props.open open status
 * @param {Array<String>} props.sensors id of deleting sensors
 * @param {funct} props.deleteFunc delete call back
 * @returns 
 */
export default function DeviceBatchCtrDialog(props) {
  const userContext = useContext(UserContext);
  const classes = useStyles();
  // for lock and auto, if there are mixed (like, sensor a has true and b has false),
  // it will be 2 or 3(indeterminate, and 3 is the case with c having null)
  // , then 1 is true and 0 and false.
  const [deviceLock, setDeviceLock] = useState(0);
  const [automode, setAutoMode] = useState(0);
  const [speedModeNumber, setSpeedModeNumber] = useState(2);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const InfoTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#ffffff",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: "1.5vh",
      border: "1px solid #E0E2E5",
      boxShadow: "0px 4px 10px 0px #00000026",
    },
  }));

  const handleCancelSettingChange = () => {
    // props.setSensorDetailsOpen(false);
    setModalOpen(false);
  };

  const handleSaveChange = () => {
    //TODO: actual save to our system
    // props.setSensorDetailsOpen(false);
    let ctrObj = {
      auto: automode,
      lock: deviceLock,
      speed: speedModeNumber,
    }
    setLoading(true);
    batchRemoteControl(props.sensors, ctrObj, userContext)
      .then(() => {
        setLoading(false);
        setModalOpen(false);
        props.showMessage("Devices have been updated!")
      })
  };

  const handleDeviceLock = (event) => {
    setDeviceLock(event.target.checked);
  };
  const handleAutoMode = (event) => {
    setAutoMode(event.target.checked);
  };

  useEffect(() => {
    let objs = props.sensors.map((id) => {
      if (userContext && id) {
        if (userContext["sensorObjects"][id]) {
          let sensorObj = userContext["sensorObjects"][id];
          return sensorObj;
        }
      }
    });
    let lockSet = new Set(objs.map(obj => {
      return obj['lock'];
    }));
    if (lockSet.size > 1) {
      setDeviceLock(lockSet.size);
    } else {
      setDeviceLock(Number(lockSet.has(true)));
    }
    let autoSet = new Set(objs.map(obj => {
      return obj['auto'];
    }));
    if (autoSet.size > 1) {
      setAutoMode(autoSet.size);
    } else {
      setAutoMode(Number(autoSet.has(true)));
    }
    let maxSpeed = 0;
    objs.forEach(obj => {
      let speed = Number(obj["speed"]);
      maxSpeed = speed > maxSpeed ? speed : maxSpeed; 
    })
    setSpeedModeNumber(maxSpeed);
  }, [userContext, props.sensors, modalOpen]);

  return (
    <PopupModal
      variant={"contained"}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      buttonPrompt={props.buttonPrompt? props.buttonPrompt : "Details"}
      title={props.sensors.map((id) => {
        return userContext["sensorObjects"][id]["sensor_name"]
      }).toString()}
			subTitle={"Overwrite control settings of devices"}
      width="69vh"
      height={(props.forMgt === undefined || props.forMgt !== true) ? "75vh" : "33vh"}
      maxHeight={"400px"}
      content={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            width: "100%",
            textAlign: "left",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1.5vh",
                backgroundColor: "#F8F8F8",
                paddingLeft: "1.08vh",
                paddingRight: "1.08vh",
              }}
            >
              <h3
                style={{
                  marginTop: "0.5vh",
                  marginBottom: "0.5vh",
                  fontWeight: 700,
                }}
              >
                Remote Control
              </h3>
            </div>
            <div>
              <div className={classes.remoteControlItem}>
                <div className={classes.remoteControlItemLeft}>
                  <Typography variant="h4" style={{ marginRight: "10px" }}>
                    Device Lock
                  </Typography>
                  <InfoTooltip
                    title="If turned on, user can’t change setting on the device unless it is unlocked."
                    placement="right"
                  >
                    <InfoOutlinedIcon style={{ color: "#828282" }} />
                  </InfoTooltip>
                </div>
                <div>
                  <Typography variant="subText">
                    {deviceLock ? "ON" : "OFF"}
                  </Typography>
                  <Checkbox checked={Boolean(deviceLock)} indeterminate={deviceLock > 1} onChange={handleDeviceLock} />
                </div>
              </div>
              <div className={classes.remoteControlItem}>
                <div className={classes.remoteControlItemLeft}>
                  <Typography variant="h4" style={{ marginRight: "10px" }}>
                    Auto Mode
                  </Typography>
                  <InfoTooltip
                    title="If turned on, device will maintain the PM 2.5 at 50, based on USEPA’s suggestion."
                    placement="right"
                  >
                    <InfoOutlinedIcon style={{ color: "#828282" }} />
                  </InfoTooltip>
                </div>
                <div>
                  <Typography variant="subText">
                    {automode ? "ON" : "OFF"}
                  </Typography>
                  <Checkbox checked={Boolean(automode)} indeterminate={automode > 1} onChange={handleAutoMode} />
                </div>
              </div>
              <div className={classes.remoteControlItem}>
                <div className={classes.remoteControlItemLeft}>
                  <Typography variant="h4">Fan Speed</Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    disabled={speedModeNumber == 0 || automode || speedModeNumber == -1}
                    onClick={() => {
                      setSpeedModeNumber((o) => o - 1);
                    }}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                  <Typography>{speedModeNumber}</Typography>
                  <IconButton
                    disabled={speedModeNumber == 5 || automode || speedModeNumber == -1}
                    onClick={() => {
                      setSpeedModeNumber((o) => o + 1);
                    }}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
			action={
				<div
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}
				>
					<Button
						onClick={handleCancelSettingChange}
						variant="outlined"
						style={{ marginRight: "15px" }}
					>
						Cancel
					</Button>
					<Button onClick={handleSaveChange} disabled={loading} variant="contained">
						{loading ? "Loading" : "Update"}
					</Button>
				</div>
			}
    />
  );
}
