import React from "react";

import { Button, Grid, Paper, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import InputLabelCustomized from "../components/InputLabelCustomized";

export default function UploadMap(props) {
  const theme = useTheme();
  const PaperCustomizedDashed = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    backgroundColor: "transparent",
    height: "calc(60vh - 4px)",
    width: "100%",
    borderColor: "lightgray",
    borderWidth: "2px",
    borderStyle: "dashed",
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const PaperCustomizedSolid = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    backgroundColor: "#FFFFFF",
    height: "60vh",
    width: "100%",
    marginBottom: "20px",
  }));

  // empty dashboard
  return (
    <>
      <Grid
        container
        rowSpacing={3}
        xs={12}
        md={12}
        sx={{ padding: "30px 45px 30px 45px" }}
      >
        <Grid item xs={12} md={12}>
          <Typography variant="h3">Upload Map</Typography>
        </Grid>

        <Grid item xs={12} md={12}>
          <InputLabelCustomized
            labelName="Map Name"
            width="25%"
            placeholder="Name this Map"
          />
        </Grid>

        <Grid container item columnSpacing={5}>
          <Grid
            container
            item
            xs={12}
            md={8}
            alignItems="center"
            justifyContent="center"
          >
            <PaperCustomizedDashed>
              <Grid item>
                <Button variant="contained" sx={{ textTransform: "none" }}>
                  Upload Map
                </Button>
              </Grid>
            </PaperCustomizedDashed>
          </Grid>

          <Grid container item xs={12} md={4}>
            <PaperCustomizedSolid>
              <Grid
                container
                item
                xs={12}
                md={12}
                spacing={1}
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <Grid container item xs={12} md={12}>
                  <Grid item xs={10} md={9.8}>
                    <Typography variant="h3">Added Map</Typography>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <Button variant="outlined">Added</Button>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography
                    variant="link"
                    color={theme.palette.headingText.main}
                  >
                    Map Name
                  </Typography>
                </Grid>
              </Grid>
            </PaperCustomizedSolid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
