import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import Legends from "../../../components/Legends";
import MapWithAllSensors from "../../../components/MapWithAllSensors";
import { DeviceContext, UserContext } from "../../../components/UserContext";
import {
  FloorAggregateGraphDataProvider,
  FloorGraphDataProvider,
} from "../components/FloorGraphDataProvider";
import AllDeviceGraph from "./AllDeviceGraph";
import AreaAverageGraph from "./AreaAverageGraph";
import DeviceHistoryGraph from "./DeviceHistoryGraph";
import DeviceRealTimeTable from "./DeviceRealTimeTable";

const useStyles = makeStyles({
  leftSectionContentContainer: {
    height: "100%",
    alignItems: "stretch",
  },
  mapTableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  mapTableContainerWhite: {
    backgroundColor: "white",
    width: "100%",
  },
});

const CardRoot = styled(Card)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    height: "692px",
  },
  [theme.breakpoints.up("lg")]: {
    height: "346px",
  },
}));

const LeftSectionRoot = styled(Grid)(({ theme }) => ({
  backgroundColor: "#F8F8F9",
  paddingLeft: "16px",
  paddingRight: "20px",
  paddingBottom: "18px",
  paddingTop: "19px",
  [theme.breakpoints.down("lg")]: {
    height: "50%",
    maxHeight: "50%",
    width: "100%",
    borderBottom: "1px solid #E0E2E5",
    padding: "16px",
  },
  [theme.breakpoints.up("lg")]: {
    height: "100%",
    width: "43%",
    borderRight: "1px solid #E0E2E5",
  },
}));

const RightSectionRoot = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    height: "50%",
    maxHeight: "50%",
    width: "100%",
  },
  [theme.breakpoints.up("lg")]: {
    height: "100%",
    width: "57%",
  },
}));

/**
 * @param {string} props.floor floor name
 * @param {string} props.paramSelection
 * @param {string} props.dataTypeSelection
 * @param {object} props.floorData
 */
const OverViewCards = (props) => {
  const classes = useStyles();
  const params = useParams();
  const theme = useTheme();
  const deviceContext = useContext(DeviceContext);
  const userContext = useContext(UserContext);
  const [graphData, setGraphData] = useState(null);
  const navigation = useNavigate();

  useEffect(() => {
    if (
      props.floorData &&
      props.paramSelection &&
      props.floorDeviceData &&
      props.floor &&
      deviceContext &&
      Object.keys(deviceContext).length !== 0 &&
      deviceContext.agg
    ) {
      // setGraphData(
      //   FloorGraphDataProvider(
      //     props.floorData,
      //     props.paramSelection,
      //     props.floorDeviceData
      //   )
      // );
      // setGraphData(

      // );
      const floorAgg = FloorAggregateGraphDataProvider(
        deviceContext.agg,
        props.floor,
        props.paramSelection
      );
      setGraphData(floorAgg);
    }
  }, [
    props.floorData,
    props.paramSelection,
    props.floorDeviceData,
    deviceContext,
    props.floor,
  ]);

  let graph = () => {
    switch (params["overviewId"]) {
      case "area-average":
        return (
          <AreaAverageGraph
            nullState={
              !(
                graphData &&
                graphData.data &&
                graphData.data.length !== 0 &&
                graphData.time.length !== 0
              )
            }
            hideLegend
            eightHour={true}
            paramSelectionName={props.paramSelectionName}
            paramSelection={props.paramSelection}
            labels={graphData.label}
            data={graphData.data}
            time={graphData.time}
          />
        );
      // case "8-hour-average":
      //   return (
      //     <AreaAverageGraph
      //       eightHour={true}
      //       paramSelection={props.paramSelection}
      //       labels={graphData.label}
      //       data={graphData.data}
      //       time={graphData.time}
      //     />
      //   );
      case "harzard":
        return (
          <DeviceHistoryGraph
            paramSelection={props.paramSelection}
            labels={graphData.label}
            data={graphData.data}
            time={graphData.time}
          />
        );
      case "all-device":
        return (
          <AllDeviceGraph
            paramSelection={props.paramSelection}
            labels={graphData.label}
            data={graphData.data}
            time={graphData.time}
            null
          />
        );
      default:
        console.log("wrong overviewId url param");
    }
    // if (
    //   graphData &&
    //   graphData.data &&
    //   graphData.data.length !== 0 &&
    //   graphData.time.length !== 0
    // ) {
    //   switch (params["overviewId"]) {
    //     case "area-average":
    //       return (
    //         <AreaAverageGraph
    //           hideLegend
    //           eightHour={true}
    //           paramSelectionName={props.paramSelectionName}
    //           paramSelection={props.paramSelection}
    //           labels={graphData.label}
    //           data={graphData.data}
    //           time={graphData.time}
    //         />
    //       );
    //     // case "8-hour-average":
    //     //   return (
    //     //     <AreaAverageGraph
    //     //       eightHour={true}
    //     //       paramSelection={props.paramSelection}
    //     //       labels={graphData.label}
    //     //       data={graphData.data}
    //     //       time={graphData.time}
    //     //     />
    //     //   );
    //     case "harzard":
    //       return (
    //         <DeviceHistoryGraph
    //           paramSelection={props.paramSelection}
    //           labels={graphData.label}
    //           data={graphData.data}
    //           time={graphData.time}
    //         />
    //       );
    //     case "all-device":
    //       return (
    //         <AllDeviceGraph
    //           paramSelection={props.paramSelection}
    //           labels={graphData.label}
    //           data={graphData.data}
    //           time={graphData.time}
    //         />
    //       );
    //     default:
    //       console.log("wrong overviewId url param");
    //   }
    // } else {
    //   return (
    //     <Grid
    //       item
    //       sx={{
    //         width: "100%",
    //         height: "100%",
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //       }}
    //     >
    //       <Typography
    //         sx={{
    //           width: "100%",
    //           height: "100%",
    //           justifyContent: "center",
    //           display: "flex",
    //           alignItems: "center",
    //         }}
    //         variant="h3"
    //         color={theme.palette.h2.main}
    //       >
    //         {" "}
    //         No data is Avaliable
    //       </Typography>
    //     </Grid>
    //   );
    // }
  };

  return (
    <CardRoot variant="outlined" style={{ marginBottom: "20px" }}>
      {graphData && graphData.data ? (
        <Grid container height="100%">
          <LeftSectionRoot item>
            <Grid
              container
              className={classes.leftSectionContentContainer}
              directon="column"
            >
              <Grid
                item
                className={classes.mapTableHeader}
                paddingBottom={{ md: "20px", xs: "12px" }}
                height="fit-content"
              >
                <div style={{ marginRight: "5px" }}>
                  <Typography style={{ display: "inline-block" }} variant="h2">
                    {props.floor + " "}
                  </Typography>
                  <Typography
                    style={{ display: "inline-block", marginLeft: "3px" }}
                    variant="bodyText"
                  >
                    {" / " +
                      (props.floor && userContext.sensorMapInfoOverview
                        ? userContext.sensorMapInfoOverview[props.floor].sensors
                            .length
                        : 0) +
                      " device(s)"}
                  </Typography>
                </div>
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigation(
                      `/dashboard/data-analytics/floor-detail/${props.floor}`
                    );
                  }}
                  sx={{
                    height: "36px",
                    width: "109px",
                    minWidth: "109px",
                    minHeight: "36px",
                    maxWidth: "109px",
                    maxHeight: "36px",
                  }}
                >
                  View More
                </Button>
              </Grid>
              <Grid
                item
                className={classes.mapTableContainerWhite}
                padding={{ xs: "12px", md: "20px" }}
                height={{ xs: "78%", md: "calc(100% - 65px)" }}
              >
                {params.overviewId !== "all-device" ? (
                  <Grid
                    container
                    style={{ alignItems: "center", height: "100%" }}
                  >
                    <Grid
                      item
                      container
                      xs={7.35}
                      height="calc(100% - 26px)"
                      justifyContent="center"
                    >
                      <Box
                        sx={{
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingLeft: "12px",
                          paddingRight: "18px",
                          maxWidth: "100%",
                          maxHeight: "100%",
                          [theme.breakpoints.down("sm")]: {
                            paddingLeft: "0px",
                            paddingRight: "10px",
                          },
                        }}
                      >
                        <MapWithAllSensors
                          style={{ width: "100%", height: "80%" }}
                          type={props.paramSelection}
                          id={
                            "da-" + params["overviewId"] + "-" + props.floorId
                          }
                          map={props.floorData["background_name"]}
                          mapFloor={props.floor}
                        />
                        {/* <Grid display={{ xs: "none", md: "block" }}>
                          <Legends
                            simplify={
                              props.paramSelection
                                ? !props.paramSelection
                                    .toLowerCase()
                                    .includes("pm")
                                : false
                            }
                            alignLeft={true}
                          />
                        </Grid> */}
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={4.65}
                      height="calc(100% - 26px)"
                      style={{
                        alignItems: "flex-start",
                        alignSelf: "flex-start",
                      }}
                    >
                      <div
                        style={{ alignSelf: "flex-start" }}
                        className={classes.deviceRealTimeTable}
                      >
                        <DeviceRealTimeTable
                          floor={props.floor}
                          paramSelection={props.paramSelection}
                          floorData={props.floorData}
                        />
                      </div>
                    </Grid>
                    <Grid height="16px" marginTop="10px">
                      <Legends
                        simplify={
                          props.paramSelection
                            ? !props.paramSelection.toLowerCase().includes("pm")
                            : false
                        }
                        alignLeft={true}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item style={{ height: "100%" }}>
                    <Grid
                      item
                      sx={{
                        height: "100%",
                        width: "100%",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        paddingLeft: "12px",
                        paddingRight: "18px",
                      }}
                      justifyContent="center"
                    >
                      <MapWithAllSensors
                        id={"da-" + params["overviewId"] + "-" + props.floorId}
                        map={props.floorData["background_name"]}
                        mapFloor={props.floor}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </LeftSectionRoot>
          <RightSectionRoot item>
            {/* <AreaAverageGraph
              hideLegend
              eightHour={true}
              paramSelectionName={props.paramSelectionName}
              paramSelection={props.paramSelection}
              labels={graphData.label}
              data={graphData.data}
              time={graphData.time}
            /> */}
            {graph()}
          </RightSectionRoot>
        </Grid>
      ) : (
        <div>"Graph Data Fetching Error"</div>
      )}
    </CardRoot>
  );
};
export default OverViewCards;
