import React, {
  createRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  InputLabel,
  Link,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { Input } from "semantic-ui-react";

import DeleteSensorDialog from "../features/device-management/Components/DeleteSensorDialog";
import AddLabelChip from "../features/home/components/AddLabelChip";
import InputLabelCustomized from "../features/home/components/InputLabelCustomized";
import SetupBLETimeout from "../util/SetUpBLETimeout.svg";
import {
  addDeviceToLayer,
  checkSensorValidation,
  deleteSensor,
  insertSensorToDBWithLocation,
  insertSensorToDBWithoutLocation,
  updateContextAfterUpdateSensorLayer,
  updatePosition,
  updateSensor,
} from "./AccessDBGlobalFunctions";
import Dropdown from "./Dropdown";
import MapWithAllSensors from "./MapWithAllSensors";
import Messager from "./Messager";
import PopupModal from "./PopupModal";
import { UserContext } from "./UserContext";

const TIMEOUT_TITLE_LIST = ["Device Not Detected", "Connection Fail"];
const uuid_wifi_config_service = "b4ad5b8d-d2db-44d6-9d35-5d43b9e5060c";
const uuid_wifi_config_tx = "3c673f3a-382a-4835-8433-c1c1b6b65346";
const uuid_wifi_config_rx = "226285d5-7a5a-448d-8317-dae1fd2d6c36";
const new_uuid_wifi_get_device_id = "2e4b18df-48ff-4fa4-9332-c0e41ce02133";
const uuid_wifi_get_device_id = "a0de4858-87b4-11ed-a1eb-0242ac120002";

export default function EditSensorsPopup(props) {
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const TITLE_LIST = [
    <>
      Step 1: Enable Pairing Mode (1/4)
      {/* <Tooltip
        style={{ display: "inline-block" }}
        title={
          <Box
            sx={{
              "& p": { marginBottom: "0px" },
              "& b": { fontWeight: 400 },
            }}
          >
            <p>
              Please note that you will need a Google Chrome or Microsoft Edge
              browser for the setup process.
            </p>
          </Box>
        }
        placement={isMobileScreen ? "top" : "right"}
      >
        <InfoOutlinedIcon
          style={{ position: "relative", top: "5px", color: "#828282" }}
        />
      </Tooltip> */}
    </>,
    <>
      Step 2: Wi-Fi Connection (2/4){" "}
      <Tooltip
        style={{ display: "inline-block" }}
        title={
          <Box
            sx={{
              "& p": { marginBottom: "0px" },
              "& b": { fontWeight: 400 },
            }}
          >
            <p>
              Tip: If your network doesn't appear, click the "REFRESH" button.
              For hidden networks, choose "Other".
            </p>
          </Box>
        }
        placement="right"
      >
        <InfoOutlinedIcon
          style={{ position: "relative", top: "5px", color: "#828282" }}
        />
      </Tooltip>
    </>,
    <>
      Step 3: Name Your Device (3/4){" "}
      <Tooltip
        style={{ display: "inline-block" }}
        title={
          <Box
            sx={{
              "& p": { marginBottom: "0px" },
              "& b": { fontWeight: 400 },
            }}
          >
            <p>
              Tip: After connecting to Wi-Fi, assign a name to your unit and
              place it on a floor plan.
            </p>
          </Box>
        }
        placement="right"
      >
        <InfoOutlinedIcon
          style={{ position: "relative", top: "5px", color: "#828282" }}
        />
      </Tooltip>
    </>,
    <>
      Step 4: Map Placement (Optional) (4/4){" "}
      <Tooltip
        style={{ display: "inline-block" }}
        title={
          <Box
            sx={{
              "& p": { marginBottom: "0px" },
              "& b": { fontWeight: 400 },
            }}
          >
            <p>
              Tip: Opt to add your unit to a map now or later. Unassigned
              devices can be found in the "Unassigned" list on the Management
              page.
            </p>
          </Box>
        }
        placement="right"
      >
        <InfoOutlinedIcon
          style={{ position: "relative", top: "5px", color: "#828282" }}
        />
      </Tooltip>
    </>,
  ];
  const context = useContext(UserContext);
  const theme = useTheme();
  const [floorOptions, setFloorOptions] = useState([]);
  // const [children, setChildren] = useState([]);
  const [selectMapDropdownOption, setSelectMapDropdownOption] = useState(
    props.location ? props.location : "Select Map"
  );
  const [step, setStep] = useState(props.add ? 0 : 2);
  const [deviceName, setDeviceName] = useState(
    props.deviceName ? props.deviceName : ""
  );
  const [deviceId, setDeviceId] = useState(
    props.deviceId ? props.deviceId : ""
  );
  // const [productId, setProductId] = useState("");
  const [open, setOpen] = useState(false);
  // const selectMapDropdownRef = createRef();
  const changedMarkerRef = createRef();
  const deviceNameRef = useRef(null);
  const deviceIdRef = useRef(null);
  const wifiPasswordRef = useRef(null);
  const [changeLocationGrayScale, setChangeLocationGrayScale] = useState(false);

  // const productIdRef = useRef(null);
  const [finalPosition, setFinalPosition] = useState(
    props.initPosition
      ? props.initPosition
      : {
          x_coordinate: -50.0,
          y_coordinate: 50.0,
        }
  );
  const [delDiaOpen, setDelDiaOpen] = useState(false);
  const [editName, setEditName] = useState(props.add);
  const [particleDevice, setParticleDevice] = useState(null);
  const [ssids, setSsids] = useState(["other"]);
  const [selectedSSID, setSelectedSSID] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [timeoutPage, setTimeoutPage] = useState(false);
  const [hiddenSSID, setHiddenSSID] = useState(false);
  const hiddenSSIDInputRef = useRef(null);
  const hiddenSSIDInputNoNameErrorRef = useRef(null);
  const hiddenWifiPassword = useRef(null);
  const hiddenWifiNoPasswordErrorRef = useRef(null);
  const passwordInputNoPasswordErrorRef = useRef(null);
  const noDeviceNameInputErrorRef = useRef(null);
  const [owned, setOwned] = useState(false);
  const [closeConfirmModalOpen, setCloseConfirmModalOpen] = useState(false);
  const [ownedModalOpen, setOwnedModalOpen] = useState(false);
  const [hiddenSSIDModalOpen, setHiddenSSIDModalOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState();
  const memorizedPasswordField = useMemo(
    () => (
      <div
        style={{
          height: "44px",
          display: "grid",
          gridTemplateColumns: "19% auto",
          columnGap: "20px",
          rowGap: "15px",
          marginBottom: "10px",
          alignItems: "center",
        }}
      >
        {console.log("memorized rerendered")}
        <Typography variant="h5" color={theme.palette.h2.main}>
          Password
        </Typography>
        <InputLabelCustomized
          minify
          type={"password"}
          ref={wifiPasswordRef}
          id="wifi-password"
          labelName="Wifi Password"
          width="60%"
          placeholder="Enter Password"
          required
        />
      </div>
    ),
    []
  );

  function showMessage(message) {
    setMessageOpen(true);
    setMessage(message);
  }

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  useEffect(() => {
    if (owned) {
      setOwnedModalOpen(true);
    }
  }, [owned]);

  useEffect(() => {
    console.log("hiddenSSID", hiddenSSID);
    if (hiddenSSID) {
      setHiddenSSIDModalOpen(true);
    }
  }, [hiddenSSID]);

  const startLoading = () => {
    console.log("startloading");
    setLoading(true);

    const id = setTimeout(() => {
      setLoading(false);
      setTimeoutPage(true);
    }, 20000);

    setTimeoutId(id);
  };

  const endLoading = () => {
    setLoading(false);
    setTimeoutPage(false);
    clearTimeout(timeoutId);
    setTimeoutId(null);
  };

  useEffect(() => {
    let options = Object.keys(context.layerToSensors);
    let index = options.indexOf("Unassigned");
    if (index > -1) {
      let temp = options[0];
      options[0] = options[index];
      options[index] = temp;
    }
    options[0] = "Select Map";
    setFloorOptions(options);
  }, [context.layerToSensors]);

  useEffect(() => {
    setSsids([...ssids]);
  }, [step]); // force refrech wifi list, DON'T DELETE

  const handleClean = () => {
    setStep(props.add ? 0 : 2);
    setDeviceName(props.deviceName ? props.deviceName : "");
    setDeviceId(props.deviceId ? props.deviceId : "");
    setSelectMapDropdownOption(props.location ? props.location : "");
    setSsids(["other"]);
    setSelectedSSID("");
    setHiddenSSID(false);
    setPassword("");
    setLoading(false);
    setEditName(props.add);
    endLoading();
    setTimeoutId(null);
    setOwned(false);
  };

  const updateDeviceName = () => {
    // setDeviceId(deviceIdRef.current?.querySelector("input").value);
    // setProductId(productIdRef.current?.querySelector("input").value);
  };

  const handleUpdate = () => {
    let newName;
    if (deviceNameRef.current) {
      newName = deviceNameRef.current.querySelector("input").value;
      deviceNameRef.current.querySelector("input").value = newName; // to keep display constist
    }
    if (newName === undefined) {
      newName = deviceName;
    }
    setLoading(true);
    updateSensor(
      deviceId,
      context.sensorObjects[deviceId]["particle_product_id"],
      newName,
      context
    )
      .then((context) => {
        addDeviceToLayer(deviceId, selectMapDropdownOption, context)
          .then(() => {
            updateContextAfterUpdateSensorLayer(
              deviceId,
              props.location,
              selectMapDropdownOption,
              context
            );
          })
          .then(() => {
            updatePosition(deviceId, finalPosition, context, true);
            setDeviceName(newName);
          });
      })
      .then(() => {
        setLoading(false);
        setEditName(props.add);
        props.showMessage("Device " + newName + " has been updated!");
        setOpen(false);
      });
  };

  const handleDelete = () => {
    deleteSensor([deviceId], context);
    props.showMessage("Device " + deviceName + " has been deleted!");
    handleClean();
    setOpen(false);
  };

  const handleAddSensor = (another) => {
    if (owned) {
      setOpen(false);
      handleClean();
      return;
    }
    if (
      selectMapDropdownOption === "" ||
      selectMapDropdownOption === "Select Map"
    ) {
      setLoading(true);
      insertSensorToDBWithoutLocation(
        deviceId,
        deviceName,
        18711, // todo: modify accessdbglobalfunctions to fit api
        // productIdRef.current.querySelector("input").value,
        context
      ).then(() => {
        if (!another) {
          handleClean();
        }
        setOpen(false);
        props.showMessage(
          <div>
            <Typography variant="h3Light">
              Device
              <Typography
                variant="h3"
                sx={{
                  display: "inline-block",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                {deviceName}
              </Typography>
              added without specific locations! Please update location under
              <Typography
                variant="h3"
                sx={{
                  display: "inline-block",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                Management {">"} List View
              </Typography>
              when ready.
            </Typography>
          </div>
        );
      });
    } else {
      setLoading(true);
      insertSensorToDBWithLocation(
        selectMapDropdownOption,
        deviceId,
        deviceName,
        18711, // todo: modify accessdbglobalfunctions to fit api
        finalPosition,
        context
      ).then(() => {
        if (!another) {
          handleClean();
        }
        setOpen(false);
        props.showMessage(
          <div>
            <Typography variant="h3Light" sx={{ display: "inline-block" }}>
              Device&nbsp;
            </Typography>
            <Typography variant="h3" sx={{ display: "inline-block" }}>
              {deviceName}&nbsp;
            </Typography>
            <Typography variant="h3Light" sx={{ display: "inline-block" }}>
              add to&nbsp;
            </Typography>
            <Typography variant="h3" sx={{ display: "inline-block" }}>
              {selectMapDropdownOption}
            </Typography>
          </div>
        );
      });
    }
  };

  function rxDataHandler(event) {
    let decoder = new TextDecoder("utf-8");
    let result_str = decoder.decode(event.target.value);
    try {
      let obj = JSON.parse(result_str);
      if (obj) {
        if (obj.msg_t == "scan_resp") {
          if (obj.ssid != "") {
            let cpy = ssids;
            if (!cpy.includes(obj.ssid)) {
              cpy.pop();
              cpy.push(obj.ssid);
              cpy.push("other");
              setPassword(
                wifiPasswordRef.current?.querySelector("input").value
              );
                            if (!(cpy.length - 1 > 1)) {
                setSelectedSSID(obj.ssid);
              }
              setSsids(cpy);
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  function handelBLEConnect() {
    navigator.bluetooth
      .requestDevice({
        filters: [
          {
            services: [uuid_wifi_config_service], // Only show devices that have the WiFi config service enabled
          },
        ],
      })
      .then((device) => {
        // Connect to the selected device
        startLoading();
        setParticleDevice(device);
        return device.gatt.connect();
      })
      .then((server) => {
        endLoading();
        server
          .getPrimaryService(uuid_wifi_config_service)
          .then(async (service) => {
            // after b36 firmware, the value the contain device id is in complex format
            let inComplexFormat = false; 
            let characteristic;
            try {
               characteristic = await service
                .getCharacteristic(uuid_wifi_get_device_id);
            } catch (error) {
              characteristic = await service
                .getCharacteristic(new_uuid_wifi_get_device_id);
              inComplexFormat = true;
            }

            characteristic.readValue()
              .then((characteristicValue) => {
                let value = new TextDecoder("utf-8").decode(
                  characteristicValue
                );
                if (inComplexFormat) {
                  value = JSON.parse(value)["devID"];
                }
                setDeviceId(value);
                return value;
              })
              .then(checkSensorValidation)
              .then((response) => {
                if (!response.result && props.add) {
                  setOwned(true);
                }
              })
              .catch((error) => {
                console.error(error);
              });
            return service;
          })
          .then((service) => {
            service
              .getCharacteristic(uuid_wifi_config_rx)
              .then((characteristic) => {
                characteristic.startNotifications();
                return characteristic;
              })
              .then((characteristic) => {
                characteristic.addEventListener(
                  "characteristicvaluechanged",
                  rxDataHandler
                );
              })
              .catch((error) => {
                console.error(error);
              });
            return service;
          })
          // Initiate WiFi scan
          .then((service) => {
            return service.getCharacteristic(uuid_wifi_config_tx);
          })
          .then((characteristic) => {
            let encoder = new TextEncoder();
            let scan_msg = {
              msg_type: "scan",
            };
            let message_str = JSON.stringify(scan_msg);
            let bytes = encoder.encode(message_str);
            setStep(1);
            endLoading();
            return characteristic.writeValue(bytes);
          })
          .catch((error) => {
            console.error(error);
          });
      });
  }

  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function handleSubmitWifiCred() {
    if (hiddenSSID && hiddenSSIDInputNoNameErrorRef.current) {
      hiddenSSIDInputNoNameErrorRef.current.style.display = "none";
    }
    passwordInputNoPasswordErrorRef.current.style.display = "none";

    let ssid = selectedSSID;
    let password = wifiPasswordRef.current?.querySelector("input").value;
    if (selectedSSID === "other") {
      ssid = hiddenSSIDInputRef.current?.querySelector("input").value;
      password = hiddenWifiPassword.current?.querySelector("input").value;
    }

    if (ssid === "" && hiddenSSIDInputNoNameErrorRef.current) {
      hiddenSSIDInputNoNameErrorRef.current.style.display = "block";
      return;
    }
    // if (password === "" && passwordInputNoPasswordErrorRef.current) {
    //   passwordInputNoPasswordErrorRef.current.style.display = "block";
    //   return;
    // }
    startLoading();
    // reset input value due to state change
    if (selectedSSID === "other" && hiddenSSIDInputRef.current) {
      hiddenSSIDInputRef.current.querySelector("input").value = ssid;
    }
    wifiPasswordRef.current.querySelector("input").value = password;
    console.log("ssid:", ssid);
    console.log("password:", password);
    // await particleDevice.gatt
    //   .getPrimaryService(uuid_wifi_config_service)
    //   // Send credentials to device
    //   .then(async (service) => {
    //     let characteristic = await service.getCharacteristic(
    //       uuid_wifi_config_tx
    //     );
    //     let credentials_msg = {
    //       msg_type: "set_creds",
    //       ssid: ssid,
    //       password: password,
    //     };
    //     let message_str = JSON.stringify(credentials_msg);
    //     let encoder = new TextEncoder();
    //     let bytes = encoder.encode(message_str);
    //     await characteristic.writeValue(bytes);
    //     return service;
    //   })
    //   .then(async (service) => {
    //     // check if SSID characteristic has value, if yes, then connect success.
    //     let wifiChar = await service.getCharacteristic(
    //       uuid_wifi_ssid_characteristic
    //     );
    //     await setTimeout(async () => {
    //       await wifiChar.readValue().then((characteristicValue) => {
    //         let value = new TextDecoder("utf-8").decode(characteristicValue);
    //         endLoading();
    //         if (value == null || value == "") {
    //           showMessage("Connect Failed. Please Retry");
    //         } else {
    //           showMessage("Connect Successful!");
    //           setStep(2);
    //         }
    //       });
    //     }, 4000);
    //   })
    //   .catch((error) => {});
    particleDevice.gatt
      .getPrimaryService(uuid_wifi_config_service)
      .then((service) => {
        return service.getCharacteristic(uuid_wifi_config_tx);
      })
      // Send credentials to device
      .then((characteristic) => {
        let credentials_msg = {
          msg_type: "set_creds",
          ssid: ssid,
          password: password,
        };
        let message_str = JSON.stringify(credentials_msg);
        let encoder = new TextEncoder();
        let bytes = encoder.encode(message_str);
        return characteristic.writeValue(bytes);
      })
      .then(() => {
        endLoading();
        showMessage("Connect Successful!");
        setStep(2);
      })
      .catch((error) => {});
  }

  function renderContent() {
    return [
      timeoutPage ? (
        <>
          <div
            style={{
              textAlign: "left",
            }}
          >
            <Typography variant="bodyText">
              We're unable to locate your device. Please ensure it's set to
              pairing mode and try again.
            </Typography>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              textAlign: "left",
            }}
          >
            <div style={{ marginBottom: "15px" }}>
              <Typography variant="bodyText">
                Instructions: Follow these steps to prepare your device for
                pairing.{" "}
              </Typography>
              <div style={{ display: "flex", marginTop: "10px" }}>
                <Typography variant="bodyText">
                  {" "}
                  Please note that you will need a Google Chrome or Microsoft
                  Edge browser for the setup process.
                </Typography>
              </div>
            </div>
            <img
              style={{ width: "100%" }}
              src="https://violett-dashboard-userfile162811-staging.s3.amazonaws.com/violett_setup.gif"
            ></img>
          </div>
        </>
      ),
      timeoutPage ? (
        <div
          style={{
            textAlign: "left",
          }}
        >
          <Typography variant="bodyText">
            Wi-Fi connection failed. Please verify details and try again.
          </Typography>
        </div>
      ) : (
        <>
          <div>
            <div
              style={{
                height: "44px",
                display: "grid",
                gridTemplateColumns: "19% auto",
                columnGap: "20px",
                rowGap: "15px",
                marginBottom: "10px",
                alignItems: "center",
              }}
            >
              <Typography variant="h5" color={theme.palette.h2.main}>
                Network
              </Typography>
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContents: "space-between",
                }}
              >
                <Dropdown
                  selection={selectedSSID}
                  setSelection={(selection) => {
                    setSelectedSSID(selection);
                    setHiddenSSID(false);
                    if (selection === "other") {
                      setHiddenSSID(true);
                    }
                  }}
                  options={ssids}
                  width="60%"
                />
                <Link
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    setSsids(() => {
                      return [...ssids];
                    });
                  }}
                >
                  Refresh
                </Link>
              </div>
            </div>
            <HiddenSSIDModal
              password={password}
              loading={loading}
              open={hiddenSSID}
              onClose={() => {
                setHiddenSSID(false);
              }}
              setModalOpen={setHiddenSSID}
              theme={theme}
              handleNext={handleSubmitWifiCred}
              ref={{
                hiddenSSIDInputRef,
                hiddenSSIDInputNoNameErrorRef,
                hiddenWifiPassword,
                hiddenWifiNoPasswordErrorRef,
              }}
            />
            {/* {hiddenSSID && (
              <div
                style={{
                  height: "44px",
                  display: "grid",
                  gridTemplateColumns: "19% auto",
                  columnGap: "20px",
                  rowGap: "15px",
                  marginBottom: "10px",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" color={theme.palette.h2.main}>
                  Name
                </Typography>
                <InputLabelCustomized
                  minify
                  // type={"password"}
                  ref={hiddenSSIDInputRef}
                  id="wifi-hidden-name"
                  labelName="Wifi name"
                  width="95%"
                  placeholder="Enter Network Name"
                  required
                />
              </div>
            )}
            {hiddenSSID && (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "19% auto",
                  columnGap: "20px",
                  rowGap: "15px",
                  marginBottom: "5px",
                  alignItems: "center",
                }}
              >
                <div></div>
                <Typography
                  variant="subText"
                  ref={hiddenSSIDInputNoNameErrorRef}
                  sx={{
                    fontSize: "12px",
                    display: "none",
                    paddingLeft: "5px",
                    color: theme.palette.red.main,
                  }}
                >
                  Please enter a Network (WiFi) name.
                </Typography>
              </div>
            )} */}
            {memorizedPasswordField}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "19% auto",
                columnGap: "20px",
                rowGap: "15px",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              <div></div>
              <Typography
                variant="subText"
                ref={passwordInputNoPasswordErrorRef}
                sx={{
                  fontSize: "12px",
                  display: "none",
                  paddingLeft: "5px",
                  color: theme.palette.red.main,
                }}
              >
                Please enter a Network (WiFi) Password.
              </Typography>
            </div>
          </div>
          {owned && (
            <div
              style={{ width: "100%", display: "flex", marginBottom: "20px" }}
            >
              <Typography variant="subText">
                This unit has already been registered under another account. To
                connect it to this account, you'll need to remove the device
                from the other account first.
              </Typography>
            </div>
          )}
        </>
      ),
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1.5vh",
            backgroundColor: "#F8F8F8",
            paddingLeft: "10px",
            paddingRight: "10px",
            opacity: changeLocationGrayScale ? 0.5 : 1,
          }}
        >
          <h3
            style={{
              marginTop: "0.5vh",
              marginBottom: "0.5vh",
              fontWeight: 700,
            }}
          >
            Device Info
          </h3>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "18% auto",
            gridTemplateRows: "auto 40px",
            columnGap: "20px",
            rowGap: "15px",
            marginBottom: "15px",
            paddingLeft: "10px",
            opacity: changeLocationGrayScale ? 0.5 : 1,
          }}
        >
          {!owned && (
            <>
              <Typography
                variant="h5"
                color={theme.palette.h2.main}
                style={{ alignSelf: "center" }}
              >
                Device Name
              </Typography>
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  {editName ? (
                    <>
                      <InputLabelCustomized
                        minify
                        value={deviceName}
                        ref={deviceNameRef}
                        id="add-device-name"
                        labelName="Device Name"
                        width="95%"
                        placeholder="Name this device"
                        required
                      />
                      {!props.add && (
                        <IconButton
                          onClick={() => {
                            setDeviceName(props.deviceName);
                            setEditName(false);
                          }}
                        >
                          <CancelIcon
                            style={{ display: "inline-block" }}
                          ></CancelIcon>
                        </IconButton>
                      )}
                    </>
                  ) : (
                    <>
                      <Typography
                        variant="bodyText"
                        sx={{
                          display: "inline-block",
                          textAlign: "left",
                          verticalAlign: "center",
                        }}
                      >
                        {deviceName}
                      </Typography>
                      <IconButton
                        onClick={() => {
                          setEditName(true);
                        }}
                      >
                        <EditIcon
                          style={{ display: "inline-block" }}
                        ></EditIcon>
                      </IconButton>
                    </>
                  )}
                </div>
                <Typography
                  variant="subText"
                  ref={noDeviceNameInputErrorRef}
                  sx={{
                    fontSize: "12px",
                    display: "none",
                    paddingLeft: "5px",
                    color: theme.palette.red.main,
                  }}
                >
                  Please enter a device name.
                </Typography>
              </div>
            </>
          )}
          <Typography
            variant="h5"
            color={theme.palette.h2.main}
            style={{ alignSelf: "center" }}
          >
            Device ID
          </Typography>
          <Typography
            variant="bodyText"
            sx={{ textAlign: "left", alignSelf: "center" }}
          >
            {deviceId}
          </Typography>
          <Typography
            variant="h5"
            color={theme.palette.h2.main}
            style={{ alignSelf: "center" }}
          >
            Network Status
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              variant="bodyText"
              sx={{
                display: "inline-block",
                textAlign: "left",
                verticalAlign: "center",
              }}
            >
              {props.add ||
              (context.sensorObjects[deviceId] &&
                context.sensorObjects[deviceId].online)
                ? "Online"
                : "Offline"}
            </Typography>
            <Link
              style={{ marginLeft: "10px" }}
              onClick={() => {
                setStep(0);
              }}
            >
              Reset Network
            </Link>
          </div>
        </div>
        {!props.add && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1.5vh",
                backgroundColor: "#F8F8F8",
                paddingLeft: "10px",
                paddingRight: "10px",
                opacity: changeLocationGrayScale ? 0.5 : 1,
              }}
            >
              <h3
                style={{
                  marginTop: "0.5vh",
                  marginBottom: "0.5vh",
                  fontWeight: 700,
                }}
              >
                Device Location
              </h3>
            </div>
            <div
              style={{
                height: "44px",
                display: "grid",
                gridTemplateColumns: "13% auto",
                columnGap: "20px",
                rowGap: "15px",
                marginBottom: "15px",
                alignItems: "center",
                paddingLeft: "10px",
                opacity: changeLocationGrayScale ? 0.5 : 1,
              }}
            >
              <Typography variant="h5" color={theme.palette.h2.main}>
                Map
              </Typography>
              <Dropdown
                style={{ paddingRight: "20px", width: "250px" }}
                width="auto"
                selection={
                  selectMapDropdownOption == "Unassigned"
                    ? "Select Map"
                    : selectMapDropdownOption
                }
                setSelection={(option) => {
                  if (deviceNameRef.current) {
                    setDeviceName(
                      deviceNameRef.current?.querySelector("input").value
                    );
                  }
                  setSelectMapDropdownOption(option);
                }}
                placeholder={""}
                value="add-device-map"
                init={
                  selectMapDropdownOption == "Unassigned"
                    ? "Select Map"
                    : selectMapDropdownOption
                }
                options={floorOptions}
              />
            </div>
            <div
              style={{
                width: "100%",
                height: "35vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                /* Border - Light */
                border: "1px solid #E0E2E5",
                /* Section Basic Shadow */
                boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
                borderRadius: "10px",
              }}
            >
              {(!props.add && selectMapDropdownOption === "") ||
              selectMapDropdownOption === "Select Map" ||
              selectMapDropdownOption === "Unassigned" ? (
                <Typography variant="h5" color={theme.palette.h2.main}>
                  After selecting a map, the map will be displayed here.
                </Typography>
              ) : (
                <MapWithAllSensors
                  setChangeLocationGrayScale={setChangeLocationGrayScale}
                  initPosition={
                    props.initPosition
                      ? props.initPosition
                      : {
                          x_coordinate: -50.0,
                          y_coordinate: 50.0,
                        }
                  }
                  ref={changedMarkerRef}
                  id="add-sensor-on-map-pop"
                  setFinalPosition={(position) => {
                    if (deviceNameRef.current) {
                      setDeviceName(
                        deviceNameRef.current?.querySelector("input").value
                      );
                    }
                    setFinalPosition(position);
                  }}
                  map={context.layerNameToMapUrl[selectMapDropdownOption]}
                  mapFloor={context.layerNameToMapUrl[selectMapDropdownOption]}
                  changeDeviceLocation={deviceId}
                />
              )}
            </div>
          </>
        )}
      </div>,
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1.5vh",
            backgroundColor: "#F8F8F8",
            paddingLeft: "1.08vh",
            paddingRight: "1.08vh",
          }}
        >
          <h3
            style={{
              marginTop: "0.5vh",
              marginBottom: "0.5vh",
              fontWeight: 700,
            }}
          >
            Device Location
          </h3>
        </div>
        <div
          style={{
            height: "44px",
            display: "grid",
            gridTemplateColumns: "13% auto",
            columnGap: "20px",
            rowGap: "15px",
            marginBottom: "15px",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" color={theme.palette.h2.main}>
            Map
          </Typography>
          <Dropdown
            style={{ paddingRight: "20px", width: "250px" }}
            width="auto"
            selection={selectMapDropdownOption}
            setSelection={(option) => {
              if (deviceNameRef.current) {
                setDeviceName(
                  deviceNameRef.current?.querySelector("input").value
                );
              }
              setSelectMapDropdownOption(option);
            }}
            placeholder={""}
            value="add-device-map"
            init={selectMapDropdownOption}
            options={floorOptions}
          />
        </div>
        <div
          style={{
            width: "100%",
            height: "35vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            /* Border - Light */
            border: "1px solid #E0E2E5",
            /* Section Basic Shadow */
            boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
            borderRadius: "10px",
          }}
        >
          {(!props.add && selectMapDropdownOption === "") ||
          selectMapDropdownOption === "Select Map" ? (
            <Typography variant="h5" color={theme.palette.h2.main}>
              After selecting a map, the map will be displayed here.
            </Typography>
          ) : (
            <MapWithAllSensors
              setChangeLocationGrayScale={setChangeLocationGrayScale}
              initPosition={
                props.initPosition
                  ? props.initPosition
                  : {
                      x_coordinate: -50.0,
                      y_coordinate: 50.0,
                    }
              }
              ref={changedMarkerRef}
              id="add-sensor-on-map-pop"
              setFinalPosition={(position) => {
                if (deviceNameRef.current) {
                  setDeviceName(
                    deviceNameRef.current?.querySelector("input").value
                  );
                }
                setFinalPosition(position);
              }}
              map={context.layerNameToMapUrl[selectMapDropdownOption]}
              mapFloor={context.layerNameToMapUrl[selectMapDropdownOption]}
              changeDeviceLocation={deviceId}
            />
          )}
        </div>
      </>,
    ];
  }

  let action = [
    timeoutPage ? (
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            setOpen(false);
            handleClean();
          }}
          style={{ marginRight: "10px" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setTimeoutPage(false);
            setParticleDevice(null);
          }}
        >
          Reconnect
        </Button>
      </div>
    ) : (
      <Button
        variant="contained"
        disabled={loading}
        onClick={() => {
          handelBLEConnect();
        }}
      >
        {loading ? (
          <>
            <CircularProgress
              style={{ height: "20px", marginRight: "5px", width: "20px" }}
            />
            <>Loading</>
          </>
        ) : (
          "Next"
        )}
      </Button>
    ),
    timeoutPage ? (
      <div style={{ display: "flex" }}>
        <Button
          variant="outlined"
          onClick={() => {
            setOpen(false);
            setTimeoutPage(false);
            setParticleDevice(null);
            handleClean();
          }}
          style={{ marginRight: "10px" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setTimeoutPage(false);
            setParticleDevice(null);
            setStep(0);
          }}
        >
          Retry
        </Button>
      </div>
    ) : (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="text"
          onClick={() => {
            setHiddenSSID(true);
          }}
          style={{ textTransform: "none" }}
        >
          Other Wi-Fi Network
        </Button>
        <div style={{ display: "flex" }}>
          <Button
            variant="outlined"
            onClick={() => {
              setCloseConfirmModalOpen(true);
            }}
            style={{ textTransform: "none", marginRight: "10px" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={loading || selectedSSID == "" || !selectedSSID}
            onClick={() => {
              handleSubmitWifiCred();
            }}
          >
            {loading ? (
              <>
                <CircularProgress
                  style={{ height: "20px", marginRight: "5px", width: "20px" }}
                />
                <>Loading</>
              </>
            ) : (
              "Next"
            )}
          </Button>
        </div>
      </div>
    ),
    props.add ? (
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        {/* <Button variant="outlined">Add Another</Button> */}
        <Button
          variant="contained"
          onClick={() => {
            if (!owned) {
              deviceNameRef.current.style.marginTop = "0px";
              noDeviceNameInputErrorRef.current.style.display = "hidden";
              let newDeviceName =
                deviceNameRef.current?.querySelector("input").value;
              if (!newDeviceName || newDeviceName === "") {
                deviceNameRef.current.style.marginTop = "20px";
                noDeviceNameInputErrorRef.current.style.display = "block";
                return;
              }
              setDeviceName(newDeviceName);
              setStep(3);
            } else {
              handleAddSensor();
            }
          }}
        >
          {owned ? "Done" : "Next"}
        </Button>
      </div>
    ) : (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <DeleteSensorDialog
          open={delDiaOpen}
          sensorsName={[
            deviceNameRef.current
              ? deviceNameRef.current.querySelector("input").value
              : deviceName,
          ]}
          sensors={[deviceId]}
          setOpen={(state) => {
            let input;
            if (deviceNameRef.current) {
              input = deviceNameRef.current.querySelector("input").value;
            }
            setDelDiaOpen(state);
            if (deviceNameRef.current) {
              deviceNameRef.current.querySelector("input").value = input;
            }
          }}
          deleteFunc={handleDelete}
        />

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            columnGap: "10px",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              setOpen(false);
              handleClean();
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" disabled={loading} onClick={handleUpdate}>
            Update
          </Button>
        </div>
      </div>
    ),
    <Button
      variant="contained"
      disabled={loading}
      onClick={() => {
        handleAddSensor();
      }}
    >
      {owned ? (
        "Done"
      ) : loading ? (
        <>
          <CircularProgress
            style={{ height: "20px", marginRight: "5px", width: "20px" }}
          />
          <>Loading</>
        </>
      ) : selectMapDropdownOption === "" ||
        selectMapDropdownOption === "Select Map" ? (
        "Add without Location"
      ) : (
        "Add"
      )}
    </Button>,
  ];

  return (
    <>
      <CloseConfirmModal
        open={closeConfirmModalOpen}
        setMainModalOpen={setOpen}
        onClose={() => {
          setCloseConfirmModalOpen(false);
        }}
        handleExit={() => {
          setCloseConfirmModalOpen(false);
          handleClean();
          setOpen(false);
        }}
        setModalOpen={setCloseConfirmModalOpen}
      />
      <OwnedModal
        open={ownedModalOpen}
        onClose={() => {
          setOwnedModalOpen(false);
        }}
        handleClean={handleClean}
        setMainModalOpen={setOpen}
        setStep={setStep}
        setModalOpen={setOwnedModalOpen}
      />
      <PopupModal
        disabled={props.disabled}
        iflink={props.iflink}
        modalOpen={open}
        setModalOpen={setOpen}
        onClose={() => {
          if (props.add) {
            setCloseConfirmModalOpen(true);
          } else {
            setOpen(false);
            handleClean();
          }
        }}
        closeConfirm
        buttonPrompt={props.buttonPrompt ? props.buttonPrompt : "Add Device"}
        title={
          <div style={{ opacity: changeLocationGrayScale ? 0.5 : 1 }}>
            {props.title
              ? props.title
              : props.add
              ? timeoutPage
                ? TIMEOUT_TITLE_LIST[step]
                : TITLE_LIST[step]
              : "Edit " + deviceName}
          </div>
        }
        width="69vh"
        content={renderContent()[step]}
        action={action[step]}
      />
      <Messager
        open={messageOpen}
        onClose={() => {
          setMessageOpen(false);
        }}
        message={message}
      />
    </>
  );
}

const CloseConfirmModal = (props) => {
  useEffect(() => {
    if (props.open) {
      props.setMainModalOpen(false);
    }
  }, [props.open]);

  let actionButton = (
    <>
      <Button
        variant="outlined"
        onClick={() => {
          props.setMainModalOpen(true);
          props.setModalOpen(false);
        }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          props.handleExit();
        }}
      >
        Exit
      </Button>
    </>
  );
  return (
    <PopupModal
      button={<></>}
      modalOpen={props.open}
      setModalOpen={props.setModalOpen}
      title={"Close Confirm"}
      width="69vh"
      content={
        <div style={{ textAlign: "left" }}>
          <Typography variant="bodyText">
            Are you sure you want to exit the sensor setting process? Any
            unsaved data will be lost.
          </Typography>
        </div>
      }
      action={actionButton}
    />
  );
};

const HiddenSSIDModal = React.forwardRef((props, ref) => {
  const {
    hiddenSSIDInputRef,
    hiddenSSIDInputNoNameErrorRef,
    hiddenWifiPassword,
    hiddenWifiNoPasswordErrorRef,
  } = ref;

  let actionButton = (
    <>
      <Button
        variant="outlined"
        onClick={() => {
          props.setModalOpen(false);
          props.setMainModalOpen(false);
          props.handleClean();
        }}
      >
        Back
      </Button>

      <Button
        disabled={props.loading}
        variant="contained"
        onClick={() => {
          props.handleNext();
        }}
      >
        {props.loading ? (
          <>
            <CircularProgress
              style={{ height: "20px", marginRight: "5px", width: "20px" }}
            />
            <>Loading</>
          </>
        ) : (
          "Next"
        )}
      </Button>
    </>
  );

  let content = (
    <div>
      <div
        style={{
          height: "44px",
          display: "grid",
          gridTemplateColumns: "19% auto",
          columnGap: "20px",
          rowGap: "15px",
          marginBottom: "10px",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" color={props.theme.palette.h2.main}>
          Wifi name
        </Typography>
        <InputLabelCustomized
          minify
          ref={hiddenSSIDInputRef}
          id="wifi-hidden-name"
          labelName="Wifi name"
          width="60%"
          placeholder="Enter Network Name"
          required
        />
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "19% auto",
          columnGap: "20px",
          rowGap: "15px",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <div></div>
        <Typography
          variant="subText"
          ref={hiddenSSIDInputNoNameErrorRef}
          sx={{
            fontSize: "12px",
            display: "none",
            paddingLeft: "5px",
            color: props.theme.palette.red.main,
          }}
        >
          Please enter a Network (WiFi) name.
        </Typography>
      </div>

      <div
        style={{
          height: "44px",
          display: "grid",
          gridTemplateColumns: "19% auto",
          columnGap: "20px",
          rowGap: "15px",
          marginBottom: "10px",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" color={props.theme.palette.h2.main}>
          Password
        </Typography>
        <InputLabelCustomized
          minify
          type={"password"}
          ref={hiddenWifiPassword}
          id="hidden-wifi-password"
          labelName="Wifi Password"
          width="60%"
          placeholder="Enter Password"
          required
        />
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "19% auto",
          columnGap: "20px",
          rowGap: "15px",
          alignItems: "center",
          marginBottom: "5px",
        }}
      >
        <div></div>
        <Typography
          variant="subText"
          ref={hiddenWifiNoPasswordErrorRef}
          sx={{
            fontSize: "12px",
            display: "none",
            paddingLeft: "5px",
            color: props.theme.palette.red.main,
          }}
        >
          Please enter a Network (WiFi) Password.
        </Typography>
      </div>
    </div>
  );
  console.log("hiddenSSDOpen", props.open);
  return (
    <PopupModal
      button={<></>}
      modalOpen={props.open}
      setModalOpen={props.setModalOpen}
      title={
        <>
          Step 2: Wi-Fi Connection (2/4){" "}
          <Tooltip
            style={{ display: "inline-block" }}
            title={
              <Box
                sx={{
                  "& p": { marginBottom: "0px" },
                  "& b": { fontWeight: 400 },
                }}
              >
                <p>
                  Tip: For hidden network, please type in your Wi-Fi credential.
                </p>
              </Box>
            }
            placement="right"
          >
            <InfoOutlinedIcon
              style={{ position: "relative", top: "5px", color: "#828282" }}
            />
          </Tooltip>
        </>
      }
      width="69vh"
      content={content}
      action={actionButton}
    />
  );
});

const OwnedModal = (props) => {
  let actionButton = (
    <>
      <Button
        variant="outlined"
        onClick={() => {
          props.setModalOpen(false);
          props.setMainModalOpen(false);
          props.handleClean();
        }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          props.setMainModalOpen(true);
          props.handleClean();
          props.onClose();
        }}
      >
        Connect Another Device
      </Button>
    </>
  );

  useEffect(() => {
    if (props.open) {
      props.setMainModalOpen(false);
    }
  }, [props.open]);
  return (
    <PopupModal
      button={<></>}
      modalOpen={props.open}
      setModalOpen={props.setModalOpen}
      title={"Device Already Linked"}
      width="69vh"
      content={
        <div style={{ textAlign: "left" }}>
          <Typography variant="bodyText">
            This device is currently connected to a different account. To link
            it to this account, please first disconnect it from the other
            account.
          </Typography>
        </div>
      }
      action={actionButton}
    />
  );
};
