import * as React from "react";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useAuthenticator } from "@aws-amplify/ui-react";

import { AppBar, Tab, Tabs, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import AeroButton from "../../../components/AeroButton";
import logo from "../../../util/landing/logo.png";

const useStyles = makeStyles({
  appBar: {
    height: "72px",
    background: "#FFFFFF !important",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.03)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  tabs: {
    background: "#FFFFFF",
    maxHeight: "26px !important",
    minHeight: "26px !important",
    "& .MuiTabs-flexContainer": {
      height: "100%",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#8C9EE0",
      height: "3px !important",
    },
    marginRight: "132px",
  },
  tab: {
    padding: "0 !important",
    margin: "0 15px !important",
    height: "100%",
    fontWeight: 400,
    fontFamily: "Inter !important",
    fontStyle: "normal !important",
    fontSize: "15px !important",
    lineHeight: "26px !important",
    color: "#333333 !important",
    display: "flex",
    justifyContent: "start !important",
    "&.Mui-selected": {
      fontWeight: 700,
    },
  },
  logo: {
    width: "181px",
    height: "36px",
    marginRight: "52px",
  },
  buttonGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& button": {
      marginRight: "12px",
    },
  },
});

const navIndex = [
  { text: "Home", id: "" },
  { text: "How It Works", id: "how" },
  { text: "Why Aerospec", id: "why" },
  { text: "Keep In Touch", id: "keep" },
];

export default function Navbar(props) {
  const classes = useStyles();
  const [selected, setSelected] = useState(0);
  const { pathname, hash, key } = useLocation();
  const history = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]);
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  return (
    <AppBar>
      <div className={classes.appBar}>
        <img className={classes.logo} alt="navbar logo" src={logo} />
        <Tabs value={selected} className={classes.tabs}>
          {navIndex.map((item, index, array) => {
            let lowerCaseString = item["text"]
              .toLowerCase()
              .replaceAll(" ", "-");
            return (
              <Tab
                className={classes.tab}
                onClick={() => {
                  setSelected(index);
                }}
                sx={{ textTransform: "none" }}
                key={lowerCaseString}
                id={lowerCaseString}
                label={item["text"]}
                value={index}
                component={Link}
                to={`#${item["id"].split("-")[0]}`}
              />
            );
          })}
        </Tabs>

        {user ? (
          <div className={classes.buttonGroup}>
            <AeroButton
              onClick={() => {
                history("/dashboard/home/");
              }}
              value={
                <Typography variant="button2">
                  Hello {user.attributes.email}
                </Typography>
              }
              variant={"contained"}
            ></AeroButton>
          </div>
        ) : (
          <div className={classes.buttonGroup}>
            <AeroButton
              onClick={() => {
                history("/dashboard/signIn/");
              }}
              value={<Typography variant="button2">Login</Typography>}
              variant={"outlined"}
            ></AeroButton>
            <AeroButton
              onClick={() => {
                history("/dashboard/signUp/");
              }}
              value={<Typography variant="button2">Sign Up</Typography>}
              variant={"contained"}
            ></AeroButton>
          </div>
        )}
      </div>
    </AppBar>
  );
}
