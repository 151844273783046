import React, { useContext, useEffect, useState } from "react";

import { FormatListBulleted } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

import {
  deleteMap,
  updateContextAfterDeleteMap,
} from "../../../components/AccessDBGlobalFunctions";
import MapWithAllSensors from "../../../components/MapWithAllSensors";
import Messager from "../../../components/Messager";
import UploadMapPopup from "../../../components/UploadMapPopup";
import { UserContext } from "../../../components/UserContext";
import CollapsibleMapList from "../Components/CollapsibleMapList";
import DeleteMapDialog from "../Components/DeleteMapDialog";

const PaperCustomizedDashed = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  paddingTop: 0,
  paddingBottom: 0,
  backgroundColor: "#FFFFFF",
  height: "100%",
  width: "100%",
  border: "1px solid #E0E2E5",
  boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
  borderRadius: "10px",
  marginBottom: "1px",
  display: "flex",
  alignitems: "center",
  justifyContent: "center",
  "& .MuiPaper-root": {
    backgroundColor: "none",
  },
}));

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: `auto`,
      width: `100%`,
    },
    mainView: {
      display: "flex",
      justifyContent: "space-between",
    },
    mapContainer: {
      width: "66.5vw",
      height: "74vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0",
    },
    deviceList: {
      width: "26vw",
      minWidth: "378px",
      height: "74vh",
    },
  })
);

const MapView = (props) => {
  const context = useContext(UserContext);
  const theme = useTheme();
  const {
    curLocation,
    curStatus,
    curLabel,
    filteredPlaceRows,
    setFilteredPlaceRows,
    searchText,
    setLocation,
  } = props;
  const [selectedDevice, setSelectedDevice] = useState("");
  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState(<></>);
  const [imageUploaded, setImageUploaded] = useState(false);
  const isIpadScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const classes = useStyles();

  function showMessage(message) {
    setMessageOpen(true);
    setMessage(message);
  }

  const deleteMapFun = (floor) => {
    deleteMap(floor, setLocation, context);
  };

  return (
    <div className={classes.root}>
      <Grid container className={classes.mainView}>
        <Grid
          item
          xs={12}
          lg={8}
          height="74vh"
          paddingBottom={{ xs: "20px", lg: "20px" }}
        >
          <PaperCustomizedDashed>
            {curLocation === "All Location" ||
            curLocation === "Unassigned" ||
            !curLocation ? (
              <UploadMapPopup
                setLocation={setLocation}
                showMessage={showMessage}
                buttonText={"Upload Map"}
                setImageUploaded={setImageUploaded}
                imageUploaded={imageUploaded}
              />
            ) : context.loading ? (
              <Paper
                style={{
                  border: "none",
                  boxShadow: "none",
                  display: "flex",
                  justifycontent: "center",
                  alignitems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <CircularProgress style={{ marginRight: "10px" }} />
                <Typography variant="h3" color={theme.palette.h2.main}>
                  Loading ...
                </Typography>
              </Paper>
            ) : (
              <Grid
                container
                id="hereami"
                direction="column"
                height="100%"
                justifyContent="center"
              >
                {isIpadScreen ? (
                  <Grid item>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        height: "fit-content",
                        paddingLeft: "20px",
                      }}
                    >
                      <Typography
                        variant="h3"
                        color={theme.palette.dark.main}
                        style={{ marginRight: "16px" }}
                      >
                        {curLocation}
                      </Typography>
                      <div style={{ marginRight: "5px" }}>
                        <UploadMapPopup
                          iflink
                          showMessage={showMessage}
                          update
                          buttonText="Edit Map"
                          defaultValue={curLocation}
                          setCurrentMapName={props.setLocation}
                        />
                      </div>
                      <Typography
                        variant="link"
                        style={{ marginRight: "5px", color: "#8977E4" }}
                      >
                        {" "}
                        |{" "}
                      </Typography>
                      <DeleteMapDialog
                        iflink
                        setLocation={setLocation}
                        showMessage={showMessage}
                        floorName={curLocation}
                        deleteMap={deleteMapFun}
                      />
                    </div>
                  </Grid>
                ) : (
                  <div />
                )}
                <Grid
                  style={{ display: "block" }}
                  item
                  xs
                  height="80%"
                  maxWidth="100%"
                  maxHeight="80%"
                >
                  <MapWithAllSensors
                    mgt
                    markerSize={30}
                    selectedDevice={selectedDevice}
                    setSelectedDevice={setSelectedDevice}
                    id="imageField"
                    map={context.layerNameToMapUrl[curLocation]}
                    mapFloor={curLocation}
                    className={classes.uploadedImage}
                    style={{
                      height: "100%",
                      maxHeight: "calc(100% - 80px)",
                      marginTop: "20px",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  height="20%"
                  width="100%"
                  maxWidth="100%"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft: "40px",
                    paddingRight: "40px",
                    alignItems: "center",
                    height: "fit-content",
                  }}
                >
                  {isIpadScreen ? (
                    <div />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        height: "fit-content",
                      }}
                    >
                      <Typography
                        variant="h3"
                        color={theme.palette.dark.main}
                        style={{ marginRight: "16px" }}
                      >
                        {curLocation}
                      </Typography>
                      <div style={{ marginRight: "5px" }}>
                        <UploadMapPopup
                          iflink
                          showMessage={showMessage}
                          update
                          buttonText="Edit Map"
                          defaultValue={curLocation}
                          setCurrentMapName={props.setLocation}
                        />
                      </div>
                      <Typography
                        variant="link"
                        style={{ marginRight: "5px", color: "#8977E4" }}
                      >
                        {" "}
                        |{" "}
                      </Typography>
                      <DeleteMapDialog
                        iflink
                        setLocation={setLocation}
                        showMessage={showMessage}
                        floorName={curLocation}
                        deleteMap={deleteMapFun}
                      />
                    </div>
                  )}

                  <div style={{ display: "flex", height: "fit-content" }}>
                    {selectedDevice === "" ? (
                      <>
                        <div
                          style={{
                            height: "25px",
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "36px",
                          }}
                        >
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="7"
                              cy="7"
                              r="7"
                              fill={theme.palette.green.main}
                              opacity={0.5}
                            />
                            <circle
                              cx="7"
                              cy="7"
                              r="5"
                              fill={theme.palette.green.main}
                            />
                          </svg>
                          <Typography
                            style={{
                              marginLeft: "8px",
                              color: theme.palette.subText.main,
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "12px",
                              lineHeight: "16px",
                            }}
                          >
                            Auto
                          </Typography>
                        </div>
                        <div
                          style={{
                            height: "25px",
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "36px",
                          }}
                        >
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="7"
                              cy="7"
                              r="7"
                              fill={theme.palette.blue.main}
                              opacity={0.5}
                            />
                            <circle
                              cx="7"
                              cy="7"
                              r="5"
                              fill={theme.palette.blue.main}
                            />
                          </svg>
                          <Typography
                            style={{
                              marginLeft: "8px",
                              color: theme.palette.subText.main,
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "12px",
                              lineHeight: "16px",
                            }}
                          >
                            Manual
                          </Typography>
                        </div>
                        <div
                          style={{
                            height: "25px",
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "36px",
                          }}
                        >
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="7"
                              cy="7"
                              r="7"
                              fill={theme.palette.offline.main}
                              opacity={0.5}
                            />
                            <circle
                              cx="7"
                              cy="7"
                              r="5"
                              fill={theme.palette.offline.main}
                            />
                          </svg>
                          <Typography
                            style={{
                              marginLeft: "8px",
                              color: theme.palette.subText.main,
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "12px",
                              lineHeight: "16px",
                            }}
                          >
                            Offline
                          </Typography>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            height: "25px",
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "36px",
                          }}
                        >
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="7"
                              cy="7"
                              r="7"
                              fill={theme.palette.yellow.main}
                              opacity={0.5}
                            />
                            <circle
                              cx="7"
                              cy="7"
                              r="5"
                              fill={theme.palette.yellow.main}
                            />
                          </svg>
                          <Typography
                            style={{
                              marginLeft: "8px",
                              color: theme.palette.subText.main,
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "12px",
                              lineHeight: "16px",
                            }}
                          >
                            Selected
                          </Typography>
                        </div>
                        <div
                          style={{
                            height: "25px",
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "36px",
                          }}
                        >
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="7"
                              cy="7"
                              r="7"
                              fill={theme.palette.lightGrey.main}
                              opacity={0.5}
                            />
                            <circle
                              cx="7"
                              cy="7"
                              r="5"
                              fill={theme.palette.lightGrey.main}
                            />
                          </svg>
                          <Typography
                            style={{
                              marginLeft: "8px",
                              color: theme.palette.subText.main,
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "12px",
                              lineHeight: "16px",
                            }}
                          >
                            Unselected
                          </Typography>
                        </div>
                      </>
                    )}
                  </div>
                </Grid>
              </Grid>
            )}
          </PaperCustomizedDashed>
        </Grid>

        <Grid item xs={12} lg={4} paddingLeft={{ lg: "20px" }}>
          <div style={{ width: "100%", height: "100%" }}>
            <CollapsibleMapList
              setLocation={setLocation}
              selectedMap={curLocation}
              selectedStatus={curStatus}
              selectedLabel={curLabel}
              rows={filteredPlaceRows}
              setRows={setFilteredPlaceRows}
              showMessage={showMessage}
              message={message}
              selectedDevice={selectedDevice}
              setSelectedDevice={setSelectedDevice}
            />
          </div>
        </Grid>
        <Messager
          open={messageOpen}
          onClose={() => {
            setMessageOpen(false);
          }}
          message={message}
        />
      </Grid>
    </div>
  );
};
export default MapView;
