import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { FormatColorResetOutlined } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Link as MuiLink,
  Paper,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/system";

import { TableHeaderCell } from "semantic-ui-react";

import AlertItemCardStatus from "../../../components/AlertItemCardStatus";
import Dropdown from "../../../components/Dropdown";
import EditSensorsPopup from "../../../components/EditSensorsPopup";
import GetStatusNameAndColor from "../../../components/GetStatusNameAndColor";
import MapWithAllSensors from "../../../components/MapWithAllSensors";
import Messager from "../../../components/Messager";
import SensorDetailsPopup from "../../../components/SensorDetailsPopup";
import { DeviceContext, UserContext } from "../../../components/UserContext";
import AreaAverageGraph from "../components/AreaAverageGraph";
import {
  FloorAggregateGraphDataProvider,
  FloorGraphDataProvider,
} from "../components/FloorGraphDataProvider";

const useStyles = makeStyles((theme) => {
  return {
    viewContainer: {
      height: "100%",
    },
    headerContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    section: {
      padding: "20px",
      height: "100%",
      boxSizing: "border-box",
    },
    selectorContainer: {
      display: "flex",
    },
    selector: {
      marginLeft: "20px",
      marginBottom: "20px",
    },
    iconBtn: {
      color: "black",
    },
    leftSecDeviceInfoContainer: {
      backgroundColor: "red",
      padding: "1.93vh",
    },
    leftSecDeviceInfoLine: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    leftRightContainer: {
      padding: "0px",
      boxSizing: "border-box",
      height: "100%",
      width: "100%",
    },
  };
});

const DeviceView = (props) => {
  const classes = useStyles();
  const navigation = useNavigate();
  const theme = useTheme();
  const params = useParams();
  const [deviceSelection, setDeviceSelection] = useState(
    "Device Without Map or Location"
  );
  const [deviceGraphData1, setDeviceGraphData1] = useState(null);
  const [deviceGraphData2, setDeviceGraphData2] = useState(null);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [mapInfo, setMapInfo] = useState(null);
  const [locationSelection, setLocationSelection] = useState(null);
  const [rightTabIndex, setRightTabIndex] = useState(0);
  const [alertStatusLabel, setAlertStatusLabel] = useState(null);
  const userContext = useContext(UserContext);
  const deviceContext = useContext(DeviceContext).raw;
  const aggContext = useContext(DeviceContext).agg;
  const [selectedParamId1, setSelectedParamId1] = useState(0);
  const [selectedParamId2, setSelectedParamId2] = useState(1);
  const [paramSelection1, setParamSelection1] = useState("PM 2.5");
  const [paramSelection2, setParamSelection2] = useState("PM 2.5");
  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState(<></>);

  // get map info and device info from to endpoints, get device info within mapinfo of the device
  useEffect(() => {
    const getDeviceFloorLocation = (deviceId, mapInfo) => {
      if (deviceId && mapInfo) {
        for (let i = 0; i < Object.keys(mapInfo).length; i++) {
          const mapName = Object.keys(mapInfo)[i];
          const layerSensorArr = mapInfo[mapName];
          for (let j = 0; j < layerSensorArr.length; j++) {
            if (layerSensorArr[j] === deviceId) {
              return Object.keys(mapInfo)[i];
            }
          }
        }
      }
      return null;
    };

    if (
      userContext &&
      Object.keys(userContext.sensorObjects).length > 0 &&
      Object.keys(userContext.sensorMapInfoOverview).length > 0 &&
      params.deviceId &&
      deviceContext &&
      deviceContext[params.deviceId] &&
      Object.keys(deviceContext).length > 0
    ) {
      const deviceInfo = userContext.sensorObjects;
      const mapsInfo = userContext.sensorMapInfoOverview;
      setDeviceInfo(deviceInfo);
      setMapInfo(mapsInfo);
      setDeviceSelection(deviceInfo[params.deviceId]["sensor_name"]);
      setLocationSelection(
        getDeviceFloorLocation(params.deviceId, userContext.layerToSensors)
      );
      // TODO: change param
      let currentData = "N/A";
      if (deviceContext[params.deviceId].length !== 0) {
        currentData =
          deviceContext[params.deviceId][
            deviceContext[params.deviceId].length - 1
          ]["dp03"];
      }
      const statusNameColor = GetStatusNameAndColor(
        userContext.threshold[userContext.paramIds[selectedParamId1]],
        userContext.thresholdName,
        userContext.thresholdColor,
        currentData
      );
      setAlertStatusLabel(
        <AlertItemCardStatus
          status={statusNameColor.statusName}
          color={statusNameColor.statusColor}
        />
      );
    }
  }, [userContext, deviceContext, params.deviceId, selectedParamId1]);

  useEffect(() => {
    if (mapInfo) {
      let floorGraphDataInput3 = {};
      floorGraphDataInput3[params.deviceId] = deviceContext[params.deviceId];
      let graphData1 = FloorAggregateGraphDataProvider(
        aggContext,
        locationSelection,
        userContext.paramIds[selectedParamId1],
        params.deviceId
      );
      let graphData2 = FloorAggregateGraphDataProvider(
        aggContext,
        locationSelection,
        userContext.paramIds[selectedParamId2],
        params.deviceId
      );
      setDeviceGraphData1(graphData1);
      setDeviceGraphData2(graphData2);
      if (deviceSelection) {
        for (let i = 0; i < mapInfo.length; i++) {
          for (let j = 0; j < mapInfo[i].sensors.length; j++) {
            if (
              (deviceSelection === mapInfo[i].sensors[j]["device_name"]) &
              (params.deviceId !== mapInfo[i].sensors[j]["device_id"])
            ) {
              navigation(
                `/dashboard/data-analytics/device-detail/${mapInfo[i].sensors[j]["device_id"]}`
              );
            }
          }
        }
      }
    }
  }, [mapInfo, deviceSelection, userContext, paramSelection1, paramSelection2]);

  // when select a location, default to first device
  useEffect(() => {
    if ((mapInfo !== null) & (locationSelection !== null)) {
      let deviceList = getDeviceSelections(locationSelection, mapInfo);
      setDeviceSelection(deviceList[0]);
    }
  }, [locationSelection]);

  // when made a device selection, navigate to cooresponding link

  // get device options with choosen location
  const getDeviceSelections = (locationSelection, mapInfo) => {
    if (!locationSelection) return ["Please Select a Location"];
    for (let i = 0; i < mapInfo.length; i++) {
      if (mapInfo[i]["map_name"] === locationSelection) {
        let deviceList = mapInfo[i].sensors.map(
          (deviceInfo) => deviceInfo["device_name"]
        );
        return deviceList;
      }
    }
    return [];
  };

  function showMessage(message) {
    setMessageOpen(true);
    setMessage(message);
  }

  return (
    <Grid container className={classes.viewContainer} direction="column">
      {/* <Grid item>
        <Button
          className={classes.iconBtn}
          startIcon={<ArrowBackIos />}
          onClick={() => {
            if ((locationSelection !== "null") & (locationSelection !== null)) {
              navigation(
                `/dashboard/data-analytics/floor-detail/${locationSelection}`
              );
            } else {
              navigation(`/dashboard/data-analytics`);
            }
          }}
        >
          Back
        </Button>
      </Grid> */}
      <Grid
        item
        style={{ marginBottom: "20px" }}
        className={classes.headerContainer}
      >
        <Typography variant="h2" color="#858D97">
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigation(`/dashboard/data-analytics`);
            }}
          >
            Locations
          </span>{" "}
          {">"}{" "}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigation(
                `/dashboard/data-analytics/floor-detail/${locationSelection}`
              );
            }}
          >
            {locationSelection ? locationSelection : "unknown location"}
          </span>{" "}
          {">"}{" "}
          {deviceInfo && params.deviceId
            ? deviceInfo[params.deviceId]["sensor_name"]
            : "unknown sensor name"}
        </Typography>
        <Box>
          <Box className={classes.selectorContainer}>
            <Box className={classes.selector}>
              {/* <Dropdown
                setSelection={setLocationSelection}
                placeholder="Location"
                value="da-dv-location"
                options={getLocationOptions()}
              /> */}
            </Box>
            {/* <Box className={classes.selector}>
              <Dropdown
                selection={deviceSelection}
                setSelection={setDeviceSelection}
                placeholder="Device"
                value="da-dv-device"
                options={
                  mapInfo
                    ? getDeviceSelections(locationSelection, mapInfo)
                    : ["data loading error"]
                }
              />
            </Box>
            <Box className={classes.selector}>
              <Dropdown
                setSelection={setDateSelection}
                placeholder="06/07/20-06/08/20"
                value="da-dv-date-range"
                options={["06/07/20-06/08/20"]}
              />
            </Box> */}
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        id="hereami"
        container
        height={{
          xs: "calc((100vh - 239px) * 2 + 20px)",
          lg: "calc(100vh - 239px)",
        }}
        minHeight="730px"
      >
        <Grid
          item
          component={Paper}
          width={{ xs: "100%", lg: "33.33%" }}
          marginRight={{ xs: "0px", lg: "20px" }}
          height={{ xs: "50%", lg: "100%" }}
          minHeight="680px"
          marginBottom={{ xs: "20px", lg: "0px" }}
        >
          <Grid item container padding="20px" height="100%">
            {!userContext.loading ? (
              <Grid item container direction="column" height="100%">
                <Grid
                  item
                  marginBottom="15px"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Typography variant="h3">Air Quality</Typography>
                  {/* {alertStatusLabel} */}
                </Grid>
                <Divider style={{ marginBottom: "15px" }} />
                <Grid
                  item
                  id="hereami"
                  style={{
                    height: "calc(100% - 265px)",
                    marginBottom: "15px",
                  }}
                  container
                  direction="column"
                >
                  <Grid
                    item
                    display="flex"
                    marginBottom="20px"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Typography variant="h3">Device Info</Typography>
                    {/* <Link to="/dashboard/management/list-view">
                      <Typography
                        variant="link"
                        color={theme.palette.link.main}
                      >
                        Edit Device
                      </Typography>
                    </Link> */}
                    <Messager
                      open={messageOpen}
                      onClose={() => {
                        setMessageOpen(false);
                      }}
                      message={message}
                    />
                    {deviceInfo ? (
                      <EditSensorsPopup
                        key={`edit-da-device`}
                        iflink={true}
                        deviceId={params.deviceId}
                        deviceName={deviceInfo[params.deviceId]["sensor_name"]}
                        location={locationSelection}
                        initPosition={
                          userContext.sensorObjects[params.deviceId]
                            ? userContext.sensorObjects[params.deviceId]
                                .indoor_position
                            : null
                        }
                        buttonPrompt={"Edit Device"}
                        disabled={false}
                        showMessage={showMessage}
                      />
                    ) : (
                      <div></div>
                    )}
                  </Grid>
                  <Grid
                    item
                    width="100%"
                    height="fit-content"
                    style={{
                      backgroundColor: "#FAFAFB",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <Table
                      sx={{
                        "& th": {
                          textAlign: "left",
                          width: "60%",
                          ...theme.typography.h4,
                        },
                        "& td": {
                          width: "40%",
                          borderBottom: "0px",
                          padding: "0px",
                          color: "#697077",
                        },
                      }}
                    >
                      <TableRow>
                        <TableHeaderCell style={{ paddingBottom: "16px" }}>
                          Device Name
                        </TableHeaderCell>{" "}
                        <TableCell style={{ paddingBottom: "16px" }}>
                          {deviceInfo
                            ? deviceInfo[params.deviceId]["sensor_name"]
                            : "unknown device name"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableHeaderCell>Location</TableHeaderCell>{" "}
                        <TableCell>
                          {locationSelection
                            ? locationSelection
                            : "unknown device Location"}
                        </TableCell>
                      </TableRow>
                    </Table>
                  </Grid>
                  <Grid
                    item
                    height="calc(100% - 163px)"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {mapInfo && locationSelection ? (
                      <MapWithAllSensors
                        singleDevice
                        deviceId={params.deviceId ? params.deviceId : ""}
                        id="da-dv-map"
                        mapFloor={locationSelection}
                        map={mapInfo[locationSelection]["background_name"]}
                        style={{
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    ) : (
                      <div> Map Loading Error</div>
                    )}
                  </Grid>
                </Grid>
                <Divider style={{ marginBottom: "15px" }} />
                <Grid item style={{ height: "fit-content" }}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                    }}
                  >
                    <Typography variant="h3">Device Status</Typography>

                    {deviceInfo ? (
                      <SensorDetailsPopup
                        setLocation={setLocationSelection}
                        buttonPrompt={"Remote Control"}
                        location={locationSelection}
                        deviceId={params.deviceId}
                        floor={locationSelection}
                        deviceName={deviceInfo[params.deviceId]["sensor_name"]}
                        paramSelection={
                          userContext.paramNames[selectedParamId1]
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </Box>
                  <Box
                    style={{
                      height: "fit-content",
                      backgroundColor: "#FAFAFB",
                      padding: "20px",
                      justifyContent: "center",
                    }}
                  >
                    <Table
                      sx={{
                        "& th": {
                          textAlign: "left",
                          width: "60%",
                          ...theme.typography.h4,
                          paddingBottom: "16px",
                        },
                        "& td": {
                          width: "40%",
                          borderBottom: "0px",
                          padding: "0px",
                          paddingBottom: "16px",
                          color: "#697077",
                        },
                      }}
                    >
                      <TableRow>
                        <TableHeaderCell>Device Lock</TableHeaderCell>
                        <TableCell>
                          {" "}
                          {deviceInfo &&
                          deviceInfo[params.deviceId] &&
                          deviceInfo[params.deviceId]["lock"]
                            ? "ON"
                            : "OFF"}{" "}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableHeaderCell>Auto Mode</TableHeaderCell>
                        <TableCell>
                          {" "}
                          {deviceInfo &&
                          deviceInfo[params.deviceId] &&
                          deviceInfo[params.deviceId]["auto"]
                            ? "ON"
                            : "OFF"}{" "}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableHeaderCell style={{ padding: "0px" }}>
                          Fan Speed
                        </TableHeaderCell>
                        <TableCell style={{ padding: "0px" }}>
                          {" "}
                          {deviceInfo &&
                          deviceInfo[params.deviceId] &&
                          deviceInfo[params.deviceId]["speed"]
                            ? deviceInfo[params.deviceId]["speed"]
                            : "-"}{" "}
                        </TableCell>
                      </TableRow>
                      {/* <TableRow>
                        <TableHeaderCell>Filter Condition</TableHeaderCell>
                        <TableCell> Good </TableCell>
                      </TableRow> */}
                    </Table>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress style={{ marginBottom: "10px" }} />
                <Typography variant="h3" color={theme.palette.h2.main}>
                  Loading ...
                </Typography>
              </div>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          container
          width={{ xs: "100%", lg: "calc(100% - 20px - 33.33%)" }}
          component={Paper}
          height={{ xs: "calc(50% - 20px)", lg: "100%" }}
          minHeight="680px"
        >
          <Grid item style={{ padding: "20px", width: "100%", height: "100%" }}>
            {!userContext.loading ? (
              <div style={{ height: "100%", width: "100%" }}>
                <Typography variant="h3" color={theme.palette.textPrimary.main}>
                  Historical Data
                </Typography>
                <Grid container style={{ height: "95%", width: "100%" }}>
                  {deviceGraphData1 && userContext.paramNames ? (
                    <Grid
                      item
                      container
                      height="100%"
                      direction="column"
                      width="100%"
                    >
                      <Grid
                        item
                        container
                        height="50%"
                        direction="column"
                        width="100%"
                      >
                        <Grid
                          item
                          style={{ display: "flex", justifyContent: "end" }}
                          width="100%"
                        >
                          <Dropdown
                            dropdownPurpose={"Data Type"}
                            style={{ width: "142px" }}
                            selection={paramSelection1}
                            setSelection={setParamSelection1}
                            setSelectionId={setSelectedParamId1}
                            placeholder="Parameter"
                            value="da-dv-params1"
                            tooltips={
                              userContext.paramNamesTooltips
                                ? userContext.paramNamesTooltips
                                : [""]
                            }
                            options={
                              userContext.paramNames
                                ? userContext.paramNames
                                : [""]
                            }
                          />
                        </Grid>
                        <Grid item height="70%" width="100%">
                          <AreaAverageGraph
                            nullState={deviceGraphData1.data.length == 0}
                            eightHour
                            hideLegend
                            deviceView
                            paramSelection={
                              userContext.paramIds[selectedParamId2]
                            }
                            paramSelectionName={
                              userContext.paramNames[selectedParamId1]
                            }
                            labels={deviceGraphData1.label}
                            data={deviceGraphData1.data}
                            time={deviceGraphData1.time}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container height="50%" direction="column">
                        <Grid
                          item
                          style={{ display: "flex", justifyContent: "end" }}
                          width="100%"
                        >
                          <Dropdown
                            dropdownPurpose={"Data Type"}
                            style={{ width: "142px" }}
                            selection={paramSelection2}
                            setSelection={setParamSelection2}
                            setSelectionId={setSelectedParamId2}
                            placeholder="Parameter"
                            value="da-dv-params1"
                            tooltips={
                              userContext.paramNamesTooltips
                                ? userContext.paramNamesTooltips
                                : [""]
                            }
                            options={
                              userContext.paramNames
                                ? userContext.paramNames
                                : [""]
                            }
                          />
                        </Grid>
                        <Grid item height="70%" width="100%">
                          <AreaAverageGraph
                            nullState={deviceGraphData1.data.length == 0}
                            eightHour
                            hideLegend
                            deviceView
                            paramSelection={
                              userContext.paramIds[selectedParamId2]
                            }
                            paramSelectionName={
                              userContext.paramNames[selectedParamId2]
                            }
                            labels={deviceGraphData2.label}
                            data={deviceGraphData2.data}
                            time={deviceGraphData2.time}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <div>Graph Data Process Error</div>
                  )}
                </Grid>
              </div>
            ) : (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress style={{ marginBottom: "10px" }} />
                <Typography variant="h3" color={theme.palette.h2.main}>
                  Loading ...
                </Typography>
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default DeviceView;
