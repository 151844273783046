import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Card,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import AlertItemCardStatus from "../../../components/AlertItemCardStatus";
import Dropdown from "../../../components/Dropdown";
import GetStatusNameAndColor from "../../../components/GetStatusNameAndColor";
import { DeviceContext, UserContext } from "../../../components/UserContext";
import AllDeviceMapZoomLegend from "../components/AllDeviceMapZoomLegend";
import AreaAverageGraph from "../components/AreaAverageGraph";
import DeviceHistoryGraph from "../components/DeviceHistoryGraph";
import DeviceListModal from "../components/DeviceListModal";
import {
  FloorAggregateGraphDataProvider,
  FloorDeviceHourlyGraphProvider,
  FloorGraphDataProvider,
} from "../components/FloorGraphDataProvider";

const useStyles = makeStyles({
  cardContainer: {
    height: "calc(100vh - 169px)",
    width: "100%",
    minHeight: "730px",
    paddingBottom: "20px",
  },
  headerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  mapHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px",
  },
  selectorContainer: {
    display: "flex",
  },
  selector: {
    marginLeft: "20px",
    marginBottom: "20px",
  },
  iconBtn: {
    color: "black",
  },
  sections: {
    padding: "20px",
    width: "100%",
    height: "100%",
  },
});

const FloorView = (props) => {
  const params = useParams();
  const theme = useTheme();
  const navigation = useNavigate();
  let [modalOpen, setModalOpen] = useState(false);
  const [paramSelection, setParamSelection] = useState("PM 2.5");
  const [mapsInfo, setMapsInfo] = useState(null);
  const [floorData, setFloorData] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const [areaAverageGraphData, setAreaAverageGraphData] = useState(null);
  const [selectedParamId, setSelectedParamIds] = useState(0);
  const [statusNameColor, setStatusNameColor] = useState(null);
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const deviceContext = useContext(DeviceContext).raw;
  const aggContext = useContext(DeviceContext).agg;
  // useEffect(() => {
  //   let floorData = mapsInfo[params["floorId"]];
  //   let graphData = FloorGraphDataProvider(floorData, paramSelection);
  //   setMapsInfo(mapsInfo);
  //   setFloorData(floorData);
  //   setGraphData(graphData);
  // }, [paramSelection]);

  useEffect(() => {
    if (userContext && graphData && graphData.data && graphData.data[0]) {
      const paramId =
        userContext.paramIds[userContext.paramNames.indexOf(paramSelection)];
      if (userContext.threshold[paramId]) {
        const currentParamRecordStatusColor = GetStatusNameAndColor(
          userContext.threshold[paramId],
          userContext.thresholdName,
          userContext.thresholdColor,
          graphData.data[0][0],
          paramSelection.toLowerCase().includes("pm"),
          theme
        );
        setStatusNameColor(currentParamRecordStatusColor);
      }
    }
  }, [paramSelection, graphData, userContext]);

  useEffect(() => {
    if (
      userContext &&
      Object.keys(userContext).length > 0 &&
      userContext.sensorMapInfoOverview &&
      deviceContext &&
      Object.keys(deviceContext).length > 0
    ) {
      const mapsInfo = userContext.sensorMapInfoOverview;
      let floorData = mapsInfo[params.floorId];
      let graphData = FloorGraphDataProvider(
        floorData,
        userContext.paramIds[selectedParamId],
        deviceContext
      );
      let areaAvgData = FloorAggregateGraphDataProvider(
        aggContext,
        params.floorId,
        userContext.paramIds[
          userContext.paramNames.findIndex(
            (element) => element === paramSelection
          )
        ]
      );

      let FloorDeviceHourlyAVGData = FloorDeviceHourlyGraphProvider(
        aggContext,
        params.floorId,
        userContext.paramIds[
          userContext.paramNames.findIndex(
            (element) => element === paramSelection
          )
        ]
      );
      let deviceNameLabelArr = [];
      for (let i = 0; i < FloorDeviceHourlyAVGData.label.length; i++) {
        if (userContext.sensorObjects) {
          deviceNameLabelArr.push(
            userContext.sensorObjects[FloorDeviceHourlyAVGData.label[i]][
              "sensor_name"
            ]
          );
        }
      }
      FloorDeviceHourlyAVGData.label = deviceNameLabelArr;
      setAreaAverageGraphData(areaAvgData);
      setMapsInfo(mapsInfo);
      setFloorData(floorData);
      setGraphData(FloorDeviceHourlyAVGData);
    }
  }, [userContext, deviceContext, params.floorId, paramSelection]);

  const ifGraphNullState = !(
    graphData &&
    userContext &&
    Object.keys(userContext).length > 0 &&
    graphData.data &&
    graphData.data.length > 0 &&
    areaAverageGraphData
  );
  return (
    <Grid className={classes.cardContainer} height={{ xs: "fit-content" }}>
      <Grid className={classes.headerContainer} marginBottom={{ xs: "10px" }}>
        {floorData ? (
          <Typography variant="h2" color="#858D97">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigation(`/dashboard/data-analytics/overview/area-average`);
              }}
            >
              Locations {">"}{" "}
            </span>{" "}
            {floorData["map_name"]}
          </Typography>
        ) : (
          <Typography variant="h2" color="#858D97">
            Locations {">"} Loading ...
          </Typography>
        )}
        <Grid className={classes.selectorContainer}>
          <Grid className={classes.selector}>
            <Dropdown
              dropdownPurpose={"Data Type"}
              selection={paramSelection}
              setSelection={setParamSelection}
              setSelectionId={setSelectedParamIds}
              placeholder="Parameter"
              value="da-fv-params"
              tooltips={
                userContext.paramNamesTooltips
                  ? userContext.paramNamesTooltips
                  : [""]
              }
              options={userContext.paramNames ? userContext.paramNames : [""]}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid height="calc(100% - 60px)">
        <Grid container spacing={2} height="100%" width="100%" margin="0px">
          <Grid
            item
            container
            width={{ xs: "100%", lg: "33.33%" }}
            height="100%"
            minHeight="730px"
            style={{ padding: "0px" }}
            marginRight={{ xs: "0px", lg: "20px" }}
          >
            <Card className={classes.sections}>
              {userContext.loading ? (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress
                    style={{ marginRight: "10px", marginBottom: "10px" }}
                  />
                  <Typography variant="h3" color={theme.palette.h2.main}>
                    Loading ...
                  </Typography>
                </div>
              ) : (
                <Grid
                  item
                  container
                  direction="column"
                  sx={{ height: "100%", width: "100%" }}
                >
                  <Grid item sx={{ height: "10%", width: "100%" }}>
                    <Grid className={classes.mapHeader}>
                      <Typography variant="h3">Map</Typography>
                      {mapsInfo ? (
                        <div>
                          <DeviceListModal
                            iflink
                            modalOpen={modalOpen}
                            setModalOpen={setModalOpen}
                            mapsInfo={mapsInfo}
                          />
                        </div>
                      ) : (
                        <div />
                      )}
                    </Grid>
                  </Grid>
                  <Grid item sx={{ width: "100%" }} height={{ xs: "90%" }}>
                    {mapsInfo ? (
                      <AllDeviceMapZoomLegend
                        id={"da-fv-" + params.floorId}
                        mapName={mapsInfo[params.floorId]["map_name"]}
                        map={mapsInfo[params.floorId]["background_name"]}
                        paramSelection={paramSelection}
                      />
                    ) : (
                      <div></div>
                    )}
                  </Grid>
                </Grid>
              )}
            </Card>
          </Grid>
          <Grid
            item
            width={{ xs: "100%", lg: "calc(100% - 33.33% - 20px)" }}
            height="100%"
            minHeight="730px"
            style={{ padding: "0px" }}
            sx={{ [theme.breakpoints.down("lg")]: { marginTop: "20px" } }}
          >
            <Card className={classes.sections}>
              {userContext.loading ? (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress style={{ marginRight: "10px" }} />
                  <Typography variant="h3" color={theme.palette.h2.main}>
                    Loading ...
                  </Typography>
                </div>
              ) : (
                <div style={{ height: "100%" }}>
                  <Typography variant="h4" style={{ marginBottom: "10px" }}>
                    {floorData ? floorData["map_name"] : "Loading ..."} All
                    Device Average
                  </Typography>
                  {statusNameColor && statusNameColor.status ? (
                    <Grid display="flex" alignItems="center">
                      <Typography
                        variant="h4"
                        color="#697077"
                        style={{ marginRight: "10px" }}
                      >
                        Real-time:
                      </Typography>
                      <Typography
                        variant="h4"
                        color="#b482d5"
                        style={{ marginRight: "5px" }}
                      >
                        {statusNameColor.status.toFixed(0)}
                      </Typography>
                      {paramSelection.toLowerCase().includes("pm") && (
                        <AlertItemCardStatus
                          status={statusNameColor.statusName}
                          color={statusNameColor.statusColor}
                        />
                      )}
                    </Grid>
                  ) : (
                    <div></div>
                  )}

                  <Grid
                    item
                    container
                    display="flex"
                    flexDirection="column"
                    height="calc(100% - 30px)"
                  >
                    <Grid item height="48%" width="100%" marginBottom="10px">
                      <AreaAverageGraph
                        nullState={ifGraphNullState}
                        eightHour
                        hideLegend
                        deviceView
                        paramSelection={userContext.paramIds[selectedParamId]}
                        paramSelectionName={
                          userContext.paramNames[selectedParamId]
                        }
                        labels={
                          ifGraphNullState ? [] : areaAverageGraphData.label
                        }
                        data={ifGraphNullState ? [] : areaAverageGraphData.data}
                        time={ifGraphNullState ? [] : areaAverageGraphData.time}
                      />
                    </Grid>
                    <Divider maxHeight="1px" />
                    <Grid
                      item
                      height="calc(100% - 48% - 22px)"
                      width="100%"
                      marginTop="10px"
                    >
                      <DeviceHistoryGraph
                        nullState={ifGraphNullState}
                        floorId={params.floorId}
                        paramSelection={userContext.paramIds[selectedParamId]}
                        paramSelectionName={
                          userContext.paramNames[selectedParamId]
                        }
                        labels={ifGraphNullState ? [] : graphData.label}
                        data={ifGraphNullState ? [] : graphData.data}
                        time={ifGraphNullState ? [] : graphData.time}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default FloorView;
