let allLabels = new Set();

export function parseTableData(sensorMapInfoOverview, deviceContext) {
  const result = [];
  let floors = Object.keys(sensorMapInfoOverview);
  for (var i = 0; i < floors.length; i++) {
    const all_sensors_in_this_map = sensorMapInfoOverview[floors[i]].sensors;
    const location = floors[i];
    if (all_sensors_in_this_map) {
      for (var j = 0; j < all_sensors_in_this_map.length; j++) {
        const line_result = {};
        let deviceId = Object.keys(all_sensors_in_this_map[j])[0];
        line_result.id = deviceId;
        let deviceName = all_sensors_in_this_map[j][deviceId]["sensor_name"];
        line_result.deviceName = deviceName;

        let labels = all_sensors_in_this_map[j][deviceId]["labels"];
        for (let k = 0; k < labels.length; k++) {
          allLabels.add(labels[k]);
        }

        line_result.labels = labels;

        line_result.location = location;
        let connection = "Off";

        if (all_sensors_in_this_map[j][deviceId]["online"]) {
          connection = "On";
        }
        line_result.connection = connection;

        // const device_info = all_sensors_in_this_map[j]
        // let battery = 0;
        // let data = JSON.parse(localStorage.getItem(line_result.id))
        // if (data !== null) {
        //     data = data.results;
        //     let compareDate = new Date("2020-06-07T15:00:00");
        //     let dates = Object.keys(data)
        //     for (let j = 0; j < dates.length; j++) {
        //         let date = new Date(dates[j].replace(" ", "T"));
        //         if (date > compareDate) {
        //         battery = Math.round(parseFloat(data[dates[j]][0]["battery"]));
        //         break;
        //         }
        //     }
        // }
        // let battery = 0
        // if (deviceContext[deviceId]) {
        //     battery = deviceContext[deviceId][deviceContext[deviceId].length - 1]["battery"]
        // }
        let battery = "N/A";
        line_result.battery = battery;
        line_result.operationTime = "N/A"; //----
        line_result.speed = all_sensors_in_this_map[j][deviceId]["speed"];
        line_result.auto = all_sensors_in_this_map[j][deviceId]["auto"];
        line_result.lock = all_sensors_in_this_map[j][deviceId]["lock"];
        line_result.more = {
          id: deviceId,
          product: all_sensors_in_this_map[j][deviceId]["particle_product_id"],
          location: location,
          connection: connection,
          labels: labels,
          battery: battery,
          deviceName: deviceName,
          indoorPosition:
            all_sensors_in_this_map[j][deviceId]["indoor_position"],
        };
        result.push(line_result);
      }
    }
  }
  return result;
}

export function getAllMaps() {
  const query_id_result = JSON.parse(localStorage.getItem("user301@uw.edu"));
  const maps_and_sensors = query_id_result.results;
  const result = new Set([]);
  for (var i = 0; i < maps_and_sensors.length; i++) {
    const map_with_sensors = maps_and_sensors[i];
    const location = map_with_sensors.map_name;
    if (location !== "null") {
      result.add(location);
    }
  }
  return Array.from(result);
}

export function getAllLabels(sensorMapInfoOverview, deviceContext) {
  if (allLabels.size === 0) {
    parseTableData(sensorMapInfoOverview, deviceContext);
  }
  return Array.from(allLabels);
}

export function parseRows(
  curLocation,
  curStatus,
  curLabel,
  sensorMapInfoOverview,
  deviceContext
) {
  const rows = parseTableData(sensorMapInfoOverview, deviceContext);
  const placed = [];
  const unassigned = [];
  for (var i = 0; i < rows.length; i++) {
    const r = rows[i];
    if (r.location === "Unassigned") {
      unassigned.push(r);
    }
    if (
      (r.location === curLocation || curLocation === "All Location") &&
      (r.connection === curStatus || curStatus === "all status") &&
      (r.labels.indexOf(curLabel) > -1 || curLabel === "all labels")
    ) {
      placed.push(r);
    }
  }
  return [placed, unassigned];
}
