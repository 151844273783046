import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CompareSharp } from "@mui/icons-material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, Link, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";

import AlertItemCardStatus from "../../../components/AlertItemCardStatus";
import Dropdown from "../../../components/Dropdown";
import GetStatusNameAndColor from "../../../components/GetStatusNameAndColor";
import PopupModal from "../../../components/PopupModal";
import { DeviceContext, UserContext } from "../../../components/UserContext";
import {
  FloorAggregateGraphDataProvider,
  FloorGraphDataProvider,
} from "../components/FloorGraphDataProvider";

const useStyles = makeStyles({
  realStatusDetail: {
    display: "flex",
    alignItems: "center",
  },
  table: {
    width: "100%",
  },
  selectorContainer: {
    display: "flex",
  },
  dataGrid: {
    fontSize: "16px",
    border: "none",
    "& .MuiDataGrid-cell": {
      borderBottom: "0",
      borderRight: "0",
      padding: "0px",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      padding: "0px",
    },
    "& .MuiDataGrid-columnHeader": {
      borderRight: "none",
      padding: "0px",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      border: "none",
      padding: "0",
    },
    "& .MuiDataGrid-columnHeaderWrapper": {
      width: "100%",
    },
    "& .MuiDataGrid-cell--withRenderer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  hideRightSeparator: {
    padding: "0px",
    "& > .MuiDataGrid-columnSeparator": {
      visibility: "hidden",
      padding: "0",
    },
  },
});

const DeviceListModal = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  let [modalOpen, setModalOpen] = useState(false);
  const [paramSelection, setParamSelection] = useState("PM 2.5");
  const [rows, setRows] = useState([]);
  const navigation = useNavigate();
  const params = useParams();
  const [thresholdArr, setThresholdArr] = useState(null);
  const [thresholdLabel, setThresholdLabel] = useState(null);
  const [thresholdColor, setThresholdColor] = useState(null);
  const userContext = useContext(UserContext);
  const deviceContext = useContext(DeviceContext).raw;
  const [selectionId, setSelectionId] = useState(0);
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isIpadOrSmaller = useMediaQuery((theme) =>
    theme.breakpoints.down("md")
  );
  useEffect(() => {
    if (
      userContext &&
      Object.keys(userContext).length > 0 &&
      deviceContext &&
      Object.keys(deviceContext).length > 0
    ) {
      // console.log(
      //   "userContext.paramNames.findIndex(paramSelection)",
      //   paramSelection
      // );
      const paramIds =
        userContext.paramIds[userContext.paramNames.indexOf(paramSelection)];
      setThresholdArr(userContext.threshold[paramIds]);
      setThresholdLabel(userContext.thresholdName);
      setThresholdColor(userContext.thresholdColor);
    }
  }, [userContext, deviceContext, paramSelection]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: classes.hideRightSeparator,
      hide: true,
      sortable: false,
    },
    {
      field: "name",
      headerName: (
        <Typography variant="h5" color="#697077">
          Name
        </Typography>
      ),
      headerClassName: classes.hideRightSeparator,
      flex: isMobileScreen ? 0.2 : 1,
      minWidth: 0,
      renderCell: (cellValues) => {
        return <div style={{ fontWeight: "bold" }}>{cellValues.row.name}</div>;
      },
      sortable: false,
    },
    {
      field: "connection",
      minWidth: 0,
      headerName: (
        <Typography variant="h5" color="#697077">
          Connection
        </Typography>
      ),
      headerClassName: classes.hideRightSeparator,
      sortable: false,
      flex: isMobileScreen ? 0.2 : 1,
      renderCell: (cellValues) => {
        let color = theme.palette.red.main;
        if (cellValues.value === "Online") {
          color = theme.palette.green.main;
        }
        return (
          <div
            style={{
              color: color,
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: "detailsLink",
      headerName: "",
      minWidth: 0,
      headerClassName: classes.hideRightSeparator,
      sortable: false,
      flex: isMobileScreen ? 0.1 : 0.3,
      renderCell: (cellValue) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {isIpadOrSmaller ? (
              <MoreHorizIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigation(
                    `/dashboard/data-analytics/device-detail/${cellValue.id}`
                  );
                }}
              />
            ) : (
              <Link
                onClick={() => {
                  navigation(
                    `/dashboard/data-analytics/device-detail/${cellValue.id}`
                  );
                }}
              >
                {" "}
                Details{" "}
              </Link>
            )}
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    let rows = [];
    if (
      params.floorId !== undefined &&
      userContext &&
      Object.keys(userContext).length > 0 &&
      deviceContext &&
      Object.keys(deviceContext).length > 0
    ) {
      const sensorList = props.mapsInfo[params.floorId].sensors;
      // const floorData = FloorGraphDataProvider(
      //   props.mapsInfo[params.floorId],
      //   userContext.paramIds[selectionId],
      //   deviceContext
      // );
      for (let i = 0; i < sensorList.length; i++) {
        const deviceId = Object.keys(sensorList[i])[0];
        const paramId =
          userContext.paramIds[userContext.paramNames.indexOf(paramSelection)];

        let latestData = "N/A";
        // TODO: move const data out of the if to optimize the code style
        // this is really really awful. my bad XD.
        if (
          userContext &&
          userContext.sensorObjects[deviceId] &&
          userContext.sensorObjects[deviceId][paramId]
        ) {
          latestData = userContext.sensorObjects[deviceId][paramId];
        } else if (
          deviceContext &&
          deviceContext[deviceId] &&
          deviceContext[deviceId].length > 0 &&
          deviceContext[deviceId][deviceContext[deviceId].length - 1][
            paramId
          ] !== null &&
          deviceContext[deviceId][deviceContext[deviceId].length - 1][
            paramId
          ] !== undefined
        ) {
          const data = deviceContext[deviceId];
          latestData = data[data.length - 1][paramId];
        }
        rows.push({
          id: deviceId,
          name: sensorList[i][deviceId]["sensor_name"],
          location: "floor 1",
          connection: sensorList[i][deviceId]["online"] ? "Online" : "Offline",
          realTimeStatus: latestData,
          labelOn: paramSelection.toLowerCase().includes("pm"),
        });
      }
    }
    setRows(rows);
  }, [paramSelection, userContext, deviceContext]);

  function findLatest(data) {
    let i = 0;
    while (!data[i] || i == data.length) {
      i++;
    }
    return data[i];
  }

  let dataGrid = (
    <div style={{ height: "50vh" }}>
      <Box className={classes.selectorContainer}>
        {/* <Box
          sx={{
            height: "fit-content",
            marginBottom: "20px",
            marginTop: "5px",
            [theme.breakpoints.down("sm")]: {
              marginBottom: "10px",
            },
          }}
        >
          <Dropdown
            selection={paramSelection}
            setSelection={setParamSelection}
            setSelectionId={setSelectionId}
            dropdownPurpose={"Data Type"}
            placeholder="Parameter"
            value="da-fv-dlm"
            options={userContext.paramNames ? userContext.paramNames : []}
            tooltips={
              userContext.paramNamesTooltips
                ? userContext.paramNamesTooltips
                : []
            }
          />
        </Box> */}
      </Box>
      <div style={{ height: "100%" }} className={classes.table}>
        {thresholdArr && thresholdColor && thresholdLabel ? (
          <DataGrid
            disableColumnMenu
            withBorder={false}
            className={classes.dataGrid}
            headerHeight={isMobileScreen ? 30 : 56}
            disableExtendRowFullWidth
            showCellRightBorder={false}
            showColumnRightBorder={false}
            rows={rows}
            columns={columns}
            pageSize={3}
            rowsPerPageOptions={[3]}
            hideFooterPagination
            disableSelectionOnClick
          />
        ) : (
          <div> Data Error</div>
        )}
      </div>
    </div>
  );
  return (
    <PopupModal
      disableScrollLock={true}
      height="500px"
      width="700px"
      iflink={props.iflink}
      buttonPrompt={
        "Device List(" + props.mapsInfo[params.floorId].sensors.length + ")"
      }
      title={"Devices(" + props.mapsInfo[params.floorId].sensors.length + ")"}
      content={dataGrid}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
    />
  );
};
export default DeviceListModal;
