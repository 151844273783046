import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";

import Marker from "../features/home/components/Marker";
import { GlobalSettings } from "./GlobalSettings";
import { DeviceContext, UserContext } from "./UserContext";

/**
 * @param {string} props.id - give an id to the map
 * @param {string} props.mapFloor the map name or floor name to fetch sensor data with
 * @param {object} props.mapSizeStyle the size style object {width: 'xxx', height: 'xxx'}
 * @param {boolean} props.singleDevice if specified and true, only display the device with props.deviceId on map
 * @param {string} props.deviceId the id of device, only display this device when singleDevice is true
 * @param {string} props.changeDeviceLocation -
 *                 If changing the device location, pass in the particle_id.
 *                 Otherwise, don't pass in anything.
 */
const MapWithAllSensors = React.forwardRef((props, ref) => {
  const context = useContext(UserContext);
  const theme = useTheme();
  let history = useNavigate();
  const data = useContext(DeviceContext).raw;
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [offset, setOffset] = useState({});
  const [realType, setRealType] = useState(formatType(props.type));
  const [realTypeUnit, setRealTypeUnit] = useState();
  const globalSettings = useContext(GlobalSettings);
  const AQICategoriesDetail = globalSettings.AQICategories.details;
  const mapImageHtml = React.useRef(null);
  const { selectedDevice, setSelectedDevice, markerSize } = props;
  const [changeLocationMarkerSize, setChangeLocationMarkerSize] = useState(19);

  const handleResize = () => {
    let image = mapImageHtml.current;
    if (!!image && image.complete) {
      let naturalWidth = image.naturalWidth;
      let naturalHeight = image.naturalHeight;
      let naturalRatio = naturalWidth / naturalHeight;
      let clientWidth = image.clientWidth;
      let clientHeight = image.clientHeight;
      let clientRatio = clientWidth / clientHeight;
      if (naturalRatio < clientRatio) {
        let actualWidth = clientHeight * naturalRatio;
        setImageHeight(clientHeight);
        setImageWidth(actualWidth);
      } else {
        let actualHeight = clientWidth / naturalRatio;
        setImageHeight(actualHeight);
        setImageWidth(clientWidth);
      }
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [props.id, mapImageHtml]);

  useEffect(() => {
    let newType = formatType(props.type);
    let newTypeIndex = context.paramIds.indexOf(newType);
    setRealType(newType);
    setRealTypeUnit(context.paramUnits[newTypeIndex]);
  }, [props.type]);

  let sizeStyle = props.mapSizeStyle ? props.mapSizeStyle : {};

  const mapNotUploadHandle = (e) => {
    console.log("map not upload");
    e.target.src =
      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png";
    e.target.style.height = "400px";
  };

  const statusColor = (selectedDevice, deviceId, mgt) => {
    if (mgt) {
      if (selectedDevice) {
        if (selectedDevice === deviceId) {
          return theme.palette.yellow.main;
        } else {
          return "#CDCFD2";
        }
      }
      if (!context.sensorObjects[deviceId]["online"]) {
        return AQICategoriesDetail.offline.color;
      }
      let auto = context.sensorObjects[deviceId]["auto"];
      if (auto) {
        return theme.palette.green.main;
      } else {
        return theme.palette.blue.main;
      }
    }
    if (!context.sensorObjects[deviceId]["online"]) {
      return AQICategoriesDetail.offline.color;
    }
    if (!realType || !realType.toLowerCase().includes("pm")) {
      return theme.palette.primary.main;
    }
    if (!data[deviceId] || data[deviceId].length === 0) {
      return AQICategoriesDetail.good.color;
    }
    for (let key in AQICategoriesDetail) {
      if (AQICategoriesDetail[key].thresholds) {
        let lowerBound = AQICategoriesDetail[key].thresholds[0];
        let targetData = data[deviceId][data[deviceId].length - 1]["dp03"];
        if (props.type) {
          targetData = data[deviceId][data[deviceId].length - 1][realType];
          if (realType.includes("dp")) {
            targetData = targetData / 10; // TODO: temp using 1/10 of dp data with lowerbound calcultation. will improve later. For exmpale dp is 100 and pm is 10
          }
        }
        if (targetData >= lowerBound) {
          return AQICategoriesDetail[key].color;
        }
      }
    }
  };

  React.useEffect(() => {
    if (mapImageHtml.current) {
      mapImageHtml.current.addEventListener("error", mapNotUploadHandle);
      mapImageHtml.current.addEventListener("load", handleResize);
    }
  }, []);

  function formatType(rawType) {
    let result;
    if (!rawType) {
      return result;
    }
    if (
      rawType.toLowerCase().includes("env") ||
      rawType.toLowerCase().includes("std")
    ) {
      return rawType;
    }
    if (rawType.includes("PM")) {
      result =
        rawType.replaceAll(" ", "").replaceAll(".", "p").toLowerCase() + "Env";
    } else {
      result = rawType.replaceAll(" ", "").replaceAll(".", "").toLowerCase();
    }
    return result;
  }

  return (
    <div
      style={{
        ...sizeStyle,
        width: "100%",
        height: "100%",
        position: "relative",
        border: 0,
        ...props.style,
      }}
    >
      <img
        ref={mapImageHtml}
        alt={"map on " + props.mapFloor}
        id={"map-image-" + props.id}
        src={props.map}
        style={{
          objectFit: "contain",
          position: "absolute",
          width: "90%",
          height: "90%",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
      {/* Marker that needs to be set the position */}
      <div
        style={{
          position: "absolute",
          width: imageWidth,
          height: imageHeight,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          cursor: "pointer",
        }}
        onMouseEnter={() => {
          setChangeLocationMarkerSize(36);
          props.setChangeLocationGrayScale(true);
        }}
        onMouseLeave={() => {
          setChangeLocationMarkerSize(19);
          props.setChangeLocationGrayScale(false);
        }}
        onClick={() => {
          if (setSelectedDevice) {
            setSelectedDevice("");
          }
        }}
      >
        {props.changeDeviceLocation ? (
          <Marker
            ref={ref}
            disableTooltip
            imageHeight={imageHeight}
            imageWidth={imageWidth}
            mapId={"map-image-" + props.id}
            positionPct={{
              x_coordinate: props.initPosition
                ? props.initPosition["x_coordinate"]
                : -50,
              y_coordinate: props.initPosition
                ? props.initPosition["y_coordinate"]
                : 50,
            }}
            position={{
              x_coordinate: "" + imageWidth * -0.5,
              y_coordinate: "" + imageHeight * 0.5,
            }}
            setFinalPosition={props.setFinalPosition}
            size={changeLocationMarkerSize}
            color={"#29A5EB"}
            device={"change particle test"}
          />
        ) : null}
        {context.layerToSensors[props.mapFloor] &&
          context.layerToSensors[props.mapFloor].map((sensor, index) => {
            if (context.sensorObjects && context.sensorObjects[sensor]) {
              let indoorPosition =
                context.sensorObjects[sensor]["indoor_position"];
              return (
                <Marker
                  mapId={"map-image-" + props.id}
                  key={index}
                  disableDraggable
                  positionPct={{
                    x_coordinate: indoorPosition
                      ? indoorPosition["x_coordinate"]
                      : -50,
                    y_coordinate: indoorPosition
                      ? indoorPosition["y_coordinate"]
                      : 50,
                  }}
                  position={{
                    x_coordinate:
                      ((indoorPosition ? indoorPosition["x_coordinate"] : -50) *
                        imageWidth) /
                      100,
                    y_coordinate:
                      ((indoorPosition ? indoorPosition["y_coordinate"] : 50) *
                        imageHeight) /
                      100,
                  }}
                  size={markerSize ? markerSize : null}
                  color={statusColor(selectedDevice, sensor, props.mgt)}
                  realTimeData={
                    data &&
                    data[sensor] &&
                    data[sensor].length > 0 &&
                    props.type &&
                    realType
                      ? data[sensor][data[sensor].length - 1][realType] +
                        " " +
                        realTypeUnit
                      : null
                  }
                  tooltipData={
                    data && data[sensor] && data[sensor].length > 0
                      ? data[sensor][data[sensor].length - 1]
                      : null
                  }
                  paramSelection={realType}
                  device={context.sensorObjects[sensor].particle_id}
                  deviceName={context.sensorObjects[sensor]["sensor_name"]}
                  location={props.mapFloor}
                  onClick={(e) => {
                    if (props.isAnalyticFloorDetail) {
                      history(
                        `/dashboard/data-analytics/device-detail/${sensor}`
                      );
                    }
                    e.stopPropagation();
                    if (setSelectedDevice) {
                      setSelectedDevice(sensor);
                    }
                  }}
                />
              );
            } else {
              return <div />;
            }
          })}
      </div>
    </div>
  );
});

export default MapWithAllSensors;
