import * as React from "react";

import { Typography } from "@mui/material";

export default function AlertItemCardStatus(props) {
  let statusStyle = {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14px",
    color: props.color,
    whiteSpace: "pre-wrap",
  };

  return (
    <>
      <div
        style={{
          maxHeight: "30px",
          width: "70px",
          padding: "4px 5px 4px 5px",
          border: `solid 2px ${props.color}`,
          borderRadius: "8px",
          textAlign: "center",
        }}
      >
        <Typography style={statusStyle}>{props.status}</Typography>
      </div>
    </>
  );
}
