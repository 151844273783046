import React from "react";

import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import introImg from "../../../util/landing/intro.png";

const useStyle = makeStyles({
  h3: {
    marginBottom: 8,
  },
  intro: {
    width: "100%",
    overflow: "hidden",
    backgroundImage: "url(" + introImg + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  introContent: {
    marginTop: 112,
    marginBottom: 133,
    width: (580 / 1440) * 100 + "%",
    position: "relative",
    // bottom: 520 + 29,
    left: (800 / 1440) * 100 + "%",
    zIndex: 2,
  },
  body: {
    marginTop: 51,
    marginBottom: "24px",
  },
});

export default function Intro(props) {
  const classes = useStyle();
  const theme = useTheme();
  return (
    <div className={classes.intro}>
      <div className={classes.introContent}>
        <div>
          <Typography
            variant={"h3"}
            color={theme.palette.primary.main}
            className={classes.h3}
          >
            Introducing Aero
          </Typography>
          <Typography variant={"h1"} color={theme.palette.textPrimary2.main}>
            Our all-in-one
          </Typography>
          <Typography
            variant={"h1"}
            color={theme.palette.textPrimary2.main}
            display="inline"
          >
            sensor platform
          </Typography>
        </div>
        <p className={classes.body}>
          <Typography variant={"body"} color={theme.palette.textPrimary2.main}>
            Our sensor platform Aero can be agilely deployed across buildings
            and streaming environmental data including noise, humidity,
            temperature, pressure, CO2, PM and hazardous gas.
          </Typography>
        </p>
        <p>
          <Typography variant={"body"} color={theme.palette.textPrimary2.main}>
            With the help of Aero, we can keep you informed of your indoor
            environment and provide suggestions to help you improve it.
          </Typography>
        </p>
      </div>
    </div>
  );
}
