import { createTheme } from "@mui/material/styles";

export default createTheme({
  palette: {
    textPrimary: {
      main: "#2E3134",
    },
    textPrimary2: {
      main: "#333333",
    },
    textSecondary: {
      main: "#697077",
    },
    grayIcon: {
      main: "#899097",
    },
    subText: {
      main: "#989FA7",
    },
    darkBorder: {
      main: "#ABB0B5",
    },
    dropdown: {
      main: "#CDCFD2",
    },
    lightBorder: {
      main: "#E0E2E5",
    },
    selectedBg: {
      main: "#FAFAFB",
    },
    sectionBg: {
      main: "#FBFCFD",
    },
    h2: {
      main: "#858D97",
    },
    link: {
      main: "#2F80ED",
    },
    primary: {
      main: "#3F5DCB",
    },
    secondary: {
      main: "#3F5DCB",
    },
    number: {
      main: "#8B9CC5",
    },
    footer: {
      main: "#CFD6E6",
    },
    totalOffset: 0.2,
  },
  typography: {
    h1: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "50px",
      lineHeight: "60px",
      display: "flex",
      alignItems: "center",
      letterSpacing: "-0.01em",
    },
    h2: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "36px",
      lineHeight: "43px",
      display: "flex",
      alignItems: "center",
      letterSpacing: "-0.01em",
    },
    h3: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "29px",
      display: "flex",
      letterSpacing: "-0.01em",
    },
    body: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "26px",
    },
    button: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      textTransform: "none",
    },
    button2: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "20px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      letterSpacing: "-0.03em",
    },
    number: {
      fontFamily: "Encode Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "60px",
      lineHeight: "80px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
    },
    link: {
      //styleName: Body/Link Text;
      fontFamily: "Roboto",
      fontSize: "15px",
      fontWeight: 400,
      lineHeight: "26px",
      display: "flex",
      alignItems: "center",
    },
    subText: {
      fontFamily: "Encode Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
    },
  },
});
