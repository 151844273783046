import React from "react";
import { Route, Routes } from "react-router-dom";

import AccountSetting from "../views/AccountSetting";
// import MembersSetting from "../views/MembersSetting";
// import NotificationsSetting from "../views/NotificationsSetting";

function Setting() {
  return (

      <Routes>
        <Route path={``} element={<AccountSetting />} />
              {/* <Breadcrumb separator="|" aria-label="setting breadcrumbs">
        <Link to={``}>account</Link>
        <Link to={`members-setting`}>member</Link>
        <Link to={`notifications-setting`}>notification</Link>
      </Breadcrumb> */}
        {/* <Route path={`members-setting`} element={<MembersSetting />} />
        <Route
          path={`notifications-setting`}
          element={<NotificationsSetting />}
        /> */}
      </Routes>
  );
}

export default Setting;
