import React, { useContext, useEffect, useState } from "react";

import { Grid, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";

import { UserContext } from "../../../components/UserContext";
import AlertAndActionItemsCard from "../components/AlertAndActionItemsCard";
import PaperOverlay from "../components/PaperOverlay";

export default function AlertAndActionItems(props) {
  const theme = useTheme();
  const userContext = useContext(UserContext);
  const [actionItemCards, setActionItemCards] = useState([]);
  const PaperCustomized = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: "20px",
    elevation: 2,
    color: theme.palette.text.secondary,
    width: "100%",
    height: "100%",
    overflowY: "auto",
    minHeight: "219px",
    alignItems: "center",
    boxSizing: "borderBox",
    background: "#FCFCFC",
    /* Border - Light */
    border: "1px solid #E0E2E5",
    /* Section Basic Shadow */
    boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
    borderRadius: "10px",
  }));

  /**
   * TODO: REMOVE THIS
   * This function used on translate the index of notfication class in db
   * to format of frontend
   * @param {int} index
   * @returns {int} formatted index
   */
  function databaseClassificationToFrontendClassification(index) {
    // switch (index) {
    //   case 3:
    //     return 0;
    //   case 2:
    //     return 1;
    //   case 1:
    //     return 2;
    //   default:
    //     break;
    // }
    return index;
  }

  function generateLogText(log) {
    let name;
    if (log.Type === "pm2p5") {
      name = "PM2.5";
    } else if (log.Type === "pm10p0") {
      name = "PM10";
    } else {
      return "Unknown";
    }
    if (log["Class"] == 0) {
      return `Moderate: AQI for ${name} has risen above 50. Those with respiratory issues may experience discomfort.`;
    } else if (log["Class"] == 1) {
      return `Unhealthy: AQI for ${name} has risen above 100. Members of sensitive groups may experience health effects.`
    } else if (log["Class"] == 2) {
      return `Good: AQI for ${name} has fallen below 50. Air quality is satisfactory, and air pollution poses little or no risk.`
    } else {
      return "Unknown";
    }
  }

  useEffect(() => {
    if (
      userContext &&
      userContext.notificationLogData &&
      userContext.notificationLogData.results &&
      Object.keys(userContext.sensorObjects).length > 0
    ) {
      let cardList = [];
      userContext.notificationLogData.results.forEach((log) => {
        if (
          (userContext.notificationSetting === 3 ||
            (userContext.notificationSetting === 1 && log.Type === "pm2p5") ||
            (userContext.notificationSetting === 2 && log.Type === "pm10p0")) &&
          userContext.sensorObjects[log["Device_ID"]]
        ) {
          const logTime = new Date(log["Time"] + "+0000").toLocaleString();
          const deviceName =
            userContext.sensorObjects[log["Device_ID"]]["sensor_name"];
          console.log("card pushed");
          cardList.push(
            <AlertAndActionItemsCard
              deviceName={deviceName}
              logText={generateLogText(log)}
              logTime={logTime}
              color={
                userContext.thresholdColor[
                  databaseClassificationToFrontendClassification(
                    parseInt(log["Class"])
                  )
                ]
              }
              status={
                userContext.thresholdName[
                  databaseClassificationToFrontendClassification(
                    parseInt(log["Class"])
                  )
                ]
              }
            />
          );
        }
      });
      setActionItemCards(cardList);
    }
  }, [userContext]);

  // empty dashboard
  return (
    <Grid
      container
      item
      direction="column"
      alignItems="stretch"
      minHeight="50vh"
    >
      <Grid item paddingBottom="20px" height="fit-content">
        <Typography variant="h2" color={theme.palette.h2.main}>
          Notifications
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs
        sx={{ overflow: "auto", height: "100%", minHeight: "0px" }}
      >
        {userContext &&
        userContext.userEmail &&
        Object.keys(userContext.sensorObjects).length > 0 &&
        userContext.notificationLogData &&
        Object.keys(userContext.notificationLogData).length > 0 &&
        // userContext.notificationSetting &&
        userContext.notificationLogData.results.length > 0 ? (
          <PaperCustomized
          // style={{ overflowY: "scroll", height: "100%", display: "flex" }}
          >
            {/* <Grid
              container
              item
              xs={12}
              md={12}
              alignItems="flex-start"
              direction="column"
              rowSpacing={2}
              id="hereami"
            > */}
            {actionItemCards}
            {/* </Grid> */}
          </PaperCustomized>
        ) : (
          <PaperCustomized
            sx={{ position: "relative", height: "100%", minHeight: "0px" }}
          >
            <Grid
              container
              item
              xs={12}
              md={12}
              sx={{ height: "100%" }}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Typography
                  variant="subText"
                  color={theme.palette.headingText.main}
                >
                  No Alert Yet.
                </Typography>
              </Grid>
            </Grid>
            <PaperOverlay />
          </PaperCustomized>
        )}
      </Grid>
    </Grid>
  );
}
