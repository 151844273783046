import React from "react";

import { ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import landingTheme from "../../../components/Theme/landingTheme";
import Footer from "../components/Footer";
import NavBar from "../components/Navbar";
import Banner from "../views/Banner";
import Email from "../views/Email";
import How from "../views/How";
import Intro from "../views/Intro";
import Vision from "../views/Vision";
import Why from "../views/Why";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

const Landing = (props) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={landingTheme}>
      <div className={classes.root}>
        <NavBar />
        <Banner />
        <How />
        <Intro />
        <Why />
        <Vision />
        <Email />
        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default Landing;
